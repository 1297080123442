import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import Spinner from '../otoklix-elements/components/spinner/Spinner';
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from '../otoklix-elements';
import SelectField from '@components/field/SelectField';
import ProductItemsCard from '@components/card/ProductItemsCard';
import { FormFeedback, Input, InputGroup, InputGroupText } from 'reactstrap';
import CloseIcon from '@assets/icons/close.svg';
import InfoIcon from '@assets/icons/info.svg';
import Helper from '@helpers/Helper';
import API from '@utils/API';
import useResponsive from '@utils/useResponsive';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveCar } from '@reducers/Car';
import { getIsEdit, setIsEdit, setActiveItems, setSaveLoading, getSaveLoading } from '@reducers/Order';
import { assign, isEmpty, map } from 'lodash';
import { every } from 'lodash';

const SelectProductModal = (props) => {
  const { isOpen, toggle, item, onSave } = props;
  const { isDesktop } = useResponsive();
  const dispatch = useDispatch();
  const userCar = useSelector(getActiveCar);
  const isEdit = useSelector(getIsEdit);
  const saveLoading = useSelector(getSaveLoading);
  const api = new API('v2');
  const cookies = new Cookies();
  const authorizedWorkshopsSlug = cookies.get('authorized_workshops_slug');

  const [initValue, setInitValue] = useState({});
  const [loadingPackageOptions, setLoadingPackageOptions] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [disabledBecauseAddOns, setDisabledBecauseAddOns] = useState(false);

  const fetchPackageDetail = async (initialValue) => {
    setLoadingPackageOptions(true);
    await api
      .get(
        `v2/gms/item/${authorizedWorkshopsSlug}/${item?.package_id}/details?variant_car_id=${userCar?.car_details?.id}`
      )
      .then((res) => {
        const pd = map(res.data.data.package_details, (packageDetail, index) => {
          return {
            ...packageDetail,
            error: '',
            value: isEdit ? initValue?.packageDetail[index]?.value : {},
            isDisabled: packageDetail?.is_sellable,
          };
        });
        map(pd, (pckgDetail) => {
          const qwe = every(pckgDetail?.products, (products) => disabledOption(products));
          if (qwe) setDisabledBecauseAddOns(true);
        });

        return pd;
      })
      .then((pd) => {
        setInitValue({ ...initialValue, packageDetail: pd });
        setLoadingPackageOptions(false);
      })
      .catch((err) => {
        console.log('failed get package detail -> ', err);
        setLoadingPackageOptions(false);
      });
  };

  const handleChangeQty = (e, source) => {
    if (disableQtyBtn) return;
    let qty = initValue?.qty ?? 1;
    const isNotProduct = initValue?.item_type == 'service' || initValue?.item_type == 'package';
    const isPackage = initValue?.item_type == 'package';
    if (source == 'decrease') qty = Math.floor(qty) - 1;
    if (source == 'increase') qty = Math.floor(qty) + 1;
    if (source == 'input') {
      if (isPackage) {
        qty = 1;
      } else {
        const value = e.target.value <= 0 ? 0 : e.target.value;
        qty = initValue?.category?.slug == 'oli' ? value : Math.floor(value);
      }
    }

    let msg = '';
    if (Number(qty) > Number(initValue?.sellable_qty) && !isNotProduct) {
      msg = `stok tidak memumpuni (sisa stok ${
        initValue?.category?.slug == 'oli' ? `${initValue?.sellable_qty} Liter` : Math.floor(initValue?.sellable_qty)
      })`;
    } else {
      msg = '';
    }

    setInitValue({ ...initValue, qty: Number(qty), calculatedPrice: initValue?.price * Number(qty), error_qty: msg });
  };

  const handleChangePackageDetail = (value, index) => {
    let amount = 0;
    let newData = [...initValue?.packageDetail];
    let indexedData = { ...newData[index], qty: value?.qty };
    indexedData.value = {
      ...value,
      qty: +value?.qty,
      calculated_price: +value?.qty * +value?.price,
    };
    if (indexedData.error && !isEmpty(value)) {
      indexedData.error = '';
    }
    newData[index] = indexedData;
    map(newData, (item) => {
      if (!isEmpty(item?.value)) amount += item?.value?.calculated_price;
    });

    setInitValue({ ...initValue, packageDetail: newData, calculatedPrice: amount });
  };

  const handleChangePackageQty = (value, index) => {
    const newValue = Math.max(1, Math.min(100, Number(value)));

    let amount = 0;
    let newData = [...initValue?.packageDetail];
    let indexedData = { ...newData[index], qty: +newValue };

    const checkCalculatedPrice = newData[index]?.products.filter(
      (item) => item?.product_id === indexedData?.value?.product_id
    );

    indexedData.value = {
      ...indexedData?.value,
      qty: +newValue,
      calculated_price: +newValue * +checkCalculatedPrice[0]?.price,
    };

    newData[index] = indexedData;

    map(newData, (item) => {
      if (!isEmpty(item?.value)) amount += item?.value?.calculated_price;
    });

    setInitValue({ ...initValue, packageDetail: newData, calculatedPrice: amount });
  };

  const validatePackageDetail = () => {
    let status = true;
    let tempObj = initValue?.packageDetail;
    map(tempObj, (item) => {
      if (isEmpty(item?.value)) {
        item.error = `${item?.component_label} wajib diisi!`;
        status = false;
      }
    });
    setInitValue({ ...initValue, packageDetail: tempObj });
    return status;
  };

  const handleGuaranteedCheck = (value) => {
    setInitValue({ ...initValue, is_guaranteed: value });
  };

  const handleSave = () => {
    if (typeof onSave == 'function') {
      setLoadingSave(true);
      if (!validatePackageDetail()) {
        console.log('error');
        setLoadingSave(false);
        return;
      }
      dispatch(setSaveLoading(true));
      onSave(initValue);
    }
  };

  const generateQtyOnOpenModal = (obj) => {
    let quantity = 0;
    const hasCarOilQty = obj?.car_oil_qty > 0;
    const hasSellableQty = obj?.sellable_qty > 0;

    if (obj?.item_type == 'package') quantity = obj?.is_sellable && !isEdit ? 1 : item?.qty ?? 1;
    if (obj?.item_type == 'service') quantity = obj?.is_sallable && !isEdit ? 1 : item?.qty ?? 1;

    if (obj?.item_type == 'product') {
      if (obj?.category?.slug == 'oli' && !isEdit) {
        if (hasCarOilQty && hasSellableQty) {
          quantity = obj?.sellable_qty >= obj?.car_oil_qty ? obj?.car_oil_qty : obj?.sellable_qty;
        } else {
          quantity = obj?.qty;
        }
      } else {
        quantity = obj?.is_sellable && !isEdit ? 1 : item?.qty ?? 1;
      }
    }

    return quantity;
  };

  const handleOpenModal = () => {
    const qty = generateQtyOnOpenModal(item);
    let val = {
      ...item,
      calculatedPrice: item?.item_type == 'package' ? 0 : calculatePrice(item?.qty, item?.price),
    };
    if (item?.is_consignment) {
      assign(val, {
        is_guaranteed: item?.is_guaranteed ?? false,
      });
    }
    if (item?.item_type == 'package') {
      val.calculatedPrice = calculateTotalPrice(val?.packageDetail);
    }
    setInitValue(val);
    if (item?.item_type == 'package' && !isEdit) fetchPackageDetail(val);
  };

  const handleExitModal = () => {
    setInitValue({ ...initValue, qty: 1 });
    setLoadingSave(false);
    setDisabledBecauseAddOns(false);
    dispatch(setActiveItems({}));
    dispatch(setIsEdit(false));
    dispatch(setSaveLoading(false));
  };

  const calculateTotalPrice = (packageDetail) => {
    let amount = 0;
    map(packageDetail, (packageDetailItem) => {
      amount += packageDetailItem?.value?.calculated_price ?? 0;
    });
    return amount;
  };

  const calculatePrice = (qty = 1, price = 0) => qty * price;

  const disabledOption = (option) => {
    if (
      option.product_type === 'work'
        ? !option.is_sellable
        : !option.is_sellable && (option.sellable_qty.toString() === '0' || option.sellable_qty.toString() === '0.00')
    )
      return true;

    return false;
  };

  const disableQtyBtn = item?.item_type == 'package';
  const disableIncreaseBtn =
    disableQtyBtn || (Number(initValue?.qty) >= item?.sellable_qty && initValue?.item_type !== 'service');
  const disableDecreaseBtn = disableQtyBtn || Number(initValue?.qty) < 1;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      modalMobile={false}
      centered
      size="xl"
      onOpened={handleOpenModal}
      onClosed={handleExitModal}
    >
      <ModalHeader
        close={<img src={CloseIcon} className="cursor-pointer" alt="" width={16} height={16} onClick={toggle} />}
      >
        {item?.name}
      </ModalHeader>
      <ModalBody>
        <div className="package-info">
          <img src={InfoIcon} alt="" width={16} height={16} />
          <span>Atur jumlah paket dan beberapa pilihan tertentu</span>
        </div>

        <Row className={`mt-4${!isDesktop ? ' flex-column-reverse' : ''}`}>
          <Col lg={8} md={12} sm={12} className="overflow-auto">
            <FormGroup>
              <Label className="label-required fw-bold">Quantity</Label>
              <InputGroup>
                <InputGroupText
                  className={`input-group-text-left user-select-none${disableDecreaseBtn ? '' : ' cursor-pointer'}`}
                  onClick={() => !disableDecreaseBtn && handleChangeQty(null, 'decrease')}
                >
                  -
                </InputGroupText>
                <Input
                  type="number"
                  value={Number(initValue?.qty) ?? 1}
                  onChange={(e) => handleChangeQty(e, 'input')}
                  className="text-center"
                  placeholder="quantity"
                  disabled={disableQtyBtn}
                  invalid={!!initValue?.error_qty}
                />
                <InputGroupText
                  className={`input-group-text-right user-select-none${disableIncreaseBtn ? '' : ' cursor-pointer'}`}
                  onClick={() => !disableIncreaseBtn && handleChangeQty(null, 'increase')}
                >
                  +
                </InputGroupText>
                <FormFeedback>{initValue?.error_qty}</FormFeedback>
              </InputGroup>
            </FormGroup>

            {loadingPackageOptions ? (
              <div className="w-100 text-center">
                <Spinner />
              </div>
            ) : null}
            {initValue?.packageDetail?.length > 0
              ? map(initValue?.packageDetail, (packageDetail, index) => {
                  return (
                    <FormGroup key={index}>
                      <Label className="label-required fw-bold">{packageDetail?.component_label}</Label>
                      <Row>
                        <Col md={10}>
                          <SelectField
                            options={packageDetail?.products}
                            getOptionLabel={(e) => (
                              <div className="d-flex justify-content-between w-100">
                                {e.name}{' '}
                                {e?.sellable_qty && e?.product_type !== 'work' ? (
                                  <span className="float-right"> Sisa stock : {e?.sellable_qty}</span>
                                ) : (
                                  ''
                                )}
                              </div>
                            )}
                            getOptionValue={(e) => e.product_id}
                            placeholder={packageDetail?.prompt}
                            onChange={(val) => handleChangePackageDetail(val, index)}
                            value={packageDetail?.value ?? ''}
                            errorMessage={packageDetail?.error}
                            invalid={!!packageDetail?.error}
                            isOptionDisabled={(option) => disabledOption(option)}
                          />
                        </Col>
                        <Col md={2}>
                          <Row>
                            <Col md={3} className="d-flex align-items-center">
                              <Label className="fw-bold mb-0">Qty</Label>
                            </Col>
                            <Col md={9}>
                              <Input
                                type="number"
                                maxLength={2}
                                value={packageDetail?.qty}
                                onChange={(e) => handleChangePackageQty(e?.target?.value, index)}
                                className="text-center"
                                placeholder="Qty"
                                disabled={packageDetail?.products?.[0]?.product_type === 'work'}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {+packageDetail?.value?.sellable_qty < +packageDetail?.value?.qty &&
                      packageDetail?.value?.product_type === 'part' ? (
                        <span className="text-danger">Stock tidak mencukupi</span>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                  );
                })
              : null}
          </Col>
          <Col
            lg={4}
            md={12}
            sm={12}
            className={`d-flex justify-content-${isDesktop ? 'end' : 'start'}${!isDesktop ? ' mb-2' : ''}`}
          >
            <ProductItemsCard item={item} />
          </Col>
        </Row>

        {initValue?.is_consignment && (
          <div className="mt-4 d-flex justify-content-between align-items-center">
            <span className="price-title">
              <FormGroup check>
                <Input
                  type="checkbox"
                  checked={initValue?.is_guaranteed}
                  onChange={() => handleGuaranteedCheck(!initValue?.is_guaranteed)}
                />
                <Label>Garansi</Label>
              </FormGroup>
            </span>
          </div>
        )}

        <div className="mt-4 d-flex justify-content-between align-items-center">
          <span className="price-title">Harga</span>
          <span className="package-price text-end">{`Rp ${Helper.formatMoney(
            initValue?.is_guaranteed ? 0 : initValue?.calculatedPrice
          )}`}</span>
        </div>

        <Row className="mt-3">
          <Col className="d-flex justify-content-end">
            <Button color="primary" outline className="me-2" onClick={toggle} disabled={saveLoading}>
              Kembali
            </Button>
            <Button
              color="primary"
              onClick={handleSave}
              disabled={
                (loadingSave || loadingPackageOptions || initValue?.category?.slug !== 'oli'
                  ? initValue?.qty < 1
                  : initValue?.qty < 0 || !!initValue?.error_qty || disabledBecauseAddOns) || !initValue?.is_sellable
              }
            >
              Simpan
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default SelectProductModal;
