import React from 'react';

function Skeleton({ width, height }) {
  const skeletonHeight = height || '200px';
  const skeletonWidth = width || '100%';

  return (
    <React.Fragment>
      <div
        className="skeleton-placeholder"
        style={{
          width: skeletonWidth,
          height: skeletonHeight,
        }}
      />
    </React.Fragment>
  );
}

export default Skeleton;
