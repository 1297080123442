import { createSlice } from '@reduxjs/toolkit';
import { every } from 'lodash';
import { find, includes } from 'lodash';
import { filter, map } from 'lodash';

export const initialState = {
  cart: [],
  itemCount: 0,
  customCount: 0,
  customWorkCount: 0,
  customPartCount: 0,
  totalItemCount: 0,
  totalAmount: 0,
  hasModalEditItems: false,
  hasModalEditCustomItems: false,
  activeItems: {},
  activeCustomItems: [],
  isEdit: false,
  activeTab: 'items',
  saveLoading: false,
  dataCustomer: {},
  isWashOrderOnly: false,
};

export const app = createSlice({
  name: 'order',
  initialState: initialState,
  reducers: {
    setCart: (state, action) => {
      let amount = 0;
      map(action.payload, (item) => {
        amount += item?.is_guaranteed ? 0 : item?.calculatedPrice ?? 0;
      });

      const isWashOrderOnly = every(action.payload, (x) => x?.category?.slug?.toLowerCase() === 'cuci');
      state.isWashOrderOnly = !!isWashOrderOnly;

      state.totalAmount = amount;
      state.cart = action.payload;
      state.totalItemCount = action.payload.length;
      state.itemCount = filter(action.payload, (x) => x.formType !== 'custom').length;
      state.customCount = filter(action.payload, (x) => x.formType === 'custom').length;
      state.customWorkCount = filter(action.payload, (x) => x.formType === 'custom' && x.type === 'service').length;
      state.customPartCount = filter(action.payload, (x) => x.formType === 'custom' && x.type === 'product').length;
    },
    setTotalAmount: (state, action) => {
      state.totalAmount = action.payload;
    },
    setHasModalEditItems: (state, action) => {
      state.hasModalEditItems = !state.hasModalEditItems;
    },
    setHasModalEditCustomItems: (state, action) => {
      state.hasModalEditCustomItems = !state.hasModalEditCustomItems;
    },
    setActiveItems: (state, action) => {
      state.activeItems = action.payload;
    },
    setIsEdit: (state, action) => {
      state.isEdit = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setSaveLoading: (state, action) => {
      state.saveLoading = action.payload;
    },
    setDataCustomer: (state, action) => {
      state.dataCustomer = action.payload;
    },
    setActiveCustomItems: (state, action) => {
      state.activeCustomItems = action.payload;
    },
  },
});

export const {
  setCart,
  setTotalAmount,
  setHasModalEditItems,
  setHasModalEditCustomItems,
  setActiveItems,
  setIsEdit,
  setActiveTab,
  setSaveLoading,
  setDataCustomer,
  setActiveCustomItems,
} = app.actions;
export const getCart = (state) => state?.order?.cart;
export const getTotalItemLength = (state) => state?.order?.totalItemCount;
export const getTotalItemsLength = (state) => state?.order?.itemCount;
export const getTotalCustomsLength = (state) => state?.order?.customCount;
export const getTotalCustomServiceLength = (state) => state?.order?.customWorkCount;
export const getTotalCustomProductLength = (state) => state?.order?.customPartCount;
export const getTotalAmount = (state) => state?.order?.totalAmount;
export const getHasModalEditItems = (state) => state?.order?.hasModalEditItems;
export const getHasModalEditCustomItems = (state) => state?.order?.hasModalEditCustomItems;
export const getActiveItems = (state) => state?.order?.activeItems;
export const getIsEdit = (state) => state?.order?.isEdit;
export const getActiveTab = (state) => state?.order?.activeTab;
export const getSaveLoading = (state) => state?.order?.saveLoading;
export const getDataCustomer = (state) => state?.order?.dataCustomer;
export const getActiveCustomItems = (state) => state?.order?.activeCustomItems;
export const getIsWashOrderOnly = (state) => state?.order?.isWashOrderOnly;

export default app.reducer;
