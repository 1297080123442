import React from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Button } from '../otoklix-elements';
import CloseIcon from '@assets/icons/close.svg';
import moment from 'moment';
import { useState } from 'react';
import Calendar from 'react-calendar';

const DatePickerModal = (props) => {
  const {
    isOpen,
    toggle,
    onSubmit,
    value = new Date(),
    minDate = new Date(),
    maxDate = new Date(moment().add(1, 'M')),
    disabledDates = [],
  } = props;

  const [dateValue, setDateValue] = useState(value ?? new Date());

  const tileDisabled = ({ date, view }) => {
    // Disable tiles in month view only
    if (view === 'month') {
      return disabledDates.find((dDate) => moment(date).diff(dDate, 'days') === 0);
    }
  };

  const handleChange = (datevalue) => {
    setDateValue(datevalue);
  };

  const handleSubmit = () => {
    onSubmit(dateValue);
  };

  const onExit = () => {
    handleChange(value ?? new Date());
  };

  return (
    <Modal isOpen={isOpen} centered className="datepicker-modal">
      <ModalHeader
        close={
          <img
            src={CloseIcon}
            className="cursor-pointer"
            alt=""
            width={16}
            height={16}
            onClick={() => {
              toggle();
              onExit();
            }}
          />
        }
      >
        Pilih Tanggal Servis
      </ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-center p-3">
          <Calendar
            onChange={handleChange}
            minDate={minDate}
            maxDate={maxDate}
            value={dateValue}
            showNeighboringMonth={false}
            tileDisabled={tileDisabled}
          />
        </div>

        <Row>
          <Col lg={6} md={6} sm={6} xs={6}>
            <Button
              color="primary"
              outline
              block
              onClick={() => {
                toggle();
                onExit();
              }}
            >
              Kembali
            </Button>
          </Col>
          <Col lg={6} md={6} sm={6} xs={6}>
            <Button color="primary" block onClick={handleSubmit}>
              Simpan
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default DatePickerModal;
