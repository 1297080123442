import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from '../utils';
import Dropdown from '../dropdown/Dropdown';

const propTypes = {
  tag: tagPropType,
  type: PropTypes.bool,
  size: PropTypes.string,
  inputType: PropTypes.string,
  className: PropTypes.string,
  cssModule: PropTypes.object,
};

const defaultProps = {
  tag: 'div',
};

const InputGroup = (props) => {
  const { className, cssModule, tag: Tag, type, size, inputType, ...attributes } = props;
  const classes = mapToCssModules(
    classNames(className, inputType ? `input-group-${inputType}` : 'input-group', size ? `input-group-${size}` : null),
    cssModule
  );

  if (props.type === 'dropdown') {
    return <Dropdown {...attributes} className={classes} />;
  }

  return <Tag {...attributes} className={classes} />;
};

InputGroup.propTypes = propTypes;
InputGroup.defaultProps = defaultProps;

export default InputGroup;
