import React, { useEffect, useState } from 'react';
import { FormGroup, Input, Label, FormFeedback, Button, CardFooter } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import Helper from '@helpers/Helper';
import AlertCircleIcon from '@assets/icons/alert-circle.svg';
import AlertTriangleIcon from '@assets/icons/alert-triangle.svg';
import EditIcon from '@assets/icons/edit-blue.svg';
import Spinner from '../../components/otoklix-elements/components/spinner/Spinner';
import { Row, Col, Card, CardBody } from '../../components/otoklix-elements';
import ConfirmModal from '@components/modal/ConfirmModal';
import CustomModal from '@components/modal/CustomModal';
import DetailProductItemsCard from '@components/card/DetailProductItemsCard';
import Wrapper from '@components/wrapper/Wrapper';
import Divider from '@components/divider/Divider';
import TitlePage from '@components/dashboard/TitlePage';
import SelectField from '@components/field/SelectField';
import UploadImage from '@components/uploads/UploadImage';
import DetailCustomerCard from '@components/card/DetailCustomerCard';
import CarInspectionCustomerCard from '@components/card/CarInspectionCustomerCard';
import useAlert from '@hooks/useAlert';
import ChooseItemModal from '@components/modal/ChooseItemModal';
import PrintReceiptPdf from '@helpers/pdf/ReceiptPdf.js';
import { assign } from 'lodash';
import CarInspectionService from '@services/car-inspection';
import CreateCarInspectionModal from '@components/modal/CreateCarInspectionModal';

const Detail = () => {
  const { booking_code } = useParams();
  const { generatePermission } = userPermission();

  const api = new API('v2');
  const { setAlert } = useAlert();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [dataDetail, setDataDetail] = useState();
  const [dataImage, setDataImage] = useState();
  const [bookingStatus, setBookingStatus] = useState('');
  const [formRefund, setFormRefund] = useState({ bankName: '', accountNumber: '', accountName: '', reason: '' });
  const [formErrorRefund, setFormErrorRefund] = useState({
    bankName: '',
    accountNumber: '',
    accountName: '',
    reason: '',
  });
  const [formValidRefund, setFormValidRefund] = useState(true);
  const [imagePreview, setImagePreview] = useState();
  const [errorImage, setErrorImage] = useState('');
  const [listBank, setListBank] = useState([]);
  const [editOrder, setEditOrder] = useState(false);
  const [modalAddNew, setModalAddNew] = useState(false);
  const [detailItem, setDetailItem] = useState([]);
  const [titleModalAddNew, setTitleModalAddNew] = useState('Tambah Item');
  const [selectedItemInventory, setSelectedItemInventory] = useState(null);
  const [defaultQty, setDefaultQty] = useState(1);
  const [totalAmount, setTotalAmount] = useState(0);
  const [cancelNotes, setCancelNotes] = useState('');
  const [disabledFinishOrder, setDisabledFinishOrder] = useState(false);
  const [errorFormNotes, setErrorFormNotes] = useState(false);
  const [allowAddNewItem, setAllowAddNewItem] = useState(false);
  const [defaultPrice, setDefaultPrice] = useState(0);
  const [dataInspection, setDataInspection] = useState(null);
  const [userCarId, setUserCarId] = useState(null);
  const [modalCreateInspection, setModalInspection] = useState(false);
  const [source, setSource] = useState('');
  const [disabledButtonFinishOrder, setDisabledButtonFinishOrder] = useState(false);

  const isProductWashOnly = dataDetail?.flag?.is_product_wash_only;

  const allowPermission = (menu) => {
    return !!(generatePermission('order', menu) || generatePermission('order', 'all'));
  };
  const allowRevise = allowPermission('ordereditreviseedit');
  const allowEditDocument = allowPermission('orderdocumentcreate');
  const allowUpdateStatus = allowPermission('ordereditstatus');
  const allowOrderConfirm = allowPermission('orderconfirm');
  const allowCreatePayment = allowPermission('orderpaymentcreate');
  const allowReceiptPayment = allowPermission('orderpaymentreceipt');

  const handleErrorUploadImage = (msg) => {
    setAlert(msg, 'failed');
    setErrorImage(msg);
  };

  const handlePreviewImage = () => {
    if (imagePreview) {
      setShowLightbox(!showLightbox);
    }
  };

  const handleError = (err, reloadPage = false) => {
    console.error(err);
    const message = err?.response?.data?.error?.message;
    setAlert(message, 'failed');
    setLoading(false);
    if (reloadPage) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  const handleDeleteImage = async (image, type) => {
    try {
      const reqBody = {
        id: image?.id,
        document_type: type,
        filename: image?.filename,
        key: image?.key,
        is_deleted: true,
      };

      const res = await api.post(`v2/gms/booking/${booking_code}/documents`, reqBody);
      if (+res?.status === 200) {
        setAlert('Berhasil menghapus gambar', 'success');
        fetchDataImage();
      }
      setLoading(false);
    } catch (err) {
      handleError(err, false);
    }
  };

  const uploadImage = async (image, id, type) => {
    try {
      const reqBody = {
        id: id,
        document_type: type,
        filename: image?.file?.name,
        key: image?.key,
        is_deleted: false,
      };

      const res = await api.post(`v2/gms/booking/${booking_code}/documents`, reqBody);
      if (+res?.status === 200) {
        setAlert('Berhasil menambahkan gambar', 'success');
        fetchDataImage();
      }
      setLoading(false);
    } catch (err) {
      handleError(err, false);
    }
  };

  const handleUploadImage = (image, id, type) => {
    if (image?.key) uploadImage(image, id, type);
    setImageKey(image.key);
    setImagePreview(image.preview);
  };

  const defaultValueModal = {
    reject: false,
    received: false,
    confirm: false,
    onProcess: false,
    cancel: false,
    finishService: false,
    finishOrder: false,
    printPayment: false,
    refund: false,
    carInspection: false,
  };

  const [modalConfirm, setModalConfirm] = useState(defaultValueModal);

  const handleModal = (type, value) => {
    setModalConfirm((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  };

  const handleCheckCarInspection = async () => {
    const response = await CarInspectionService.getDetail(userCarId);
    const data = response?.data?.data;
    if (response?.status == 200) {
      if (data.last_service_date == null) {
        handleModal('carInspection', !modalConfirm.carInspection);
      } else {
        handleModal('finishService', !modalConfirm?.finish);
      }
    }
  };

  const setCurrentInspection = () => {
    setModalInspection(false);
  };

  const handleSetModalInspectionClick = (value, source) => {
    setModalInspection(value);
    setSource(source);
  };

  const ImageValidation = async () => {
    let status = false;
    const arrivalImage = dataImage[0];
    const sparepartImage = dataImage[1];

    if (
      (!arrivalImage?.filename || !arrivalImage?.key || !arrivalImage?.url) &&
      !dataDetail?.flag?.is_product_wash_only
    ) {
      status = false;
    }

    if (
      (!sparepartImage?.filename || !sparepartImage?.key || !sparepartImage?.url) &&
      !dataDetail?.flag?.is_product_wash_only
    ) {
      status = false;
    }

    return status;
  };

  const changeStatus = async (status, message, typeNotif) => {
    setLoading(true);

    if (status === 'finish' && !ImageValidation()) {
      setAlert('mohon lengkapi mandatory field', 'failed');
      setLoading(false);
      return;
    }

    try {
      const reqBody = {
        booking_status: status,
        cancel_notes: cancelNotes,
      };
      const res = await api.put(`v2/gms/booking/${booking_code}/update-status`, reqBody);
      if (+res?.status === 200) {
        setAlert(message, typeNotif);
        setModalConfirm(defaultValueModal);
        fetchDataDetail();
      }
      setLoading(false);
    } catch (err) {
      handleError(err, true);
      setModalConfirm(defaultValueModal);
    }
  };

  const clearValidFormRefund = () => {
    setFormRefund({ bankName: '', accountNumber: '', accountName: '', reason: '' });
    setFormErrorRefund({
      bankName: '',
      accountNumber: '',
      accountName: '',
      reason: '',
    });
    setFormErrorRefund(false);
  };

  const validationFormRefund = () => {
    let isValid = false;
    const errors = {};

    if (formRefund?.bankName === '') {
      errors.bankName = 'Bank wajib dipilih';
      isValid = true;
    } else {
      errors.bankName = '';
      isValid = false;
    }

    if (formRefund?.accountNumber === '') {
      errors.accountNumber = 'Nomor rekening wajib diisi';
      isValid = true;
    } else {
      errors.accountNumber = '';
      isValid = false;
    }

    if (formRefund?.accountName === '') {
      errors.accountName = 'Nama pemegang wajib diisi';
      isValid = true;
    } else {
      errors.accountName = '';
      isValid = false;
    }

    if (formRefund?.reason === '') {
      errors.reason = 'Alasan wajib diisi';
      isValid = true;
    } else {
      if (formRefund?.reason?.length > 100) {
        errors.reason = 'Maksimal 100 karakter';
        isValid = true;
      }

      errors.reason = '';
      isValid = false;
    }

    setFormErrorRefund({ ...formRefund, ...errors });
    setFormValidRefund(isValid);
    return isValid;
  };

  const handleRefund = async () => {
    const checkValidation = validationFormRefund();
    setLoading(true);
    if (!checkValidation) {
      try {
        const reqBody = {
          bank_id: formRefund?.bank?.id,
          account_number: formRefund?.accountNumber,
          account_holder: formRefund?.accountName,
          reason: formRefund?.reason,
        };
        const res = await api.post(`v2/gms/booking/${booking_code}/refund`, reqBody);
        if (+res?.status === 200) {
          setAlert('Refund akan diproses', 'success');
          setModalConfirm(defaultValueModal);
          fetchDataDetail();
        }
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        setModalConfirm(defaultValueModal);
        setLoading(false);
      } catch (err) {
        handleError(err, false);
      }
    } else {
      setLoading(false);
    }
  };

  const fetchDataDetail = async () => {
    try {
      const res = await api.get(`v2/gms/booking/${booking_code}`);
      if (+res?.status === 200) {
        const data = res?.data?.data;
        let tempDetailItem = [...data?.items];
        tempDetailItem = tempDetailItem.map((item) => ({
          ...item,
          is_deleted: false,
        }));
        setDataDetail(data);
        setDetailItem(tempDetailItem);
        setBookingStatus(data?.booking?.booking_status?.slug);
        setUserCarId(data?.user_car?.id);
      }
      setLoading(false);
      setFirstLoad(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const fetchListBank = async () => {
    try {
      const res = await api.get(`v2/gms/booking/bank`);
      if (+res?.status === 200) {
        const data = res?.data?.data;
        setListBank(data);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const fetchDataImage = async () => {
    try {
      const res = await api.get(`v2/gms/booking/${booking_code}/documents`);
      if (+res?.status === 200) {
        const data = res?.data?.data;
        setDataImage(data);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const fetchUserCarinspection = async () => {
    try {
      const response = await CarInspectionService.get(userCarId);
      if (+response?.status === 200) {
        const data = response?.data?.data;
        setDataInspection(data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const formattedPackage = (value) => {
    const filteredPackage = value.filter((item) => +item?.item_details?.length > 0);
    const packageDetailData = (item) => {
      return item?.isNew
        ? item?.item_details.map((childItem) => ({
            package_details_id: childItem?.package_details_id,
            qty: childItem?.qty,
          }))
        : [];
    };
    const formattedData = filteredPackage.map((item) => ({
      package_id: item?.package_id,
      booking_package_id: item?.booking_package_id ?? null,
      booking_product_id: item?.booking_product_id ?? null,
      booking_detail_id: item?.booking_details_id ?? null,
      package_details: item?.isNew ? item?.item_details.map((childItem) => childItem?.package_details_id) : [],
      package_detail_data: packageDetailData(item),
      is_deleted: item?.is_deleted ?? false,
      deleted_reason: item?.deleted_reason ?? null,
      item_note: '',
    }));
    return [...formattedData];
  };

  const formattedItem = (value) => {
    const filteredItem = value.filter(
      (item) =>
        (item?.product_type === 'work' || item?.product_type === 'part') &&
        item?.product_id !== null &&
        item?.package_id === null
    );

    const formattedData = filteredItem.map((item) => {
      let payloadItem = {
        booking_product_id: item?.booking_product_id ?? null,
        booking_detail_id: item?.booking_details_id ?? null,
        product_id: item?.product_id,
        qty: item?.qty,
        product_type: item?.item_type === 'product' ? 'part' : 'work',
        type: item?.product_type === 'part' ? 'part' : 'work',
        is_deleted: item?.is_deleted ?? false,
        deleted_reason: item?.deleted_reason ?? null,
        item_note: '',
      };

      if (item?.is_consignment) {
        assign(payloadItem, {
          is_guaranteed: item?.is_guaranteed,
        });
      }

      return payloadItem;
    });

    return [...formattedData];
  };

  const formattedCustomItem = (value) => {
    const filteredCustomItem = value.filter((item) => item?.package_id === null && item?.product_id === null);
    const formattedData = filteredCustomItem.map((item) => ({
      booking_product_id: item?.booking_product_id ?? null,
      booking_detail_id: item?.booking_details_id ?? null,
      name: item?.name,
      type: item?.type === 'product' ? 'part' : 'work',
      brand_name: item?.brand_name ?? null,
      sell_price: item?.unit_price,
      cogs: item?.cogs,
      shipping_cost: item?.shipping_cost,
      product_id: item?.product_id,
      qty: item?.qty,
      is_deleted: item?.is_deleted ?? false,
      deleted_reason: item?.deleted_reason ?? null,
      item_note: '',
    }));
    return [...formattedData];
  };

  const submitReviseOrder = async () => {
    setLoading(true);
    const reqBody = {
      mileage: dataDetail?.user_car?.mileage,
      notes: null,
      packages: formattedPackage(detailItem),
      items: formattedItem(detailItem),
      custom_items: formattedCustomItem(detailItem),
    };
    try {
      const res = await api.put(`v2/gms/booking/${booking_code}/revise`, reqBody);
      if (+res?.status === 201) {
        setAlert('Berhasil mengubah order', 'success');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      setLoading(false);
    } catch (err) {
      handleError(err, false);
      console.error(err);
      setLoading(false);
    }
  };

  const undeletedItem = (undoItem) => {
    let tempDetailItem;
    const dataItem = detailItem.map((item) => {
      if (undoItem?.package_id === null && +undoItem?.product_id === +item?.product_id) {
        return {
          ...item,
          is_deleted: false,
          deleted_reason: '',
        };
      } else if (+item?.item_details?.length > 0 && +item?.package_id === +undoItem?.package_id) {
        return {
          ...item,
          is_deleted: false,
          deleted_reason: '',
        };
      } else {
        return {
          ...item,
          is_deleted: item?.is_deleted,
          deleted_reason: item?.deleted_reason,
        };
      }
    });
    tempDetailItem = [...dataItem];
    setDetailItem(tempDetailItem);
  };

  const checkNewItem = (newItem) => {
    if (newItem?.package_id !== null) {
      const checkPackage = detailItem.filter((item) => +item?.package_id === +newItem?.package_id);
      if (+checkPackage?.length > 0) {
        undeletedItem(newItem);
        setModalAddNew(false);
        return false;
      }
    }
    if (newItem?.item_type === 'service' || newItem?.item_type === 'product') {
      const checkItem = detailItem.filter((item) => +item?.product_id === +newItem?.product_id);
      if (+checkItem?.length > 0) {
        undeletedItem(newItem);
        if (newItem?.item_type === 'product') {
          let tempData = [...detailItem];
          const changeData = detailItem.map((item) => {
            if (+item?.product_id === +newItem?.product_id) {
              return {
                ...item,
                qty: +item?.qty + +newItem?.qty,
                price: +item?.price + +newItem?.qty * +newItem?.price,
              };
            } else {
              return {
                ...item,
              };
            }
          });
          tempData = [...changeData];
          setDetailItem(tempData);
        }
        setModalAddNew(false);
        return false;
      }
    }
    return true;
  };

  const handleSubmitNewItem = (newItem) => {
    const check = checkNewItem(newItem);
    if (check) {
      if (selectedItemInventory !== null) {
        let tempData = [...detailItem];
        const changeData = detailItem.map((item) => {
          if (+item?.booking_details_id === +selectedItemInventory?.booking_details_id) {
            return {
              ...item,
              product_id: newItem?.product_id,
              name: newItem?.name,
              price:
                dataDetail?.customer?.customer_type === 'b2c'
                  ? +newItem?.price * +newItem?.qty
                  : +item?.unit_price * +item?.qty,
              unit_price: dataDetail?.customer?.customer_type === 'b2c' ? newItem?.price : item?.unit_price,
              product_type: newItem?.item_type === 'product' ? 'part' : 'work',
              type: newItem?.item_type === 'product' ? 'part' : 'work',
              is_deleted: false,
              qty: dataDetail?.customer?.customer_type === 'b2c' ? newItem?.qty : item?.qty,
              is_consignment: newItem?.is_consignment,
              is_guaranteed: newItem?.is_guaranteed,
            };
          } else {
            return { ...item };
          }
        });
        tempData = [...changeData];
        setDetailItem(tempData);
        setModalAddNew(false);
      } else {
        let tempData;
        const changeData = {
          ...newItem,
          product_id: newItem?.product_id,
          name: newItem?.name,
          price: +newItem?.price * +newItem?.qty,
          unit_price: newItem?.price,
          product_type: newItem?.item_type === 'product' ? 'part' : 'work',
          type: newItem?.item_type === 'product' ? 'part' : 'work',
          is_deleted: false,
        };
        tempData = [...detailItem, changeData];
        setDetailItem(tempData);
        setModalAddNew(false);
      }
    }
  };

  const deletedNewItem = (value) => {
    let tempDetailItem = [...detailItem];
    const filteredItem = detailItem.filter((item) => item?.name !== value?.name);
    tempDetailItem = [...filteredItem];
    setDetailItem(tempDetailItem);
  };

  const handleAddNewItem = () => {
    setDefaultQty('');
    setSelectedItemInventory(null);
    setTitleModalAddNew('Tambah Item');
    setModalAddNew(!modalAddNew);
  };

  const handleUpdateInventory = (item) => {
    setDefaultQty(+item?.qty);
    setDefaultPrice(+item?.price);
    setSelectedItemInventory(item);
    setTitleModalAddNew('Update ke Data Inventory');
    setModalAddNew(!modalAddNew);
  };

  const deletedItem = (itemSelected) => {
    let tempDetailItem;
    const dataItem = detailItem.map((item) => {
      if (+item?.booking_details_id === +itemSelected?.booking_details_id) {
        return {
          ...item,
          is_deleted: true,
          deleted_reason: itemSelected?.deleted_reason,
        };
      } else if (+item?.item_details?.length > 0 && +item?.booking_package_id === +itemSelected?.booking_package_id) {
        return {
          ...item,
          is_deleted: true,
          deleted_reason: itemSelected?.deleted_reason,
        };
      } else {
        return {
          ...item,
          is_deleted: item?.is_deleted,
          deleted_reason: item?.deleted_reason,
        };
      }
    });
    tempDetailItem = [...dataItem];
    setDetailItem(tempDetailItem);
  };

  const handlePrintReceipt = () => {
    PrintReceiptPdf.create(dataDetail);
  };

  const handleFinishOrderButton = () => {
    if (!dataDetail?.flag?.is_product_wash_only && dataDetail?.user_car?.mileage == null) {
      setDisabledButtonFinishOrder(true);
    } else {
      setDisabledButtonFinishOrder(false);
    }
  };

  const roundingCalculation = (total) => {
    let lastTwoDigit = total % 100;
    let roundingTotal = total;
    let roundingNominal = 0;
    if (lastTwoDigit !== 0) {
      roundingNominal = 100 - lastTwoDigit;
      roundingTotal = total + roundingNominal;
    }

    let calculation = {
      rounding: roundingNominal,
      grand_total: roundingTotal,
    };

    return calculation;
  };

  const renderConfirmButton = (status) => {
    switch (status) {
      case 'waiting':
        if (allowUpdateStatus || allowOrderConfirm) {
          return (
            <>
              <Button
                color="primary"
                outline
                className="mr-2"
                onClick={() => handleModal('reject', !modalConfirm?.reject)}
              >
                Tolak
              </Button>
              <Button color="primary" className="mr-2" onClick={() => handleModal('received', !modalConfirm?.received)}>
                Terima Order
              </Button>
            </>
          );
        } else {
          return '';
        }
      case 'confirm':
        if (allowUpdateStatus || allowOrderConfirm) {
          if (dataDetail?.customer?.customer_type === 'b2c') {
            return (
              <>
                <Button
                  color="primary"
                  outline
                  className="mr-2"
                  onClick={() => handleModal('cancel', !modalConfirm?.cancel)}
                >
                  Batalkan
                </Button>
                <Button
                  color="primary"
                  className="mr-2"
                  onClick={() => handleModal('onProcess', !modalConfirm?.onProcess)}
                >
                  Konfirmasi Kedatangan
                </Button>
              </>
            );
          } else {
            return (
              <Button
                color="primary"
                className="mr-2"
                onClick={() => handleModal('onProcess', !modalConfirm?.onProcess)}
              >
                Konfirmasi Kedatangan
              </Button>
            );
          }
        } else {
          return '';
        }
      case 'on-process':
        if (allowUpdateStatus) {
          return (
            <>
              {dataDetail?.payment_details?.status !== 'settlement' ? (
                <Button
                  color="primary"
                  outline
                  className="mr-2"
                  onClick={() => handleModal('cancel', !modalConfirm?.cancel)}
                >
                  Batalkan
                </Button>
              ) : (
                ''
              )}
              {dataDetail?.payment_details?.status === 'settlement' && allowReceiptPayment && (
                <Button color="primary" className="mr-2" outline onClick={() => handlePrintReceipt()}>
                  Cetak Bukti Pembayaran
                </Button>
              )}
              {allowCreatePayment && dataDetail?.payment_details?.status !== 'settlement' ? (
                <Button
                  color="primary"
                  className="mr-2"
                  onClick={() => history.push(`/payment/${booking_code}`)}
                  disabled={disabledFinishOrder}
                >
                  Proses Pembayaran
                </Button>
              ) : (
                ''
              )}
              <Button
                color="primary"
                className="mr-2"
                onClick={() => handleCheckCarInspection()}
                disabled={disabledFinishOrder || disabledButtonFinishOrder}
              >
                Selesaikan Servis
              </Button>
            </>
          );
        } else {
          return '';
        }
      case 'service-done':
        if (
          (dataDetail?.payment_details?.status === 'pending' || dataDetail?.payment_details?.status == 'expire') &&
          dataDetail?.customer?.customer_type === 'b2c'
        ) {
          return (
            <>
              {dataDetail?.payment_details?.status === 'settlement' && allowReceiptPayment && (
                <Button color="primary" className="mr-2" outline onClick={() => handlePrintReceipt()}>
                  Cetak Bukti Pembayaran
                </Button>
              )}
              {allowCreatePayment && (
                <Button color="primary" className="mr-2" onClick={() => history.push(`/payment/${booking_code}`)}>
                  Proses Pembayaran
                </Button>
              )}
            </>
          );
        } else {
          return (
            <>
              {allowReceiptPayment && (
                <Button color="primary" className="mr-2" outline onClick={() => handlePrintReceipt()}>
                  Cetak Bukti Pembayaran
                </Button>
              )}
              {allowUpdateStatus && (
                <>
                  {dataDetail?.booking?.booking_type !== 'b2b' && (
                    <Button
                      color="primary"
                      className="mr-2"
                      onClick={() => handleModal('finishOrder', !modalConfirm?.finishOrder)}
                    >
                      Selesaikan Order
                    </Button>
                  )}
                </>
              )}
            </>
          );
        }

      case 'finish':
        return (
          <>
            {allowReceiptPayment && (
              <Button color="primary" className="mr-2" outline onClick={() => handlePrintReceipt()}>
                Cetak Bukti Pembayaran
              </Button>
            )}
          </>
        );
      case 'finishOrder':
        return (
          <>
            {allowReceiptPayment && (
              <Button color="primary" className="mr-2" outline onClick={() => handlePrintReceipt()}>
                Cetak Bukti Pembayaran
              </Button>
            )}
            {allowUpdateStatus && (
              <Button
                color="primary"
                className="mr-2"
                onClick={() => handleModal('finishOrder', !modalConfirm?.finishOrder)}
              >
                Selesaikan Order
              </Button>
            )}
          </>
        );
      case 'cancel':
        if (dataDetail?.payment_details?.status === 'settlement' && +totalAmount > 0) {
          return (
            <>
              {allowUpdateStatus && !dataDetail?.has_refund_booking && (
                <Button
                  color="danger"
                  className="mr-2"
                  outline
                  onClick={() => handleModal('refund', !modalConfirm?.refund)}
                >
                  Refund Order
                </Button>
              )}
            </>
          );
        } else {
          return null;
        }
      default:
        return <></>;
    }
  };

  useEffect(() => {
    let tempTotalAmount;
    if (+detailItem?.length > 0) {
      const filterItem = detailItem.filter((item) => item?.is_deleted === false && !item?.is_guaranteed);
      const totalPrice = filterItem.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.price;
      }, 0);
      tempTotalAmount = totalPrice;
      setTotalAmount(tempTotalAmount);
      const checkCustomItem = formattedCustomItem(detailItem);
      if (+checkCustomItem?.length > 0) {
        setDisabledFinishOrder(true);
      } else {
        setDisabledFinishOrder(false);
      }
    }
  }, [detailItem]);

  useEffect(() => {
    if (dataDetail?.bill?.amount) setTotalAmount(dataDetail?.bill?.amount);
    if (dataDetail?.booking?.booking_type === 'b2b') {
      setAllowAddNewItem(
        !(dataDetail?.payment_details?.status === 'settlement' || dataDetail?.booking?.booking_type === 'b2b')
      );
    } else {
      if (dataDetail?.payment_details?.status === 'settlement') {
        setAllowAddNewItem(false);
      } else {
        setAllowAddNewItem(true);
      }
    }

    handleFinishOrderButton();
  }, [dataDetail]);

  useEffect(() => {
    if (bookingStatus === 'cancel') {
      fetchListBank();
    }
    if (bookingStatus === 'on-process' || bookingStatus === 'service-done' || bookingStatus === 'finish') {
      fetchDataImage();
    }
  }, [bookingStatus]);

  useEffect(() => {
    fetchDataDetail();
  }, []);

  useEffect(() => {
    if (userCarId) {
      fetchUserCarinspection();
    }
  }, [userCarId, dataDetail]);

  return (
    <Wrapper title="" className="orders">
      <Row className="head">
        <Col lg={5} md={12} sm={12}>
          <TitlePage title={'Order'} subTitle={booking_code} useBackButton backButtonLink="/order" />
        </Col>
      </Row>
      <Card className="mt-3 border-table">
        <CardBody>
          <DetailCustomerCard data={{ ...dataDetail, allowEdit: allowRevise }} loading={firstLoad} />
          <Divider />
          <CarInspectionCustomerCard
            data={dataInspection ? { ...dataInspection } : null}
            loading={firstLoad}
            bookingData={dataDetail}
          />
          <Divider />
          {allowEditDocument && (
            <>
              {(bookingStatus === 'on-process' || bookingStatus === 'service-done' || bookingStatus === 'finish') && (
                <>
                  <Row className="mb-4">
                    <Col md={12} className="d-flex">
                      <span className="font-weight-bold" style={{ fontSize: 16 }}>
                        Foto Bukti Servis
                      </span>
                      {isProductWashOnly && (
                        <span className="font-weight-normal" style={{ fontSize: 14, color: '#CECDCA', marginLeft: 5 }}>
                          (Opsional)
                        </span>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col lg={6} md={6} sm={12}>
                      <UploadImage
                        isView={dataImage?.[1]?.key ? true : false}
                        image={dataImage?.[1]}
                        error={errorImage}
                        maxSize={5000}
                        maxSizeString={'5MB'}
                        nameSpace={'booking-document'}
                        identifier={'sparepart_report'}
                        uploadText={
                          <span>
                            Tambah foto report sparepart{!isProductWashOnly && <span className="text-danger">*</span>}
                          </span>
                        }
                        onError={handleErrorUploadImage}
                        onDelete={() => handleDeleteImage(dataImage?.[1], 'sparepart_report')}
                        onPreview={handlePreviewImage}
                        onUpload={(image) => handleUploadImage(image, dataImage?.[1]?.id, 'sparepart_report')}
                        simplified
                      />
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <UploadImage
                        isView={dataImage?.[0]?.key ? true : false}
                        image={dataImage?.[0]}
                        error={errorImage}
                        maxSize={5000}
                        maxSizeString={'5MB'}
                        nameSpace={'booking-document'}
                        identifier={'arrival_report'}
                        uploadText={
                          <span>
                            Tambah foto serah terima kendaraan
                            {!isProductWashOnly && <span className="text-danger">*</span>}
                          </span>
                        }
                        onError={handleErrorUploadImage}
                        onDelete={() => handleDeleteImage(dataImage?.[0], 'arrival_report')}
                        onPreview={handlePreviewImage}
                        onUpload={(image) => handleUploadImage(image, dataImage?.[0]?.id, 'arrival_report')}
                        simplified
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} md={4} sm={12}>
                      <UploadImage
                        isView={dataImage?.[2]?.key ? true : false}
                        image={dataImage?.[2]}
                        error={errorImage}
                        maxSize={5000}
                        maxSizeString={'5MB'}
                        nameSpace={'booking-document'}
                        identifier={'others'}
                        uploadText="Tambah foto lainnya"
                        onError={handleErrorUploadImage}
                        onDelete={() => handleDeleteImage(dataImage?.[2], 'sparepart_booking')}
                        onPreview={handlePreviewImage}
                        onUpload={(image) => handleUploadImage(image, dataImage?.[2]?.id, 'others')}
                        simplified
                      />
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                      <UploadImage
                        isView={dataImage?.[3]?.key ? true : false}
                        image={dataImage?.[3]}
                        error={errorImage}
                        maxSize={5000}
                        maxSizeString={'5MB'}
                        nameSpace={'booking-document'}
                        identifier={'others'}
                        uploadText="Tambah foto lainnya"
                        onError={handleErrorUploadImage}
                        onDelete={() => handleDeleteImage(dataImage?.[3], 'sparepart_booking')}
                        onPreview={handlePreviewImage}
                        onUpload={(image) => handleUploadImage(image, dataImage?.[3]?.id, 'others')}
                        simplified
                      />
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                      <UploadImage
                        isView={dataImage?.[4]?.key ? true : false}
                        image={dataImage?.[4]}
                        error={errorImage}
                        maxSize={5000}
                        maxSizeString={'5MB'}
                        nameSpace={'booking-document'}
                        identifier={'others'}
                        uploadText="Tambah foto lainnya"
                        onError={handleErrorUploadImage}
                        onDelete={() => handleDeleteImage(dataImage?.[4], 'sparepart_booking')}
                        onPreview={handlePreviewImage}
                        onUpload={(image) => handleUploadImage(image, dataImage?.[4]?.id, 'others')}
                        simplified
                      />
                    </Col>
                  </Row>
                  <Divider />
                </>
              )}
            </>
          )}

          <Row className="mb-4">
            <Col md={6} className="d-flex">
              <span className="font-weight-bold" style={{ fontSize: 16 }}>
                Ringkasan Belanja
              </span>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              {allowRevise && (
                <>
                  {bookingStatus === 'on-process' && !editOrder && (
                    <Button outline color="primary" size="sm" onClick={() => setEditOrder(true)}>
                      <img src={EditIcon} alt="Edit" className="mr-1" />
                      Update Order
                    </Button>
                  )}
                </>
              )}
            </Col>
          </Row>
          <Row>
            {detailItem.map((item, index) => {
              const isDeleted = detailItem.filter((items) => items?.is_deleted === false);
              if (item?.is_deleted) {
                return null;
              } else {
                return (
                  <Col lg={12} md={12} sm={12} key={index} className="mb-2">
                    <DetailProductItemsCard
                      item={item}
                      isEdit={editOrder}
                      dataBooking={dataDetail}
                      onDeletedNewItem={(item) => deletedNewItem(item)}
                      onDeletedItem={(item) => deletedItem(item)}
                      isDeleted={!allowAddNewItem ? false : +isDeleted.length > 1}
                      updateInventory={(item) => handleUpdateInventory(item)}
                      customerType={dataDetail?.customer?.customer_type}
                    />
                  </Col>
                );
              }
            })}
            {editOrder && allowAddNewItem && (
              <Col lg={12} md={12} sm={12} className="mb-2 text-center mt-2">
                <Button
                  outline
                  color="primary"
                  style={{ width: '95%', margin: '0 auto' }}
                  onClick={() => handleAddNewItem()}
                >
                  + Tambah Item
                </Button>
              </Col>
            )}
          </Row>
          <Divider />
          <Row className="mb-4">
            <Col md={12} className="d-flex">
              <span className="font-weight-bold" style={{ fontSize: 16 }}>
                Pembayaran
              </span>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} className="box-payment">
              <Row>
                <Col>
                  <span className="fw-bold">Total Pesanan</span>
                </Col>
                <Col className="text-end">
                  <span className="fw-bold">Rp {Helper.formatMoney(totalAmount)}</span>
                </Col>
              </Row>
              {dataDetail?.bill?.amount > 0 && (
                <>
                  <Row>
                    <Col>
                      <span className="fw-bold">Promo</span>
                    </Col>
                    <Col className="text-end">
                      <span className="fw-bold" style={{ color: 'red' }}>
                        -Rp {Helper.formatMoney(dataDetail?.discount?.amount)}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className="fw-bold">Otopoints</span>
                    </Col>
                    <Col className="text-end">
                      <span className="fw-bold" style={{ color: 'red' }}>
                        -Rp {Helper.formatMoney(dataDetail?.otopoints?.point_spent)}
                      </span>
                    </Col>
                  </Row>
                  {dataDetail?.booking?.booking_type == 'b2c' && <Divider />}
                  <Row>
                    <Col>
                      <span className="fw-bold">
                        {dataDetail?.booking?.booking_type == 'b2c' ? 'Total' : 'Total Pembayaran'}
                      </span>
                    </Col>
                    <Col className="text-end">
                      <span
                        className={dataDetail?.booking?.booking_type == 'b2c' ? 'fw-bold' : 'fw-bold font-16'}
                        style={{ color: dataDetail?.booking?.booking_type == 'b2c' ? '' : '#002EB4' }}
                      >
                        {dataDetail?.payment_details?.status === 'settlement' ? (
                          <>Rp {Helper.formatMoney(dataDetail?.bill?.amount)}</>
                        ) : (
                          <>
                            {+dataDetail?.discount?.amount > 0 || +dataDetail?.otopoints?.point_spent > 0 ? (
                              <>
                                Rp{' '}
                                {Helper.formatMoney(
                                  +totalAmount - +dataDetail?.discount?.amount - +dataDetail?.otopoints?.point_spent
                                )}
                              </>
                            ) : (
                              <>Rp {Helper.formatMoney(totalAmount)}</>
                            )}
                          </>
                        )}
                      </span>
                    </Col>
                  </Row>
                  {dataDetail?.booking?.booking_type == 'b2c' && (
                    <>
                      <Row>
                        <Col>
                          <span className="fw-bold">Pembulatan</span>
                        </Col>
                        <Col className="text-end">
                          <span className="fw-bold">
                            {dataDetail?.payment_details?.status === 'settlement' ? (
                              <>Rp {Helper.formatMoney(dataDetail?.bill?.rounding)}</>
                            ) : (
                              <>
                                {+dataDetail?.discount?.amount > 0 || +dataDetail?.otopoints?.point_spent > 0 ? (
                                  <>
                                    Rp{' '}
                                    {Helper.formatMoney(
                                      roundingCalculation(
                                        +totalAmount -
                                          +dataDetail?.discount?.amount -
                                          +dataDetail?.otopoints?.point_spent
                                      ).rounding
                                    )}
                                  </>
                                ) : (
                                  <>Rp {Helper.formatMoney(roundingCalculation(totalAmount).rounding)}</>
                                )}
                              </>
                            )}
                          </span>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col>
                          <span className="fw-bold">Total Pembayaran</span>
                        </Col>
                        <Col className="text-end">
                          <span className="fw-bold font-16" style={{ color: '#002EB4' }}>
                            {dataDetail?.payment_details?.status === 'settlement' ? (
                              <>Rp {Helper.formatMoney(dataDetail?.bill?.grand_total)}</>
                            ) : (
                              <>
                                {+dataDetail?.discount?.amount > 0 || +dataDetail?.otopoints?.point_spent > 0 ? (
                                  <>
                                    Rp{' '}
                                    {Helper.formatMoney(
                                      roundingCalculation(
                                        +totalAmount -
                                          +dataDetail?.discount?.amount -
                                          +dataDetail?.otopoints?.point_spent
                                      ).grand_total
                                    )}
                                  </>
                                ) : (
                                  <>Rp {Helper.formatMoney(roundingCalculation(totalAmount).grand_total)}</>
                                )}
                              </>
                            )}
                          </span>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>
          {dataDetail?.payment_methods?.length > 0 && dataDetail?.payment_details?.status !== 'pending' && (
            <>
              <Row className="mb-4 mt-4">
                <Col md={12} className="d-flex">
                  <span className="font-weight-bold" style={{ fontSize: 16 }}>
                    Metode Pembayaran
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} className="box-payment">
                  {dataDetail?.payment_methods?.map((item, index) => {
                    if (item?.value !== 'Returned') {
                      return (
                        <Row key={index}>
                          <Col>
                            <span className="fw-bold">Pembayaran {index + 1 + ' - ' + item?.value}</span>
                          </Col>
                          <Col className="text-end">
                            <span className="fw-bold">Rp {Helper.formatMoney(item?.amount)}</span>
                          </Col>
                        </Row>
                      );
                    }
                  })}
                  {dataDetail?.bill?.return_amount !== null && (
                    <Row>
                      <Col>
                        <span className="fw-bold">Kembalian</span>
                      </Col>
                      <Col className="text-end">
                        <span className="fw-bold">Rp {Helper.formatMoney(dataDetail?.bill?.return_amount)}</span>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </>
          )}
        </CardBody>
        <CardFooter style={{ backgroundColor: 'transparent' }}>
          <Row className="py-2">
            {editOrder ? (
              <Col lg={12} md={12} sm={12} className="d-flex justify-content-end">
                <Button
                  color="primary"
                  className="mr-2"
                  outline
                  onClick={() => {
                    let tempDetailItem = [...dataDetail?.items];
                    tempDetailItem = tempDetailItem.map((item) => ({
                      ...item,
                      is_deleted: false,
                    }));
                    setDetailItem(tempDetailItem);
                    setEditOrder(false);
                  }}
                  disabled={loading}
                >
                  Batal
                </Button>
                <Button
                  color="primary"
                  className="mr-2"
                  onClick={() => submitReviseOrder()}
                  disabled={loading}
                  style={{ minWidth: 155 }}
                >
                  {loading ? <Spinner size="sm" /> : 'Simpan Update'}
                </Button>
              </Col>
            ) : (
              <Col lg={12} md={12} sm={12} className="d-flex justify-content-end">
                {bookingStatus !== undefined && renderConfirmButton(bookingStatus)}
              </Col>
            )}
          </Row>
        </CardFooter>
      </Card>
      <ConfirmModal
        modal={modalConfirm?.received}
        icon={
          <div className="icon-bg-warning">
            <img src={AlertTriangleIcon} alt="alert circle" />
          </div>
        }
        title="Konfirmasi Order ?"
        description="Pastikan anda sudah yakin untuk mengkonfirmasi order yang akan datang."
        textSubmit="Konfirmasi Order"
        textCancel="Kembali"
        onSubmit={() => changeStatus('confirm', 'Order berhasil dikonfirmasi', 'success')}
        onCancel={() => setModalConfirm('received', !modalConfirm?.received)}
        loading={loading}
      />
      <ConfirmModal
        modal={modalConfirm?.reject}
        icon={
          <div className="icon-bg-danger">
            <img src={AlertCircleIcon} alt="alert circle" />
          </div>
        }
        title="Tolak Order?"
        description="Order yang telah ditolak tidak dapat diambil kembali. Yakin ingin tolak order?"
        textSubmit="Tolak Order"
        textCancel="Kembali"
        onSubmit={() => changeStatus('cancel', 'Order berhasil ditolak', 'failed')}
        onCancel={() => setModalConfirm('reject', !modalConfirm?.reject)}
        loading={loading}
      />
      <ConfirmModal
        modal={modalConfirm?.finishService}
        icon={
          <div className="icon-bg-warning">
            <img src={AlertTriangleIcon} alt="alert circle" />
          </div>
        }
        title="Selesaikan Servis?"
        description="Pastikan anda sudah yakin untuk menyelesaikan servis yang telah dikerjakan."
        textSubmit="Selesaikan Servis"
        textCancel="Kembali"
        onSubmit={() => changeStatus('service-done', 'Sukses mengupdate status', 'success')}
        onCancel={() => setModalConfirm('finishService', !modalConfirm?.finishService)}
        loading={loading}
      />
      <ConfirmModal
        modal={modalConfirm?.finishOrder}
        icon={
          <div className="icon-bg-warning">
            <img src={AlertTriangleIcon} alt="alert circle" />
          </div>
        }
        title="Selesaikan Order?"
        description="Pastikan anda sudah yakin untuk menyelesaikan order yang sesuai."
        textSubmit="Selesaikan Order"
        textCancel="Kembali"
        onSubmit={() => changeStatus('finish', 'Sukses mengupdate status', 'success')}
        onCancel={() => setModalConfirm('finishOrder', !modalConfirm?.finishOrder)}
        loading={loading}
      />
      <ConfirmModal
        modal={modalConfirm?.onProcess}
        icon={
          <div className="icon-bg-danger">
            <img src={AlertCircleIcon} alt="alert circle" />
          </div>
        }
        title="Konfirmasi Kedatangan"
        description="Pastikan anda sudah yakin mengkonfirmasi order dan data mobil yang akan diproses."
        textSubmit="Konfirmasi"
        textCancel="Kembali"
        onSubmit={() => changeStatus('on-process', 'Order berhasil dikonfirmasi', 'success')}
        onCancel={() => setModalConfirm('onProcess', !modalConfirm?.onProcess)}
        loading={loading}
      />
      <ConfirmModal
        modal={modalConfirm?.carInspection}
        icon={
          <div className="icon-bg-warning">
            <img src={AlertTriangleIcon} alt="alert circle" />
          </div>
        }
        title="Anda belum Menambahkan Hasil Inspeksi"
        description="Kamu bisa tetap melanjutkan ke Selesaikan Servis, atau menambahkan Hasil Inspeksi terlebih dahulu"
        textSubmit="Selesaikan Servis"
        textCancel="Tambahkan Hasil Inspeksi"
        onSubmit={() => changeStatus('service-done', 'Sukses mengupdate status', 'success')}
        onCancel={() => handleSetModalInspectionClick(!modalCreateInspection, '')}
        loading={loading}
      />
      <CreateCarInspectionModal
        modal={modalCreateInspection}
        toggle={() => handleSetModalInspectionClick(!modalCreateInspection, '')}
        onSuccess={setCurrentInspection}
        bookingData={dataDetail}
        source={source}
      />
      <CustomModal
        modal={modalConfirm?.refund}
        modalHeader="Konfirmasi Data Refund"
        modalWidth={600}
        backdrop="static"
        toggle={() => {
          clearValidFormRefund();
          setModalConfirm('refund', !modalConfirm?.refund);
        }}
        fullScreen="lg"
        size="lg"
        onExit={() => clearValidFormRefund()}
      >
        <Row className="mb-4">
          <Col>
            <span>Lengkapi terlebih dahulu informasi berikut untuk melanjutkan proses refund</span>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <FormGroup>
              <Label className="fw-bold label-required">Nama Bank</Label>
              <SelectField
                colWidth={12}
                style={{ padding: 0 }}
                disabled={loading}
                value={formRefund.bank}
                invalid={formValidRefund && formErrorRefund?.bankName}
                errorMessage={''}
                closeMenuOnSelect={true}
                options={listBank}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.code}
                placeholder="Pilih bank..."
                onChange={(e) => {
                  setFormRefund({ ...formRefund, bank: e, bankName: e?.name });
                  if (e?.code) {
                    setFormErrorRefund({ ...formErrorRefund, bankName: '' });
                  } else {
                    setFormErrorRefund({ ...formErrorRefund, bankName: 'Bank wajib dipilih' });
                  }
                }}
              />
              {formValidRefund && formErrorRefund?.bankName && (
                <div className="invalid-feedback d-block">{formErrorRefund?.bankName}</div>
              )}
            </FormGroup>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <FormGroup>
              <Label className="fw-bold label-required">Nomor Rekening</Label>
              <Input
                placeholder="Masukkan nomor rekening Anda"
                value={formRefund?.accountNumber}
                disabled={loading}
                maxLength={16}
                onChange={(e) => {
                  const regex = /^[0-9\b]+$/;
                  if (e.target.value === '' || regex.test(e.target.value)) {
                    setFormRefund({ ...formRefund, accountNumber: e.target.value });

                    if (e.target.value > 0) {
                      setFormErrorRefund({ ...formErrorRefund, accountNumber: '' });
                    } else {
                      setFormErrorRefund({ ...formErrorRefund, accountNumber: 'Nomor rekening wajib diisi' });
                    }
                  }
                }}
                invalid={formValidRefund && formErrorRefund?.accountNumber}
              />
              {formValidRefund && formErrorRefund?.accountNumber && (
                <FormFeedback>{formErrorRefund?.accountNumber}</FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <FormGroup>
              <Label className="fw-bold label-required">Nama Pemegang Rekening</Label>
              <Input
                placeholder="Masukkan nama pemegang rekening"
                value={formRefund?.accountName}
                disabled={loading}
                onChange={(e) => {
                  setFormRefund({ ...formRefund, accountName: e.target.value });
                  if (e.target.value.length > 0) {
                    setFormErrorRefund({ ...formErrorRefund, accountName: '' });
                  } else {
                    setFormErrorRefund({ ...formErrorRefund, accountName: 'Nama pemegang wajib diisi' });
                  }
                }}
                invalid={formValidRefund && formErrorRefund?.accountName}
              />
              {formValidRefund && formErrorRefund?.accountName && (
                <FormFeedback>{formErrorRefund?.accountName}</FormFeedback>
              )}
            </FormGroup>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <FormGroup>
              <Label className="fw-bold label-required">Alasan Refund</Label>
              <Input
                placeholder="Alasan"
                value={formRefund?.reason}
                disabled={loading}
                onChange={(e) => {
                  if (e.target.length <= 100) {
                    setFormRefund({ ...formRefund, reason: e.target.value });
                  } else {
                    setFormRefund({ ...formRefund, reason: Helper.stringTruncate(e.target.value, 100) });
                  }

                  if (e.target.value.length > 0) {
                    let msg = '';
                    if (e.target.value.length > 100) msg = 'Maksimal 100 karakter';
                    setFormErrorRefund({ ...formErrorRefund, reason: msg });
                  } else {
                    setFormErrorRefund({ ...formErrorRefund, reason: 'Alasan wajib diisi' });
                  }
                }}
                invalid={formValidRefund && formErrorRefund?.reason}
                maxLength={100}
              />
              {formValidRefund && formErrorRefund?.reason && <FormFeedback>{formErrorRefund?.reason}</FormFeedback>}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12} />
          <Col lg={3} md={3} sm={12} className="text-center">
            <Button
              style={{ width: '100%' }}
              color="primary"
              outline
              onClick={() => setModalConfirm('refund', !modalConfirm?.refund)}
              disabled={loading}
            >
              Kembali
            </Button>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <Button style={{ width: '100%' }} color="primary" onClick={() => handleRefund()} disabled={loading}>
              {loading ? <Spinner size={'sm'} /> : 'Lanjutkan'}
            </Button>
          </Col>
        </Row>
      </CustomModal>
      <ConfirmModal
        modal={modalConfirm?.cancel}
        icon={
          <div className="icon-bg-danger">
            <img src={AlertCircleIcon} alt="alert circle" />
          </div>
        }
        title="Batalkan Order?"
        description="Order akan dibatalkan. Lengkapi terlebih dahulu alasan pembatalan order."
        textSubmit="Batalkan Order"
        textCancel="Kembali"
        onSubmit={() => {
          +cancelNotes?.length === 0
            ? (setAlert('Alasan pembatalan harus diisi', 'failed'), setErrorFormNotes(true))
            : changeStatus('cancel', 'Order berhasil dibatalkan', 'failed');
        }}
        onCancel={() => setModalConfirm('cancel', !modalConfirm?.cancel)}
        loading={loading}
      >
        <Row className="mt-4">
          <Col>
            <FormGroup>
              <Label className="fw-bold label-required float-left">Alasan pembatalan</Label>
              <Input
                placeholder="Tuliskan alasan pembatalan"
                type="textarea"
                value={cancelNotes}
                disabled={loading}
                onChange={(e) => {
                  setCancelNotes(e.target.value);
                  if (+cancelNotes?.length === 0) setErrorFormNotes(true);
                  if (+cancelNotes?.length > 0) setErrorFormNotes(false);
                }}
                invalid={errorFormNotes && (+cancelNotes?.length === 0 || +cancelNotes?.length > 450)}
                max={450}
              />
              {+cancelNotes?.length === 0 && errorFormNotes && (
                <FormFeedback className="float-left">Alasan wajib diisi</FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>
      </ConfirmModal>
      <ChooseItemModal
        isOpen={modalAddNew}
        title={titleModalAddNew}
        toggle={(e) => setModalAddNew(e)}
        userCarId={dataDetail?.user_car?.car_details?.id ?? ''}
        defaultQty={defaultQty}
        onSubmit={(e) => handleSubmitNewItem(e)}
        defaultPrice={defaultPrice}
        customerType={dataDetail?.customer?.customer_type}
        paymentStatus={dataDetail?.payment_details?.status}
      />
    </Wrapper>
  );
};

export default Detail;
