import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from '../utils';
import CardImg from './CardImg';
import Badge from '../badge/Badge';
import Card from './Card';
import Icon from '../icon/Icon';

const propTypes = {
  image: PropTypes.string,
  roundedImage: PropTypes.bool,
  imageWidth: PropTypes.number,
  iconSize: PropTypes.number,
  imageClassName: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  titleBadgeColor: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleClassName: PropTypes.string,
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ratingImage: PropTypes.string,
  comment: PropTypes.string,
  commentImage: PropTypes.string,
  rightBadgeClassName: PropTypes.string,
  tag: tagPropType,
  className: PropTypes.string,
  cssModule: PropTypes.object,
};

const defaultProps = {
  tag: 'div',
  roundedImage: true,
  imageWidth: 30,
  iconSize: 13,
};

const CardOtotips = (props) => {
  const {
    image,
    imageWidth,
    roundedImage,
    imageClassName,
    title,
    titleBadgeColor,
    titleClassName,
    subtitle,
    subtitleClassName,
    rating,
    ratingImage,
    comment,
    commentImage,
    rightBadgeClassName,
    iconSize,
    tag: Tag,
    className,
    cssModule,
    ...attributes
  } = props;

  const classes = mapToCssModules(classNames('card-ototips', 'border-0', className), cssModule);

  const imageClasses = mapToCssModules(classNames('left-image', 'flex-shrink-0', 'me-3', imageClassName), cssModule);

  const titleClasses = mapToCssModules(classNames('badge-title', titleClassName), cssModule);

  const rightBadgeImageClasses = mapToCssModules(classNames('h-auto', 'w-auto'), cssModule);

  const rightBadgeTextClasses = mapToCssModules(classNames('fw-medium', 'right-badge', 'ps-2'), cssModule);

  const subtitleClasses = mapToCssModules(classNames('subtitle', subtitleClassName), cssModule);

  return (
    <Tag {...attributes}>
      <Card className={classes}>
        <div className="d-flex">
          <CardImg rounded={roundedImage} width={imageWidth} src={image} className={imageClasses} alt="" />

          <div className="pb-3 mb-0 small lh-sm w-100">
            <div className="d-flex justify-content-between">
              <Badge color={titleBadgeColor} className={titleClasses}>
                {title}
              </Badge>

              <div className="d-flex">
                {ratingImage && (
                  <div>
                    <Icon
                      card
                      textRight
                      image={ratingImage}
                      imageWidth={iconSize}
                      iconClassName={rightBadgeImageClasses}
                      textClassName={rightBadgeTextClasses}
                      title={rating}
                      alt=""
                    />
                  </div>
                )}

                {commentImage && (
                  <div className="ms-3">
                    <Icon
                      card
                      textRight
                      image={commentImage}
                      imageWidth={iconSize}
                      iconClassName={rightBadgeImageClasses}
                      textClassName={rightBadgeTextClasses}
                      title={comment}
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="mt-2">
              <span className={subtitleClasses}>{subtitle}</span>
            </div>
          </div>
        </div>
      </Card>
    </Tag>
  );
};

CardOtotips.propTypes = propTypes;
CardOtotips.defaultProps = defaultProps;

export default CardOtotips;
