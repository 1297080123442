import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import Wrapper from '@components/wrapper/Wrapper';
import Loading from '@components/loading/Loading';
import Helper from '@helpers/Helper';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import ModalPayment from '@components/payments/ModalPayment';
import { FormGroup, Tooltip, Badge } from 'reactstrap';
import { Input, Button, Row, Col, Card, CardBody, Label } from '../../components/otoklix-elements';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import NoImage from '@assets/images/no-image.jpeg';
import SelectIcon from '@assets/icons/select-icon.svg';
import CopyIcon from '@assets/icons/copy.svg';
import InputField from '@components/field/InputField';
import CloseWhite from '@assets/icons/close-white.svg';
import ClearInput from '@assets/icons/clear-input.svg';
import PrintReceiptPdf from '@helpers/pdf/ReceiptPdf.js';
import { map } from 'lodash';
import NotFound from '@assets/images/logout.png';
import Skeleton from '@components/skeleton/Skeleton';
import TitlePage from '@components/dashboard/TitlePage';
import useAlert from '@hooks/useAlert';
import Modalcampaign from '@components/payments/ModalCampaign';
import ModalCampaignItem from '@components/payments/ModalCampaignItem';
import ModalCampaignItemPackage from '@components/payments/ModalCampaignItemPackage';
import cardList from '@assets/icons/card-list.svg';
import CustomModal from '@components/modal/CustomModal';
import AlertTriangleIcon from '@assets/icons/alert-triangle.svg';

const Payments = () => {
  const { generatePermission } = userPermission();
  const history = useHistory();
  const { id } = useParams();
  const api = new API('v2');
  const { setAlert } = useAlert();

  const [loading, setLoading] = useState(false);
  const [loadingBill, setLoadingBill] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [disableOtopoint, setDisableOtopoint] = useState(false);
  const [hasUsedOtopoints, setHasUsedOtopoint] = useState(false);
  const [disablePromo, setDisablePromo] = useState(false);
  const [disablePaymentMethod, setDisablePaymentMethod] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({});
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [customerMoney, setCustomerMoney] = useState({ formatMoney: null, formatMoneyString: '' });
  const [erroCustomerMoney, setErrorCustomerMoney] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [promoNominal, setPromoNominal] = useState({ formatMoney: 0, formatMoneyString: '0' });
  const [erroPromoCode, setErrorPromoCode] = useState({});
  const [isValidPromo, setIsValidPromo] = useState(null);
  const [isValidpaymentMethod, setIsValidpaymentMethod] = useState(null);
  const [options, setOptions] = useState([]);
  const [hasNotif, setHasNotif] = useState();
  const [notification, setNotification] = useState({});
  const [checked, setChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedPayment, setSelectedPayment] = useState([]);
  const [orderDetailsItem, setOrderDetailsItem] = useState([]);
  const [indexCampaignItem, setIndexCampaignItem] = useState({});
  const [totalPromoPerLine, setTotalPromoPerLine] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalCampaign, setModalCampaign] = useState(false);
  const [modalCampaignItem, setModalCampaignItem] = useState(false);
  const [modalCampaignItemPackage, setModalCampaignItemPackage] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [insuffientAmount, setInsuffientAmount] = useState(0);
  const [errorPromoPerItem, setErrorPromoPerItem] = useState(false);
  const [hasLoading, setHasLoading] = useState(false);
  const [errorMessagePromoPerItem, setErrorMessagePromoPerItem] = useState('');

  const toggle = () => {
    if (paymentDetails?.otopoint?.customer !== paymentDetails?.otopoint?.redeem) {
      setTooltipOpen(!tooltipOpen);
    }
  };

  const handlePaymentMethodModal = () => {
    if (!disablePaymentMethod) {
      setOptions(paymentMethod);
      setModal(true);
    }
  };

  const handleApplyPayment = (data) => {
    const selectedButton = data.filter((item) => item.selected);

    if (selectedButton?.length > 0) {
      setPaymentMethod(data);
      setIsValidpaymentMethod(true);
      setModal(!modal);
      calculateBills(promoCode, checked, customerMoney?.formatMoney, data);
    }
  };

  const generatePaymentMethod = () => {
    const selectedButton = paymentMethod?.filter((item) => item.selected);
    const result = map(selectedButton, (item) => {
      return item?.label;
    }).join(', ');

    return selectedButton?.length > 0 ? result : 'Pilih Metode Pembayaran';
  };

  const calculateTotalPromoPerLine = () => {
    const total = orderDetailsItem.reduce((acc, currentValue) => {
      return acc + currentValue?.discount_per_row?.discount?.value ?? 0;
    }, 0);
    setTotalPromoPerLine(total);
  };

  const handleCloseModal = () => {
    const filterData =
      selectedPayment?.length > 0
        ? map(selectedPayment, (item) => {
            return item?.slug;
          })
        : ['initiate'];

    const newState = paymentMethod.map((item) =>
      new RegExp(filterData.join('|')).test(item.value) ? { ...item, selected: true } : { ...item, selected: false }
    );
    setPaymentMethod(newState);
    setIsValidpaymentMethod(true);
    setModal(!modal);
  };

  const handleChangeCampaign = (data) => {
    const nominal = {
      formatMoney: 0,
      formatMoneyString: Helper.numberWithCommas(0),
    };
    setPromoCode(data?.code?.toUpperCase()?.replace(' ', ''));
    calculateBills(data?.code, checked, customerMoney?.formatMoney, paymentMethod, true);
    setModalCampaign(false);
    setErrorPromoCode({});
    setIsValidPromo(null);
  };

  const handleOpenCampaignItem = (index, price, isItem) => {
    isItem ? setModalCampaignItem(true) : setModalCampaignItemPackage(true);
    setIndexCampaignItem({ index: index, price: price });
  };

  const calcValueDiscount = (disc, price, data) => {
    if (+price === 0) setAlert(`Nominal Rp 0 Tidak bisa menggunakan Promo Baris`, 'failed');
    if (disc?.type === 'percent') {
      if (data?.discount_for !== 'per-item') {
        let maxDiscount = disc?.max_discount ?? 0;
        let discount = Math.round(+price * (+disc?.value / 100));
        return discount > maxDiscount ? maxDiscount : discount;
      } else {
        let discount = Math.round(+price * (+disc?.value / 100));
        return discount;
      }
    } else {
      if (+disc?.value > price) {
        return price;
      } else {
        return +disc?.value;
      }
    }
  };

  const validationCampaignItem = (data) => {
    promoValidation(data);
  };

  const handleChangeCampaignItem = (data) => {
    const tempOrderDetailsitem = [...orderDetailsItem];
    let tempValueDiscount = {
      ...data,
      discount: {
        value: calcValueDiscount(data?.discount, +indexCampaignItem?.price, data),
      },
    };
    let updatedItem = { ...orderDetailsItem[+indexCampaignItem?.index], discount_per_row: tempValueDiscount };
    tempOrderDetailsitem[+indexCampaignItem?.index] = updatedItem;
    setOrderDetailsItem(tempOrderDetailsitem);
    setModalCampaignItem(false);
    setModalCampaignItemPackage(false);
  };

  useEffect(() => {
    calculateTotalPromoPerLine();
  }, [orderDetailsItem]);

  const handleRemmoveCampaignItem = (index) => {
    const tempOrderDetailsitem = [...orderDetailsItem];
    let updatedItem = { ...orderDetailsItem[index], discount_per_row: { discount: { value: 0 } } };
    tempOrderDetailsitem[index] = updatedItem;
    setOrderDetailsItem(tempOrderDetailsitem);
    setModalCampaignItem(false);
  };

  const handleChangePromoCode = (e) => {
    let value = e?.target?.value;
    setPromoCode(value?.toUpperCase()?.replace(' ', ''));
    setPromoNominal({ formatMoney: 0, formatMoneyString: '0' });
    setErrorPromoCode({});
    setIsValidPromo(null);
    if (
      paymentDetails?.promo?.is_valid &&
      (paymentDetails?.promo?.code?.length - value?.length == 1 ||
        value?.length - paymentDetails?.promo?.code?.length == 1)
    ) {
      const nominal = {
        formatMoney: 0,
        formatMoneyString: Helper.numberWithCommas(0),
      };
      const resetPayment = paymentMethod.map((item) => ({ ...item, nominal: nominal }));
      setPaymentMethod(resetPayment);
      setOptions(resetPayment);
      calculateBills(null, checked, customerMoney?.formatMoney, resetPayment);
    }
  };

  const handleClearPromoCode = () => {
    setPromoCode('');
    setIsValidPromo(null);
    setErrorPromoCode({});
    if (paymentDetails?.promo?.is_valid && !!paymentDetails?.promo?.code) {
      const nominal = {
        formatMoney: 0,
        formatMoneyString: Helper.numberWithCommas(0),
      };
      const resetPayment = paymentMethod.map((item) => ({ ...item, nominal: nominal }));
      setPaymentMethod(resetPayment);
      setOptions(resetPayment);
      calculateBills(null, checked, customerMoney?.formatMoney, resetPayment);
    }
  };

  const handleApplyPromo = () => {
    if (!disablePromo) {
      if (promoCode && !erroPromoCode?.status) {
        calculateBills(promoCode, checked, customerMoney?.formatMoney, paymentMethod, true);
      } else if (!promoCode) {
        setErrorPromoCode({ status: false, message: 'Masukkan kode promo terlebih dahulu' });
        setIsValidPromo(false);
      }
    }
  };

  const handleChangeCustomerMoney = (e) => {
    const value = e.target.value !== ' ' ? Helper.formatMoneyOnField(e?.target?.value) : '';
    if (value?.formatMoney <= 99999999) {
      setCustomerMoney(value);
      setErrorCustomerMoney('');
      calculateBills(
        paymentDetails?.promo?.is_valid && !!paymentDetails?.promo?.code ? promoCode : null,
        checked,
        value?.formatMoney
      );
    }
  };

  const handleChangeNominal = (index, e) => {
    const value = e.target.value !== ' ' ? Helper.formatMoneyOnField(e?.target?.value) : '';
    if (value?.formatMoney <= 99999999) {
      paymentMethod[index].nominal = value;
      setPaymentMethod([...options]);
      calculateBills(promoCode, checked, customerMoney?.formatMoney, paymentMethod, false, true);
    }
  };

  const handleNoChangeMoney = () => {
    const value = {
      formatMoney: paymentDetails?.payment_detail?.payment_amount,
      formatMoneyString: Helper.numberWithCommas(paymentDetails?.payment_detail?.payment_amount),
    };
    setCustomerMoney(value);
    calculateBills(
      paymentDetails?.promo?.is_valid && !!paymentDetails?.promo?.code ? promoCode : null,
      checked,
      paymentDetails?.payment_detail?.payment_amount
    );
    setErrorCustomerMoney('');
  };

  const handleApplyOtopoints = (checked) => {
    const nominal = {
      formatMoney: 0,
      formatMoneyString: Helper.numberWithCommas(0),
    };
    const resetPayment = paymentMethod.map((item) => ({ ...item, nominal: nominal }));
    setPaymentMethod(resetPayment);
    setOptions(resetPayment);
    calculateBills(
      paymentDetails?.promo?.is_valid && !!paymentDetails?.promo?.code ? promoCode : null,
      checked,
      customerMoney?.formatMoney,
      resetPayment
    );
  };

  const handleChecked = (checked) => {
    if (!disableOtopoint) {
      setChecked(checked);
      handleApplyOtopoints(checked);
    }
  };

  const validationForm = () => {
    let isValid = true;

    if (paymentMethod?.filter((item) => item.selected)?.length < 1) {
      isValid = false;
      setIsValidpaymentMethod(false);
    }

    let amounts = paymentDetails?.payment_method;
    let sum = amounts?.reduce((accumulator, item) => {
      return accumulator + (item?.slug !== 'returned' ? item?.amount ?? 0 : 0);
    }, 0);

    let sumMinusCash = amounts?.reduce((accumulator, item) => {
      return accumulator + (item?.slug == 'returned' || item?.slug == 'cash' ? 0 : item?.amount ?? 0);
    }, 0);

    let checkAmount = amounts?.some((item) => {
      if (item?.slug == 'returned' || item?.slug == 'cash') {
        return false;
      } else if (item?.slug !== 'returned' || item?.slug !== 'cash') {
        return item?.amount < 10000;
      }
    });

    if (amounts?.length > 0 && checkAmount) {
      isValid = false;
      setAlert(`Nominal Tiap Pembayaran kecuali Tunai minimal Rp 10.000`, 'failed');
    }

    if (
      amounts?.length > 0 &&
      !amounts.some((item) => item?.slug.includes('cash')) &&
      sum !== paymentDetails?.payment_detail?.grand_total
    ) {
      isValid = false;
      setAlert(`Nominal Pembayaran tidak sesuai`, 'failed');
    }

    /// with cash  ** total(all selected method - cash) !== payment_amount
    if (
      amounts?.length > 0 &&
      amounts.some((item) => item?.slug.includes('cash')) &&
      sumMinusCash > paymentDetails?.payment_detail?.grand_total
    ) {
      isValid = false;
      setAlert(`Nominal Pembayaran non tunai tidak sesuai`, 'failed');
    }

    if (amounts?.length > 0 && sum < paymentDetails?.payment_detail?.grand_total) {
      isValid = false;
      setAlert(`Nominal Pembayaran Kurang`, 'failed');
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    setDisableForm(true);
    const isValid = validationForm();
    if (isValid) {
      handlePaymentCompletion();
    } else {
      setDisableForm(false);
    }
  };

  const handlePrintReceipt = () => {
    getDataPayment();
  };

  async function getDataPayment() {
    // onLoading(true);
    PrintReceiptPdf.create(orderDetails, paymentDetails, paymentMethod);
    // onLoading(false);
  }

  const goToDetailPage = () => {
    history.push(`/order/detail/${id}`);
  };

  const allowPermission = (menu) => {
    return !!(generatePermission('booking', menu) || generatePermission('booking', 'all'));
  };

  const allowShow = allowPermission('all') || allowPermission('estimationshow');
  const allowApproval = allowPermission('all') || allowPermission('estimationapproval');

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const onResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  const calcInsuffientAmount = (paymentList) => {
    let tempTotalAmount;
    const totalPrice = paymentList.reduce((accumulator, currentItem) => {
      return accumulator + currentItem?.nominal?.formatMoney;
    }, 0);
    tempTotalAmount = totalPrice;
    const a = +paymentDetails?.payment_detail?.grand_total - +tempTotalAmount;
    setInsuffientAmount(a);
  };

  const promoValidation = (data) => {
    setHasLoading(true);

    const item = orderDetailsItem[+indexCampaignItem?.index];
    const type = item?.flag_type == 'paket' ? 'paket' : 'produk';

    let payload = {
      promo_code: data?.code ?? '',
      items: {
        id: item?.package_id ?? item?.product_id ?? null,
        type: type,
        price: item?.price,
      },
    };

    return api
      .post(`v2/gms/campaign/${orderDetails?.workshop?.slug}/validate`, payload)
      .then((response) => {
        setHasLoading(false);
        handleChangeCampaignItem(data);
      })
      .catch((err) => {
        const error = err?.response?.data?.error?.message ?? 'not found';
        setHasLoading(false);
        setErrorPromoPerItem(true);
        setErrorMessagePromoPerItem(error);
      });
  };

  const calculateBills = (promo_code, use_otopoint, paid, pay_method, hitApply = false, changeNominal = false) => {
    setLoadingBill(true);

    let params = {
      promo_code,
      use_otopoint,
      paid,
      total_voucher_line: totalPromoPerLine ?? 0,
    };

    let pay = '';
    if (pay_method?.length > 0) {
      const selectedButton = pay_method?.filter((item) => item.selected);
      pay = map(selectedButton, (item) => {
        return item?.value + '=' + item?.nominal?.formatMoney;
      }).join('&');
    }

    const cashPayment = pay_method.map((item) => (item.value === 'cash' ? { ...item, selected: true } : item));

    return api
      .get(`v2/gms/booking/${id}/bill?${pay}`, { params })
      .then((response) => {
        const data = response?.data?.data;
        setSelectedPayment(data?.payment_method);
        if (data) {
          setPaymentDetails(data ?? {});
          setHasUsedOtopoint(data?.otopoint?.has_used_otopoint);

          if (data?.promo?.code && data?.promo?.is_valid && !changeNominal) {
            setErrorPromoCode({ status: true, message: `Kode promo dapat digunakan` });
            setIsValidPromo(true);
            if (hitApply) {
              const nominal = {
                formatMoney: 0,
                formatMoneyString: Helper.numberWithCommas(0),
              };
              const resetPayment = paymentMethod.map((item) => ({ ...item, nominal: nominal }));
              setPaymentMethod(resetPayment);
              setOptions(resetPayment);
              calculateBills(promo_code, checked, customerMoney?.formatMoney, resetPayment);
            }
          } else if (data?.promo?.code && !data?.promo?.is_valid) {
            if (!changeNominal) {
              setErrorPromoCode({ status: false, message: data?.promo?.message ?? 'Kode promo tidak dapat digunakan' });
              setIsValidPromo(false);
            }
          }
          if (data?.otopoint?.redeem == 0) {
            setDisableOtopoint(true);
            setChecked(false);
          } else if (data?.otopoint?.redeem > 0 && !data?.otopoint?.has_used_otopoint) {
            setDisableOtopoint(false);
          }
          // if (data?.payment_detail?.payment_amount < 10000) {
          //   setPaymentMethod(cashPayment);
          //   setDisablePaymentMethod(true);
          //   setIsValidpaymentMethod(true);
          // } else {
          //   setDisablePaymentMethod(false);
          // }
          setLoading(false);
          setLoadingBill(false);
        }
      })
      .catch((err) => {
        const error = err?.response?.data?.error?.message ?? '';
        const errorUp = error?.charAt(0).toUpperCase() + error?.slice(1);
        setPaymentDetails({});
        setLoading(false);
        setLoadingBill(false);
        setDisableForm(false);
        setErrorMessage(errorUp);
        // handleResultNotification(true, 'failed', `Gagal` + error);
        // setErrorPromoCode('Kode promo tidak dapat digunakan');
      });
  };

  const handlePaymentCompletion = () => {
    setLoading(true);

    const selectedButton = paymentMethod.filter((item) => item.selected);
    const mappingPayment =
      selectedButton?.length > 1
        ? selectedButton?.filter((item) => !(item?.value == 'cash' && item?.nominal?.formatMoney == 0))
        : selectedButton;

    const payment = mappingPayment?.map((item) => {
      if (item?.value == 'cash' && item?.nominal?.formatMoney == 0) {
      }
      return { value: item?.value, amount: item?.nominal?.formatMoney };
    });

    const mappingVoucherProducts = orderDetailsItem.map((item) => ({
      booking_package_id: item?.booking_package_id ?? null,
      booking_product_id: item?.booking_product_id ?? null,
      voucher_code: item?.discount_per_row?.code ?? item?.voucher_code ?? '',
    }));

    let payload = {
      pay_method: payment ?? [],
      onsite_payment_method_slug: paymentMethod?.value ?? '',
      promo_code: erroPromoCode?.status ? promoCode : null,
      use_otopoint: checked,
      cash: paymentMethod?.value == 'cash' ? paymentDetails?.cash?.paid : paymentDetails?.payment_detail?.grand_total,
      voucher_products: [...mappingVoucherProducts],
    };

    return api
      .post(`v2/gms/payment/${id}`, payload)
      .then((response) => {
        setLoading(false);
        setDisableForm(false);
        setAlert(`Berhasil melakukan pembayaran`, 'success');
        setTimeout(() => {
          history.push(`/order/detail/${id}`);
        }, 300);
      })
      .catch((err) => {
        const error = err?.response?.data?.error?.message ?? '';
        setAlert(`Gagal melakukan pembayaran ${error}`, 'failed');
        setLoading(false);
        setDisableForm(false);
      });
  };

  const mappingPaymentOption = (data) => {
    if (data?.length > 0) {
      const transformedOptions = data?.map((item) => {
        return { value: item?.slug, label: item?.gms_name, selected: false, nominal: Helper.formatMoneyOnField('0') };
      });
      setOptions(transformedOptions);
      setPaymentMethod(transformedOptions);
    }
  };

  const fetchOrderDetail = () => {
    setLoading(true);

    return api
      .get(`v2/gms/booking/${id}`)
      .then((response) => {
        const data = response?.data?.data;
        setOrderDetails(data ?? {});
        const mappingDataItem = data?.items?.map((item) => ({
          ...item,
          discount_per_row: { discount: { value: 0 } },
        }));
        setOrderDetailsItem([...mappingDataItem]);
        const usedOtopoint = data?.otopoints?.use_otopoint;
        const usedPromo = data?.discount?.promo;
        calculateBills(usedPromo ? usedPromo : null, usedOtopoint, null, paymentMethod);
        if (usedOtopoint) {
          setChecked(true);
          setDisableOtopoint(true);
        }
        if (usedPromo) {
          setDisablePromo(true);
          setPromoCode(usedPromo);
        }
        if (data?.bill?.amount < 10000) {
          setCustomerMoney({ formatMoney: null, formatMoneyString: '0' });
        }
      })
      .catch((err) => {
        const error = err?.response?.data?.error?.message ?? '';
        const errorUp = error?.charAt(0).toUpperCase() + error?.slice(1);
        setOrderDetails({});
        setLoading(false);
        setErrorMessage(errorUp);
      });
  };

  const fetchPaymentOption = () => {
    return api
      .get(`v2/gms/payment/onsite-method`)
      .then((response) => {
        mappingPaymentOption(response?.data?.data ?? []);
      })
      .catch(() => {
        setAlert(`Payment method options gagal didapatkan`, 'failed');
        mappingPaymentOption([]);
      });
  };

  useEffect(() => {
    const checkPaymentMethod = paymentMethod.filter((item) => item?.selected === true);
    if (+checkPaymentMethod?.length > 0) calcInsuffientAmount(paymentMethod);
  }, [paymentDetails, paymentMethod]);

  useEffect(() => {
    if (orderDetails?.booking?.booking_code) {
      if (promoCode == orderDetails?.discount?.promo || orderDetails?.discount?.promo == null) {
        calculateBills(promoCode, checked, customerMoney?.formatMoney, paymentMethod);
      }
    }
  }, [totalPromoPerLine, orderDetails]);

  useEffect(() => {
    fetchOrderDetail();
    fetchPaymentOption();
  }, []);

  const renderContent = () => {
    if (loading) {
      return <LoadingWrapper />;
    }

    return (
      <>
        <Wrapper
          className="payments"
          showNotification={hasNotif}
          notification={notification}
          onCloseNotification={handleCloseNotification}
        >
          <Row className="head">
            <Col lg={5} md={12} sm={12}>
              <TitlePage title={'Konfirmasi Pembayaran'} useBackButton />
            </Col>
          </Row>
          {orderDetails?.booking && paymentDetails?.payment_detail ? (
            <Row>
              <Col lg={7} sm={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={4} className="mb-3">
                        <div className="text-title-grey font-12">Order ID</div>
                        <div className="text-subtitle-grey font-14">{orderDetails?.booking?.booking_code ?? '-'}</div>
                      </Col>
                      <Col lg={4} className="mb-3">
                        <div className="text-title-grey font-12">Tanggal dan Waktu</div>
                        <div className="text-subtitle-grey font-14">
                          {moment(orderDetails?.booking?.booking_datetime).isValid()
                            ? moment(orderDetails?.booking?.booking_datetime).format('DD MMM YYYY, HH:mm')
                            : '-'}
                        </div>
                      </Col>
                      <Col lg={4} className="mb-3">
                        <div className="text-title-grey font-12">Booking Source</div>
                        <div className="text-subtitle-grey font-14">
                          {orderDetails?.booking?.booking_source?.name ?? '-'}
                        </div>
                      </Col>
                      <Col lg={12} className="mb-3">
                        <div className="text-title-grey font-12">Booking Note</div>
                        <div className="text-subtitle-grey font-14">
                          {!orderDetails?.booking?.booking_note ? '-' : orderDetails?.booking?.booking_note}
                        </div>
                      </Col>
                    </Row>
                    <div className="divider-line mt-0 mb-3">
                      <span />
                    </div>
                    <Row>
                      <Col lg={12} className={'mb-3'}>
                        <h4 className={'font-14 text-black text-bold-600 m-0'}>Informasi Pelanggan</h4>
                      </Col>
                      <Col lg={4} className="mb-3">
                        <div className="text-title-grey font-12">Nama Pelanggan</div>
                        <div className="text-subtitle-grey font-14">{orderDetails?.customer?.name ?? '-'}</div>
                      </Col>
                      <Col lg={4} className="mb-3">
                        <div className="text-title-grey font-12">No handphone</div>
                        <div className="text-subtitle-grey font-14">
                          {orderDetails?.customer?.phone_no?.replace('+62', '0')}
                        </div>
                      </Col>
                      <Col lg={4} />
                      <Col lg={4} className="mb-3">
                        <div className="text-title-grey font-12">Kendaraan</div>
                        <div className="text-subtitle-grey font-14">
                          {orderDetails?.user_car?.car_details?.car_model?.brand?.name +
                            ' ' +
                            orderDetails?.user_car?.car_details?.car_model?.model_name +
                            ' ' +
                            orderDetails?.user_car?.car_details?.variant}
                        </div>
                      </Col>
                      <Col lg={2} className="mb-3">
                        <div className="text-title-grey font-12">Plat Nomor</div>
                        <div className="text-subtitle-grey font-14">{orderDetails?.user_car?.license_plate ?? '-'}</div>
                      </Col>
                      <Col lg={6} className="mb-3">
                        <div className="text-title-grey font-12">
                          Membership
                          {moment(orderDetails?.membership?.expired_at) < moment(new Date().now) && (
                            <Badge className="p-1 ml-1 badge-danger">Expired</Badge>
                          )}
                        </div>
                        <div className="text-subtitle-grey font-14">
                          {orderDetails?.membership?.name ?? '-'}{' '}
                          {orderDetails?.membership?.expired_at
                            ? `(${moment(orderDetails?.membership?.expired_at).format('DD/MM/YYYY')})`
                            : ''}
                        </div>
                      </Col>
                    </Row>
                    <div className="divider-line mt-0 mb-3">
                      <span />
                    </div>
                    <Row>
                      <Col lg={12} className={''}>
                        <h4 className={'font-14 text-black text-bold-600 m-0'}>Ringkasan Belanja</h4>
                      </Col>
                      <Col lg={12} md={12} sm={12}>
                        {orderDetailsItem?.length > 0 &&
                          orderDetailsItem.map((item, index) => {
                            return (
                              <div className="mt-3" key={index}>
                                <Card className="cart">
                                  <CardBody style={{ borderRadius: '0.5rem', zIndex: 10, backgroundColor: '#FCFCFC' }}>
                                    <Row>
                                      <Col lg={3} md={2} sm={3} className="">
                                        <div className="gallery-container-item">
                                          <div className="box mb-2">
                                            <img src={item?.image_link ?? NoImage} height={80}></img>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={9} md={10} sm={9} className="">
                                        <div className="text-subtitle-grey font-14">{item?.name ?? '-'}</div>
                                        {item?.item_details?.map((products, i) => {
                                          return (
                                            <div className="text-title-grey font-14" key={i}>{`${
                                              products?.qty ?? '-'
                                            } x ${products?.name ?? '-'}`}</div>
                                          );
                                        })}
                                        <div className={`divider-line ${item?.package_details ? 'my-3' : 'mt-4 mb-3'}`}>
                                          <span />
                                        </div>
                                        <Row>
                                          <Col lg={6}>
                                            <div className="text-subtitle-grey font-14">{`${
                                              item?.qty
                                            } x Rp${Helper.numberWithCommas(
                                              item?.unit_price ?? +item?.voucher_amount > 0
                                                ? item?.price + +item?.voucher_amount
                                                : item?.price ?? '-'
                                            )}`}</div>
                                          </Col>
                                          <Col lg={6}>
                                            {(+item?.discount_per_row?.discount?.value > 0 ||
                                              +item?.voucher_amount > 0) && (
                                              <div className="text-red text-right text-bold-400 font-12 text-decoration-line-through">{`Rp${Helper.numberWithCommas(
                                                +item?.voucher_amount > 0
                                                  ? item?.price + +item?.voucher_amount
                                                  : item?.price
                                              )}`}</div>
                                            )}
                                            <div className="text-default-blue text-right text-bold-600 font-14">{`Rp${Helper.numberWithCommas(
                                              +item?.discount_per_row?.discount?.value > 0
                                                ? +item?.price - +item?.discount_per_row?.discount?.value
                                                : +item?.price
                                            )}`}</div>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                  {+item?.discount_per_row?.discount?.value > 0 ? (
                                    <CardBody onClick={() => handleRemmoveCampaignItem(index)} className="promo-body">
                                      <Row>
                                        <Col lg={12} md={12} sm={12} className="promo-col">
                                          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <div className="icon-close">
                                              <img src={CloseWhite} height={6} width={6} />
                                            </div>
                                            <span className="text-promo">
                                              {item?.discount_per_row?.name} ({item?.discount_per_row?.code})
                                            </span>
                                          </div>
                                          <span className="nominal-promo">
                                            - {`Rp${Helper.numberWithCommas(+item?.discount_per_row?.discount?.value)}`}
                                          </span>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  ) : (
                                    <>
                                      {+item?.voucher_amount > 0 ? (
                                        <CardBody className="promo-body">
                                          <Row>
                                            <Col lg={12} md={12} sm={12} className="promo-col">
                                              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                <span className="text-promo">
                                                  {item?.voucher_name ?? '-'} ({item?.voucher_code ?? '-'})
                                                </span>
                                              </div>
                                              <span className="nominal-promo">
                                                - {`Rp${Helper.numberWithCommas(+item?.voucher_amount ?? 0)}`}
                                              </span>
                                            </Col>
                                          </Row>
                                        </CardBody>
                                      ) : (
                                        <CardBody
                                          onClick={() =>
                                            handleOpenCampaignItem(
                                              index,
                                              +item?.price,
                                              !item?.item_details ? true : false
                                            )
                                          }
                                          className="promo-body"
                                        >
                                          <Row>
                                            <Col lg={12} md={12} sm={12} className="promo-col">
                                              <span className="nominal-promo">Pilih Promo</span>
                                              <div className="icon-plus">+</div>
                                            </Col>
                                          </Row>
                                        </CardBody>
                                      )}
                                    </>
                                  )}
                                </Card>
                              </div>
                            );
                          })}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={5} sm={12}>
                <Row>
                  <Col lg={12} sm={12}>
                    <Card className="card-bordered">
                      <CardBody>
                        <h4 className={'font-14 text-black text-bold-600 m-0'}>Otopoints</h4>
                        <FormGroup row className="m-0">
                          <Tooltip target="info-otopoints" isOpen={tooltipOpen} toggle={toggle}>
                            OtoPoints hanya dapat ditukar sebagian karena terdapat pembayaran minimum sebesar Rp10.000.
                          </Tooltip>
                          <Label
                            lg={8}
                            sm={8}
                            id={'info-otopoints'}
                            className={'text-left text-subtitle-grey font-14 text-bold-600 p-0 mt-3'}
                          >
                            {`Pakai ${
                              paymentDetails?.otopoint?.customer == paymentDetails?.otopoint?.redeem ? 'Semua' : ''
                            } `}
                            <span className="text-default-blue text-right text-bold-600 font-14">
                              {`${Helper.numberWithCommas(
                                paymentDetails?.otopoint?.redeem
                                  ? paymentDetails?.otopoint?.redeem ?? ''
                                  : paymentDetails?.otopoint?.used ?? ''
                              )} Otopoints`}
                            </span>
                          </Label>
                          <Col lg={4} sm={4}>
                            <div className="custom-control custom-switch switch-otopoints mt-3 mx-0 text-right">
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id={'promo'}
                                name={'promo'}
                                onChange={() => handleChecked(!checked)}
                                checked={checked}
                                disabled={disableForm || disableOtopoint}
                              />
                              <Label className="custom-control-label" htmlFor={'promo'}>
                                {checked ? '' : ''}
                              </Label>
                            </div>
                          </Col>
                        </FormGroup>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={12} sm={12} className={''}>
                    <Card className="card-bordered">
                      <CardBody>
                        <h4 className={'font-14 text-black text-bold-600 m-0'}>Kode Promo Transaksi</h4>
                        <Row className="mt-2">
                          <Col className="input-promo">
                            <Row>
                              <Col lg={2} sm={2} xs={12}>
                                <img
                                  className={`promo-icon-list ${disablePromo ? '' : 'cursor-pointer'}`}
                                  src={cardList}
                                  height="20px"
                                  onClick={() => {
                                    if (!disablePromo) {
                                      setModalCampaign(true);
                                    }
                                  }}
                                ></img>
                              </Col>
                              <Col lg={10} sm={10}>
                                <Input
                                  className={`input-custom ${
                                    isValidPromo !== null ? (isValidPromo ? 'valid' : 'invalid') : ''
                                  }`}
                                  inputName={'promoCode'}
                                  inputType={'text'}
                                  disabled={disableForm || disablePromo}
                                  placeholder="Masukkan Kode Promo"
                                  value={promoCode ?? ''}
                                  onChange={handleChangePromoCode}
                                />
                                <div className={`${isValidPromo ? 'text-green' : 'invalid-document'}`}>
                                  {erroPromoCode?.message ?? ''}
                                </div>
                              </Col>
                            </Row>
                            {!!promoCode && !disablePromo && (
                              <div className="clear-icon-box">
                                <img
                                  src={ClearInput}
                                  className="clear-icon cursor-pointer p-2"
                                  alt=""
                                  onClick={(e) => handleClearPromoCode()}
                                />
                              </div>
                            )}
                          </Col>
                          <Col lg={4} sm={4}>
                            <Button
                              color="primary"
                              size="sm"
                              className="w-p-100 m-0"
                              disabled={disablePromo}
                              onClick={() => handleApplyPromo()}
                            >
                              Pakai
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg={12} sm={12}>
                    <Card>
                      <CardBody>
                        <h4 className={'font-14 text-black text-bold-600 mt-0'}>Metode Pembayaran</h4>
                        <Row>
                          <div
                            className={`select-payment ${isValidpaymentMethod == false ? 'invalid' : ''}`}
                            onClick={() => handlePaymentMethodModal()}
                          >
                            <div className="text-field text-black font-14">{generatePaymentMethod()}</div>
                            <div className="icon-field-box">
                              <img className="icon-field" src={SelectIcon} height="20px"></img>
                            </div>
                          </div>
                          <div className={`invalid-document`}>
                            {isValidpaymentMethod == false ? 'Silahkan pilih metode pembayaran' : ''}
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  {paymentMethod?.filter((item) => item.selected)?.length > 0 &&
                    paymentMethod?.map((item, index) => {
                      if (item?.selected)
                        return (
                          <Col lg={12} sm={12} className={'m-0'} key={index}>
                            <Card className="card-bordered">
                              <CardBody>
                                <h4 className={'font-14 text-black text-bold-600 mt-0'}>{item?.label}</h4>
                                <Row>
                                  <InputField
                                    preffix="Rp"
                                    colWidth={12}
                                    className={`input-custom ${erroCustomerMoney ? 'invalid' : ''}`}
                                    inputName={'paymentmethod'}
                                    inputType={'text'}
                                    disabled={disableForm}
                                    placeholder="0"
                                    autoComplete="off"
                                    value={item?.nominal?.formatMoneyString}
                                    onChange={(e) => handleChangeNominal(index, e)}
                                  />
                                  <div className={`invalid-document`}>{erroCustomerMoney ?? ''}</div>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        );
                    })}
                  <Col lg={12} sm={12}>
                    <Card className="card-bordered">
                      <CardBody>
                        <h4 className={'font-14 text-black text-bold-600 m-0'}>Ringkasan Pembayaran</h4>
                        {true ? (
                          <Row>
                            <Col lg={6} sm={6} xs={12} className="text-title-grey font-14 mt-2">
                              Total Pesanan
                            </Col>
                            <Col lg={6} sm={6} xs={12} className="text-black text-bold-600 font-14 text-right mt-2">
                              {!loadingBill ? (
                                `Rp${Helper.numberWithCommas(paymentDetails?.payment_detail?.order_amount ?? '')}`
                              ) : (
                                <Skeleton height={15} />
                              )}
                            </Col>
                            <Col lg={6} sm={6} xs={12} className="text-title-grey font-14  mt-2">
                              Promo Transaksi
                            </Col>
                            <Col lg={6} sm={6} xs={12} className="text-red text-bold-600 font-14 text-right mt-2">
                              {!loadingBill ? (
                                `-Rp${Helper.numberWithCommas(paymentDetails?.promo?.amount ?? '')}`
                              ) : (
                                <Skeleton height={15} />
                              )}
                            </Col>
                            <Col lg={6} sm={6} className="text-title-grey font-14  mt-2">
                              Otopoints
                            </Col>
                            <Col lg={6} sm={6} className="text-red text-bold-600 font-14 text-right mt-2">
                              {!loadingBill ? (
                                `-Rp${Helper.numberWithCommas(paymentDetails?.otopoint?.used ?? '')}`
                              ) : (
                                <Skeleton height={15} />
                              )}
                            </Col>
                            <Col lg={12}>
                              <hr className="dashed-line" />
                            </Col>
                            <Col lg={6} sm={6} className="text-title-grey font-14  mt-2">
                              Total
                            </Col>
                            <Col lg={6} sm={6} className="text-black text-bold-600 font-14 text-right">
                              {!loadingBill ? (
                                `Rp${Helper.numberWithCommas(paymentDetails?.payment_detail?.payment_amount ?? '')}`
                              ) : (
                                <Skeleton height={20} />
                              )}
                            </Col>
                            <Col lg={6} sm={6} className="text-title-grey font-14  mt-2">
                              Pembulatan
                            </Col>
                            <Col lg={6} sm={6} className="text-black text-bold-600 font-14 text-right">
                              {!loadingBill ? (
                                `Rp${Helper.numberWithCommas(paymentDetails?.payment_detail?.rounding ?? 0)}`
                              ) : (
                                <Skeleton height={20} />
                              )}
                            </Col>
                            <Col lg={12}>
                              <hr className="dashed-line" />
                            </Col>
                            <Col lg={6} sm={6} className="text-title-grey font-14  mt-2">
                              Total pembayaran
                            </Col>
                            <Col lg={6} sm={6} className="text-default-blue text-bold-600 font-18 text-right">
                              {!loadingBill ? (
                                `Rp${Helper.numberWithCommas(paymentDetails?.payment_detail?.grand_total ?? 0)}`
                              ) : (
                                <Skeleton height={20} />
                              )}
                            </Col>
                            {+insuffientAmount > 0 && (
                              <>
                                <Col lg={6} sm={6} className="text-title-grey font-14  mt-2">
                                  Sisa pembayaran
                                </Col>
                                <Col
                                  lg={6}
                                  sm={6}
                                  className="text-red text-bold-600 font-18 text-right mt-2 d-flex justify-content-end align-items-center"
                                >
                                  {!loadingBill ? (
                                    <>
                                      Rp {Helper.numberWithCommas(insuffientAmount ?? '')}
                                      <img
                                        className="icon-field ml-2 cursor-pointer"
                                        src={CopyIcon}
                                        height="16px"
                                        onClick={() => {
                                          navigator.clipboard.writeText(insuffientAmount);
                                          setAlert('Berhasil menyalin', 'success');
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <Skeleton height={20} />
                                  )}
                                </Col>
                              </>
                            )}
                            {paymentDetails?.payment_method?.length > 0 && (
                              <Col lg={12}>
                                <hr className="dashed-line" />
                              </Col>
                            )}
                            {paymentDetails?.payment_method?.length > 0 &&
                              paymentDetails?.payment_method?.map((item, index) => {
                                if (
                                  paymentDetails?.payment_method?.some((item) => item?.slug.includes('cash')) &&
                                  item?.slug == 'returned' &&
                                  item?.amount >= 0
                                ) {
                                  return (
                                    <>
                                      <Col lg={6} sm={6} xs={12} className="text-title-grey font-14 mt-2">
                                        Kembalian
                                      </Col>
                                      <Col
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                        className="text-black text-bold-600 font-14 text-right mt-2"
                                      >
                                        {!loadingBill ? (
                                          `Rp ${item?.amount ? Helper.numberWithCommas(item?.amount ?? 0) : '0'}`
                                        ) : (
                                          <Skeleton height={15} />
                                        )}
                                      </Col>
                                    </>
                                  );
                                } else if (item?.slug !== 'returned') {
                                  return (
                                    <>
                                      <Col lg={6} sm={6} xs={12} className="text-title-grey font-14 mt-2">
                                        Pembayaran {index + 1} - {item?.value}
                                      </Col>
                                      <Col
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                        className="text-black text-bold-600 font-14 text-right mt-2"
                                      >
                                        {!loadingBill ? (
                                          `Rp ${item?.amount ? Helper.numberWithCommas(item?.amount ?? 0) : '0'}`
                                        ) : (
                                          <Skeleton height={15} />
                                        )}
                                      </Col>
                                    </>
                                  );
                                }
                              })}
                            {paymentDetails?.cash?.paid > paymentDetails?.payment_detail?.grand_total && (
                              <>
                                <Col lg={6} sm={6} xs={12} className="text-title-grey font-14  mt-2">
                                  Kembalian
                                </Col>
                                <Col lg={6} sm={6} xs={12} className="text-black text-bold-600 font-14 text-right">
                                  {!loadingBill ? (
                                    `Rp ${Helper.numberWithCommas(paymentDetails?.cash?.returned ?? 0)}`
                                  ) : (
                                    <Skeleton height={15} />
                                  )}
                                </Col>
                              </>
                            )}
                            <Col lg={12}>
                              <div className="divider-line my-3">
                                <span />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <Button
                                color="primary"
                                size="sm"
                                className="w-p-100"
                                disabled={disableForm || loadingBill}
                                onClick={() => handleSubmit()}
                              >
                                Selesaikan
                              </Button>
                            </Col>
                          </Row>
                        ) : (
                          <Row className="text-center">
                            <Loading className="spinner-border thumb-sm text-primary" />
                          </Row>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <div className="text-center">
                      <img src={NotFound} height={120} />
                      <div className="text-bold-600 text-black font-14">Hasil tidak ditemukan</div>
                      <div className="text-grey font-12 mt-2">{errorMessage ?? ''}</div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <ModalPayment
            modal={modal}
            toggle={() => handleCloseModal()}
            handleSubmit={(data) => handleApplyPayment(data)}
            options={options}
          ></ModalPayment>

          <ModalCampaignItem
            isOpen={modalCampaignItem}
            toggle={() => setModalCampaignItem(false)}
            handleChangeWorkshop={(data) => validationCampaignItem(data)}
            dataWorkshop={orderDetails?.workshop}
            hasLoading={hasLoading}
          />
          <ModalCampaignItemPackage
            isOpen={modalCampaignItemPackage}
            toggle={() => setModalCampaignItemPackage(false)}
            handleChangeWorkshop={(data) => validationCampaignItem(data)}
            dataWorkshop={orderDetails?.workshop}
            hasLoading={hasLoading}
          />
          <Modalcampaign
            isOpen={modalCampaign}
            toggle={() => setModalCampaign(false)}
            handleChangeWorkshop={(data) => handleChangeCampaign(data)}
            dataWorkshop={orderDetails?.workshop}
          />
          <CustomModal
            modal={errorPromoPerItem}
            modalWidth={400}
            backdrop="static"
            toggle={() => setErrorPromoPerItem(!errorPromoPerItem)}
          >
            <Row>
              <div className="icon-bg-warning">
                <img src={AlertTriangleIcon} alt="alert circle" />
              </div>
            </Row>
            <Row className="text-center text-bold my-4">
              <span>{errorMessagePromoPerItem ?? 'error api'}</span>
            </Row>
            <Row lg={12} className="d-flex justify-content-center">
              <Button
                color="primary"
                outline
                size="sm"
                className="w-p-100 mx-0"
                onClick={() => {
                  setHasLoading(false);
                  setErrorPromoPerItem(!errorPromoPerItem);
                }}
              >
                Kembali
              </Button>
            </Row>
          </CustomModal>
        </Wrapper>
      </>
    );
  };

  return renderContent();
};

export default Payments;
