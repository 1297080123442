import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules } from '../utils';
import Card from './Card';
import CardImg from './CardImg';
import CardBody from './CardBody';
import CardTitle from './CardTitle';
import CardSubtitle from './CardSubtitle';
import CardText from './CardText';
import Icon from '../icon/Icon';
import Badge from '../badge/Badge';

const propTypes = {
  roundedImage: PropTypes.bool,
  image: PropTypes.string,
  imageClassName: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleClassName: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitleClassName: PropTypes.string,
  subtitleAddon: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  descriptionClassName: PropTypes.string,
  badge: PropTypes.string,
  badgeColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  badgeAddon: PropTypes.string,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  imgLazyLoad: PropTypes.bool,
};

const defaultProps = {
  roundedImage: true,
  imgLazyLoad: true,
};

const CardTrending = (props) => {
  const {
    roundedImage,
    image,
    imageClassName,
    title,
    titleClassName,
    subtitle,
    subtitleClassName,
    subtitleAddon,
    description,
    descriptionClassName,
    badge,
    badgeColor,
    badgeAddon,
    className,
    cssModule,
    imgLazyLoad,
    ...attributes
  } = props;

  const classes = mapToCssModules(classNames(className, 'card-trending'), cssModule);

  const imageClasses = mapToCssModules(classNames(imageClassName), cssModule);

  const titleClasses = mapToCssModules(classNames(titleClassName), cssModule);

  const subtitleClasses = mapToCssModules(
    classNames('text-muted', 'd-flex', 'align-items-center', subtitleClassName),
    cssModule
  );

  const subtitleAddonClasses = mapToCssModules(
    classNames('m-0', 'd-flex', 'align-items-center', 'w-auto', 'h-auto', 'me-2'),
    cssModule
  );

  const descriptionClasses = mapToCssModules(
    classNames('fw-medium', 'text-truncate-double', descriptionClassName),
    cssModule
  );

  const cardBadgeClasses = mapToCssModules(
    classNames('badge-top-right', 'h-auto', 'position-absolute', 'rounded-pill', 'p-0'),
    cssModule
  );

  const badgeWrapperClasses = mapToCssModules(classNames('px-2'), cssModule);

  const badgeTitleClasses = mapToCssModules(classNames('fw-bold'), cssModule);

  const badgeIconClasses = mapToCssModules(classNames('h-auto', 'w-auto'), cssModule);

  return (
    <Card className={classes} {...attributes}>
      {badge && (
        <Badge color={badgeColor} className={cardBadgeClasses}>
          <Icon
            card
            textRight
            image={badgeAddon}
            imageWidth={12}
            imageHeight={12}
            className={badgeWrapperClasses}
            iconClassName={badgeIconClasses}
            textClassName={badgeTitleClasses}
            title={badge}
            alt=""
          />
        </Badge>
      )}
      <CardImg
        top
        width="100%"
        height="100px"
        rounded={roundedImage}
        className={imageClasses}
        src={image}
        alt=""
        loading={imgLazyLoad ? 'lazy' : 'eager'}
      />
      <CardBody>
        <CardTitle tag="h5" className={titleClasses}>
          {title}
        </CardTitle>

        {subtitle && (
          <CardSubtitle tag="h6" className={subtitleClasses}>
            <Icon
              card
              textRight
              image={subtitleAddon}
              imageWidth={16}
              imageHeight={16}
              iconClassName={subtitleAddonClasses}
              title={subtitle}
              alt=""
            />
          </CardSubtitle>
        )}

        {description && <hr />}
        {description && <CardText className={descriptionClasses}>{description}</CardText>}
      </CardBody>
    </Card>
  );
};

CardTrending.propTypes = propTypes;
CardTrending.defaultProps = defaultProps;

export default CardTrending;
