import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { hasLoggedIn } from '@reducers/Auth';
import { setActiveLink } from '@reducers/App';
import Navbar from '@components/navbar/Navbar';
import Login from '@containers/auth/Login';
import Dashboard from '@containers/dashboard/Dashboard';
import ForgotPassword from '@containers/auth/ForgotPassword';
import ResetPassword from '@containers/auth/ResetPassword';
import ConfirmEmail from '@containers/auth/ConfirmEmail';
import PageNotFound from '@containers/error/404';
import BlankPage from '@containers/dashboard/BlankPage';
import Orders from '@containers/orders/Orders';
import DetailOrder from '@containers/orders/Detail';
import CreateOrder from '@containers/orders/Create';
import OrderReports from '@containers/order-reports/OrderReports';
import Draft from '@containers/draft/Draft';
import Payments from '@containers/payments/Payments';
import AlertPopup from '@components/notifications/AlertPopup';
import moment from 'moment';
import 'moment/locale/id';

moment.locale('id');

function PrivateRoute({ routeName, withNavbar = true, children, ...rest }) {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(hasLoggedIn);

  dispatch(setActiveLink(routeName));

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          routeName !== 'fullframe' ? (
            <React.Fragment>
              <AlertPopup />
              {withNavbar ? <Navbar /> : null}
              {children}
            </React.Fragment>
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function PublicRoute({ routeName, children, ...rest }) {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(hasLoggedIn);

  dispatch(setActiveLink(routeName));

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          <Redirect
            to={{
              pathname: '/order',
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  );
}

export default function App() {
  return (
    <Router>
      <Switch>
        <PublicRoute exact routeName="login" path="/login">
          <Login />
        </PublicRoute>

        <PublicRoute exact routeName="forgotPassword" path="/forgot-password">
          <ForgotPassword />
        </PublicRoute>

        <PublicRoute exact routeName="resetPassword" path="/reset-password">
          <ResetPassword />
        </PublicRoute>

        <PublicRoute exact routeName="confirmEmail" path="/confirm-email">
          <ConfirmEmail />
        </PublicRoute>

        <PrivateRoute exact routeName="fullframe" path="/404">
          <PageNotFound />
        </PrivateRoute>

        <PrivateRoute routeName="dashboard" path="/dashboard">
          <Dashboard />
        </PrivateRoute>

        <PrivateRoute exact routeName="order" path="/order">
          <Orders />
        </PrivateRoute>

        <PrivateRoute exact routeName="order" path="/order/detail/:booking_code">
          <DetailOrder />
        </PrivateRoute>

        <PrivateRoute exact routeName="order" path="/order/create" withNavbar={false}>
          <CreateOrder />
        </PrivateRoute>

        <PrivateRoute routeName="draft" path="/draft">
          <Draft />
        </PrivateRoute>

        <PrivateRoute routeName="payment" path="/payment/:id">
          <Payments />
        </PrivateRoute>

        <PrivateRoute routeName="order-reports" path="/order-reports">
          <OrderReports />
        </PrivateRoute>

        <PrivateRoute routeName="empty" path="/">
          <BlankPage />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}
