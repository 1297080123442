import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
};

const Divider = (props) => {
  const { title, type } = props;

  if (title) {
    return (
      <div className="divider-text">
        <h1>{title}</h1>
      </div>
    );
  }

  return <hr className={type} />;
};

Divider.propTypes = propTypes;

export default Divider;
