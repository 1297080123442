import React from 'react';
import { Alert } from 'reactstrap';
import CheckIcon from '@assets/icons/check-circle-white.svg';
import AlertIcon from '@assets/icons/alert-circle-white.svg';
import useAlert from '@hooks/useAlert';

const AlertPopup = () => {
  const { text, type } = useAlert();
  if (text && type) {
    return (
      <div className="div-alert">
        {type === 'success' && (
          <Alert className="alert-custom alert-success" isOpen={true}>
            <img src={CheckIcon} alt="check icon" />
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </Alert>
        )}
        {type === 'warning' && (
          <Alert className="alert-custom alert-warning" isOpen={true}>
            <img src={AlertIcon} alt="check icon" />
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </Alert>
        )}
        {type === 'failed' && (
          <Alert className="alert-custom alert-failed" isOpen={true}>
            <img src={AlertIcon} alt="check icon" />
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </Alert>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};

export default AlertPopup;
