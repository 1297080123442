import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from './Card';
import CardImg from './CardImg';
import { mapToCssModules } from '../utils';
import CardBody from './CardBody';
import Text from '../text/Text';

const propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  image: PropTypes.string,
  imgLazyLoad: PropTypes.bool,
  className: PropTypes.string,
  classNameTitle: PropTypes.string,
  cssModule: PropTypes.object,
  showStartPrice: PropTypes.bool,
  startFrom: PropTypes.bool,
  startPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  category: PropTypes.string,
  discountLabel: PropTypes.string,
  isRecommended: PropTypes.string,
  onCardClick: PropTypes.func,
  pageLocation: PropTypes.string,
  cardIndex: PropTypes.number,
  showUsp: PropTypes.bool,
  guaranteeIcon: PropTypes.string,
  discountIcon: PropTypes.string,
  bookmarkIcon: PropTypes.string,
};

const defaultProps = {
  imgLazyLoad: true,
  showStartPrice: true,
  startFrom: true,
  cardIndex: 0,
};

const CardRecommendService = (props) => {
  const {
    title,
    subTitle,
    image,
    imgLazyLoad,
    className,
    classNameTitle,
    cssModule,
    showStartPrice,
    startFrom,
    startPrice,
    price,
    subPrice,
    category,
    discountLabel,
    isRecommended,
    onCardClick,
    pageLocation,
    cardIndex,
    showUsp,
    guaranteeIcon,
    discountIcon,
    bookmarkIcon,
    ...attributes
  } = props;

  const classes = mapToCssModules(classNames(className, 'card-recommend-service'), cssModule);

  const classesTitle = mapToCssModules(classNames(classNameTitle, 'text-md'), cssModule);

  return (
    <Card className={classes} {...attributes} onClick={onCardClick}>
      <div className="img-card-service">
        <CardImg top width="100%" height="140px" src={image} alt={title} loading={imgLazyLoad ? 'lazy' : 'eager'} />

        {showUsp && (
          <div className="usp-top">
            <div>
              {bookmarkIcon && <img className="me-1" src={bookmarkIcon} alt="original" height={10} />}
              <span>Dijamin Ori</span>
            </div>
            <div>
              {guaranteeIcon && <img className="me-1" src={guaranteeIcon} alt="garansi" height={10} />}
              <span>Garansi 14 Hari</span>
            </div>
          </div>
        )}
      </div>
      <Text className="category-label text-xxs text-center">{category}</Text>

      {isRecommended && (
        <Text color="white" className="recommended-ribbon text-xxs">
          {isRecommended}
        </Text>
      )}

      <CardBody className="d-flex flex-column justify-content-between">
        <div className="mb-1">
          <Text
            weight="bold"
            className={classesTitle}
            data-automation={pageLocation ? `${pageLocation}_service_rec_service_title_${cardIndex}` : null}
          >
            {title}
          </Text>
          {subTitle && <Text className="text-xs service-subtitle">{subTitle}</Text>}
        </div>

        <div>
          {showStartPrice && (
            <div className="d-flex flex-column">
              {startFrom && (
                <Text color="label" className="text-xxs my-1">
                  Mulai dari
                </Text>
              )}
              <div className="d-flex align-items-center">
                <Text
                  color="placeholder"
                  className="text-xxs text-strikethrough"
                  data-automation={pageLocation ? `${pageLocation}_service_rec_price_discount_${cardIndex}` : null}
                >
                  {startPrice}
                </Text>

                {discountLabel && (
                  <Text
                    className="discount-label text-xxs"
                    data-automation={pageLocation ? `${pageLocation}_service_rec_discount_${cardIndex}` : null}
                  >
                    {discountLabel}
                  </Text>
                )}
              </div>
            </div>
          )}

          <Text
            weight="bold"
            color="secondary"
            className="text-xs"
            data-automation={pageLocation ? `${pageLocation}_service_rec_price_main_${cardIndex}` : null}
          >
            {price}
          </Text>

          {subPrice && (
            <Text
              className="text-xxs sub-price d-block"
              data-automation={pageLocation ? `${pageLocation}_service_rec_price_litre_${cardIndex}` : null}
            >
              {subPrice}
            </Text>
          )}
        </div>
      </CardBody>

      {showUsp && (
        <div className="usp-bottom">
          {discountIcon && <img className="me-1" src={discountIcon} alt="diskon" height={10} />}
          <span>Booking online lebih murah</span>
        </div>
      )}
    </Card>
  );
};

CardRecommendService.propTypes = propTypes;
CardRecommendService.defaultProps = defaultProps;

export default CardRecommendService;
