import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import {
  Button,
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
  FormGroup,
  InputGroup,
  InputGroupText,
} from '../otoklix-elements';
import ChooseItemTypeModal from '@components/modal/ChooseItemTypeModal';
import EditIcon from '@assets/icons/edit.svg';
import TrashIcon from '@assets/icons/trash.svg';
import Helper from '@helpers/Helper';
import { getActiveItems, getActiveCustomItems, setActiveCustomItems, getCart, setCart } from '@reducers/Order';
import { useDispatch, useSelector } from 'react-redux';
import Empty from '@components/empty-state/Empty';
import { findIndex, isEmpty, map } from 'lodash';
import { filter } from 'lodash';
import useResponsive from '@utils/useResponsive';
import API from '@utils/API';
import SelectField from '../field/SelectField';

const CustomForms = (props) => {
  const { onAddCart, source } = props;
  const api = new API('v2');
  const { isDesktop } = useResponsive();
  const dispatch = useDispatch();
  const activeCustomItems = useSelector(getActiveCustomItems);
  const cart = useSelector(getCart);

  const [hasItemTypeModal, setHasItemTypeModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [marginOptions, setMarginOptions] = useState([]);

  const fetchMargin = async () => {
    await api
      .get('v2/gms/booking/margin-options')
      .then((res) => {
        setMarginOptions(res.data.data);
      })
      .catch((err) => {
        log(err);
      });
  };

  const toggleItemTypeModal = () => setHasItemTypeModal(!hasItemTypeModal);

  const handleChangeInput = (e, index) => {
    const { name, value } = e.target;
    let tempData = [...activeCustomItems];
    let tempDataIndex = { ...tempData[index] };
    let errMsg = '';

    if (name == 'brand' && !value) errMsg = 'Brand harus diisi';
    if (name == 'name' && !value) errMsg = 'Name harus diisi';

    tempDataIndex[name] = value;
    tempDataIndex[`error_${name}`] = errMsg;
    tempData[index] = tempDataIndex;

    dispatch(setActiveCustomItems([...tempData]));
  };

  const handleChangeQty = (e, index) => {
    const { value } = e.target;
    // let tempData = [...activeCustomItems];
    // let tempDataIndex = { ...tempData[index] };
    let quantity = 0;
    let errMsg = '';
    const qtyValue = value > 999 ? 999 : value;

    try {
      quantity = Helper.formatMoneyOnField(qtyValue);
    } catch {
      quantity = {
        formatIsMoney: false,
        formatMoney: qtyValue,
        formatMoneyString: Helper.formatMoney(qtyValue),
      };
    }

    if (value < 1) {
      errMsg = 'Quantity harus diisi';
    } else {
      errMsg = '';
    }
    // tempDataIndex.qty = quantity;
    // tempDataIndex.error_qty = errMsg;
    // tempData[index] = tempDataIndex;
    handleCalculatePrice(
      activeCustomItems[index]?.cogs?.formatMoney,
      activeCustomItems[index]?.shipping_cost?.formatMoney,
      quantity?.formatMoney,
      activeCustomItems[index]?.margin,
      quantity,
      'qty',
      errMsg,
      index
    );
    // dispatch(setActiveCustomItems([...tempData]));
  };

  const handleChangeCOGS = (e, index) => {
    const { value } = e.target;
    let cogs = 0;
    let errMsg = '';

    try {
      cogs = Helper.formatMoneyOnField(value);
    } catch {
      cogs = {
        formatIsMoney: false,
        formatMoney: value,
        formatMoneyString: Helper.formatMoney(value),
      };
    }

    if (value < 1) {
      errMsg = 'Cogs harus diisi';
    } else {
      errMsg = '';
    }

    handleCalculatePrice(
      cogs?.formatMoney,
      activeCustomItems[index]?.shipping_cost?.formatMoney,
      activeCustomItems[index]?.qty?.formatMoney,
      activeCustomItems[index]?.margin,
      cogs,
      'cogs',
      errMsg,
      index
    );
  };

  const handleChangeMargin = (value, index) => {
    handleCalculatePrice(
      activeCustomItems[index]?.cogs?.formatMoney,
      activeCustomItems[index]?.shipping_cost?.formatMoney,
      activeCustomItems[index]?.qty?.formatMoney,
      value,
      value,
      'margin',
      '',
      index
    );
  };

  const handleChangeShippingCost = (e, index) => {
    const { value } = e.target;
    let shipping_cost = 0;
    let errMsg = '';

    try {
      shipping_cost = Helper.formatMoneyOnField(value);
    } catch {
      shipping_cost = {
        formatIsMoney: false,
        formatMoney: value,
        formatMoneyString: Helper.formatMoney(value),
      };
    }

    handleCalculatePrice(
      activeCustomItems[index]?.cogs?.formatMoney,
      shipping_cost?.formatMoney,
      activeCustomItems[index]?.qty?.formatMoney,
      activeCustomItems[index]?.margin,
      shipping_cost,
      'shipping_cost',
      errMsg,
      index
    );
  };

  const handleCalculatePrice = (cogs, ongkir, qty, margin, valueToSet, nameToSet, errMsg, index) => {
    let tempData = [...activeCustomItems];
    let tempDataIndex = { ...tempData[index] };
    let amount = 0;
    let amount_before_tax = 0;
    let amount_unqty = 0;
    let amount_unqty_before_tax = 0;
    let price = 0;
    let unit_price = 0;
    let unit_price_before_tax = 0;

    amount_unqty = Helper.PriceGenerator(cogs, ongkir, qty, margin)?.finalUnqty ?? 0;
    amount = Helper.PriceGenerator(cogs, ongkir, qty, margin)?.final ?? 0;
    amount_before_tax = Helper.PriceGenerator(cogs, ongkir, qty, margin, false)?.final ?? 0;
    amount_unqty_before_tax = Helper.PriceGenerator(cogs, ongkir, qty, margin, false)?.finalUnqty ?? 0;

    try {
      price = Helper.formatMoneyOnField(amount);
      unit_price = Helper.formatMoneyOnField(amount_unqty);
      unit_price_before_tax = Helper.formatMoneyOnField(amount_unqty_before_tax);
    } catch {
      price = {
        formatIsMoney: false,
        formatMoney: amount,
        formatMoneyString: Helper.formatMoney(amount),
      };
      unit_price = {
        formatIsMoney: false,
        formatMoney: amount_unqty,
        formatMoneyString: Helper.formatMoney(amount_unqty),
      };
      unit_price_before_tax = {
        formatIsMoney: false,
        formatMoney: amount_unqty_before_tax,
        formatMoneyString: Helper.formatMoney(amount_unqty_before_tax),
      };
    }

    tempDataIndex.price = price;
    tempDataIndex.error_price = nameToSet !== 'qty' && nameToSet !== 'margin' && amount < 1 ? 'Harga harus diisi' : '';
    tempDataIndex.price_before_tax = amount_before_tax;
    tempDataIndex.unit_price = unit_price;
    tempDataIndex.unit_price_before_tax = unit_price_before_tax;
    tempDataIndex[nameToSet] = valueToSet;
    tempDataIndex[`error_${nameToSet}`] = errMsg;
    tempData[index] = tempDataIndex;
    dispatch(setActiveCustomItems([...tempData]));
  };

  const handleChangePrice = (e) => {
    const { value } = e.target;
    let price = 0;

    try {
      price = Helper.formatMoneyOnField(value);
    } catch {
      price = {
        formatIsMoney: false,
        formatMoney: value,
        formatMoneyString: Helper.formatMoney(value),
      };
    }

    dispatch(setActiveCustomItems({ ...activeCustomItems, price }));
  };

  const handleDeleteItem = (index) => {
    let tempData = [...activeCustomItems];
    let cartTempData = [...cart];
    const findCartItemIndex = findIndex(cart, (item) => {
      return item.product_id == tempData[index].product_id;
    });
    tempData.splice(index, 1);
    dispatch(setActiveCustomItems(tempData));
    if (findCartItemIndex > -1 && !isEmpty(cartTempData[findCartItemIndex])) {
      cartTempData.splice(findCartItemIndex, 1);
      dispatch(setCart(cartTempData));
    }
  };

  const renderId = (type) => {
    let customProductCart = filter(cart, (x) => x.type == 'product' && x.formType == 'custom')?.length;
    let customServiceCart = filter(cart, (x) => x.type == 'service' && x.formType == 'custom')?.length;
    let customProductOnForm = filter(activeCustomItems, (x) => x.type == 'product' && !x.isEdit)?.length;
    let customServiceOnForm = filter(activeCustomItems, (x) => x.type == 'service' && !x.isEdit)?.length;
    const productLength = Number(customProductCart) + Number(customProductOnForm) + 1;
    const serviceLength = Number(customServiceCart) + Number(customServiceOnForm) + 1;

    return type == 'product' ? productLength : serviceLength;
  };

  const defaultValue = (value, activeItem = {}) => {
    let customItem = {
      headTitle: `${handleRenderType(value, 'header')} Kustom ${renderId(value)}`,
      product_id: activeItem?.product_id ?? `custom_${value}_${Math.random()}`,
      type: activeItem?.type ?? value ?? '',
      brand: '',
      name: '',
      qty: {
        formatIsMoney: false,
        formatMoney: 0,
        formatMoneyString: '0',
      },
      cogs: {
        formatIsMoney: false,
        formatMoney: 0,
        formatMoneyString: '0',
      },
      shipping_cost: {
        formatIsMoney: false,
        formatMoney: 0,
        formatMoneyString: '0',
      },
      price: {
        formatIsMoney: false,
        formatMoney: 0,
        formatMoneyString: '0',
      },
      unit_price: {
        formatIsMoney: false,
        formatMoney: 0,
        formatMoneyString: '0',
      },
      unit_price_before_tax: {
        formatIsMoney: false,
        formatMoney: 0,
        formatMoneyString: '0',
      },
      price_before_tax: 0,
      margin: {},
      error_brand: '',
      error_name: '',
      error_qty: '',
      error_cogs: '',
      error_price: '',
      error_margin: '',
    };

    return customItem;
  };

  const handleSubmitItemType = (value) => {
    let customItem = defaultValue(value);
    if (!activeCustomItems[0]?.isEdit) {
      defaultValue(value);
      dispatch(setActiveCustomItems([customItem]));
    } else {
      let editedItem = [...activeCustomItems];
      let editedItemIndex = { ...activeCustomItems[selectedItem?.index] };

      editedItemIndex = { ...defaultValue(value, editedItemIndex) };
      editedItemIndex.product_id = editedItemIndex.product_id;
      editedItemIndex.isEdit = true;
      editedItemIndex.type = value;
      editedItemIndex.headTitle = `${handleRenderType(value, 'header')} Kustom ${renderId(value)}`;

      editedItem[selectedItem?.index] = editedItemIndex;

      dispatch(setActiveCustomItems([...editedItem]));
      setSelectedItem({});
    }

    toggleItemTypeModal();
  };

  const handleRenderType = (type, source) => {
    switch (type) {
      case 'product':
        return source == 'header' ? 'Sparepart' : 'Part';
      case 'service':
        return 'Service';
      default:
        return '';
    }
  };

  const validation = (item, index) => {
    let tempData = [...activeCustomItems];
    let tempDataIndex = { ...activeCustomItems[index] };
    let status = true;
    let error_brand = '';
    let error_name = '';
    let error_qty = '';
    let error_cogs = '';
    let error_shipping_cost = '';
    let error_price = '';
    let error_margin = '';

    if (item?.type == 'product') {
      if (!item?.brand) {
        error_brand = 'Brand harus diisi';
        status = false;
      } else {
        error_brand = '';
      }
    }

    if (!item?.name) {
      error_name = 'Name harus diisi';
      status = false;
    } else {
      error_name = '';
    }

    if (item?.qty?.formatMoney < 1) {
      error_qty = 'Quantity harus diisi';
      status = false;
    } else {
      error_qty = '';
    }

    if (item?.cogs?.formatMoney < 1) {
      error_cogs = 'COGS harus diisi';
      status = false;
    } else {
      error_cogs = '';
    }

    // if (item?.shipping_cost?.formatMoney < 1) {
    //   error_shipping_cost = 'COGS harus diisi';
    //   status = false;
    // } else {
    //   error_shipping_cost = '';
    // }

    if (item?.price?.formatMoney < 1) {
      error_price = 'Harga harus diisi';
      status = false;
    } else {
      error_price = '';
    }

    if (isEmpty(item?.margin)) {
      error_margin = 'Margin harus dipilih';
      status = false;
    } else {
      error_margin = '';
    }

    tempDataIndex = {
      ...tempDataIndex,
      error_brand,
      error_name,
      error_qty,
      error_cogs,
      error_shipping_cost,
      error_price,
      error_margin,
    };

    tempData[index] = tempDataIndex;

    dispatch(setActiveCustomItems([...tempData]));
    return status;
  };

  const onSubmit = async (item, index) => {
    if (!validation(item, index)) {
      return console.log('validation error');
    }

    let tempData = [...activeCustomItems];
    let params = { ...item };
    delete params.error_brand;
    delete params.error_name;
    delete params.error_qty;
    delete params.error_cogs;
    delete params.error_shipping_cost;
    delete params.error_price;

    params = {
      ...params,
      formType: 'custom',
      calculatedPrice: params?.qty?.formatMoney * params?.unit_price?.formatMoney,
    };

    if (typeof onAddCart == 'function') await onAddCart(params, 'custom');
    tempData.splice(index, 1);
    dispatch(setActiveCustomItems([...tempData]));
  };

  useEffect(() => {
    fetchMargin();
  }, []);

  return (
    <>
      {!source && (
        <Row className="align-items-center mb-2">
          <Col lg={9} md={6} sm={12} xs={12}>
            <span style={{ fontWeight: 600, fontSize: 20 }}>Tambahkan Pesanan Custom</span>
          </Col>
          <Col lg={3} md={6} sm={12} xs={12} className="d-flex justify-content-end">
            {activeCustomItems?.length < 1 && (
              <Button
                color="primary"
                outline
                block={!isDesktop}
                onClick={toggleItemTypeModal}
                className="mt-3"
                size="sm"
              >
                + Tambah Item
              </Button>
            )}
          </Col>
        </Row>
      )}

      {activeCustomItems?.length < 1 && !source ? (
        <div className="mt-2">
          <Empty type="empty-custom-order" />
        </div>
      ) : null}

      {activeCustomItems?.length > 0
        ? map(activeCustomItems, (customItem, index) => {
            return (
              <Card className="mt-2">
                <CardBody>
                  <div
                    className={`d-flex justify-content-between align-items-center ${
                      isDesktop ? 'flex-row' : 'flex-column'
                    }`}
                  >
                    <span className="fw-bold" style={{ fontSize: 16 }}>
                      {customItem?.headTitle}
                    </span>

                    <div className={isDesktop ? '' : 'w-100 mt-3'}>
                      <Button
                        color="light"
                        outline
                        size="sm"
                        className={`m${isDesktop ? 'e' : 'b'}-2`}
                        onClick={() => {
                          toggleItemTypeModal();
                          setSelectedItem({ ...customItem, index });
                        }}
                        block={!isDesktop}
                      >
                        <img src={EditIcon} alt="" width={16} height={16} className="me-1" />
                        Ubah Jenis Custom
                      </Button>
                      <Button
                        color="light"
                        outline
                        size="sm"
                        className={`m${isDesktop ? 'e' : 'b'}-2`}
                        onClick={() => handleDeleteItem(index)}
                        block={!isDesktop}
                      >
                        <img src={TrashIcon} alt="" width={16} height={16} className="me-1" />
                        Hapus
                      </Button>
                      <Button color="primary" size="sm" onClick={() => onSubmit(customItem, index)} block={!isDesktop}>
                        Simpan
                      </Button>
                    </div>
                  </div>

                  <Row>
                    {customItem?.type == 'product' && (
                      <Col lg={4} md={4} sm={4} xs={4} className="mt-3">
                        <FormGroup>
                          <Label className="fw-bold label-required">Brand</Label>
                          <Input
                            name="brand"
                            onChange={(e) => handleChangeInput(e, index)}
                            value={customItem?.brand ?? '-'}
                            placeholder="Masukkan Brand"
                            invalid={!!customItem?.error_brand}
                          />
                          <FormFeedback>{customItem?.error_brand}</FormFeedback>
                        </FormGroup>
                      </Col>
                    )}

                    <Col lg={4} md={4} sm={4} xs={4} className="mt-3">
                      <FormGroup>
                        <Label className="fw-bold label-required">{`Nama ${handleRenderType(customItem?.type)}`}</Label>
                        <Input
                          name="name"
                          onChange={(e) => handleChangeInput(e, index)}
                          value={customItem?.name ?? '-'}
                          placeholder={`Masukkan nama ${handleRenderType(customItem?.type)}`}
                          invalid={!!customItem?.error_name}
                        />
                        <FormFeedback>{customItem?.error_name}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={4} sm={4} xs={4} className="mt-3">
                      <Label className="fw-bold label-required">Quantity</Label>
                      <Input
                        name="qty"
                        onChange={(e) => handleChangeQty(e, index)}
                        value={customItem?.qty?.formatMoneyString}
                        placeholder="Masukkan jumlah quantity"
                        invalid={!!customItem?.error_qty}
                      />
                      <FormFeedback>{customItem?.error_qty}</FormFeedback>
                    </Col>

                    <Col lg={4} md={4} sm={4} xs={4} className="mt-3">
                      <Label className="fw-bold label-required">COGS</Label>
                      <InputGroup>
                        <InputGroupText className="fw-bold">Rp</InputGroupText>
                        <Input
                          name="cogs"
                          onChange={(e) => handleChangeCOGS(e, index)}
                          value={customItem?.cogs?.formatMoneyString}
                          placeholder="Masukkan COGS"
                          invalid={!!customItem?.error_cogs}
                        />
                        <FormFeedback>{customItem?.error_cogs}</FormFeedback>
                      </InputGroup>
                    </Col>

                    <Col lg={4} md={4} sm={4} xs={4} className="mt-3 margin-selector">
                      <Label className="fw-bold label-required">Margin</Label>
                      <SelectField
                        name="margin"
                        value={!isEmpty(customItem?.margin) ? customItem?.margin : ''}
                        placeholder="Pilih margin"
                        options={marginOptions}
                        getOptionLabel={(e) => e.margin_name}
                        getOptionValue={(e) => e.margin_value}
                        onChange={(value) => handleChangeMargin(value, index)}
                        errorMessage={customItem?.error_margin}
                      />
                    </Col>

                    <Col lg={4} md={4} sm={4} xs={4} className="mt-3">
                      <Label className="fw-bold">
                        Ongkos Kirim <span className="fw-normal text-disabled">(Opsional)</span>
                      </Label>
                      <InputGroup>
                        <InputGroupText className="fw-bold">Rp</InputGroupText>
                        <Input
                          name="shipping_cost"
                          onChange={(e) => handleChangeShippingCost(e, index)}
                          value={customItem?.shipping_cost?.formatMoneyString}
                          placeholder="Masukkan ongkos kirim"
                        />
                      </InputGroup>
                    </Col>

                    <Col lg={4} md={4} sm={4} xs={4} className="mt-3">
                      <Label className="fw-bold label-required">Harga Jual</Label>
                      <InputGroup>
                        <InputGroupText className="fw-bold">Rp</InputGroupText>
                        <Input
                          disabled
                          name="price"
                          onChange={(e) => handleChangePrice(e, index)}
                          value={customItem?.price?.formatMoneyString ?? 0}
                          placeholder="Masukkan harga jual"
                          invalid={!!customItem?.error_price}
                        />
                        <FormFeedback>{customItem?.error_price}</FormFeedback>
                      </InputGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            );
          })
        : null}

      <ChooseItemTypeModal
        isOpen={hasItemTypeModal}
        toggle={toggleItemTypeModal}
        onSubmit={handleSubmitItemType}
        activeOptions={selectedItem?.type}
      />
    </>
  );
};

export default CustomForms;
