import React, { useState } from 'react';
import { find } from 'lodash';
import { Spinner } from 'reactstrap';
import Dropzone, { useDropzone } from 'react-dropzone';
import Lightbox from 'rhino-react-image-lightbox-rotate';
import API from '@utils/API';
import CameraIcon from '@assets/icons/camera.svg';
import TrashIcon from '@assets/icons/trash.svg';
import NoImage from '@assets/images/no-image.jpeg';
import Helper from '@helpers/Helper';

const UploadImage = (props) => {
  const {
    isView,
    image,
    nameSpace,
    identifier,
    maxSize = 10240,
    maxSizeString = '10MB',
    allowTypesString = 'jpeg, jpg, png',
    allowTypes = ['jpeg', 'jpg', 'png'],
    onError,
    onDelete,
    onUpload,
    uploadTitle,
    uploadText = 'Upload Image',
    simplified = false,
    disabled = false,
  } = props;

  const api = new API('v2');
  const [loading, setLoading] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const [showLightbox, setShowLightbox] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'image/jpg': ['.jpg', '.jpeg', '.png'],
      'image/jpeg': ['.jpg', '.jpeg', '.png'],
      'image/png': ['.jpg', '.jpeg', '.png'],
    },
    disabled: loading || disabled,
    onDrop: (files) => {
      handleDropFileImage(files[0]);
      setDragOver(false);
    },
    onClick: (files) => {
      handleDropFileImage(files[0]);
      setDragOver(false);
    },
    onDragOver: () => {
      onError('');
      setDragOver(true);
    },
    onDragLeave: () => {
      setDragOver(false);
    },
    onFileDialogOpen: () => {
      onError('');
    },
  });

  const handlePreviewImage = () => {
    if (image?.url) {
      setShowLightbox(!showLightbox);
    }
  };

  const handleDeleteImage = () => onDelete();

  const handleDropFileImage = (file) => {
    const fileSize = file?.size ? Math.round(file?.size / 1024) : null;
    const nameFileSplit = file?.name ? file?.name.split('.') : [];

    if (nameFileSplit.length == 0) {
      onError('Format file tidak diketahui');
    } else {
      const selected = find(allowTypes, (item) => {
        return nameFileSplit[nameFileSplit.length - 1].toLowerCase() === item;
      });
      if (!selected) {
        onError('File tidak sesuai dengan format');
      } else {
        if (fileSize == null) {
          onError('Ukuran file tidak diketahui');
        } else if (fileSize > maxSize) {
          onError('Ukuran file maksimal ' + maxSizeString);
        } else {
          const previewImage = URL.createObjectURL(file);
          handleUploadImage(file, previewImage);
        }
      }
    }
  };

  const handleUploadImage = (file, preview) => {
    if (file?.name) {
      setLoading(true);
      const paramsImagedUpload = new FormData();
      paramsImagedUpload.append('namespace', nameSpace);
      paramsImagedUpload.append('identifier', identifier);
      paramsImagedUpload.append('image', file);

      api
        .post(`v2/gms/image-upload/`, paramsImagedUpload)
        .then((response) => {
          const keyFile = response?.data?.data?.key ?? '';
          if (keyFile !== '') {
            onUpload({ preview: preview, key: keyFile, file });
            onError('');
          } else {
            onError('Gagal upload image');
          }
          setLoading(false);
        })
        .catch((e) => {
          const message = `API Error : ${e?.response?.data?.error?.message}`;
          if (e?.response?.data?.error?.message) onError(message);
          setLoading(false);
        });
    } else {
      onError('Gagal upload image, file tidak diketahui');
    }
  };

  return (
    <>
      {showLightbox && <Lightbox mainSrc={image?.url} onCloseRequest={() => setShowLightbox(false)} />}
      {uploadTitle && <span>{uploadTitle}</span>}
      <div className={`${simplified ? '' : 'gallery-container-workshop '}mb-0 mt-0`}>
        {isView && (
          <div className="box-image-preview">
            <img src={image?.url ?? NoImage} height={48} onClick={() => handlePreviewImage()} />
            <div>
              <span className="title-image">{Helper.shortenString(image?.filename, 25) ?? '-'}</span>
              <span></span>
            </div>
            <div className="icon-trash" onClick={handleDeleteImage}>
              <img src={TrashIcon} alt="trash" />
            </div>
          </div>
        )}
        {!isView && (
          <>
            {simplified ? (
              <Dropzone>
                {() => (
                  <div {...getRootProps({ className: dragOver ? 'box-upload drag-over' : 'box-upload' })}>
                    <input {...getInputProps()} />
                    {loading ? <Spinner size="sm" /> : <img src={CameraIcon}></img>}
                    <div className="text-center">{loading ? 'Please wait...' : uploadText}</div>
                  </div>
                )}
              </Dropzone>
            ) : (
              <div {...getRootProps({ className: dragOver ? 'box-upload drag-over' : 'box-upload' })}>
                <img src={CameraIcon}></img>
                <div>
                  <div className="title mt-3">
                    {loading
                      ? 'Please wait...'
                      : dragOver
                      ? 'Release File'
                      : 'Click or drag file to this area to upload'}
                  </div>
                  <div className="detail mt-2">Maximum file size {maxSizeString}.</div>
                  <div className="detail">{allowTypesString}</div>
                </div>
              </div>
            )}
          </>
        )}
        {/* <div className="invalid-document">{error}</div> */}
        <span style={{ fontSize: 12, color: '#676765', marginTop: 2 }}>File foto max {maxSizeString}</span>
      </div>
    </>
  );
};

export default UploadImage;
