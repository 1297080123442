import React, { useEffect, useState } from 'react';
import Wrapper from '@components/wrapper/Wrapper';
import { Spinner } from 'reactstrap';
import Iframe from 'react-iframe';
import Cookies from 'universal-cookie';
import { find } from 'lodash';
import { LUMOS_REPORT_BY_WS_SLUG } from '../../utils/Constants';

const OrderReports = () => {
  const cookie = new Cookies();

  const [loading, setLoading] = useState(true);

  const BreadcrumbItem = [
    {
      id: 1,
      label: 'Home',
      active: false,
      url: '/dashboard',
    },
    {
      id: 2,
      label: `Laporan Penjualan`,
      active: true,
      url: `/order-reports`,
    },
  ];

  const handleLoading = () => setLoading(false);

  const getSlugandUrl = () => {
    const authorized_workshops_slug = cookie.get('authorized_workshops_slug');
    const findWorkshopBySlug = find(LUMOS_REPORT_BY_WS_SLUG, (x) => x?.slug === authorized_workshops_slug);

    return findWorkshopBySlug;
  };

  const LumosUrlBySlug = getSlugandUrl();

  return (
    <Wrapper
      customContentClassName="px-2"
      title="Laporan Penjualan"
      breadcrumbData={BreadcrumbItem}
      customBreadcrumbClassName={'pb-0 pt-3'}
    >
      <div className="mt-3 w-100" style={{ height: '75vh' }}>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner size="sm">...Loading</Spinner>
          </div>
        ) : null}

        <Iframe
          url={LumosUrlBySlug?.embedUrl}
          width="100%"
          height="100%"
          id=""
          className=""
          styles={{ borderRadius: '1.5rem' }}
          onLoad={handleLoading}
        />
      </div>
    </Wrapper>
  );
};

export default OrderReports;
