import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from '../utils';

const propTypes = {
  tag: tagPropType,
  tabs: PropTypes.bool,
  className: PropTypes.string,
  cssModule: PropTypes.object,
};

const defaultProps = {
  tag: 'div',
};

const Appbar = (props) => {
  const { className, cssModule, tabs, tag: Tag, ...attributes } = props;

  const classes = mapToCssModules(classNames(tabs ? 'tabs' : 'appbar', className), cssModule);

  return <Tag {...attributes} className={classes} />;
};

Appbar.propTypes = propTypes;
Appbar.defaultProps = defaultProps;

export default Appbar;
