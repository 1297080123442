import React, { useEffect, useState } from 'react';

const TranmissionOption = (props) => {
  const { onClick, isDisabled, value } = props;

  const [selected, setSelected] = useState('at');

  const handleClick = (type) => {
    if (!isDisabled) {
      setSelected(type);
      onClick(type);
    }
  };

  useEffect(() => {
    if (value !== undefined) setSelected(value);
  }, [value]);

  return (
    <div className="d-flex">
      <div
        className={`px-2 py-2 mx-1 ${!isDisabled ? 'cursor-pointer' : ''}`}
        style={{
          whiteSpace: 'nowrap',
          border: `1px solid ${selected === 'at' ? '#002eb4' : '#dee2e6'}`,
          backgroundColor: selected === 'at' ? '#D2DCF7' : isDisabled ? '#eff0f7' : 'unset',
          width: '50%',
          textAlign: 'center',
          borderRadius: '0.25rem',
          height: 38,
          fontSize: 14,
        }}
        onClick={() => handleClick('at')}
      >
        Automatic
      </div>
      <div
        className={`px-2 py-2 mx-1 ${!isDisabled ? 'cursor-pointer' : ''}`}
        style={{
          whiteSpace: 'nowrap',
          border: `1px solid ${selected === 'mt' ? '#002eb4' : '#dee2e6'}`,
          backgroundColor: selected === 'mt' ? '#D2DCF7' : isDisabled ? '#eff0f7' : 'unset',
          width: '50%',
          textAlign: 'center',
          borderRadius: '0.25rem',
          height: 38,
          fontSize: 14,
        }}
        onClick={() => handleClick('mt')}
      >
        Manual
      </div>
    </div>
  );
};

export default TranmissionOption;
