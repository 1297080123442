import OrderListIcon from '@assets/icons/menus/order-list.svg';
import OrderListActiveIcon from '@assets/icons/menus/order-list-active.svg';
import OrderDraftIcon from '@assets/icons/menus/draft-order.svg';
import OrderDraftActiveIcon from '@assets/icons/menus/draft-order-active.svg';

export const MenuList = [
  // {
  //   name: 'Dashboard',
  //   icon: OrderListIcon,
  //   iconActive: OrderListActiveIcon,
  //   url: '',
  //   child: [],
  // },
  {
    name: 'Daftar Pesanan',
    icon: OrderListIcon,
    iconActive: OrderListActiveIcon,
    url: 'order',
    child: [],
  },
  {
    name: 'Draft Pesanan Baru',
    icon: OrderDraftIcon,
    iconActive: OrderDraftActiveIcon,
    url: 'draft',
    child: [],
  },
  {
    name: 'Laporan Penjualan',
    icon: OrderDraftIcon,
    iconActive: OrderDraftActiveIcon,
    url: 'order-reports',
    child: [],
  },
];
