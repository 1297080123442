import React, { useState, useMemo, useEffect } from 'react';
import { debounce } from 'lodash';
import Cookies from 'universal-cookie';
import Helper from '@helpers/Helper';
import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  InputGroup,
  InputGroupAddon,
} from '../../components/otoklix-elements';
import ScrollMenu from '../../components/scroll-menu/ScrollMenu';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { customStylesHeaderTable } from '@utils/Constants';
import { statusFilter } from '@utils/Constants';
import useResponsive from '@utils/useResponsive';
import userPermission from '@utils/userPermission';
import API from '@utils/API';
import Wrapper from '@components/wrapper/Wrapper';
import Loading from '@components/loading/Loading';
import NoData from '@components/empty-state/NoData';
import StatusBadge from '@components/badge/StatusBadge';
import SettlementBadge from '@components/badge/SettlementBadge';
import TitlePage from '@components/dashboard/TitlePage';
import CustomPagination from '@components/pagination/CustomPagination';
import SearchIcon from '@assets/icons/search.svg';
import moment from 'moment';

const Orders = () => {
  const { isDesktop } = useResponsive();
  const { generatePermission } = userPermission();
  const cookies = new Cookies();
  const authorizedWorkshopsSlug = cookies.get('authorized_workshops_slug');

  const history = useHistory();
  const api = new API('v2');

  const defaultSelectedStatusFilter = {
    id: null,
    value: '',
    label: 'Semua',
  };
  const startDate = `${moment(new Date()).format('YYYY-MM-DD')}`;
  const endDate = `${moment(new Date()).format('YYYY-MM-DD')}`;

  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [query, setQuery] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [bookingStatus, setBookingStatus] = useState('');
  const [bookingStartDate, setBookingStartDate] = useState(startDate);
  const [bookingEndDate, setBookingEndDate] = useState(endDate);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(defaultSelectedStatusFilter);

  const allowPermission = (menu) => {
    return !!(generatePermission('order', menu) || generatePermission('order', 'all'));
  };

  const allowList = allowPermission('orderall') || allowPermission('orderlist');
  const allowAdd = allowPermission('orderall') || allowPermission('ordercreate');
  const allowDetail = allowPermission('orderall') || allowPermission('orderdetail');

  const columns = useMemo(
    () => [
      {
        name: 'Nama Customer',
        selector: (row) => row?.customer?.name,
        cell: (row) => {
          return row?.customer?.name ? Helper.shortenString(row?.customer?.name, 15) : '-';
        },
        sortable: true,
        sortField: 'customer_name', // This line appears to be duplicated
        minWidth: '160px',
      },
      {
        name: 'Kode Booking',
        selector: (row) => row?.booking_code,
        cell: (row) => row?.booking_code,
        sortable: true,
        sortField: 'booking_code',
        sortable: true,
        minWidth: '140px',
      },
      {
        name: 'Kategori',
        selector: (row) => row?.service_category,
        cell: (row) => row?.service_category,
        sortable: true,
        sortField: 'service_category',
        sortable: true,
        minWidth: '80px',
      },
      {
        name: 'Status',
        selector: (row) => row?.booking_status?.name,
        cell: (row) => <StatusBadge status={row?.booking_status} />,
        sortable: true,
        sortField: 'booking_status',
        sortable: true,
        minWidth: '190px',
      },
      {
        name: 'Payment',
        selector: (row) => row?.payment_status,
        cell: (row) =>
          row?.payment_status ? (
            <SettlementBadge status={row?.payment_detail?.code} statusName={row?.payment_detail?.name} />
          ) : (
            '-'
          ),
        sortable: true,
        sortField: 'payment_status',
        sortable: true,
        minWidth: '200px',
      },
      {
        name: 'Merk Kendaraan',
        selector: (row) => row?.car_model,
        cell: (row) => row?.car_model ?? '-',
        sortable: true,
        sortField: 'car_model',
        sortable: true,
        minWidth: '150px',
      },
      {
        name: 'No Polisi',
        selector: (row) => row?.license_plate,
        cell: (row) => row?.license_plate ?? '-',
        sortable: true,
        sortField: 'license_plate',
        sortable: true,
        minWidth: '150px',
      },
      {
        name: 'Inspeksi Kendaraan',
        selector: (row) => row?.inspection_status,
        cell: (row) => row?.inspection_status,
        sortable: true,
        sortField: 'inspection_status',
        sortable: true,
        minWidth: '150px',
      },
      {
        name: 'Tipe Servis',
        selector: (row) => row?.service_type?.name,
        cell: (row) => row?.service_type?.name ?? '-',
        sortable: true,
        sortField: 'service_type',
        sortable: true,
        minWidth: '125px',
      },
      {
        name: 'Booking Source',
        selector: (row) => row?.booking_source?.name,
        cell: (row) => row?.booking_source?.name ?? '-',
        sortable: true,
        sortField: 'booking_source',
        sortable: true,
        minWidth: '165px',
      },
      {
        name: 'Tipe Pelanggan',
        selector: (row) => row?.customer_type,
        cell: (row) => row?.customer_type ?? '-',
        sortable: true,
        sortField: 'customer_type',
        sortable: true,
        minWidth: '145px',
      },
      {
        name: 'Membership',
        selector: (row) => row?.membership?.name,
        cell: (row) => row?.membership?.name ?? '-',
        sortable: true,
        sortField: 'membership',
        sortable: true,
        minWidth: '145px',
      },
      {
        name: 'Jadwal Booking',
        selector: (row) => row?.booking_datetime,
        cell: (row) => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{moment(row?.booking_datetime).format('DD MMM YYYY')}</span>
            <span>{row?.booking_time}</span>
          </div>
        ),
        sortable: true,
        sortField: 'booking_datetime',
        sortable: true,
        minWidth: '150px',
      },
      {
        name: 'Dibuat',
        selector: (row) => row?.created_at,
        cell: (row) => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{moment(row?.created_at).format('DD MMM YYYY')}</span>
            <span>{moment(row?.created_at).format('HH:mm')}</span>
          </div>
        ),
        sortable: true,
        sortField: 'created_at',
        sortable: true,
        minWidth: '150px',
      },
    ],
    []
  );

  const handleChangeStatus = (status) => {
    setSelectedStatusFilter(status);
    setBookingStatus(status?.value);
    setPage(1);
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const validateCountPage = (pagination) => {
    if (+page > +pagination?.total_page) {
      setPage(pagination?.total_page);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const fetchDataTable = async () => {
    setLoading(true);
    try {
      const res = await api.get(
        `v2/gms/booking?workshop_slug=${authorizedWorkshopsSlug}&query=${query}&booking_status=${bookingStatus}&booking_start_date=${
          bookingStartDate && bookingStartDate + ' 00:00:00'
        }&booking_end_date=${bookingEndDate && bookingEndDate + ' 23:59:59'}&page=${page}&limit=${pageLimit}`
      );
      if (+res?.status === 200) {
        setDataTable(res?.data?.data);
        const pagination = res?.data?.pagination;
        if (pagination) {
          setTotalPages(pagination?.total_page);
          setTotalRows(pagination?.total_rows);
          setPageLimit(pagination?.limit);
          setPage(pagination?.current_page);
          validateCountPage(pagination);
        }
      }
    } catch (error) {
      setDataTable([]);
      setLoading(false);
      console.error(error);
    }
  };

  const handleRowClick = (row) => {
    if (allowDetail) {
      history.push(`/order/detail/${row?.booking_code}`);
    }
  };

  const debouncedFetchDataTable = debounce(() => {
    fetchDataTable();
  }, 1500);

  useEffect(() => {
    debouncedFetchDataTable();
  }, [query]);

  useEffect(() => {
    if (page && pageLimit) {
      fetchDataTable();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    fetchDataTable();
  }, [bookingStatus, bookingStartDate, bookingEndDate]);

  useEffect(() => {
    fetchDataTable();
  }, []);

  return (
    <Wrapper title="" className="orders">
      <Row className="head">
        <Col lg={2} md={12} sm={12}>
          <TitlePage title="Pesanan Baru" />
        </Col>
        <Col lg={10} md={12} sm={12}>
          <Row>
            <Col lg={1} md={1} sm={12} className="mt-2" />
            <Col lg={5} md={5} sm={12} className="mt-2">
              <InputGroup className="input-search">
                <InputGroupAddon addonType="prepend">
                  <img src={SearchIcon} alt="Search Icon" />
                </InputGroupAddon>
                <Input
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="Cari nama, kendaraan, kategori, kode booking, plat nomor"
                />
              </InputGroup>
            </Col>
            <Col lg={4} md={4} sm={12} className="mt-2 d-flex justify-content-between">
              <Input
                type="date"
                style={{ width: '50%' }}
                placeholder="Tanggal awal"
                onChange={(e) => setBookingStartDate(e.target.value)}
                defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                value={bookingStartDate}
                className={bookingStartDate ? '' : 'date-placeholder'}
              />
              {isDesktop && (
                <span style={{ fontWeight: 100 }} className="pt-1">
                  -
                </span>
              )}
              <Input
                type="date"
                style={{ width: '50%' }}
                placeholder="Tanggal akhir"
                onChange={(e) => setBookingEndDate(e.target.value)}
                min={bookingStartDate ? new Date(bookingStartDate).toJSON().slice(0, 10) : ''}
                value={bookingEndDate}
                defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                className={bookingEndDate ? '' : 'date-placeholder'}
              />
            </Col>
            <Col lg={2} md={2} sm={12} className="mt-2">
              {allowAdd && (
                <Button
                  id="create-order"
                  outline
                  color="primary"
                  size="sm"
                  block={!isDesktop}
                  onClick={() => history.push('/order/create')}
                >
                  + Order Baru
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col lg={1} md={12} sm={12} className="d-flex align-items-center">
          <span className="fw-bold text-nowrap">Status Order</span>
        </Col>
        <Col lg={11} md={12} sm={12}>
          <ScrollMenu
            data={statusFilter}
            activeFilter={selectedStatusFilter}
            onClick={handleChangeStatus}
            disabled={loading}
          />
        </Col>
      </Row>
      {allowList && (
        <Card className="mt-3 border-table">
          <CardBody>
            <Row>
              <Col lg={12} md={12}>
                <DataTable
                  clearSelectedRows={loading}
                  persistTableHead
                  highlightOnHover
                  sortServer
                  noHeader={true}
                  progressPending={loading}
                  data={dataTable}
                  className="table dt-responsive table-custom"
                  noDataComponent={loading ? <Loading /> : <NoData />}
                  progressComponent={<Loading />}
                  customStyles={customStylesHeaderTable}
                  onRowClicked={(row) => handleRowClick(row)}
                  columns={columns}
                />
              </Col>
              <Col sm={12} md={12} className="mt-2">
                {dataTable?.length > 1 && !loading && (
                  <CustomPagination
                    page={page}
                    pageLimit={pageLimit}
                    totalRows={dataTable?.length ?? 0}
                    totalAllRows={totalRows}
                    totalPages={totalPages}
                    handleChangePage={handleChangePage}
                    handleChangeRowPerPage={handleChangeRowPerPage}
                  ></CustomPagination>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
    </Wrapper>
  );
};

export default Orders;
