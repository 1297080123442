import React, { useCallback, useState } from 'react';
import { Alert, Button, Col, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import API from '@utils/API';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import PromoCard from './PromoCard';
import InputField from '../field/InputField';
import Helper from '@helpers/Helper';

const ModalCampaignItem = (props) => {
  const { isOpen, toggle, handleChangeWorkshop, dataWorkshop, hasLoading } = props;
  const api = new API('v2');
  const [workshops, setWorkshops] = useState([]);
  const [hasMoreWorkshop, setHasMoreWorkshop] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [hasErrorAPI, setHasErrorAPI] = useState('');

  const [fetching, setFetching] = useState(false);
  const [restart, setRestart] = useState(false);

  async function fetchIssues(page = 1, search = query, restart = false) {
    const datetime = moment().format('YYYY-MM-DD HH:mm:ss');
    let params = {
      // query: search,
      page: page,
      limit: 10,
      discount_for: 'per-item',
    };
    const response = await api.get(`v2/gms/campaign/${dataWorkshop?.slug}`, { params });

    const pages = page + 1;
    const items = await response?.data?.data;

    if (pages > response?.data?.pagination?.total_page) {
      setHasMoreWorkshop(false);
    } else {
      setHasMoreWorkshop(true);
    }

    return {
      pages,
      items,
    };
  }

  const fetchItems = useCallback(async () => {
    if (fetching) {
      return;
    }

    setFetching(true);

    try {
      if (dataWorkshop) {
        const { pages, items } = await fetchIssues(currentPage);
        setWorkshops([...workshops, ...items]);
        if (pages) {
          setCurrentPage(pages);
        } else {
          setCurrentPage(null);
        }
      }
    } finally {
      setFetching(false);
    }
  }, [workshops, fetching, currentPage, dataWorkshop]);

  const getWorkshopList = async (page = 1, search = query, restart) => {
    const datetime = moment().format('YYYY-MM-DD HH:mm:ss');
    let params = {
      query: search,
      booking_datetime: datetime,
      page,
      limit: 10,
      discount_for: 'per-item',
    };

    setHasErrorAPI('');
    if (restart) setLoading(true);

    await api
      .post(`v2/intools/workshops/inventory-related/`, params)
      .then((res) => {
        const data = res.data.data;
        if (data?.length < 1) setHasMoreWorkshop(false);
        if (restart) {
          setWorkshops(res.data.data);
          setHasMoreWorkshop(true);
          setLoading(false);
        } else {
          setWorkshops([...workshops, ...data]);
          setLoading(false);
        }
      })
      .catch((err) => {
        setHasMoreWorkshop(false);
        setHasErrorAPI(`Gagal! - ${err?.response?.data?.error?.message}`);
        setLoading(false);
      });

    if (restart) {
      setCurrentPage(1);
    } else {
      setCurrentPage(page);
    }
  };

  const handleInputQuery = (e) => {
    if (!hasLoading) {
      setQuery(e.target.value);
      setRestart(true);
      // getWorkshopList(1, e.target.value, true);
    }
  };

  const handleChangeQuery = Helper.debounce(handleInputQuery, 500);

  const handleDismissAlert = () => setHasErrorAPI('');

  const handleOpenedModal = () => {
    // getWorkshopList(1, '');
    setQuery('');
    // onOpened={() => handleOpenedModal()}
  };

  const handleClosedModal = () => {
    setQuery('');
    // onExit={handleClosedModal}
  };

  return (
    <Modal isOpen={isOpen} toggle={hasLoading ? null : toggle} centered size="lg" onOpened={() => handleOpenedModal()}>
      <ModalBody>
        <Row className="text-center">
          <span className="text-right">{hasLoading && <Spinner size="sm" color="primary" />}</span>
          <span style={{ fontWeight: 700, fontSize: 24 }}>Cari Promo</span>
        </Row>

        <Row
          style={{
            border: '1px solid #002EB4',
            boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.05)',
            borderRadius: 4,
            padding: 8,
            margin: '0px 32px',
          }}
        >
          <Row className="w-100 mt-2 mb-3">
            <Col className="d-flex p-0 align-items-center">
              <InputField disabled={hasLoading} placeholder="Cari Promo" onChange={handleInputQuery} colWidth={12} />
            </Col>
          </Row>

          {hasErrorAPI && (
            <Row className="w-100">
              <Alert color="danger" className="w-100 m-2" isOpen={!!hasErrorAPI} toggle={handleDismissAlert}>
                <span dangerouslySetInnerHTML={{ __html: hasErrorAPI }}></span>
              </Alert>
              <hr />
            </Row>
          )}

          <Row className="w-100">
            <Col className="promo-field" style={{ height: 300, overflow: 'auto' }}>
              <InfiniteScroll
                pageStart={1}
                loadMore={fetchItems}
                hasMore={hasMoreWorkshop}
                loader={
                  <div className="loader d-flex justify-content-center my-2" key={0}>
                    <Spinner size="sm" color="primary" />
                  </div>
                }
                useWindow={false}
              >
                {workshops
                  ?.filter(
                    (item) => item?.name?.toLowerCase()?.includes(query) || item?.code?.toLowerCase()?.includes(query)
                  )
                  ?.map((item, index) => {
                    return (
                      <PromoCard
                        key={index}
                        data={item}
                        handleChangeWorkshop={!hasLoading ? handleChangeWorkshop : null}
                      />
                    );
                  })}

                {workshops?.filter(
                  (item) => item?.name?.toLowerCase()?.includes(query) || item?.code?.toLowerCase()?.includes(query)
                )?.length < 1 && !fetching ? (
                  <Row className="justify-content-center text-center pt-5 w-100">
                    <div className="d-flex flex-column justify-content-center">
                      <span style={{ fontSize: 24 }}>🙂</span>
                      <span>
                        Tidak ditemukan promo yang sesuai <br /> Cari lagi dengan kata kunci lain yaa!
                      </span>
                    </div>
                  </Row>
                ) : null}
              </InfiniteScroll>
            </Col>
          </Row>
        </Row>

        <Row>
          <Col lg={12} className="d-flex justify-content-center mt-2">
            <Button color="primary" outline size="sm" className="w-p-35 mx-3" onClick={!hasLoading ? toggle : null}>
              Kembali
            </Button>
            {/* <Button color="primary" size="sm" onClick={() => handleSubmit()}>
              Lanjutkan
            </Button> */}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ModalCampaignItem;
