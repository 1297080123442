import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from '../utils';

const propTypes = {
  tag: tagPropType,
  top: PropTypes.bool,
  bottom: PropTypes.bool,
  rounded: PropTypes.bool,
  className: PropTypes.string,
  alt: PropTypes.string,
  cssModule: PropTypes.object,
};

const defaultProps = {
  tag: 'img',
  alt: '',
};

const CardImg = (props) => {
  const { className, cssModule, top, bottom, rounded, alt, tag: Tag, ...attributes } = props;

  let cardImgClassName = 'card-img';
  if (top) {
    cardImgClassName = 'card-img-top';
  }
  if (bottom) {
    cardImgClassName = 'card-img-bottom';
  }

  const classes = mapToCssModules(classNames(className, cardImgClassName, rounded ? 'card-image' : false), cssModule);

  return <Tag {...attributes} className={classes} alt={alt} />;
};

CardImg.propTypes = propTypes;
CardImg.defaultProps = defaultProps;

export default CardImg;
