import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Row from '../row/Row';
import Col from '../col/Col';
import Text from '../text/Text';
import { mapToCssModules, tagPropType } from '../utils';

const propTypes = {
  tag: tagPropType,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  bgColor: PropTypes.string,
  titleColor: PropTypes.string,
  subtitleColor: PropTypes.string,
  shadow: PropTypes.string,
  leftImage: PropTypes.string,
  leftImageWidth: PropTypes.number,
  className: PropTypes.string,
  cssModule: PropTypes.object,
};

const defaultProps = {
  tag: 'div',
  leftImageWidth: 40,
};

const Widget = (props) => {
  const {
    title,
    shadow,
    bgColor,
    subtitle,
    titleColor,
    subtitleColor,
    leftImage,
    leftImageWidth,
    className,
    cssModule,
    tag: Tag,
    ...attributes
  } = props;

  const classes = mapToCssModules(
    classNames('widget', 'mb-3', bgColor ? `bg-${bgColor}` : false, shadow ? `shadow-${shadow}` : false, className),
    cssModule
  );

  return (
    <Tag {...attributes} className={classes}>
      <Row>
        <Col widths={['base']} base="4" relative>
          <div className="vertical-center-box">
            <img src={leftImage} width={leftImageWidth} alt="icon-widget" loading="lazy" />
          </div>
        </Col>
        <Col widths={['base']} base="8" className="text-right">
          <Text tag="h5" truncate className="title mb-1" color={titleColor}>
            {title}
          </Text>
          <Text tag="h5" truncate className="subtitle my-1" color={subtitleColor}>
            {subtitle}
          </Text>
        </Col>
      </Row>
    </Tag>
  );
};

Widget.propTypes = propTypes;
Widget.defaultProps = defaultProps;

export default Widget;
