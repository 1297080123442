import React from 'react';

const FormTitle = (props) => {
  const { title, subTitle } = props;
  return (
    <>
      <span style={{ fontWeight: 600, fontSize: 16, lineHeight: '24px' }}>{title}</span>
      <span style={{ fontWeight: 400, fontSize: 14, lineHeight: '20px' }}>{subTitle}</span>
    </>
  );
};

export default FormTitle;
