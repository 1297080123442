import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import SearchIcon from '@assets/icons/search.svg';
import Helper from '@helpers/Helper';

const SearchAsync = (props) => {
  const {
    options = [],
    handleChangeQuery = () => {},
    onChange = () => {},
    onBlurAction = () => {},
    loading = false,
  } = props;

  const [query, setQuery] = useState('');
  const [optionWidth, setOptionsWidth] = useState(0);

  const handleChangeInput = async (e) => {
    const { value } = e.target;
    await setQuery(value);
  };

  const handleSelectOption = (opt) => {
    onChange(opt);
    setQuery(opt?.name);
  };

  const onFocusInput = () => {
    const elm = document.getElementById('dropdown-list');
    elm.style.display = 'flex';
  };

  const onBlurInput = () => {
    setTimeout(() => {
      const elm = document.getElementById('dropdown-list');
      elm.style.display = 'none';
      onBlurAction();
    }, 300);
  };

  const getOptionWidth = () => {
    const elm = document.getElementById('search-input');
    const elmWidth = elm?.offsetWidth;
    setOptionsWidth(elmWidth);
  };

  useEffect(() => {
    getOptionWidth();
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      handleChangeQuery(query);
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [query]);

  return (
    <div style={{ position: 'relative' }}>
      <div className="search-input">
        <img src={SearchIcon} alt="" />
        <Input
          id="search-input"
          onChange={handleChangeInput}
          value={query}
          placeholder="Cari Produk/Paket"
          onFocus={onFocusInput}
          onBlur={onBlurInput}
        />
      </div>

      {loading && <span>Loading ...</span>}
      <div id="dropdown-list" className="dropdown-list" style={{ width: optionWidth }}>
        {options?.length > 0 ? (
          map(options, (opt, index) => {
            return (
              <span className="option-list" key={index} onClick={() => handleSelectOption(opt)}>
                {opt?.name}
              </span>
            );
          })
        ) : (
          <span className="placeholder">No options</span>
        )}
      </div>
    </div>
  );
};

export default SearchAsync;
