import { useSelector } from 'react-redux';
import { permission } from '@reducers/Auth';
import { Router, useHistory } from 'react-router-dom';
import { map } from 'lodash';

function userPermission() {
  const permissionList = useSelector(permission);
  const history = useHistory();

  const getAccess = () => {
    let result = map(permissionList, (item) => {
      return item;
    });
    const superAdmin = result?.find((permission) => permission == 'all');
    const access = result?.map((permission) => {
      const list = permission.split('.');
      const subMenu = `${list[1]?.toLowerCase()}${list[2]?.toLowerCase() || ''}${list[3]?.toLowerCase() || ''}${
        list[4]?.toLowerCase() || ''
      }`;

      return {
        main: list[1]?.toLowerCase() || '',
        sub: subMenu,
      };
    });

    if (superAdmin) {
      return { main: 'all', sub: '' };
    } else {
      return access;
    }
  };

  const showMenuAll = () => {
    return !!getAccess()?.find((access) => access.main == 'all');
  };

  const showMainMenu = (menu) => {
    return !!(getAccess()?.find((access) => access.main === menu) || showMenuAll());
  };

  const showSubMenu = (mainMenu, subMenu) => {
    return !!(getAccess()?.find((access) => access.main === mainMenu && access.sub == subMenu) || showMenuAll());
  };

  const generatePermission = (mainMenu, subMenu) => {
    return !!(showSubMenu(mainMenu, subMenu) || showSubMenu(mainMenu, 'all'));
  };

  return {
    showMainMenu,
    showSubMenu,
    generatePermission,
  };
}

export default userPermission;
