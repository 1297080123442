import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getActiveLink } from '@reducers/App';
import { resetAuth, getUsername, getFullName } from '@reducers/Auth';
import { authLogout } from '@actions/Auth';
import userPermission from '@utils/userPermission';
import Logo from '@assets/images/logo.svg';
import CustomModal from '@components/modal/CustomModal';
import ChangePasswordForm from '@components/navbar/ChangePasswordForm';
import API from '@utils/API';
import { regexWithMinimumNumberCharacter } from '@utils/Constants';
import useResponsive from '@utils/useResponsive';
import Avatar from '@assets/icons/default-profile-img.svg';
import MenuIcon from '@assets/icons/menus/menus.svg';
import { find, last, map } from 'lodash';
import { MenuList } from '@utils/MenuList';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';

function Dashboard() {
  const api = new API('v2');
  const dispatch = useDispatch();
  const activeLink = useSelector(getActiveLink);
  const username = useSelector(getUsername);
  const fullname = useSelector(getFullName);
  const history = useHistory();
  const { showMainMenu, showSubMenu } = userPermission();
  const { isDesktop } = useResponsive();

  const [hideSidebar, setHideSidebar] = useState(true);
  const [showChangePass, setShowChangePass] = useState(false);
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [ConfirmNewPassword, setConfirmNewPassword] = useState();
  const [errorMessageCurrent, setErrorMessageCurrent] = useState();
  const [errorMessageNew, setErrorMessageNew] = useState();
  const [errorMessageConfirm, setErrorMessageConfirm] = useState();
  const [disabledForm, setDisabledForm] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);

  const showSystemAll = showSubMenu('system', 'all');

  const toggleSidebar = () => {
    if (!isDesktop) setHideSidebar(!hideSidebar);
  };

  const handleLogout = () => {
    const cookies = new Cookies();
    cookies.remove('intools_tok_c');

    dispatch(resetAuth());
  };

  const hasActiveLink = (router) => {
    if (router === activeLink) {
      return 'active';
    }
    return 'inactive';
  };

  const handleCurrentPassword = (e) => {
    setSubmitStatus(null);
    setCurrentPassword(e.target.value);
    if (!e.target.value) {
      setErrorMessageCurrent('Password is empty or invalid format');
    } else {
      setErrorMessageCurrent();
    }
  };

  const handleNewPassword = (e) => {
    setSubmitStatus(null);
    setNewPassword(e.target.value);
    const isPasswordCorrect = regexWithMinimumNumberCharacter.test(e.target.value);
    if (isPasswordCorrect) {
      setErrorMessageNew();
      if (ConfirmNewPassword !== e.target.value) {
        setErrorMessageConfirm('Passwords are not matching');
      } else {
        setErrorMessageConfirm();
      }
    } else if (!e.target.value) {
      setErrorMessageNew('Password is empty or invalid format');
    } else if (!isPasswordCorrect) {
      setErrorMessageNew('Use 8 or more characters with a mix of letters and numbers');
    }
  };

  const handleConfirmNewPassword = (e) => {
    setSubmitStatus(null);
    setConfirmNewPassword(e.target.value);
    if (!e.target.value) {
      setErrorMessageConfirm('Repeat Password is empty or invalid format');
    } else if (newPassword !== e.target.value) {
      setErrorMessageConfirm('Passwords are not matching');
    } else {
      setErrorMessageConfirm();
    }
  };

  const resetForm = () => {
    setSubmitStatus(null);
    setCurrentPassword();
    setNewPassword();
    setConfirmNewPassword();
    setErrorMessageCurrent();
    setErrorMessageNew();
    setErrorMessageConfirm();
  };

  const validationData = () => {
    let status = true;

    if (!currentPassword) {
      setErrorMessageCurrent('Password is empty or invalid format');
      status = false;
    }

    if (!newPassword) {
      setErrorMessageNew('Password is empty or invalid format');
      status = false;
    }

    const isNewPasswordCorrect = regexWithMinimumNumberCharacter.test(newPassword);
    if (newPassword && !isNewPasswordCorrect) {
      setErrorMessageNew('Use 8 or more characters with a mix of letters and numbers');
      status = false;
    }

    if (!ConfirmNewPassword) {
      setErrorMessageConfirm('Password is empty or invalid format');
      status = false;
    }

    if (newPassword !== ConfirmNewPassword) {
      setErrorMessageConfirm('Passwords are not matching');
      status = false;
    }

    return status;
  };

  const handleSubmitNewPassword = (e) => {
    e.preventDefault();
    if (!validationData()) {
      return;
    }
    handleOnConfirm();
  };

  const handleOnConfirm = () => {
    setSubmitStatus(null);
    setDisabledForm(true);
    const payload = {
      current: currentPassword,
      new: newPassword,
      confirm: ConfirmNewPassword,
    };

    api
      .post(`v2/gms/auth/change-password/`, payload)
      .then((response) => {
        showAlert('success', response?.message ?? 'Berhasil update password.');
        setTimeout(() => {
          setShowChangePass(false);
          setDisabledForm(false);
          resetForm();
        }, 500);
      })
      .catch((error) => {
        showAlert('danger', error?.response?.data?.error?.message ?? 'Gagal update password.');
        setTimeout(() => {
          setDisabledForm(false);
        }, 500);
      });
  };

  const showAlert = (color, message) => {
    setSubmitStatus({
      color: color,
      message: message,
    });
  };

  const handleOpenChat = () => {
    if (typeof window !== 'undefined') {
      const chatId = document.getElementById('fc_frame');
      if (chatId) {
        window.fcWidget.open();
      }
    }
  };

  const getInitials = (email) => {
    const intials = email.split('@')[0].split('.');
    return intials.map((item) => item.charAt(0)).join('');
  };

  const getInitialsName = (email) => {
    const [name] = email.split('@');
    const [firstName, lastName] = name.split('.');
    return firstName + ' ' + lastName;
  };

  useEffect(() => {
    if (isDesktop) {
      setHideSidebar(false);
    } else {
      setHideSidebar(true);
    }
  }, [isDesktop]);

  return (
    <>
      <CustomModal
        modal={showChangePass}
        modalWidth="800"
        backdrop="static"
        toggle={() => {
          setShowChangePass(false);
          resetForm();
        }}
      >
        <ChangePasswordForm
          currentPassword={currentPassword}
          newPassword={newPassword}
          ConfirmNewPassword={ConfirmNewPassword}
          errorMessageCurrent={errorMessageCurrent}
          errorMessageNew={errorMessageNew}
          errorMessageConfirm={errorMessageConfirm}
          toggleClose={() => {
            setShowChangePass(false);
            resetForm();
          }}
          disabledForm={disabledForm}
          onChangeCurrentPassword={handleCurrentPassword}
          onChangeNewPassword={handleNewPassword}
          onChangeConfirmNewPassword={handleConfirmNewPassword}
          onConfirm={(e) => handleSubmitNewPassword(e)}
          submitStatus={submitStatus}
        />
      </CustomModal>

      <div className={`left-sidenav`} style={{ width: !hideSidebar ? '100%' : '0%', height: '100vh' }}>
        <div className="topbar-left">
          <a href="/" className="logo">
            <span>
              <img src={Logo} alt="logo-small" className="logo-sm" />
            </span>
          </a>
        </div>

        <div className="navbar d-flex justify-content-between align-items-end flex-column w-100">
          <ul className="navbar-menu slimscroll w-100">
            {map(MenuList, (item, index) => {
              const hasAccess =
                showMainMenu(item?.url) ||
                item?.url == 'order' ||
                item?.url == 'draft' ||
                item?.url == 'order-reports' ||
                item?.url == '';
              const isActive = activeLink === item?.url;

              if (hasAccess)
                return (
                  <li className="leftbar-menu-item mt-2" key={index}>
                    <Link
                      to={`/${item?.url}`}
                      onClick={toggleSidebar}
                      className={`d-flex align-items-center${isActive ? ' active' : ''}`}
                    >
                      <img src={isActive ? item?.iconActive : item?.icon} className="mr-2" />
                      <span>{item?.name}</span>
                    </Link>
                  </li>
                );
            })}
          </ul>

          <div className="leftbar-profile p-2 w-100">
            <div className="media position-relative">
              <div className="leftbar-user online">
                <div className="initial-avatar thumb-md rounded-circle">{getInitials(username)}</div>
              </div>
              <div className="media-body align-self-center text-truncate mx-2">
                <h5 className="mt-0 mb-1 font-weight-semibold font-14 text-capitalize">
                  {fullname !== null ? fullname : getInitialsName(username)}
                </h5>
                <h6 className="mt-0 mb-1 font-12">{username}</h6>
              </div>

              <Menu
                menuButton={
                  <div>
                    <img src={MenuIcon} />
                  </div>
                }
                align="end"
                position="initial"
                direction="top"
              >
                <MenuItem onClick={() => setShowChangePass(!showChangePass)}>Change Password</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>

      <div className="topbar">
        <nav className="navbar-custom">
          <ul className="list-unstyled topbar-nav mb-0 menu-resp">
            <li>
              <button className="button-menu-mobile nav-link" onClick={toggleSidebar} style={{ height: 60 }}>
                <div className={`menu-toggle ${!hideSidebar ? 'active' : ''}`} />
              </button>
            </li>
            <li style={{ height: 60, display: 'flex', alignItems: 'center' }}>
              <h4>{find(MenuList, (item) => item?.url === activeLink)?.name}</h4>
            </li>
          </ul>
        </nav>
      </div>
      <div
        id="custom_chat_button"
        className="default-custom_chat_button d-none "
        onClick={() => handleOpenChat()}
      ></div>
    </>
  );
}

export default Dashboard;
