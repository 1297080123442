import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Input,
  Card,
  FormGroup,
  FormText,
  Label,
  InputGroupText,
  InputGroup,
} from 'reactstrap';
import Cookies from 'universal-cookie';
import API from '@utils/API';
import useResponsive from '@utils/useResponsive';
import InfoSquareIcon from '@assets/icons/info-square.svg';
import ProductItemsCard from '@components/card/ProductItemsCard';
import CustomPagination from '@components/pagination/CustomPagination';
import SelectAsyncField from '@components/field/SelectAsyncField';
import NoData from '@components/empty-state/NoData';
import useAlert from '@hooks/useAlert';
import Helper from '@helpers/Helper';
import CustomModal from '@components/modal/CustomModal';
import { assign, map } from 'lodash';

const ChooseItemModal = (props) => {
  const { isOpen, title, toggle, userCarId, onSubmit, defaultQty, defaultPrice, customerType, paymentStatus } = props;

  const api = new API('v2');
  const { isDesktop } = useResponsive();
  const { setAlert } = useAlert();
  const cookies = new Cookies();
  const authorizedWorkshopsSlug = cookies.get('authorized_workshops_slug');

  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [titleHeader, setTitleHeader] = useState('');
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(8);
  const [totalPage, setTotalPage] = useState(20);
  const [totalRows, setTotalRows] = useState(0);
  const [productItems, setProductItems] = useState([]);
  const [selectedProductItems, setSelectedProductItems] = useState(null);
  const [disableQtyBtn, setDisableQtyBtn] = useState(false);
  const [mainQty, setMainQty] = useState(0);
  const [listPackageDetail, setListPackageDetail] = useState([]);
  const [selectedPackageDetail, setSelectedPackageDetail] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [sellableQty, setSellableQty] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isDisableSaveButton, setIsDisableSaveButton] = useState(false);
  const [itemType, setItemType] = useState('');
  const [openWarning, setOpenWarning] = useState(false);
  const [isValidPrice, setIsValidPrice] = useState(false);
  const [isGuaranteed, setIsGuaranteed] = useState(false);

  const calculatedPriceItem = () => {
    let totalValue;
    totalValue = +mainQty * +selectedProductItems?.price;
    setTotalPrice(totalValue);
  };

  const calculatedPricePackage = () => {
    const totalValue = selectedPackageDetail.reduce((accumulator, currentItem) => {
      const price = currentItem?.price ? +currentItem?.price * +currentItem?.qty : 0;
      return accumulator + price;
    }, 0);
    setTotalPrice(totalValue);
  };

  const handleSelectPackage = (item) => {
    setSelectedProductItems(item);
    setActiveStep(1);
    setTitleHeader(item?.name);
    setListPackageDetail([]);
    setItemType(item?.item_type);
    calculatedPriceItem();
    if (item?.item_type === 'package') {
      if (item?.item_type === 'package') fetchDetailItems(item?.package_id);

      setSelectedPackageDetail([]);
      setDisableQtyBtn(true);
      setMainQty(1);
    } else {
      if (defaultQty) {
        setMainQty(defaultQty);
        if (item?.item_type !== 'service') {
          setIsDisableSaveButton(!!(defaultQty > item?.sellable_qty));
        }
        if (
          customerType.toLowerCase() === 'b2b' ||
          (customerType.toLowerCase() === 'b2c' && paymentStatus === 'settlement')
        ) {
          setDisableQtyBtn(true);
        }
      } else {
        if (item?.item_type !== 'service' && !!defaultQty) {
          setIsDisableSaveButton(!!(!!(defaultQty > item?.sellable_qty) || defaultQty || item?.sellable_qty < 1));
        }
        if (item?.item_type !== 'service') {
          if (item?.car_oil_qty) {
            setMainQty(item?.sellable_qty < item?.car_oil_qty ? item?.sellable_qty : item?.car_oil_qty);
          } else {
            setMainQty(item?.qty);
          }
        } else {
          setMainQty(1);
        }
      }
      const calculateTotalPrice = +mainQty * +item?.price;
      setTotalPrice(calculateTotalPrice);
      setSellableQty(+item?.sellable_qty);
    }
  };

  const handleChangePackageDetail = (e, index) => {
    let tempData = [...selectedPackageDetail];
    tempData[index] = e;
    setSelectedPackageDetail(tempData);
  };

  const handleChangePackageQty = (value, index) => {
    const newValue = Math.max(1, Math.min(100, Number(value)));
    let tempData = [...selectedPackageDetail];
    tempData[index] = { ...selectedPackageDetail[index], qty: +newValue };
    setSelectedPackageDetail(tempData);
  };

  const fetchDetailItems = async (packageId) => {
    setLoading(true);
    try {
      const res = await api.get(
        `v2/gms/item/${authorizedWorkshopsSlug}/${packageId}/details?variant_car_id=${userCarId}`
      );
      if (+res?.status === 200) {
        const data = res?.data?.data;
        setListPackageDetail(data?.package_details);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchItems = async () => {
    setLoading(true);
    const itemType = title === 'Tambah Item' ? 'all' : 'product,service';
    try {
      const res = await api.get(
        `v2/gms/item/${authorizedWorkshopsSlug}?item_type=${itemType}&variant_car_id=${userCarId}&limit=${pageLimit}&page=${page}&query=${query}&category=all`
      );
      if (+res?.status === 200) {
        const data = res?.data;
        setProductItems(data?.data);
        setTotalPage(data?.total_page);
        setTotalRows(data?.total_rows);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeQty = (e, source) => {
    if (disableQtyBtn) return;
    let qty = mainQty ?? 1;
    if (source == 'decrease') {
      qty -= 1;
      qty = Math.max(qty, 0);
    }
    if (source == 'increase') qty = qty + 1;
    if (source == 'input') qty = e;
    if (qty > sellableQty && itemType !== 'service') {
      qty = sellableQty;
    }
    setMainQty(qty);
  };

  const handleGuaranteedCheck = () => {
    setIsGuaranteed(!isGuaranteed);
  };

  useEffect(() => {
    setIsValidPrice(defaultPrice === totalPrice);
  }, [defaultPrice, totalPrice]);

  const handleSubmit = () => {
    const checkdata = selectedPackageDetail.map((item, i) => {
      return { ...item, index: i, name: item?.name };
    });
    const tempData = [...checkdata];
    const filterUndefined = tempData.filter((item) => item === undefined);
    if (+filterUndefined.length > 0 && selectedProductItems?.item_type === 'package') {
      setAlert('Mohon lengkapi detail package', 'warning');
    } else if (defaultQty && !isValidPrice && customerType.toLowerCase() === 'b2c' && paymentStatus === 'settlement') {
      setOpenWarning(true);
    } else {
      let dataPayload;
      let tempSelectedProductItem = { ...selectedProductItems, qty: mainQty, isNew: true };
      if (selectedProductItems?.item_type === 'package') {
        dataPayload = { ...tempSelectedProductItem, item_details: [...selectedPackageDetail] };
      } else {
        dataPayload = { ...tempSelectedProductItem };
        assign(dataPayload, {
          is_guaranteed: isGuaranteed,
        });
      }
      setListPackageDetail([]);
      setSelectedPackageDetail([]);
      setActiveStep(0);
      setQuery('');
      setDisableQtyBtn(false);
      onSubmit(dataPayload);
    }
  };

  useEffect(() => {
    calculatedPricePackage();
  }, [selectedPackageDetail]);

  useEffect(() => {
    calculatedPriceItem();
  }, [mainQty]);

  useEffect(() => {
    fetchItems();
  }, [page]);

  useEffect(() => {
    setPage(1);
    fetchItems();
  }, [query]);

  useEffect(() => {
    setTitleHeader(title);
  }, [title]);

  useEffect(() => {
    setOpen(isOpen);
    if (isOpen) {
      fetchItems();
    }
  }, [isOpen]);

  useEffect(() => {
    toggle(open);
    if (!open) setTitleHeader(title);
    if (open) {
      setActiveStep(0);
      setQuery('');
      setIsGuaranteed(false);
    }
  }, [open]);

  return (
    <>
      <Modal isOpen={open} centered className="choose-car-modal" toggle={() => setOpen(!open)} size={'xl'}>
        <ModalHeader toggle={() => setOpen(!open)}>{titleHeader}</ModalHeader>
        <ModalBody>
          <Row>
            {+activeStep === 0 && (
              <>
                <div>
                  <Row>
                    <span>Pilih item yang ingin kamu tambahkan ke order</span>
                  </Row>
                  <Input
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Cari Produk/Paket"
                    value={query}
                    className="mt-2"
                  />
                  <Row style={{ maxHeight: 548, minHeight: 548, overflow: 'auto' }}>
                    {+productItems?.length === 0 && <NoData />}
                    {productItems.map((item, index) => (
                      <Col lg={3} md={3} sm={6} xs={12} key={index} className="mt-4">
                        <ProductItemsCard item={item} onClick={() => handleSelectPackage(item)} />
                      </Col>
                    ))}
                    <Col md={12} lg={12} className="mt-4">
                      <CustomPagination
                        page={page}
                        pageLimit={pageLimit}
                        totalRows={productItems?.length ?? 0}
                        totalAllRows={totalRows}
                        totalPages={totalPage}
                        handleChangePage={handleChangePage}
                        short
                        usePerPage={false}
                      />
                    </Col>
                  </Row>
                </div>
              </>
            )}
            {+activeStep === 1 && (
              <>
                <div></div>
              </>
            )}
          </Row>

          {+activeStep === 1 && (
            <div style={{ maxHeight: 450, minHeight: 450 }}>
              <Row>
                <Col>
                  <Card
                    className="d-flex"
                    style={{ padding: '12px 16px', backgroundColor: '#F5F5F4', flexDirection: 'initial' }}
                  >
                    <img src={InfoSquareIcon} height={16} width={16} alt="" />
                    <span style={{ marginLeft: 12, fontSize: 12 }}>
                      Atur jumlah paket dan beberapa pilihan tertentu
                    </span>
                  </Card>
                </Col>
              </Row>
              <Row className={`mt-4${!isDesktop ? ' flex-column-reverse' : ''}`}>
                <Col lg={8} md={12} sm={12} className="overflow-auto">
                  <FormGroup>
                    <Label className="label-required fw-bold">Quantity</Label>
                    <InputGroup>
                      <InputGroupText
                        className={`input-group-text-left user-select-none${disableQtyBtn ? '' : ' cursor-pointer'}`}
                        onClick={() => handleChangeQty(null, 'decrease')}
                        disabled={mainQty === 1}
                      >
                        -
                      </InputGroupText>
                      <Input
                        type="number"
                        value={Number(mainQty) ?? 1}
                        onChange={(e) => handleChangeQty(e.target.value, 'input')}
                        className="text-center"
                        placeholder="quantity"
                        max={sellableQty}
                        disabled={disableQtyBtn}
                      />
                      <InputGroupText
                        className={`input-group-text-right user-select-none${disableQtyBtn ? '' : ' cursor-pointer'}`}
                        onClick={() => handleChangeQty(null, 'increase')}
                      >
                        +
                      </InputGroupText>
                    </InputGroup>
                    {(+mainQty === +sellableQty || isDisableSaveButton == true) && (
                      <FormText color="danger">Max qty {sellableQty}</FormText>
                    )}
                  </FormGroup>
                  <div style={{ height: 100 }}>
                    {+listPackageDetail.length > 0 &&
                      listPackageDetail.map((item, index) => {
                        let tempOptions = [];
                        const mappingOptions = item?.products.map((items) => ({
                          ...items,
                          isDisabled: !items?.is_sellable,
                        }));
                        tempOptions = [...mappingOptions];
                        return (
                          <FormGroup key={index}>
                            <Label className="label-required fw-bold">{item?.component_label}</Label>
                            <Row>
                              <Col md={10}>
                                <SelectAsyncField
                                  defaultOptions={tempOptions}
                                  getOptionLabel={(e) => (
                                    <div className="d-flex justify-content-between w-100">
                                      {e.name}{' '}
                                      {e?.sellable_qty && e?.product_type !== 'work' ? (
                                        <span className="float-right"> Sisa stock : {e?.sellable_qty}</span>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  )}
                                  getOptionValue={(e) => e.product_id}
                                  placeholder={item?.prompt}
                                  onChange={(val) => handleChangePackageDetail(val, index)}
                                  value={selectedPackageDetail[index] ?? ''}
                                  errorMessage={item?.error}
                                />
                              </Col>
                              <Col md={2}>
                                <Row>
                                  <Col md={3} className="d-flex align-items-center">
                                    <Label className="fw-bold mb-0">Qty</Label>
                                  </Col>
                                  <Col md={9}>
                                    <Input
                                      type="number"
                                      maxLength={2}
                                      value={Number(selectedPackageDetail[index]?.qty) ?? 1}
                                      onChange={(e) => handleChangePackageQty(e?.target?.value, index)}
                                      className="text-center"
                                      disabled={selectedPackageDetail[index]?.product_type === 'work'}
                                      placeholder="Qty"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            {+selectedPackageDetail?.[index]?.sellable_qty < +selectedPackageDetail?.[index]?.qty &&
                            selectedPackageDetail?.[index]?.product_type === 'part' ? (
                              <span className="text-danger">Stock tidak mencukupi</span>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        );
                      })}
                  </div>
                </Col>
                <Col
                  lg={4}
                  md={12}
                  sm={12}
                  className={`d-flex justify-content-${isDesktop ? 'end' : 'start'}${!isDesktop ? ' mb-2' : ''}`}
                >
                  <ProductItemsCard item={selectedProductItems} />
                </Col>
              </Row>
              {selectedProductItems?.is_consignment && (
                <Row>
                  <Col className="mt-2 mb-0 d-flex justify-content-between align-items-center">
                    <span className="price-title">
                      <FormGroup check>
                        <Input type="checkbox" checked={isGuaranteed} onChange={() => handleGuaranteedCheck()} />
                        <Label>Garansi</Label>
                      </FormGroup>
                    </span>
                  </Col>
                </Row>
              )}
              <Row>
                <Col className="mt-4 d-flex justify-content-between align-items-center">
                  <span className="price-title">Harga Paket</span>
                  <span className="package-price text-end">{`Rp ${Helper.formatMoney(
                    isGuaranteed ? 0 : totalPrice
                  )}`}</span>
                </Col>
              </Row>
              <div className="d-flex justify-content-end mt-2" style={{ position: 'absolute', bottom: 20, right: 16 }}>
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => {
                    setTitleHeader(title);
                    setDisableQtyBtn(false);
                    setActiveStep(0);
                    setIsGuaranteed(false);
                  }}
                  outline
                >
                  Kembali
                </Button>
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => {}}
                  disabled={(isDisableSaveButton && +mainQty > +sellableQty) || !selectedProductItems?.is_sellable}
                  className="ml-2"
                  onClickCapture={() => handleSubmit()}
                >
                  Simpan
                </Button>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
      <CustomModal
        modal={openWarning}
        modalHeader="Terdapat perbedaan harga"
        // eslint-disable-next-line react/no-children-prop
        children={'Terdapat perbedaan harga dengan harga estimasi awal. Silakan periksa kembali atau batalkan order.'}
        modalWidth={600}
        backdrop="static"
        toggle={() => {
          setOpenWarning(false);
          setOpen(false);
        }}
        fullScreen="lg"
        size="lg"
      />
    </>
  );
};

export default ChooseItemModal;
