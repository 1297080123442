import React, { useEffect, useState } from 'react';
import { Row, Col, Badge, Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import moment from 'moment';
import API from '@utils/API';
import useAlert from '@hooks/useAlert';
import Helper from '@helpers/Helper';
import Divider from '@components/divider/Divider';
import DetailField from '@components/field/DetailField';
import StatusBadge from '@components/badge/StatusBadge';
import SettlementBadge from '@components/badge/SettlementBadge';
import ConfirmModal from '@components/modal/ConfirmModal';
import AlertTriangleIcon from '@assets/icons/alert-triangle.svg';
import SelectAsyncField from '@components/field/SelectAsyncField';
import TranmissionOption from '@components/field/TransmissionOptions';
import LicensePlateField from '@components/field/LicensePlateField';

const DetailCustomerCard = (props) => {
  const { data, loading } = props;
  const { setAlert } = useAlert();
  const api = new API('v2');

  const [editCustomer, setEditCustomer] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [selectedCar, setSelectedCar] = useState({});
  const [transmission, setTransmission] = useState('');
  const [mileage, setMileage] = useState('');
  const [licensePlate, setLicensePlate] = useState('');
  const [year, setYear] = useState('');
  const [loadCar, setLoadCar] = useState(false);
  const [loadSubmit, setLoadSubmit] = useState(false);

  const handleChangeNewCar = (e) => {
    setSelectedCar(e);
  };

  const checkValidation = () => {
    if (!data?.flag?.is_product_wash_only) {
      if (mileage === '') {
        setAlert('Kilometer wajib diisi !', 'failed');
        return false;
      }
    }
    return true;
  };

  const submiEditCustomer = async () => {
    setLoadSubmit(true);
    const reqBody = {
      booking_code: data?.booking?.booking_code,
      user_car_id: data?.user_car?.id ?? null,
      license_plate: licensePlate ?? null,
      transmission: transmission ?? null,
      year: year ?? null,
      mileage: mileage ?? null,
      car_detail_id: selectedCar?.id ?? null,
    };
    try {
      const res = await api.put(`v2/gms/customer/car`, reqBody);
      if (+res?.status === 200) {
        setAlert('Berhasil mengubah data customer', 'success');
        setModalConfirm(false);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
      setLoadSubmit(false);
    } catch (err) {
      setAlert(`${err}`, 'failed');
      setModalConfirm(false);
      console.error(err);
      setLoadSubmit(false);
    }
  };

  const setDefaultValue = (data) => {
    setYear(data?.year);
    setMileage(data?.mileage);
    setTransmission(data?.transmission);
    setLicensePlate(data?.license_plate);
    setSelectedCar(data?.car_details);
  };

  useEffect(() => {
    if (data?.user_car) {
      setDefaultValue(data?.user_car);
    }
  }, [data?.user_car]);

  return (
    <>
      <Row>
        <Col lg={4} md={4} sm={6} xs={6}>
          <DetailField
            title="Status Order"
            useSkeleton={loading}
            value={data?.booking?.booking_status ? <StatusBadge status={data?.booking?.booking_status} /> : '-'}
          />
        </Col>
        <Col lg={4} md={4} sm={6} xs={6}>
          <DetailField
            title="Status Pembayaran"
            useSkeleton={loading}
            value={
              data?.payment_details?.status ? (
                <SettlementBadge
                  status={data?.payment_details?.status}
                  statusName={data?.payment_details?.status_name}
                />
              ) : (
                '-'
              )
            }
          />
        </Col>
        <Col lg={4} md={4} sm={6} xs={6}>
          <DetailField
            title="Kode Booking"
            value={<span className="font-weight-bold">{data?.booking?.booking_code}</span>}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={4} sm={6} xs={6}>
          <DetailField
            title="Booking Source"
            useSkeleton={loading}
            value={
              data?.booking?.booking_source?.name ? (
                <span className="font-weight-bold">{data?.booking?.booking_source?.name}</span>
              ) : (
                '-'
              )
            }
          />
        </Col>
        <Col lg={4} md={4} sm={6} xs={6}>
          <DetailField
            title="Tipe Order"
            useSkeleton={loading}
            value={
              data?.order_type?.name ? (
                <span className="font-weight-bold text-uppercase">{data?.order_type?.name}</span>
              ) : (
                '-'
              )
            }
          />
        </Col>
        <Col lg={4} md={4} sm={6} xs={6}>
          <DetailField
            title="Tanggal dan Waktu"
            useSkeleton={loading}
            value={
              data?.booking?.booking_datetime ? (
                <span className="font-weight-bold">
                  {moment(data?.booking?.booking_datetime).format('DD MMM YYYY, HH:mm')}
                </span>
              ) : (
                '-'
              )
            }
          />
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
          <DetailField
            title="Booking Note"
            useSkeleton={loading}
            value={
              data?.booking?.booking_note ? (
                <span className="font-weight-bold">
                  {!!data?.booking?.booking_note ? data?.booking?.booking_note : '-'}
                </span>
              ) : (
                '-'
              )
            }
          />
        </Col>
      </Row>
      <Divider />
      <Row className="mb-4">
        <Col md={12}>
          <span className="font-weight-bold" style={{ fontSize: 16 }}>
            Informasi Pelanggan
          </span>
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={4} sm={6} xs={6}>
          <DetailField
            title="Nama Pelanggan"
            useSkeleton={loading}
            value={data?.customer?.name ? <span className="font-weight-bold">{data?.customer?.name}</span> : '-'}
          />
        </Col>
        <Col lg={4} md={4} sm={6} xs={6}>
          <DetailField
            title="Tipe Pelanggan"
            useSkeleton={loading}
            value={
              data?.customer?.customer_type ? (
                <span className="font-weight-bold text-uppercase">{data?.customer?.customer_type}</span>
              ) : (
                '-'
              )
            }
          />
        </Col>
        <Col lg={4} md={4} sm={6} xs={6}>
          <DetailField
            title="Kendaraan"
            useSkeleton={loading}
            value={
              !editCustomer ? (
                data?.user_car?.car_details?.car_model?.model_name ? (
                  <span className="font-weight-bold">
                    {data?.user_car?.car_details?.car_model?.model_name + ' ' + data?.user_car?.car_details?.variant}
                  </span>
                ) : (
                  '-'
                )
              ) : (
                <>
                  <SelectAsyncField
                    cacheOptions
                    colClassName={'p-0'}
                    defaultOptions={[]}
                    value={selectedCar}
                    getOptionLabel={(e) => `${e?.model ?? e?.car_model?.model_name} - ${e?.variant ?? ''}`}
                    getOptionValue={(e) => e.id}
                    loadOptions={Helper.fetchCars}
                    onChange={handleChangeNewCar}
                    menuPlacement="top"
                    placeholder="Pilih merek kendaraan"
                    disabled={loadCar}
                  />
                </>
              )
            }
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={4} sm={6} xs={6}>
          <DetailField
            title="Nomor Polisi"
            useSkeleton={loading}
            value={
              !editCustomer ? (
                data?.user_car?.license_plate ? (
                  <span className="font-weight-bold text-uppercase">{data?.user_car?.license_plate}</span>
                ) : (
                  '-'
                )
              ) : (
                <LicensePlateField
                  data={data?.user_car?.license_plate}
                  onChange={(e) => setLicensePlate(e)}
                  loading={loading}
                />
              )
            }
          />
        </Col>
        <Col lg={4} md={4} sm={6} xs={6}>
          <DetailField
            title="Transmisi"
            useSkeleton={loading}
            value={
              !editCustomer ? (
                data?.user_car?.transmission ? (
                  <span className="font-weight-bold">
                    {data?.user_car?.transmission === 'at' ? 'Automatic' : 'Manual'}
                  </span>
                ) : (
                  '-'
                )
              ) : (
                <Row>
                  <TranmissionOption
                    onClick={(e) => {
                      setTransmission(e);
                    }}
                    isDisabled={loading}
                    value={transmission}
                  />
                </Row>
              )
            }
          />
        </Col>
        <Col lg={4} md={4} sm={6} xs={6}>
          <DetailField
            title="Tahun"
            useSkeleton={loading}
            value={
              !editCustomer ? (
                data?.user_car?.year ? (
                  <span className="font-weight-bold">{data?.user_car?.year}</span>
                ) : (
                  '-'
                )
              ) : (
                <>
                  {' '}
                  <Input
                    placeholder="2023"
                    maxLength={4}
                    disabled={loading}
                    value={year}
                    onChange={(e) => {
                      setYear(Helper.inputNumber(e.target.value));
                    }}
                  />
                </>
              )
            }
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={4} sm={6} xs={6}>
          <DetailField
            title="Kilometer Kendaraan"
            isRequired={!data?.flag?.is_product_wash_only}
            useSkeleton={loading}
            value={
              !editCustomer ? (
                data?.user_car?.mileage ? (
                  <span className="font-weight-bold">{data?.user_car?.mileage} Km</span>
                ) : (
                  '-'
                )
              ) : (
                <>
                  <InputGroup>
                    <Input
                      placeholder="0"
                      value={mileage}
                      onChange={(e) => {
                        setMileage(Helper.inputNumber(e.target.value));
                      }}
                      maxLength={6}
                    />
                    <InputGroupAddon addonType="append">km</InputGroupAddon>
                  </InputGroup>
                </>
              )
            }
          />
        </Col>
        <Col lg={4} md={4} sm={6} xs={6}>
          <DetailField
            title="Membership"
            badgeTitle={
              moment(data?.membership?.expired_at) < moment(new Date().now) && (
                <Badge className="p-1 ml-1 badge-danger">Expired</Badge>
              )
            }
            useSkeleton={loading}
            value={
              data?.membership?.name ? (
                <span className="font-weight-bold">
                  {data?.membership?.name} (
                  {data?.membership?.expired_at ? moment(data?.membership?.expired_at).format('DD/MM/YYYY') : ''})
                </span>
              ) : (
                '-'
              )
            }
          />
        </Col>
        <Col lg={4} md={4} sm={6} xs={6} />
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          {data?.allowEdit && (
            <>
              {data?.booking?.booking_status?.slug === 'on-process' &&
                (!editCustomer ? (
                  <Button color="primary" className="float-right" size="sm" onClick={() => setEditCustomer(true)}>
                    Edit Data
                  </Button>
                ) : (
                  <>
                    <Button
                      color="primary"
                      className="float-right ml-2"
                      size="sm"
                      onClick={() => {
                        if (checkValidation()) {
                          setModalConfirm(!modalConfirm);
                        }
                      }}
                    >
                      Simpan
                    </Button>
                    <Button
                      color="primary"
                      outline
                      className="float-right"
                      size="sm"
                      onClick={() => setEditCustomer(false)}
                    >
                      Batalkan
                    </Button>
                  </>
                ))}
            </>
          )}
        </Col>
      </Row>
      <ConfirmModal
        modal={modalConfirm}
        icon={
          <div className="icon-bg-danger">
            <img src={AlertTriangleIcon} alt="alert circle" />
          </div>
        }
        title="Konfirmasi Perubahan Data?"
        description="Pastikan produk/paket sudah sesuai dan kompatibel dengan data mobil yang baru"
        textSubmit="Konfirmasi"
        textCancel="Kembali"
        onSubmit={() => submiEditCustomer()}
        onCancel={() => setModalConfirm(!modalConfirm)}
        loading={loadSubmit}
      />
    </>
  );
};

export default DetailCustomerCard;
