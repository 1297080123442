import React, { useEffect, useState } from 'react';
import { Button, Row, Col, FormGroup, Input, Label, FormFeedback, FormText, Badge, Card, CardBody } from 'reactstrap';
import Spinner from '../otoklix-elements/components/spinner/Spinner';
import Helper from '@helpers/Helper';
import DefaultImage from '@assets/images/default-image.jpeg';
import EditIcon from '@assets/icons/edit-blue.svg';
import DangerIcon from '@assets/icons/danger.svg';
import TrashIcon from '@assets/icons/trash.svg';
import Divider from '@components/divider/Divider';
import CustomModal from '@components/modal/CustomModal';

const DetailProductItemsCard = (props) => {
  const { item, isEdit, isDeleted, onDeletedNewItem, onDeletedItem, updateInventory, customerType } = props;

  const [modalDelete, setModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');
  const [reasonError, setReasonError] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);

  const calculatedPricePackage = () => {
    if (item?.item_details?.length > 0) {
      const totalValue = item?.item_details.reduce((accumulator, currentItem) => {
        const price = currentItem?.price ? +currentItem?.price * +currentItem?.qty : 0;
        return accumulator + price;
      }, 0);
      setTotalPrice(totalValue);
    }
  };

  const handleModalRemoveItem = () => {
    if (item?.isNew) {
      onDeletedNewItem(item);
    } else {
      setModalDelete(true);
    }
  };

  const handleChangeReason = (value) => {
    setReason(value);
    ValidateReason(value);
  };

  const ValidateReason = (value) => {
    let isValid = true;

    if (value?.length === 0) {
      setReasonError('Alasan wajib diisi');
      isValid = false;
    } else if (value?.length > 450) {
      setReasonError('Alasan maksimal 450 karakter');
      isValid = false;
    } else if (value?.length > 0 && value?.length <= 450) {
      setReasonError('');
      isValid = true;
    }

    return isValid;
  };

  const cancelRemoveItem = () => {
    setModalDelete(false);
    setReason('');
    setReasonError('');
  };

  const removeItem = () => {
    const isValid = ValidateReason(reason);
    if (isValid) {
      onDeletedItem({ ...item, deleted_reason: reason, is_deleted: true });
      setModalDelete(false);
      setReason('');
    }
  };

  useEffect(() => {
    calculatedPricePackage();
  }, [item?.item_details]);

  return (
    <>
      <div className={`detail-card-product ${item?.is_deleted && 'd-none'}`}>
        <div className="d-flex mb-2">
          <div className="img-wrapper">
            <img
              className="img-display"
              src={item?.image_link ? item?.image_link : DefaultImage}
              alt=""
              width={80}
              height={80}
              loading="lazy"
            />
          </div>
          <div className="content-wrapper">
            <div className="">
              <div className="d-flex justify-content-between">
                <span className="fw-bold d-flex justify-content-between align-items-center">
                  {Helper.shortenString(item?.name, 100) ?? 'N/A'}
                  {item?.package_id === null && item?.product_id === null && (
                    <Badge
                      className="ml-2 px-3 py-1 d-flex justify-content-between align-items-center badge-warning"
                      style={{
                        fontSize: '12px !important',
                        backgroundColor: '#FAF6CF',
                        color: '#946612',
                        fontWeight: 500,
                        width: 'fit-content',
                      }}
                    >
                      <img src={DangerIcon} alt="Danger" className="mr-1" /> Perlu Diupdate
                    </Badge>
                  )}
                </span>

                {item?.is_consignment && (
                  <span className="badge-status">
                    <Badge className="p-2 badge-secondary">
                      {item?.is_guaranteed ? 'Consignment - Garansi' : 'Consignment - Non Garansi'}
                    </Badge>
                  </span>
                )}
              </div>
            </div>
            {item?.remark && (
              <div className="item-wrapper">
                <div className="remarks-item">
                  Keterangan : <br /> {item?.remark?.replace(/-/g, '‑') ?? ''}
                </div>
              </div>
            )}
            <div className="item-wrapper">
              {item?.item_details?.length > 0 &&
                item?.item_details.map((itemChild, index) => (
                  <div key={index} className="d-flex justify-content-between">
                    <span className="">
                      {item?.package_id ? Math.trunc(itemChild?.qty) : itemChild?.qty} x{' '}
                      {Helper.shortenString(itemChild?.name, 100) ?? 'N/A'}
                    </span>
                  </div>
                ))}
            </div>
            <Divider />
            <div className="d-flex justify-content-between">
              <span className="fw-semi-bold">{`${item?.qty} x Rp${Helper.formatMoney(
                item?.item_details?.length > 0
                  ? item?.is_guaranteed && isEdit
                    ? 0
                    : item?.item_details?.length > 0 && item?.isNew
                    ? totalPrice
                    : item?.price
                  : item?.is_guaranteed && isEdit
                  ? 0
                  : item?.unit_price
              )}`}</span>
              {+item?.voucher_amount > 0 ? (
                <div className="d-grid">
                  <span className="text-decoration-line-through text-red font-12 fw-bold text-end">
                    Rp{Helper.formatMoney(item?.is_guaranteed && isEdit ? 0 : +item?.price + +item?.voucher_amount)}
                  </span>
                  <span className="text-red font-12 text-bold-400">
                    - {`Rp${Helper.formatMoney(+item?.voucher_amount)}`}
                  </span>{' '}
                  <span className="fw-bold" style={{ color: '#002EB4' }}>
                    Rp{Helper.formatMoney(+item?.price)}
                  </span>
                </div>
              ) : (
                <span className="fw-bold" style={{ color: '#002EB4' }}>
                  {item?.item_details?.length > 0 && item?.isNew
                    ? `Rp ${Helper.formatMoney(totalPrice)}`
                    : `Rp ${Helper.formatMoney(item?.is_guaranteed && isEdit ? 0 : item?.price)}`}
                </span>
              )}
            </div>
          </div>
        </div>
        {isEdit && (
          <div className="d-flex justify-content-end">
            {isDeleted && customerType.toLowerCase() !== 'b2b' && (
              <Button
                color="primary"
                size="sm"
                style={{ marginLeft: 6 }}
                className="btn-outline"
                outline
                onClick={() => handleModalRemoveItem()}
              >
                <img src={TrashIcon} alt="Edit" className="mr-1" />
                Hapus Item
              </Button>
            )}
            {item?.package_id === null && item?.product_id === null && (
              <Button color="primary" size="sm" style={{ marginLeft: 6 }} outline onClick={() => updateInventory(item)}>
                <img src={EditIcon} alt="Edit" className="mr-1" /> Update ke data inventory
              </Button>
            )}
          </div>
        )}
        {+item?.voucher_amount > 0 && (
          <>
            <div className="mt-4" />
            <div className="promo-wrapper">
              <span className="">Kode Promo : {item?.voucher_code} </span>
            </div>
          </>
        )}
      </div>
      <CustomModal
        modal={modalDelete}
        modalHeader="Konfirmasi Hapus Item"
        modalWidth={600}
        backdrop="static"
        toggle={() => setModalDelete(!modalDelete)}
        fullScreen="lg"
        size="lg"
      >
        <Row className="mb-4">
          <Col>
            <span>Lengkapi terlebih dahulu alasan hapus item berikut untuk melanjutkan.</span>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <FormGroup className="position-relative">
              <Label className="fw-bold label-required">Alasan hapus item</Label>
              <Input
                placeholder="Isi alasan singkat hapus item"
                type="textarea"
                value={reason}
                disabled={loading}
                onChange={(e) => handleChangeReason(e.target.value)}
                invalid={reasonError}
              />
              {reasonError && <FormFeedback>{reasonError ?? ''}</FormFeedback>}
              <FormText
                className="float-right"
                style={{
                  color: +reason?.length > 450 ? 'red' : 'unset',
                  position: 'absolute',
                  right: 0,
                  bottom: reasonError?.length === 0 ? 'unset' : 0,
                }}
              >
                {reason?.length}/450
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg={6} md={6} sm={12} />
          <Col lg={3} md={3} sm={12} className="text-center">
            <Button
              style={{ width: '100%' }}
              color="primary"
              outline
              onClick={() => cancelRemoveItem()}
              disabled={loading}
            >
              Kembali
            </Button>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <Button style={{ width: '100%' }} color="primary" onClick={() => removeItem()} disabled={loading}>
              {loading ? <Spinner size={'sm'} /> : 'Lanjutkan'}
            </Button>
          </Col>
        </Row>
      </CustomModal>
    </>
  );
};

export default DetailProductItemsCard;
