import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Row, Col } from '../otoklix-elements';
import CloseIcon from '@assets/icons/close.svg';

const ChooseItemTypeModal = (props) => {
  const { isOpen, toggle, onSubmit, activeOptions } = props;

  const [selectedType, setSelectedType] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = () => {
    if (!selectedType) return setError('Pilih dulu jenis pesanannya!');
    if (typeof onSubmit == 'function' && selectedType) onSubmit(selectedType), setError('');
  };

  const handleExit = () => {
    setSelectedType('');
    setError('');
  };

  const handleOpen = () => {
    if (activeOptions) setSelectedType(activeOptions);
  };

  return (
    <Modal isOpen={isOpen} modalMobile={false} centered size="md" onClosed={handleExit} onOpened={handleOpen}>
      <ModalHeader
        close={<img src={CloseIcon} className="cursor-pointer" alt="" width={16} height={16} onClick={toggle} />}
      >
        Pilih Jenis Pesanan Custom
      </ModalHeader>
      <ModalBody>
        <span>Pilih jenis pesanan custom yang ingin kamu tambahkan</span>
        <Row className="mt-2 mb-4">
          <Col lg={6} md={6} sm={6} xs={6}>
            <Button
              className="btn-select-type"
              color={selectedType == 'service' ? 'primary' : 'light'}
              block
              outline
              onClick={() => setSelectedType('service')}
            >
              Pesanan Service
            </Button>
          </Col>
          <Col lg={6} md={6} sm={6} xs={6}>
            <Button
              className="btn-select-type"
              color={selectedType == 'product' ? 'primary' : 'light'}
              block
              outline
              onClick={() => setSelectedType('product')}
            >
              Pesanan Part
            </Button>
          </Col>
        </Row>
        <Row>
          <span className="text-danger">{error}</span>
        </Row>

        <div className="d-flex justify-content-end">
          <Button color="primary" outline onClick={toggle} className="me-2">
            Kembali
          </Button>
          <Button color="primary" onClick={handleSubmit}>
            Lanjutkan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ChooseItemTypeModal;
