import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import API from '@utils/API';
import useResponsive from '@utils/useResponsive';
import { Button } from '../otoklix-elements';
import SelectAsyncField from '@components/field/SelectAsyncField';
import FormTitle from '@components/form/TitleForm';
import Helper from '@helpers/Helper';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveCar, setActiveCar } from '@reducers/Car';
import {
  setActiveItems,
  setActiveTab,
  setCart,
  setDataCustomer,
  getDataCustomer,
  setActiveCustomItems,
  getIsWashOrderOnly,
} from '@reducers/Order';
import useAlert from '@hooks/useAlert';
import TranmissionOption from '@components/field/TransmissionOptions';
import ConfirmModal from '@components/modal/ConfirmModal';
import CustomModal from '@components/modal/CustomModal';
import AlertTriangleIcon from '@assets/icons/alert-triangle.svg';
import { isEmpty, isNull } from 'lodash';

const OrderCustomerForms = (props) => {
  const { changeStep, draftData, isDraftPage, setDisabledSubmit } = props;
  const { isDesktop } = useResponsive();
  const { setAlert } = useAlert();
  const dispatch = useDispatch();
  const isWashOrderOnly = useSelector(getIsWashOrderOnly);
  const userCar = useSelector(getActiveCar);
  const customerData = useSelector(getDataCustomer);

  const api = new API('v2');

  const [optionUserCars, setOptionUserCars] = useState([]);
  const [optionChooseUser, setOptionChooseUser] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);
  const [selectedNewCar, setSelectedNewCar] = useState(userCar);
  const [tempSelectedCar, setTempSelectedCar] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedLicensePlate, setSelectedLicensePlate] = useState('');
  const [carError, setCarError] = useState('');
  const [carNewError, setCarNewError] = useState('');
  const [chooseUserError, setChooseUserError] = useState('');
  const [isRegisteredUser, setIsRegisteredUser] = useState(false);
  const [disabledInputName, setDisabledInputName] = useState(false);
  const [disabledInputPhone, setDisabledInputPhone] = useState(false);
  const [loadCar, setLoadCar] = useState(false);
  const [loadCheckUser, setLoadCheckUser] = useState(false);
  const [loadSaveNewCar, setLoadSaveNewCar] = useState(false);
  const [loadUpdateCar, setLoadUpdateCar] = useState(false);
  const [licensePlateStart, setLicensePlateStart] = useState(
    Helper.AnotherlicensePlateSplit(userCar?.license_plate)?.[0]
  );
  const [licensePlateMiddle, setLicensePlateMiddle] = useState(
    Helper.AnotherlicensePlateSplit(userCar?.license_plate)?.[1]
  );
  const [licensePlateEnd, setLicensePlateEnd] = useState(Helper.AnotherlicensePlateSplit(userCar?.license_plate)?.[2]);
  const [mileage, setMileage] = useState(0);
  const [transmission, setTransmission] = useState('at');
  const [year, setYear] = useState('');
  const [showCarDetail, setShowCarDetail] = useState('');
  const [showModalChooseUser, setShowModalChooseUser] = useState(false);
  const [showModalChangeCar, setShowModalChangeCar] = useState(false);
  const [disabledUseData, setDisabledUseData] = useState(true);
  const [loadUpdatePhone, setLoadUpdatePhone] = useState(false);
  const [disableEditField, setDisableEditField] = useState(true);

  const handleChangeCar = (value) => {
    const tempData = {
      ...value,
      brand: value?.car_details?.car_model?.brand?.name,
      model_id: value?.car_details?.car_model?.id,
      model: value?.car_details?.car_model?.model_name,
      variant: value?.variant,
      oil_qty_rounded: value?.oil_qty_rounded,
    };
    setTempSelectedCar(tempData);
    if (+value?.id === 0) {
      dispatch(setActiveCar({ ...value, customer_new_car: true }));
    } else if (+userCar?.car_details?.id !== +value?.car_details?.id) {
      setShowModalChangeCar(true);
    } else {
      dispatch(setActiveCar({ ...value, customer_new_car: false }));
    }
    if (carError && value) setCarError('');
  };

  const handleChangeNewCar = (value) => {
    const tempValue = {
      ...value,
      car_details: {
        ...value,
      },
    };
    setTempSelectedCar(tempValue);
    if (userCar?.id !== value?.car_details?.id) {
      setShowModalChangeCar(true);
      dispatch(
        setDataCustomer({
          ...customerData,
          customer_car: {
            car_details: value,
            ...userCar,
          },
        })
      );
    }
    if (carNewError && value) setCarNewError('');
  };

  const handleChangeUserPhone = (e) => {
    let valuePhone;
    if (/^[0+]/.test(e)) {
      valuePhone = e;
    }
    const tempUser = { ...customerData, phone_number: valuePhone };
    dispatch(setDataCustomer(tempUser));
  };

  const handleChangeUserName = (e) => {
    const tempUser = { ...customerData, name: e };
    dispatch(setDataCustomer(tempUser));
  };

  const handleChangeChooseUser = (value) => {
    setSelectedUser(value);
    setDisabledUseData(false);
    if (chooseUserError && value) setChooseUserError('');
  };

  const handleError = (err, useNotif = true) => {
    const message = err?.response?.data?.error?.message ?? err;
    if (useNotif) setAlert(`${JSON.stringify(message)}`, 'failed');
    console.error(err);
  };

  const checkUncompletedCarData = (data) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        if (
          value === '' ||
          (typeof value === 'string' && value.trim() === '') ||
          value === null ||
          value === undefined
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const submitChangeCar = () => {
    setShowModalChangeCar(false);
    const uncompletedDataCar = checkUncompletedCarData(tempSelectedCar);
    dispatch(
      setActiveCar({
        ...tempSelectedCar,
        customer_new_car: false,
        uncompleted_data_car: uncompletedDataCar,
      })
    );
    dispatch(setActiveItems({}));
    dispatch(setCart([]));
    dispatch(setActiveTab('items'));
    dispatch(setActiveCustomItems([]));
    changeStep(0);
  };

  const fetchUser = async (useNotif) => {
    try {
      const res = await api.get(`v2/gms/customer/${customerData?.phone_number}`);
      const data = res?.data?.data?.customer;
      if (data) {
        const uncompletedName = data?.name ? false : true;
        dispatch(
          setDataCustomer({ ...data, name: data?.name, is_registered: true, uncompleted_name: uncompletedName })
        );
        setIsRegisteredUser(true);
        if (selectedLicensePlate) {
          fetchUserCars(data?.id, selectedLicensePlate);
        } else {
          fetchUserCars(data?.id, '');
        }
      } else {
        setIsRegisteredUser(false);
        setDisabledInputName(false);
        if (useNotif) setAlert(`User tidak ditemukan`, 'failed');
      }
      setLoadCheckUser(false);
    } catch (err) {
      setLoadCheckUser(false);
      setIsRegisteredUser(false);
      handleError(err, useNotif);
    }
  };

  const handleSetDataCustomer = (customerId, userCar) => {
    let tempUserData = customerData;
    if (customerId) {
      tempUserData = { ...customerData, id: customerId, is_registered: true };
    }
    const tempDataCustomer = {
      ...tempUserData,
      customer_car: {
        id: userCar?.id,
        license_plate: userCar?.license_plate,
        year: userCar?.year,
        transmission: userCar?.transmission,
        mileage: mileage,
      },
    };
    dispatch(setDataCustomer(tempDataCustomer));
  };

  const mappingOptionsCar = (listCar) => {
    let tempRes = [];
    const res = listCar.map((item) => {
      return {
        ...item,
        id: item?.id,
        brand: item?.car_details?.car_model?.brand?.name,
        model_id: item?.car_details?.car_model?.id,
        model: item?.car_details?.car_model?.model_name,
        variant: item?.car_details?.variant,
        oil_qty_rounded: item?.car_details?.oil_qty_rounded,
      };
    });
    tempRes = [...res];
    return tempRes;
  };

  const fetchUserCars = async (userId, licensePlate) => {
    try {
      let checkCar;
      const res = await api.get(`v2/gms/customer/${userId}/car?limit=100`);
      const listUserCar = res?.data?.data;
      const optionsNewCar = {
        id: 0,
        variant: '+ Tambah Baru',
      };
      const mappingDataUserCar = mappingOptionsCar(listUserCar);
      const tempData = [...mappingDataUserCar, optionsNewCar];
      setOptionUserCars(tempData);
      if (licensePlate) {
        checkCar = mappingDataUserCar.filter((item) => item?.license_plate === licensePlate);
      } else {
        checkCar = mappingDataUserCar.filter((item) => +item?.car_details?.id === +userCar?.car_details?.id);
      }
      if (checkCar?.length > 0 && customerData?.id !== null) {
        const uncompletedDataCar = checkUncompletedCarData(checkCar[0]);
        if (!uncompletedDataCar) setDisableEditField(true);
        dispatch(
          setActiveCar({
            ...checkCar[0],
            uncompleted_data_car: uncompletedDataCar,
            customer_new_car: false,
          })
        );
        // handleSetDataCustomer(customerData?.id ?? userId, checkCar[0]);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleClickFindUser = () => {
    setLoadCheckUser(true);
    fetchUser(true);
  };

  const handleUpdatePhoneNumber = async () => {
    setLoadUpdatePhone(true);
    try {
      const payload = {
        phone_number: customerData?.phone_number,
      };
      const res = await api.put(`v2/gms/customer/${customerData?.id}/phone-number`, payload);
      if (+res?.status === 200) {
        dispatch(setDataCustomer({ ...customerData, uncompleted_phone: false, uncompleted_phone: false }));
        setAlert(`Berhasil update nomor HP`, 'success');
        setLoadUpdatePhone(false);
      }
    } catch (err) {
      handleError(err);
      setLoadUpdatePhone(false);
    }
  };

  const postNewCar = async (isNewUser) => {
    try {
      const params = {
        customer_id: customerData?.id || null,
        phone_number: customerData?.phone_number || null,
        name: customerData?.name,
        car_detail_id: selectedNewCar?.car_details?.id,
        license_plate: !isEmpty(draftData)
          ? `${licensePlateStart}${licensePlateMiddle}${licensePlateEnd}`
          : `${licensePlateStart} ${licensePlateMiddle} ${licensePlateEnd}`,
        year: +userCar?.year > 0 ? +userCar?.year : null,
        transmission: transmission,
        mileage: +mileage ?? null,
        is_wash_item_only: isWashOrderOnly,
      };
      const res = await api.post(`v2/gms/customer/car`, params);
      if (+res?.status === 201) {
        let tempUserData = { ...customerData };
        let tempCarData;
        setAlert(`Berhasil menambahkan mobil baru`, 'success');
        const data = res?.data?.data;
        if (data) {
          tempUserData = {
            ...customerData,
            id: data?.customer_id,
            is_registered: true,
            customer_car: { ...data, id: data?.user_car_id },
          };
          tempCarData = {
            ...data,
            ...selectedNewCar,
            customer_new_car: false,
            id: data?.user_car_id,
          };
          dispatch(setDataCustomer(tempUserData));
          dispatch(setActiveCar(tempCarData));
          setIsRegisteredUser(isNewUser);
          fetchUserCars(data?.customer_id, params?.license_plate);
          setTimeout(() => {
            setLoadSaveNewCar(false);
          }, 2000);
        }
      }
    } catch (err) {
      setLoadSaveNewCar(false);
      handleError(err);
    }
  };

  const postUpdateCar = async () => {
    try {
      const payload = getPayloadUpdateCar();
      const res = await api.put(`v2/gms/customer/car/update`, payload);
      if (+res?.status === 200) {
        setAlert(`Berhasil update data mobil`, 'success');
        const data = res?.data?.data;
        if (data) {
          setSelectedCar((prevData) => ({
            ...prevData,
            license_plate: data?.license_plate ?? '',
            year: data?.year ?? null,
            transmission: data?.transmission ?? '',
            uncompleted_data_car: false,
          }));
          fetchUserCars(data?.customer_id, data?.license_plate);
          setTimeout(() => {
            setLoadUpdateCar(false);
          }, 2000);
        }
      }
    } catch (err) {
      setLoadUpdateCar(false);
      handleError(err);
    }
  };

  const checkLicensePlate = async () => {
    try {
      const params = {
        car_model_id: selectedNewCar?.model_id ?? userCar?.model_id ?? userCar?.id,
        license_plate: isDraftPage
          ? `${licensePlateStart}${licensePlateMiddle}${licensePlateEnd}`
          : `${licensePlateStart} ${licensePlateMiddle} ${licensePlateEnd}`,
      };
      const res = await api.post(`v2/gms/customer/car/availability`, params);
      const data = res?.data?.data;
      if (+data?.length > 0) {
        const mappingDataCar = mappingOptionsCar(data);
        setShowModalChooseUser(true);
        setOptionChooseUser(mappingDataCar);
      } else if (+data?.length === 0) {
        postNewCar(true);
        dispatch(setDataCustomer({ ...customerData, is_registered: true }));
      }
      setDisabledSubmit(false);
    } catch (err) {
      setLoadSaveNewCar(false);
      handleError(err);
      console.error(err);
    }
  };

  const checkValidationSaveNewCar = () => {
    if (!licensePlateStart && !licensePlateMiddle) {
      setAlert('Silahkan masukan Nomor Polisi', 'failed');
      return false;
    }
    if (userCar?.year === '') {
      setAlert('Silahkan masukan Tahun mobil', 'failed');
      return false;
    }
    if (customerData?.name === undefined || customerData?.name === '') {
      setAlert('Silahkan masukan Nama Pelanggan', 'failed');
      return false;
    }
    return true;
  };

  const checkValidationUpdateCar = () => {
    const payload = getPayloadUpdateCar();

    if (!licensePlateStart && !licensePlateMiddle) {
      setAlert('Silahkan masukan Nomor Polisi', 'failed');
      return false;
    }
    if (!payload?.year) {
      setAlert('Silahkan masukan Tahun mobil', 'failed');
      return false;
    }
    if (customerData?.name === undefined || customerData?.name === '') {
      setAlert('Silahkan masukan Nama Pelanggan', 'failed');
      return false;
    }

    return true;
  };

  const getPayloadUpdateCar = () => {
    const startLicensePlate = disabledLicensePlate
      ? userCar?.license_plate
        ? Helper.AnotherlicensePlateSplit(userCar?.license_plate)?.[0]
        : ''
      : !licensePlateStart
      ? ''
      : licensePlateStart + ' ';
    const middleLicensePlate = disabledLicensePlate
      ? userCar?.license_plate
        ? Helper.AnotherlicensePlateSplit(userCar?.license_plate)?.[1]
        : ''
      : !licensePlateMiddle
      ? ''
      : licensePlateMiddle + ' ';
    const endLicensePlate = disabledLicensePlate
      ? userCar?.license_plate
        ? Helper.AnotherlicensePlateSplit(userCar?.license_plate)?.[2]
        : ''
      : licensePlateEnd ?? '';
    const carYear = disabledYear ? userCar?.year ?? '' : year ?? '';
    const carTransmission = disabledTransmission
      ? !userCar?.transmission
        ? 'at'
        : userCar?.transmission
      : !transmission
      ? 'at'
      : transmission;

    let license_plate = startLicensePlate + middleLicensePlate + endLicensePlate;
    license_plate = license_plate ? license_plate.replace(/ {2,}/g, ' ') : license_plate;

    const payload = {
      customer_id: customerData?.id || null,
      user_car_id: userCar?.id ?? null,
      license_plate: license_plate,
      year: carYear,
      transmission: carTransmission,
    };

    return payload;
  };

  const handleSaveNewCar = () => {
    const check = checkValidationSaveNewCar();
    if (check) {
      setLoadSaveNewCar(true);
      // postNewCar(true);
      // dispatch(setDataCustomer({ ...customerData, is_registered: true }));
      checkLicensePlate();
    }
  };

  const handleUpdateCar = () => {
    const check = checkValidationUpdateCar();
    if (check) {
      setLoadUpdateCar(true);
      postUpdateCar();
    }
  };

  const handleChooseNewData = () => {
    setLoadSaveNewCar(false);
    setShowModalChooseUser(false);
    setIsRegisteredUser(false);
    postNewCar(true);
  };

  const handleUseData = () => {
    const tempDataCustomer = {
      ...selectedUser,
      customer_car: {
        car_details: selectedUser?.car_details,
        mileage: mileage,
        license_plate: userCar?.license_plate ?? null,
        year: userCar?.year,
        transmission: userCar?.transmission ?? transmission,
      },
      is_registered: true,
    };
    setSelectedLicensePlate(selectedUser?.license_plate);
    setLoadSaveNewCar(false);
    setShowModalChooseUser(false);
    setIsRegisteredUser(true);
    dispatch(setActiveCar(selectedUser));
    dispatch(setDataCustomer(tempDataCustomer));
  };

  const handleChangeLicensePlate = (e, arr) => {
    let tempLicensePlate;
    let tempLicensePlateStart = Helper.AnotherlicensePlateSplit(userCar?.license_plate)?.[0] ?? '';
    let tempLicensePlateMiddle = Helper.AnotherlicensePlateSplit(userCar?.license_plate)?.[1] ?? '';
    let tempLicensePlateEnd = Helper.AnotherlicensePlateSplit(userCar?.license_plate)?.[2] ?? '';
    if (+arr === 0) {
      const formatted = Helper.AbjadRegex(e, 2) ? Helper.AbjadRegex(e, 2).toUpperCase() : '';
      setLicensePlateStart(formatted);
      tempLicensePlateStart = formatted;
      tempLicensePlate = formatted + ' ' + tempLicensePlateMiddle + ' ' + tempLicensePlateEnd;
    }
    if (+arr === 1) {
      setLicensePlateMiddle(Helper.inputNumber(e, 9999));
      tempLicensePlateMiddle = Helper.inputNumber(e, 9999);
      tempLicensePlate = tempLicensePlateStart + ' ' + Helper.inputNumber(e, 9999) + ' ' + tempLicensePlateEnd;
    }
    if (+arr === 2) {
      const formatted = e?.toUpperCase();
      setLicensePlateEnd(formatted);
      tempLicensePlateEnd = formatted;
      tempLicensePlate = tempLicensePlateStart + ' ' + tempLicensePlateMiddle + ' ' + formatted;
    }
    dispatch(
      setActiveCar({
        ...userCar,
        license_plate: tempLicensePlate.replace(/\s+/g, ' '),
      })
    );
  };

  const clearCarSelected = () => {
    setSelectedCar(null);
  };

  const renderDataMobil = () => {
    if (customerData?.id !== null && userCar !== null) {
      const customUsersFilter = (search) => {
        const filtered = optionUserCars.filter(
          (item) =>
            item?.license_plate?.toLowerCase().includes(search?.toLowerCase()) ||
            item?.model?.toLowerCase().includes(search?.toLowerCase()) ||
            item?.variant?.toLowerCase().includes(search?.toLowerCase())
        );
        const tempOptions = [...filtered];
        return tempOptions;
      };
      const checkOPtions = (value) =>
        new Promise((resolve) => {
          setTimeout(() => {
            resolve(customUsersFilter(value));
          }, 1000);
        });
      return (
        <Row>
          <SelectAsyncField
            cacheOptions
            defaultOptions={optionUserCars}
            getOptionLabel={(e) => `${e?.model ? e?.model + ' - ' : ''} ${e?.variant ?? ''} ${e?.license_plate ?? ''}`}
            getOptionValue={(e) => e?.id}
            onChange={handleChangeCar}
            value={userCar}
            loadOptions={checkOPtions}
            placeholder="Pilih merek kendaraan..."
          />
        </Row>
      );
    } else {
      if (userCar === null) {
        return <Input disabled value={`+ Tambah baru`} fullWidth />;
      } else {
        <Row>
          <SelectAsyncField
            cacheOptions
            defaultOptions={null}
            value={selectedNewCar?.id === 0 ? null : selectedNewCar}
            getOptionLabel={(e) => `${e?.model} - ${e?.variant}`}
            getOptionValue={(e) => e.id}
            loadOptions={Helper.fetchCars}
            onChange={handleChangeNewCar}
            placeholder="Pilih merek kendaraan..."
          />
        </Row>;
      }
    }
  };

  const getDisableFieldFormCarDetail = (fieldForm) => {
    let isDisabledField = false;
    if (userCar?.[fieldForm] !== undefined) {
      isDisabledField = true;
      if (userCar?.id && !userCar?.[fieldForm]) {
        isDisabledField = false;
      }
    }

    return isDisabledField;
  };

  const isShowButtonUpdateCar = () => {
    const isEmptyDataCar = userCar?.uncompleted_data_car;
    const isShowButton = userCar?.id && isEmptyDataCar ? true : false;

    return isShowButton;
  };

  useEffect(() => {
    if (customerData?.id !== undefined && customerData?.id !== null) {
      if (customerData?.phone_number) {
        // fetchUser(false);
        setIsRegisteredUser(false);
        clearCarSelected();
      }
    }
  }, [customerData?.id, customerData?.phone_number]);

  useEffect(() => {
    if (draftData) {
      const customerCar = draftData?.customer_data?.customer_car;
      setMileage(customerCar?.mileage);
      setLicensePlateStart(Helper.AnotherlicensePlateSplit(customerCar?.license_plate)?.[0]);
      setLicensePlateMiddle(Helper.AnotherlicensePlateSplit(customerCar?.license_plate)?.[1]);
      setLicensePlateEnd(Helper.AnotherlicensePlateSplit(customerCar?.license_plate)?.[2]);
      setTransmission(customerCar?.transmission);
      setIsRegisteredUser(draftData?.customer_data?.is_registered);
      setDisabledInputName(draftData?.customer_data?.id ? true : false);
      setDisabledInputPhone(draftData?.customer_data?.id ? true : false);
      dispatch(setActiveCar(customerCar));
      dispatch(setDataCustomer(draftData?.customer_data));
      fetchUserCars(draftData?.customer_data?.id, customerCar?.licensePlate);
    }
  }, [draftData]);

  useEffect(() => {
    if (+userCar?.id === 0 || !isRegisteredUser) {
      setShowCarDetail(true);
    } else {
      setShowCarDetail(true);
    }
  }, [isRegisteredUser, userCar]);

  useEffect(() => {
    setLoadCheckUser(false);
    if (customerData?.id) {
      setDisabledSubmit(false);
      fetchUserCars(customerData?.id, userCar?.license_plate);
    }
  }, [customerData, customerData?.id]);

  useEffect(() => {
    if (!customerData?.uncompleted_name) {
      setDisabledInputName(true);
    } else {
      setDisabledInputName(false);
    }
    if (!customerData?.uncompleted_phone && customerData?.user_first_selected) setDisabledInputPhone(true);
  }, [customerData?.uncompleted_name, customerData?.uncompleted_phone, customerData?.user_first_selected]);

  const disabledLicensePlate = getDisableFieldFormCarDetail('license_plate');
  const disabledTransmission = getDisableFieldFormCarDetail('transmission');
  const disabledYear = getDisableFieldFormCarDetail('year');

  useEffect(() => {
    if (loadCheckUser) setDisableEditField(true);
    if (userCar?.customer_new_car || userCar?.uncompleted_data_car) {
      setDisableEditField(false);
    }
  }, [loadCheckUser, userCar?.customer_new_car, userCar?.uncompleted_data_car]);

  return (
    <div style={{ padding: isDesktop ? '16px 16px 0px 16px' : '14px 14px 0px 14px' }}>
      <span style={{ fontWeight: 600, fontSize: 20 }}>Data Pelanggan</span>
      <Card>
        <CardBody>
          <div className="mb-3 d-flex flex-column">
            <FormTitle
              title="Cari Data Pelanggan"
              subTitle="Masukkan nomor HP pelanggan untuk mencari data pelanggan"
            />
          </div>
          <Row>
            <Col lg={6} md={12} sm={12} xs={12}>
              <FormGroup>
                <Label className="fw-bold">
                  Nomor HP
                  <span className="fw-normal">(Opsional)</span>
                </Label>
                <Input
                  placeholder="Masukkan nomor hp pelanggan"
                  value={customerData?.phone_number ? customerData?.phone_number : ''}
                  disabled={loadCheckUser || disabledInputPhone}
                  onChange={(e) => handleChangeUserPhone(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={6} md={12} sm={12} xs={12}>
              <FormGroup>
                <Label className="fw-bold">Nama Pelanggan</Label>
                <Input
                  placeholder="Masukkan nama pelanggan"
                  disabled={loadCheckUser || disabledInputName}
                  onChange={(e) => handleChangeUserName(e.target.value)}
                  value={customerData?.name ?? ''}
                />
              </FormGroup>
            </Col>
          </Row>
          {customerData?.phone_number?.length > 0 && !customerData?.user_first_selected && (
            <Button
              color="primary"
              size="sm"
              onClick={() => handleClickFindUser()}
              style={{ minWidth: 110 }}
              disabled={loadCheckUser}
            >
              {loadCheckUser ? <Spinner size="sm">...Loading</Spinner> : 'Cek Data'}
            </Button>
          )}
          {customerData?.phone_number?.length > 0 &&
            customerData?.uncompleted_phone &&
            customerData?.user_first_selected && (
              <Button
                color="primary"
                size="sm"
                onClick={() => handleUpdatePhoneNumber()}
                style={{ minWidth: 110 }}
                disabled={loadUpdatePhone}
              >
                {loadUpdatePhone ? <Spinner size="sm">...Loading</Spinner> : 'Simpan Nomor HP'}
              </Button>
            )}
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div className="mb-3 d-flex flex-column">
            <FormTitle
              title="Data Kendaraan Pelanggan"
              subTitle="Pilih data kendaraan pelanggan jika tersedia. Kamu juga dapat tambah data baru"
            />
          </div>
          <Row>
            <Col lg={4} md={12} sm={12} xs={12}>
              <FormGroup>
                <Label className="fw-bold label-required">Data Kendaraan</Label>
                {renderDataMobil()}
              </FormGroup>
            </Col>
          </Row>
          {showCarDetail && (
            <>
              <div className="mb-3 d-flex flex-column">
                <FormTitle title="Ringkasan Data Kendaraan" />
              </div>
              <Row>
                <Col lg={4} md={12} sm={12} xs={12}>
                  <FormGroup>
                    <Label className="fw-bold label-required">Merek Kendaraan</Label>
                    {userCar?.id ? (
                      <Input
                        disabled
                        value={`${userCar?.model ?? userCar?.car_model?.model_name} - ${userCar?.variant}`}
                      />
                    ) : (
                      <Row>
                        <SelectAsyncField
                          cacheOptions
                          defaultOptions={[]}
                          value={selectedNewCar}
                          getOptionLabel={(e) => `${e?.model ?? e?.car_model?.model_name} - ${e?.variant}` ?? ''}
                          getOptionValue={(e) => e.id}
                          loadOptions={Helper.fetchCars}
                          onChange={handleChangeNewCar}
                          menuPlacement="top"
                          placeholder="Pilih merek kendaraan"
                          disabled={loadCar}
                        />
                      </Row>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={4} md={12} sm={12} xs={12}>
                  <FormGroup>
                    <Label className="fw-bold label-required">Nomor Polisi</Label>
                    <Row>
                      <Col md={3}>
                        <Input
                          placeholder="B"
                          disabled={disableEditField}
                          value={licensePlateStart}
                          maxLength={2}
                          onChange={(e) => handleChangeLicensePlate(e.target.value, 0)}
                        />
                      </Col>
                      <Col md={6}>
                        <Input
                          placeholder="1234"
                          disabled={disableEditField}
                          value={licensePlateMiddle}
                          onChange={(e) => handleChangeLicensePlate(e.target.value, 1)}
                          maxLength={4}
                        />
                      </Col>
                      <Col md={3}>
                        <Input
                          placeholder="AA"
                          disabled={disableEditField}
                          value={licensePlateEnd}
                          maxLength={3}
                          onChange={(e) => handleChangeLicensePlate(e.target.value, 2)}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
                <Col lg={4} md={12} sm={12} xs={12}>
                  <FormGroup>
                    <Label className="fw-bold label-required">Transmisi</Label>
                    <Row>
                      <TranmissionOption
                        onClick={(e) => {
                          dispatch(
                            setActiveCar({
                              ...userCar,
                              transmission: userCar?.transmission ?? e,
                            })
                          );
                          setTransmission(e);
                        }}
                        isDisabled={disableEditField}
                        value={userCar?.transmission}
                      />
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={12} sm={12} xs={12}>
                  <FormGroup>
                    <Label className="fw-bold label-required">Tahun</Label>
                    <Input
                      placeholder="2023"
                      maxLength={4}
                      disabled={disableEditField}
                      value={userCar?.year ? userCar?.year : year}
                      onChange={(e) => {
                        dispatch(
                          setActiveCar({
                            ...userCar,
                            year: Helper.inputNumber(e.target.value, 9999),
                          })
                        );
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg={8} md={12} sm={12} xs={12} />
              </Row>
            </>
          )}
          {userCar?.customer_new_car ? (
            <Button
              color="primary"
              size="sm"
              onClick={() => handleSaveNewCar()}
              style={{ minWidth: 200 }}
              disabled={loadSaveNewCar}
            >
              {loadSaveNewCar ? <Spinner size="sm">...Loading</Spinner> : 'Simpan Data Kendaraan Baru'}
            </Button>
          ) : (
            ''
          )}
          {isShowButtonUpdateCar() ? (
            <Button
              color="primary"
              size="sm"
              onClick={() => handleUpdateCar()}
              style={{ minWidth: 200 }}
              disabled={loadUpdateCar}
            >
              {loadUpdateCar ? <Spinner size="sm">...Loading</Spinner> : 'Update Data Kendaraan'}
            </Button>
          ) : (
            ''
          )}
        </CardBody>
      </Card>
      <CustomModal
        modal={showModalChooseUser}
        modalWidth="auto"
        backdrop="static"
        toggle={() => setShowModalChooseUser(!showModalChooseUser)}
      >
        <div className="header">Plat Nomor Sudah Terdaftar</div>
        <Row className="mt-4">
          <Col sm={12} md={12}>
            <span>
              Plat nomor yang akan kamu tambahkan sudah terdaftar sebelumnya, silahkan pilih customer yang sesuai.
            </span>
          </Col>
          <Col sm={12} md={12} className="mt-4">
            <FormGroup>
              <Label className="fw-bold label-required">Customer</Label>
              <Row>
                <SelectAsyncField
                  defaultOptions={optionChooseUser}
                  getOptionLabel={(e) => `${e?.customer?.name + ' - ' + e?.model + ' - ' + e?.license_plate}`}
                  getOptionValue={(e) => e?.id}
                  onChange={handleChangeChooseUser}
                  value={selectedUser}
                  placeholder="Pilih customer ..."
                />
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <div className="action">
          <Button color="primary" outline style={{ minWidth: '45%' }} onClick={() => handleChooseNewData()}>
            Buat Data Baru
          </Button>
          <Button
            color="primary"
            style={{ minWidth: '45%' }}
            onClick={() => handleUseData()}
            disabled={disabledUseData}
          >
            Gunakan Data
          </Button>
        </div>
      </CustomModal>
      <ConfirmModal
        modal={showModalChangeCar}
        icon={
          <div className="icon-bg-warning">
            <img src={AlertTriangleIcon} alt="alert circle" />
          </div>
        }
        title="Yakin Ubah Kendaraan ?"
        description="Jika kamu mengubah data kendaraan, data order yang telah kamu masukkan akan diatur ulang."
        textSubmit="Ubah Data"
        textCancel="Kembali"
        onSubmit={() => submitChangeCar()}
        onCancel={() => setShowModalChangeCar(false)}
      />
    </div>
  );
};

export default OrderCustomerForms;
