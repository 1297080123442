import React, { useEffect, useState } from 'react';
import { Row, Col, Input } from 'reactstrap';
import Helper from '@helpers/Helper';

const LicensePlateField = (props) => {
  const { data, onChange, loading } = props;

  const [licensePlateStart, setLicensePlateStart] = useState('');
  const [licensePlateMiddle, setLicensePlateMiddle] = useState('');
  const [licensePlateEnd, setLicensePlateEnd] = useState('');

  const setDefaultData = (val) => {
    setLicensePlateStart(Helper.licensePlateSplit(val)?.[0]?.replace(/\s/g, ''));
    setLicensePlateMiddle(Helper.licensePlateSplit(val)?.[1]?.replace(/\s/g, ''));
    setLicensePlateEnd(Helper.licensePlateSplit(val)?.[2]?.replace(/\s/g, ''));
  };

  useEffect(() => {
    if (data) {
      setDefaultData(data);
    }
  }, [data]);

  useEffect(() => {
    const tempData = licensePlateStart + ' ' + licensePlateMiddle + ' ' + licensePlateEnd;
    onChange(tempData);
  }, [licensePlateStart, licensePlateMiddle, licensePlateEnd]);

  return (
    <Row>
      <Col md={3} className="pr-0">
        <Input
          placeholder="B"
          disabled={loading}
          value={licensePlateStart}
          onChange={(e) => setLicensePlateStart(Helper.AbjadRegex(e.target.value, 2).toUpperCase())}
          maxLength={2}
        />
      </Col>
      <Col md={6}>
        <Input
          placeholder="1234"
          disabled={loading}
          value={licensePlateMiddle}
          onChange={(e) => setLicensePlateMiddle(Helper.inputNumber(e.target.value, 9999))}
          maxLength={4}
        />
      </Col>
      <Col md={3} className="pl-0">
        <Input
          placeholder="AA"
          disabled={loading}
          value={licensePlateEnd}
          onChange={(e) => setLicensePlateEnd(Helper.AbjadRegex(e.target.value, 3).toUpperCase())}
          maxLength={3}
        />
      </Col>
    </Row>
  );
};

export default LicensePlateField;
