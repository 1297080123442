import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { mapToCssModules, tagPropType } from '../utils';

const propTypes = {
  onBackClick: PropTypes.func,
  title: PropTypes.string,
  rightImageSrc: PropTypes.string,
  rightImageClick: PropTypes.func,
  headerTextClassName: PropTypes.string,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  titleTag: tagPropType,
  dataAutomationTitle: PropTypes.string,
  dataAutomationButton: PropTypes.string,
};

const defaultProps = {
  titleTag: 'span',
};

const Header = (props) => {
  const {
    title,
    onBackClick,
    rightImageSrc,
    rightImageClick,
    headerTextClassName,
    className,
    cssModule,
    titleTag: Tag,
    dataAutomationTitle,
    dataAutomationButton,
  } = props;

  const classes = mapToCssModules(classNames('header', className), cssModule);
  const headerTextClasses = mapToCssModules(classNames('text-left', 'header-text', headerTextClassName));

  return (
    <div className={classes}>
      <div className="header-container">
        <div className="d-flex">
          <div className="d-flex align-items-center w-100">
            {onBackClick && (
              <div
                className="back-page"
                aria-hidden="true"
                onClick={onBackClick}
                data-automation={dataAutomationButton}
              />
            )}
            <Tag type={Tag} className={headerTextClasses} data-automation={dataAutomationTitle}>
              {title}
            </Tag>
            {rightImageSrc && (
              <img className="right-image" role="presentation" src={rightImageSrc} alt="" onClick={rightImageClick} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
