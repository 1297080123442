import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from '../utils';

const propTypes = {
  tag: tagPropType,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func]),
};

const defaultProps = {
  tag: 'div',
};

const CardBody = (props) => {
  const { className, cssModule, innerRef, tag: Tag, ...attributes } = props;
  const classes = mapToCssModules(classNames(className, 'card-body'), cssModule);

  return <Tag {...attributes} className={classes} ref={innerRef} />;
};

CardBody.propTypes = propTypes;
CardBody.defaultProps = defaultProps;

export default CardBody;
