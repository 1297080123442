import React from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import ArrowLeftIcon from '@assets/icons/arrow-left.svg';

const TitlePage = (props) => {
  const { title, subTitle, useBackButton, backButtonLink } = props;
  const clickBackButton = () => {
    if (backButtonLink) {
      history.push(backButtonLink);
    } else {
      history.goBack();
    }
  };
  const history = useHistory();

  return (
    <div className="title-page">
      {useBackButton && (
        <Button color="link" onClick={clickBackButton}>
          <img src={ArrowLeftIcon} alt="Arrow left" />
        </Button>
      )}
      <span>{title}</span>
      <p>{subTitle}</p>
    </div>
  );
};

export default TitlePage;
