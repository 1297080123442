import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from '../utils';

const propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  textColor: PropTypes.string,
  icon: PropTypes.string,
  pill: PropTypes.bool,
  active: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  tag: tagPropType,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  cssModule: PropTypes.object,
};

const defaultProps = {
  pill: false,
  color: 'primary',
  textColor: 'off-white',
  size: 'md',
  tag: 'div',
};

const Tags = (props) => {
  let {
    className,
    iconClassName,
    cssModule,
    title,
    active,
    color,
    textColor,
    size,
    icon,
    innerRef,
    pill,
    tag: Tag,
    ...attributes
  } = props;

  const classes = mapToCssModules(
    classNames(
      className,
      'tags',
      `bg-${color}`,
      textColor ? `text-${textColor}` : false,
      size ? `${size}` : false,
      pill ? 'pill' : false,
      active ? 'active' : false
    ),
    cssModule
  );

  const iconClasses = mapToCssModules(classNames(iconClassName), cssModule);

  if (attributes.href && Tag === 'span') {
    Tag = 'a';
  }

  return (
    <Tag className={classes} {...attributes}>
      {icon && <img src={icon} alt="" className={iconClasses} />}
      {title}
    </Tag>
  );
};

Tags.propTypes = propTypes;
Tags.defaultProps = defaultProps;

export default Tags;
