import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import DataTable from 'react-data-table-component';
import Wrapper from '@components/wrapper/Wrapper';
import API from '@utils/API';
import { Row, Col, Card, CardBody, Input, InputGroup, InputGroupAddon } from '../../components/otoklix-elements';
import NoData from '@components/empty-state/NoData';
import TitlePage from '@components/dashboard/TitlePage';
import CustomPagination from '@components/pagination/CustomPagination';
import SearchIcon from '@assets/icons/search.svg';
import Loading from '@components/loading/Loading';
import { customStylesHeaderTable } from '@utils/Constants';
import moment from 'moment';
import EditIcon from '@assets/icons/edit.svg';
import TrashIcon from '@assets/icons/trash.svg';
import ConfirmModal from '@components/modal/ConfirmModal';
import useAlert from '@hooks/useAlert';
import AlertTriangleIcon from '@assets/icons/alert-triangle.svg';

const Draft = () => {
  const { setAlert } = useAlert();
  const api = new API('v2');
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [query, setQuery] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [modalConfirm, setModalConfirm] = useState(false);
  const [darftId, setDraftId] = useState(0);

  const columns = useMemo(
    () => [
      {
        name: 'Merek Kendaraan',
        selector: (row) => row?.car_detail?.brand,
        cell: (row) => `${row?.car_detail?.brand + ' ' + row?.car_detail?.model + ' ' + row?.car_detail?.variant}`,
        sortField: 'car_model',
        sortable: true,
        minWidth: '140px',
      },
      {
        name: 'Terakhir Diperbarui',
        selector: (row) => row?.draft_date,
        cell: (row) => moment(row?.draft_date).format('DD MMM yyyy, HH:mm'),
        sortField: 'latest_update',
        sortable: true,
        minWidth: '140px',
      },
      {
        name: '',
        minWidth: '140px',
        right: true,
        // selector: (row) => row?.draft_date,
        cell: (row) => (
          <>
            <Row>
              <Col>
                <img onClick={() => history.push(`/order/create?draftId=${row?.id}`)} src={EditIcon} alt="Edit" />
              </Col>
              <Col>
                <img onClick={() => handleModalConfirmDelete(row?.id)} src={TrashIcon} alt="Hapus" />
              </Col>
            </Row>
          </>
        ),
        sortable: true,
        sortField: 'action',
        sortable: true,
        minWidth: '80px',
      },
    ],
    []
  );

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleModalConfirmDelete = (value) => {
    setDraftId(value);
    setModalConfirm(true);
  };

  const handleRowClick = (row) => {
    history.push(`/order/create?draftId=${row?.id}`);
  };

  const onDeleteSelectedDraft = async (id, message, typeNotif) => {
    try {
      const res = await api.delete(`v2/gms/draft/${id}/cart`);

      if (+res?.status === 200) {
        setAlert(message, typeNotif);
        setModalConfirm(false);
        fetchDataTable();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const validateCountPage = (pagination) => {
    if (page > pagination?.total_page) {
      setPage(pagination?.total_page);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const fetchDataTable = async () => {
    setLoading(true);
    try {
      const res = await api.get(
        `v2/gms/draft/cart?query=${query}&start_date=${startDate === '' ? endDate : startDate}&end_date=${
          endDate === '' ? startDate : endDate
        }&page=${page}&limit=${pageLimit}`
      );
      if (+res?.status === 200) {
        setDataTable(res?.data?.data);
        const pagination = res?.data;
        if (pagination) {
          setTotalPages(pagination?.total_page);
          setTotalRows(pagination?.total_rows);
          setPageLimit(pagination?.limit);
          setPage(pagination?.current_page);
          validateCountPage(pagination);
        }
        setLoading(false);
      }
    } catch (err) {
      setDataTable([]);
      setLoading(false);
      console.error(err);
    }
  };

  const debouncedFetchDataTable = debounce((term) => {
    fetchDataTable();
  }, 1500);

  useEffect(() => {
    debouncedFetchDataTable();
  }, [query]);

  useEffect(() => {
    if (page && pageLimit) {
      fetchDataTable();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    fetchDataTable();
  }, [startDate, endDate]);

  return (
    <Wrapper title="" className="orders">
      <Row className="head justify-content-between">
        <Col lg={3} md={12} sm={12}>
          <TitlePage title="Draf Pesanan Baru" />
        </Col>
        <Col lg={9} md={12} sm={12}>
          <Row className="float-lg-end">
            <Col lg={5} md={5} sm={12} className="mt-2">
              <InputGroup className="input-search" style={{ width: '100%' }}>
                <InputGroupAddon addonType="prepend">
                  <img src={SearchIcon} alt="Search Icon" />
                </InputGroupAddon>
                <Input value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Cari merek kendaraan" />
              </InputGroup>
            </Col>
            <Col lg={7} md={7} sm={12} className="mt-2 d-flex justify-content-between">
              <Input
                type="date"
                style={{ width: '45%' }}
                placeholder="Start date"
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
              <span style={{ fontWeight: 100 }} className="pt-1">
                -
              </span>
              <Input
                type="date"
                style={{ width: '45%' }}
                placeholder="End date"
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
                min={startDate ? new Date(startDate).toJSON().slice(0, 10) : ''}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Card className="mt-3 border-table">
        <CardBody>
          <Row>
            <Col lg={12} md={12}>
              <DataTable
                clearSelectedRows={loading}
                persistTableHead
                highlightOnHover
                sortServer
                noHeader={true}
                progressPending={loading}
                data={dataTable}
                className="table dt-responsive table-custom"
                noDataComponent={loading ? <Loading /> : <NoData />}
                progressComponent={<Loading />}
                customStyles={customStylesHeaderTable}
                onRowClicked={(row) => handleRowClick(row)}
                columns={columns}
              />
            </Col>
            <Col sm={12} md={12} className="mt-2">
              {dataTable?.length > 1 && (
                <CustomPagination
                  page={page}
                  pageLimit={pageLimit}
                  totalRows={dataTable?.length ?? 0}
                  totalAllRows={totalRows}
                  totalPages={totalPages}
                  handleChangePage={handleChangePage}
                  handleChangeRowPerPage={handleChangeRowPerPage}
                ></CustomPagination>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ConfirmModal
        modal={modalConfirm}
        icon={
          <div className="icon-bg-warning">
            <img src={AlertTriangleIcon} alt="alert circle" />
          </div>
        }
        title="Apakah sudah yakin?"
        description="Yakin akan menghapus draft order baru ini?"
        textSubmit="Hapus"
        textCancel="Kembali"
        onSubmit={() => onDeleteSelectedDraft(darftId, 'Sukses menghapus draft', 'success')}
        onCancel={() => setModalConfirm(false)}
        loading={loading}
      />
    </Wrapper>
  );
};

export default Draft;
