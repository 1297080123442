import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from '../utils';

const propTypes = {
  tag: tagPropType,
  inverse: PropTypes.bool,
  color: PropTypes.string,
  body: PropTypes.bool,
  outline: PropTypes.bool,
  noBorderRadius: PropTypes.bool,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func]),
};

const defaultProps = {
  tag: 'div',
};

const Card = (props) => {
  const {
    className,
    cssModule,
    color,
    body,
    inverse,
    outline,
    noBorderRadius,
    tag: Tag,
    innerRef,
    ...attributes
  } = props;
  const classes = mapToCssModules(
    classNames(
      className,
      'card',
      inverse ? 'text-white' : false,
      body ? 'card-body' : false,
      color ? `${outline ? 'border' : 'bg'}-${color}` : false,
      noBorderRadius ? 'rounded-0' : false
    ),
    cssModule
  );

  return <Tag {...attributes} className={classes} ref={innerRef} />;
};

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

export default Card;
