import React, { useState, useMemo, useEffect } from 'react';
import Wrapper from '@components/wrapper/Wrapper';
import { useHistory } from 'react-router-dom';
import userPermission from '@utils/userPermission';
import { Row, Col, Button } from 'reactstrap';
import moment from 'moment';
import Helper from '@helpers/Helper';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import API from '@utils/API';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import { assign } from 'lodash';
import DashboardCard from '@components/card/DashboardCard';

function Dashboard() {
  const { generatePermission } = userPermission();
  const history = useHistory();
  const api = new API('v2');

  const [loading, setLoading] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [dataDashboard, setDataDashboard] = useState({});
  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingData, setSortingData] = useState({});
  const [filter, setFilter] = useState({ value: 'daily', label: 'Harian' });
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [statusApprovement, setStatusApprovement] = useState(false);
  const [selectedBookingCode, setSelectedBookingCode] = useState(false);
  const [hasNotif, setHasNotif] = useState();
  const [notification, setNotification] = useState({});

  const allowPermission = (menu) => {
    return !!(generatePermission('booking', menu) || generatePermission('booking', 'all'));
  };

  const allowPermissionFleet = (menu) => {
    return !!(generatePermission('garage', menu) || generatePermission('garage', 'all'));
  };

  const allowList = allowPermission('all') || allowPermission('list');
  const allowCreate = allowPermission('all') || allowPermission('create');
  const allowShow = allowPermission('all') || allowPermission('show');
  const allowShowFleet = allowPermissionFleet('all') || allowPermissionFleet('show');

  const generateBreadcrumb = () => {
    return [
      {
        id: 1,
        label: 'Dashboard',
        active: false,
        url: '/dashboard',
      },
    ];
  };

  const handleCreateBooking = () => {
    history.push(`/bookings/create`);
  };

  const handleListBooking = (status) => {
    history.push(`/bookings?status=${status}`);
  };

  const openBookingDetail = (id) => {
    if (allowShow) {
      history.push(`/bookings/${id}`);
    }
  };

  const openCarDetail = (id) => {
    if (allowShowFleet) {
      history.push(`/fleets/${id}`);
    }
  };

  const generateCategory = (data) => {
    const mapping = data.map((item, index) => {
      if (index < 3) {
        return (
          <div key={index} className="mb-4">
            {item}
          </div>
        );
      } else if (index == 3) {
        return (
          <div key={index} className="mb-4">
            {'+' + (data?.length - 3) + ' Lainnya'}
          </div>
        );
      }
    });
    return mapping;
  };

  const generateArray = (data) => {
    const mapping = data.map((item, index) => {
      if (index < 3) {
        return (
          <div key={index} className="mb-4">
            {item}
          </div>
        );
      } else if (index == 3) {
        return (
          <div key={index} className="mb-4">
            {'+' + (data?.length - 3) + ' Lainnya'}
          </div>
        );
      }
    });
    return mapping;
  };

  const typeOptions = [
    { value: '', label: 'Semua' },
    { value: 'daily', label: 'Harian' },
    { value: 'weekly', label: 'Mingguan' },
    { value: 'monthly', label: 'Bulanan' },
    { value: 'yearly', label: 'Tahunan' },
    { value: 'custom', label: 'Custom Tanggal' },
  ];

  let dataFilter = [
    {
      id: 'filter_select_booking_type',
      type: 'select',
      title: 'Filter',
      name: 'time',
      name_field: 'time',
      options: typeOptions,
      width: 'w-170',
      value: '',
      placeholder: 'choose ...',
    },
  ];

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const onResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  async function approveEstimation(status) {
    setLoading(true);
    setDisableForm(true);

    const payload = {
      update_event: 'approval-booking',
      is_approve: status ?? null,
    };
    try {
      const response = await api.put(`v2/gms/booking/${selectedBookingCode}`, payload);
      if (response?.data) {
        setLoading(false);
        setTimeout(() => {
          handleResultNotification(
            true,
            'success',
            `Berhasil - Semua Permintaan Approval Booking '${selectedBookingCode}' ${status ? 'disetujui' : 'ditolak'}`
          );
          fetchBookings();
        }, 300);
      }
    } catch (error) {
      setLoading(false);
      setTimeout(() => {
        setDisableForm(false);
        handleResultNotification(
          true,
          'failed',
          `Gagal - Semua Permintaan Approval Booking '${selectedBookingCode}' gagal ${
            status ? 'disetujui' : 'ditolak'
          }, ` + error
        );
      }, 300);
    }

    setDisableForm(false);
    setConfirmModal(false);
  }

  async function fetchDashboard() {
    setLoading(true);

    const params = {
      period: filtered?.date ? null : filtered?.time?.value ?? null,
    };

    if (filtered?.date) {
      assign(params, {
        start_date: filtered?.date?.start_date ? moment(filtered?.date?.start_date).format('YYYY-MM-DD') : null,
        end_date: filtered?.date?.end_date ? moment(filtered?.date?.end_date).format('YYYY-MM-DD') : null,
      });
    }

    try {
      const response = await api.get(`v2/gms/dashboard/?`, { params });
      setDataDashboard(response?.data?.data ?? {});
      setLoading(false);
      setFirstLoad(false);
    } catch (err) {
      const error = err?.response?.data?.error?.message;
      setDataDashboard({});
      setLoading(false);
      setFirstLoad(false);
      handleResultNotification(true, 'failed', `Gagal mendapatkan Data Overview, ` + error);
    }
  }

  const resetBookings = () => {
    setSortingData({});
  };

  useEffect(() => {
    if (firstLoad && filtered) {
      fetchDashboard();
    } else {
      resetBookings();
    }
  }, [filtered]);

  useEffect(() => {
    if (filtered?.time?.value == 'custom' && !firstLoad) {
      if (filtered?.date?.start_date) {
        fetchDashboard();
      }
    } else {
      if (!firstLoad) fetchDashboard();
    }
  }, [filtered]);

  useEffect(() => {
    const dateFilter = {
      id: 'filter_date_date',
      type: 'date',
      name: 'date',
      name_field: 'date',
      date_range: true,
      width: 'w-170',
      value: '',
      backDate: true,
      futureDate: false,
      placeholder: 'Range Tanggal',
    };
    const checkFilter = filters.filter((item) => item.name == 'date');
    if (filtered?.time?.value == 'custom') {
      if (checkFilter?.length < 1) {
        setFilters([...filters, dateFilter]);
      }
    } else {
      if (checkFilter?.length > 0) {
        setFilters(filters.filter((item) => item.name != 'date'));
        delete filtered.date;
      }
    }
  }, [filtered]);

  const setDefaultFilters = async () => {
    dataFilter[0].value = { value: 'monthly', label: 'Bulanan' };
    setFilters([...dataFilter]);

    setFiltered({
      time: { value: 'monthly', label: 'Bulanan' },
    });
  };

  useEffect(() => {
    setDefaultFilters();
  }, []);

  const renderContent = () => {
    if (loading) {
      return <LoadingWrapper />;
    }

    return (
      <>
        <Wrapper
          customContentClassName="px-2"
          breadcrumbData={generateBreadcrumb()}
          customBreadcrumbClassName={'pb-0 pt-3'}
          showNotification={hasNotif}
          notification={notification}
          onCloseNotification={() => setHasNotif(false)}
        >
          <ConfirmationModal
            modal={confirmModal}
            modalWidth={'390'}
            buttonWidth={'50'}
            titleColor={statusApprovement ? 'green' : 'red'}
            toggle={() => setConfirmModal(!confirmModal)}
            header={`${statusApprovement ? 'Menyetujui' : 'Menolak'} Semua Permintaan Approval ?`}
            subHeader={`Apakah kamu yakin ingin ${
              statusApprovement ? 'menyetujui' : 'menolak'
            } semua permintaan approval untuk order ini?`}
            isDisabled={disableForm}
            onConfirm={() => approveEstimation(statusApprovement)}
            toggleClose={() => setConfirmModal(!confirmModal)}
          ></ConfirmationModal>
          <ConfirmationModal
            modal={alertModal}
            modalWidth={'400'}
            uttonWidth={'50'}
            titleColor={'red'}
            buttonClose={'Tutup'}
            withButtonConfirm={false}
            toggle={() => setAlertModal(!alertModal)}
            header={`Approval Gagal`}
            subHeader={`Untuk transaksi >10 juta rupiah hanya bisa diproses oleh Admin`}
            isDisabled={disableForm}
            toggleClose={() => setAlertModal(!alertModal)}
          ></ConfirmationModal>
          <Row className="mb-3 mt-0 d-flex" xs={2}>
            <Col xs={12} md={4}>
              <h3>Data Overview</h3>
            </Col>
            <Col xs={12} md={8} className="">
              <div className="filter-sides">
                {allowList && (
                  <ActionFilterCustom
                    filters={filters}
                    showButton={false}
                    loading={loading}
                    onApplyFilters={(e) => setFiltered(e)}
                  ></ActionFilterCustom>
                )}
                {allowCreate && (
                  <div className="btn-approve-resp">
                    <Button className="button-action save btn-blue btn-create-responsive" onClick={handleCreateBooking}>
                      {'Ajukan Servis'}
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row className="dashboard-card">
            <Col md={6}>
              <DashboardCard
                title="Mobil Terdaftar"
                total={dataDashboard?.gms_total ? Helper.numberWithCommas(dataDashboard?.gms_total) : 0}
                className="bg-dark-blue"
              />
            </Col>
            <Col md={6}>
              <DashboardCard
                title="Total Transaksi"
                total={`Rp ${dataDashboard?.total_spent ? Helper.numberWithCommas(dataDashboard?.total_spent) : 0}`}
                className="bg-dark-orange"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Row className="dashboard-card">
                <Col md={12}>
                  <h5>Permintaan Servis</h5>
                </Col>
                <Col md={4} sm={4}>
                  <DashboardCard
                    title="Menunggu Estimasi"
                    total={
                      dataDashboard?.booking_by_status?.length > 0
                        ? Helper.numberWithCommas(dataDashboard?.booking_by_status[0]?.total ?? 0)
                        : 0
                    }
                    className="bg-red h-90"
                    onViewAll={() => handleListBooking('review')}
                  />
                </Col>
                <Col md={4} sm={4}>
                  <DashboardCard
                    title="Menunggu Estimasi"
                    total={
                      dataDashboard?.booking_by_status?.length > 0
                        ? Helper.numberWithCommas(dataDashboard?.booking_by_status[0]?.total ?? 0)
                        : 0
                    }
                    className="bg-yellow h-90"
                    onViewAll={() => handleListBooking('review')}
                  />
                </Col>
                <Col md={4} sm={4}>
                  <DashboardCard
                    title="Menunggu Estimasi"
                    total={
                      dataDashboard?.booking_by_status?.length > 0
                        ? Helper.numberWithCommas(dataDashboard?.booking_by_status[0]?.total ?? 0)
                        : 0
                    }
                    className="bg-green h-90"
                    onViewAll={() => handleListBooking('review')}
                  />
                </Col>
                <div className="vertical-line"></div>
              </Row>
            </Col>
            <Col md={6}>
              <Row className="dashboard-card">
                <Col md={12}>
                  <h5>Permintaan Servis</h5>
                </Col>
                <Col md={4} sm={4}>
                  <DashboardCard
                    title="Menunggu Estimasi"
                    total={
                      dataDashboard?.booking_by_status?.length > 0
                        ? Helper.numberWithCommas(dataDashboard?.booking_by_status[0]?.total ?? 0)
                        : 0
                    }
                    className="bg-red h-90"
                    onViewAll={() => handleListBooking('review')}
                  />
                </Col>
                <Col md={4} sm={4}>
                  <DashboardCard
                    title="Menunggu Estimasi"
                    total={
                      dataDashboard?.booking_by_status?.length > 0
                        ? Helper.numberWithCommas(dataDashboard?.booking_by_status[0]?.total ?? 0)
                        : 0
                    }
                    className="bg-yellow h-90"
                    onViewAll={() => handleListBooking('review')}
                  />
                </Col>
                <Col md={4} sm={4}>
                  <DashboardCard
                    title="Menunggu Estimasi"
                    total={
                      dataDashboard?.booking_by_status?.length > 0
                        ? Helper.numberWithCommas(dataDashboard?.booking_by_status[0]?.total ?? 0)
                        : 0
                    }
                    className="bg-green h-90"
                    onViewAll={() => handleListBooking('review')}
                  />
                </Col>
                <div className="vertical-line"></div>
              </Row>
            </Col>
          </Row>
        </Wrapper>
      </>
    );
  };

  return renderContent();
}

export default Dashboard;
