import React from 'react';
import Logo from '@assets/images/logo.svg';
import useResponsive from '@utils/useResponsive';

const WrapperNoFrame = ({ title, extras, className, children }) => {
  const { isDesktop } = useResponsive();

  return (
    <div className={`wrapper-no-frame${className ? ` ${className}` : ''}`}>
      <div className="wrapper-head d-flex justify-content-between align-items-center py-2 px-3">
        {isDesktop && <img src={Logo} alt="logo-small" className="logo-sm ms-3" />}
        <span>{title ?? ''}</span>
        <div className="extras d-flex align-items-center">{extras ? extras : null}</div>
      </div>
      <div className="wrapper-body">{children}</div>
    </div>
  );
};

export default WrapperNoFrame;
