import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/Button';

const propTypes = {
  image: PropTypes.string,
  imgAlt: PropTypes.string,
  imgHeight: PropTypes.number,
  imgWidth: PropTypes.number,
  title: PropTypes.string,
  children: PropTypes.node,
  captionAsTitle: PropTypes.bool,
  titleColor: PropTypes.string,
  mainButtonTitle: PropTypes.string,
  mainButtonColor: PropTypes.string,
  mainButtonOutline: PropTypes.bool,
  onMainButtonClick: PropTypes.func,
  secondaryButtonTitle: PropTypes.string,
  secondaryButtonColor: PropTypes.string,
  secondaryButtonOutline: PropTypes.bool,
  onSecondaryButtonClick: PropTypes.func,
  dataAutoMainButton: PropTypes.string,
  dataAutoSecButton: PropTypes.string,
  dataAutoContainer: PropTypes.string,
};

const defaultProps = {
  titleColor: 'dark',
  secondaryButtonColor: 'subtle',
  dataAutoMainButton: 'empty_state_button_main',
  dataAutoSecButton: 'empty_state_button_second',
  dataAutoContainer: 'empty_state_container',
};

const EmptyState = (props) => {
  let {
    image,
    imgAlt,
    imgHeight,
    imgWidth,
    title,
    titleColor,
    children,
    captionAsTitle,
    mainButtonTitle,
    mainButtonColor,
    mainButtonOutline,
    onMainButtonClick,
    secondaryButtonTitle,
    secondaryButtonColor,
    secondaryButtonOutline,
    onSecondaryButtonClick,
    dataAutoMainButton,
    dataAutoSecButton,
    dataAutoContainer,
  } = props;

  return (
    <div className="empty-state-container" data-automation={dataAutoContainer}>
      {image && <img className="image" src={image} alt={imgAlt} height={imgHeight} width={imgWidth} loading="lazy" />}

      {title && <span className={`title text-${titleColor}`}>{title}</span>}

      <div className={`${captionAsTitle ? 'title' : 'caption'} text-dark`}>{children}</div>

      <div className="footer">
        {mainButtonTitle && (
          <Button
            data-automation={dataAutoMainButton}
            className="button"
            size="sm"
            color={mainButtonColor}
            outline={mainButtonOutline}
            block={!secondaryButtonTitle}
            onClick={onMainButtonClick}
          >
            {mainButtonTitle}
          </Button>
        )}

        {secondaryButtonTitle && (
          <Button
            data-automation={dataAutoSecButton}
            className="button"
            size="sm"
            color={secondaryButtonColor}
            outline={secondaryButtonOutline}
            onClick={onSecondaryButtonClick}
          >
            {secondaryButtonTitle}
          </Button>
        )}
      </div>
    </div>
  );
};

EmptyState.propTypes = propTypes;
EmptyState.defaultProps = defaultProps;

export default EmptyState;
