import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules } from '../utils';
import Text from '../text/Text';

const propTypes = {
  rating: PropTypes.number,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  activeColor: PropTypes.string,
  inactiveColor: PropTypes.string,
};

const defaultProps = {
  activeColor: 'label',
  inactiveColor: 'line',
};

const RatingPrice = (props) => {
  const { rating, className, cssModule, activeColor, inactiveColor } = props;

  const classes = mapToCssModules(classNames(className), cssModule);

  const ratings = () => {
    const levels = [];
    for (let i = 0; i < 5; i += 1) {
      levels.push(i <= rating - 1);
    }

    return levels;
  };

  return (
    <div className="d-flex">
      {ratings().map((item, index) => (
        <Text color={item ? activeColor : inactiveColor} weight="bold" key={`price-${index}`} className={classes}>
          $
        </Text>
      ))}
    </div>
  );
};

RatingPrice.propTypes = propTypes;
RatingPrice.defaultProps = defaultProps;

export default RatingPrice;
