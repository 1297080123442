import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from '../utils';

const propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  titleClassName: PropTypes.string,
  titleTruncate: PropTypes.bool,
  subtitleClassName: PropTypes.string,
  subtitleColor: PropTypes.string,
  accordionRightImage: PropTypes.any,
  tag: tagPropType,
  className: PropTypes.string,
  cssModule: PropTypes.object,
};

const defaultProps = {
  tag: 'div',
};

const WidgetListItem = (props) => {
  const {
    title,
    subtitle,
    titleClassName,
    titleTruncate,
    subtitleClassName,
    subtitleColor,
    accordionRightImage,
    className,
    cssModule,
    tag: Tag,
    ...attributes
  } = props;

  const classes = mapToCssModules(classNames('d-flex', 'widget-item', 'list-condition', className), cssModule);

  const titleClasses = mapToCssModules(
    classNames('py-1', 'flex-grow-1', 'title', titleTruncate ? 'text-truncate' : false, titleClassName)
  );

  const subtitleClasses = mapToCssModules(
    classNames(
      'py-1',
      'flex-shrink-1',
      'title',
      subtitleColor ? `text-${subtitleColor}` : 'text-success',
      subtitleClassName
    )
  );

  return (
    <Tag className={classes} {...attributes}>
      <div className={titleClasses}>{title}</div>

      {subtitle && <div className={subtitleClasses}>{subtitle}</div>}

      {accordionRightImage && <div className="ms-1">{accordionRightImage}</div>}
    </Tag>
  );
};

WidgetListItem.propTypes = propTypes;
WidgetListItem.defaultProps = defaultProps;

export default WidgetListItem;
