import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Badge from '../badge/Badge';
import { mapToCssModules, tagPropType } from '../utils';

const propTypes = {
  tag: tagPropType,
  sm: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  image: PropTypes.string,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  badgeColor: PropTypes.string,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  bgIconColor: PropTypes.string,
  iconClassName: PropTypes.string,
  textClassName: PropTypes.string,
  imgAlt: PropTypes.string,
  textRight: PropTypes.bool,
  card: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  badge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lazyLoad: PropTypes.bool,
};

const defaultProps = {
  tag: 'div',
  imageWidth: 45,
  imageHeight: 45,
  badgeColor: 'secondary',
  size: 'lg',
  imgAlt: '',
  lazyLoad: true,
};

const Icon = (props) => {
  const {
    sm,
    size,
    badge,
    title,
    image,
    tag: Tag,
    className,
    cssModule,
    imageWidth,
    imageHeight,
    badgeColor,
    bgIconColor,
    iconClassName,
    textClassName,
    textRight,
    imgAlt,
    lazyLoad,
    card,
    ...attributes
  } = props;

  const classes = mapToCssModules(
    classNames(
      'box-icon',
      size ? `box-icon-${size}` : false,
      card ? 'box-icon-card-image' : false,
      textRight && 'w-auto',
      className
    ),
    cssModule
  );

  const iconClasses = mapToCssModules(
    classNames('icon', 'rounded', bgIconColor ? `bg-${bgIconColor}` : false, iconClassName)
  );

  const iconTextWrapper = mapToCssModules(classNames(textRight && 'd-flex align-items-center'), cssModule);

  const textClasses = mapToCssModules(classNames('text-center', 'icon-text', textRight && 'ps-1 m-0', textClassName));

  return (
    <Tag {...attributes} className={classes}>
      {badge && (
        <Badge color={badgeColor} className="badge-top-right">
          {badge}
        </Badge>
      )}
      <div className={iconTextWrapper}>
        <div className={iconClasses}>
          {image && (
            <img
              src={image}
              width={imageWidth}
              height={imageHeight}
              alt={imgAlt}
              loading={lazyLoad ? 'lazy' : 'eager'}
            />
          )}
        </div>

        {title && <div className={textClasses}>{title}</div>}
      </div>
    </Tag>
  );
};

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
