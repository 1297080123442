import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import WrapperNoFrame from '@components/wrapper/WrapperNoFrame';
import { AbsoluteWrapper, Button, Col, Divider, FormGroup, Label, Row } from '../../components/otoklix-elements';
import Stepper from '../../components/stepper/Stepper';
import Spinner from '../../components/otoklix-elements/components/spinner/Spinner';
import SelectAsyncField from '@components/field/SelectAsyncField';
import ChooseCarModal from '@components/modal/ChooseCarModal';
import OrderItemsForms from '@components/order/OrderItemsForms';
import OrderSummary from '@components/order/OrderSummary';
import DatePickerModal from '@components/modal/DatePickerModal';
import OrderCustomerForms from '@components/order/OrderCustomerForms';
import SelectProductModal from '@components/modal/SelectProductModal';
import ConfirmModal from '@components/modal/ConfirmModal';
import CustomFormModal from '@components/modal/CustomFormModal';
import AlertCircleIcon from '@assets/icons/alert-circle.svg';
import AlertTriangleIcon from '@assets/icons/alert-triangle.svg';
import SaveDraftIcon from '@assets/icons/document.svg';
import CloseIcon from '@assets/icons/close.svg';
import DateIcon from '@assets/icons/date.svg';
import ChevronUpIcon from '@assets/icons/chevron-up.svg';
import ArrowRightIcon from '@assets/icons/arrow-right.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveCar, getActiveCar } from '@reducers/Car';
import { getFullName } from '@reducers/Auth';
import {
  getCart,
  setCart,
  getActiveItems,
  getHasModalEditItems,
  setHasModalEditItems,
  getHasModalEditCustomItems,
  setHasModalEditCustomItems,
  setActiveItems,
  getActiveTab,
  setActiveTab,
  getIsEdit,
  setIsEdit,
  setSaveLoading,
  getTotalItemLength,
  getTotalAmount,
  getDataCustomer,
  setDataCustomer,
  setActiveCustomItems,
  getIsWashOrderOnly,
} from '@reducers/Order';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import useResponsive from '@utils/useResponsive';
import useAlert from '@hooks/useAlert';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { find, findIndex, isEmpty, map, assign, isNaN, isNull } from 'lodash';
import { BottomSheet } from 'react-spring-bottom-sheet';
import EstimationPricePdf from '@helpers/pdf/EstimationPricePdf';

const CreateOrder = () => {
  const api = new API('v2');
  const history = useHistory();
  const dispatch = useDispatch();
  const userCar = useSelector(getActiveCar);
  const totalAmount = useSelector(getTotalAmount);
  const totalItems = useSelector(getTotalItemLength);
  const cart = useSelector(getCart);
  const dataCustomer = useSelector(getDataCustomer);
  const hasModalEditItems = useSelector(getHasModalEditItems);
  const activeItems = useSelector(getActiveItems);
  const isEdit = useSelector(getIsEdit);
  const fullname = useSelector(getFullName);
  const isWashOrderOnly = useSelector(getIsWashOrderOnly);
  const { setAlert } = useAlert();
  const { isDesktop } = useResponsive();
  const queryParams = useLocation().search;
  const draftId = new URLSearchParams(queryParams).get('draftId');
  const cookies = new Cookies();
  const authorizedWorkshopsSlug = cookies.get('authorized_workshops_slug');

  const [activeStep, setActiveStep] = useState(0);
  const [hasChooseCarModal, setHasChooseCarModal] = useState(false);
  const [hasCloseModal, setHasCloseModal] = useState(false);
  const [hasConfirmModal, setHasConfirmModal] = useState(false);
  const [carError, setCarError] = useState('');
  const [loadingCar, setLoadingCar] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [hasDatePickerModal, setHasDatePickerModal] = useState(false);
  const [date, setDate] = useState({
    default: null,
    formatted: null,
  });
  const [time, setTime] = useState('');
  const [wsSchedule, setWsSchedule] = useState({});
  const [wsTimeSlot, setWsTimeSlot] = useState([]);
  const [hasBottomSheetDetail, setHasBottomSheetDetail] = useState(false);
  const [draftData, setDraftData] = useState(null);
  const [draftCar, setDraftCar] = useState({});
  const [hasModalChangeActiveCar, setHasModalChangeActiveCar] = useState(false);
  const [newActiveCar, setNewActiveCar] = useState({});
  const [loadingWorkshopLocation, setLoadingWorkshopLocation] = useState(false);
  const [workshopLocation, setWorkshopLocation] = useState({});

  const fetchCars = (q) => {
    if (q) {
      return api.get(`v2/gms/car/variant/search?query=${q}`).then((res) => {
        return res.data.data;
      });
    }
  };

  const fetchWorkshopLocation = async () => {
    setLoadingWorkshopLocation(true);
    await api
      .get(`v2/gms/workshop/${authorizedWorkshopsSlug}/location`)
      .then((res) => {
        setWorkshopLocation(res?.data?.data ?? {});
        setLoadingWorkshopLocation(false);
      })
      .catch((err) => {
        setWorkshopLocation({});
        setLoadingWorkshopLocation(false);
        console.log(err);
      });
  };

  const fetchWorkshopSchedule = async (selectedDate) => {
    if (selectedDate?.default) {
      await api
        .get(
          `v2/gms/workshop/${authorizedWorkshopsSlug}/schedule/${moment(selectedDate.default).format(
            'YYYY-MM-DD'
          )}/time-slot`
        )
        .then((res) => {
          setWsTimeSlot(res.data.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (!selectedDate) {
      await api
        .get(`v2/gms/workshop/${authorizedWorkshopsSlug}/schedule`)
        .then((res) => {
          setWsSchedule(res.data.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleError = (err) => {
    console.error(err);
    const message = err?.response?.data?.error?.message;
    setAlert(JSON.stringify(message), 'failed');
  };

  const handlePrintEstimation = () => {
    EstimationPricePdf.create(cart, totalAmount, fullname, userCar, workshopLocation);
  };

  const handleSaveDraft = async () => {
    setLoadingDraft(true);
    const bookingTime = time ? time + ':00' : '';

    const payload = {
      id: draftId ?? null,
      variant_car_id: userCar?.car_details?.id,
      draft_data: {
        customer_data: {
          ...dataCustomer,
          is_registered: !disabledSubmit,
          customer_car: {
            ...userCar,
          },
        },
        workshop_slug_name: authorizedWorkshopsSlug,
        booking_datetime: date?.default && `${moment(date?.default).format('yyyy-MM-DD ') + bookingTime}`,
        cart: cart,
        notes: '',
      },
    };

    try {
      const res = await api.post(`v2/gms/draft/cart`, payload);
      if (+res?.status === 200) {
        setAlert(`Sukses menyimpan draft order`, 'success');
        setTimeout(() => {
          history.push('/draft');
        }, 1000);
      }
    } catch (err) {
      handleError(err);
      setLoadingDraft(false);
    }
  };

  const handleCloseCreateOrder = () => {
    if (draftId) {
      history.push('/draft');
    } else {
      history.push('/order');
    }
  };

  const handleChangeStep = (step) => {
    setActiveStep(step);
  };

  const handleSelectedCar = (car, customer, onSave) => {
    const checkNewCarData = customer?.id ? false : true;
    const checkUncompletedCarData = isNull(car?.license_plate) || isNull(car?.year) || isNull(car?.transmission);
    const checkName = customer?.name ? false : true;
    const checkPhone = customer?.phone_number ? false : true;
    const tempCar = {
      ...car,
      customer_new_car: checkNewCarData,
      uncompleted_data_car: checkUncompletedCarData,
    };
    const tempCarDraft = { ...car, car_details: { id: car?.id } };
    const tempDataCustomer = {
      ...customer,
      uncompleted_name: checkName,
      uncompleted_phone: checkPhone,
      user_first_selected: !checkNewCarData,
    };
    dispatch(setActiveCar(onSave ? tempCar : tempCarDraft));
    onSave ? dispatch(setDataCustomer(tempDataCustomer)) : null;
    setHasChooseCarModal(false);
  };

  const handleOpenDatePicker = () => {
    setHasDatePickerModal(!hasDatePickerModal);
  };

  const handleChangeDate = (value) => {
    const selectedDate = {
      default: value,
      formatted: moment(value).format('dddd, D MMM YYYY'),
      timeFormatted: moment(value).format('HH:MM'),
    };
    setDate(selectedDate);
    setTime('');
    setHasDatePickerModal(!hasDatePickerModal);
    fetchWorkshopSchedule(selectedDate);
  };

  const handleSelectTime = (value) => {
    setTime(value);
  };

  const renderActiveStep = () => {
    if (activeStep == 0) return <OrderItemsForms />;
    if (activeStep == 1)
      return (
        <OrderCustomerForms
          draftData={draftData}
          isDraftPage={!!draftId}
          changeStep={() => handleChangeStep(0)}
          setDisabledSubmit={setDisabledSubmit}
        />
      );

    return null;
  };

  const checkValidationItemsForm = () => {
    if (+cart.length > 0 && time) {
      handleChangeStep(1);
    } else {
      if (cart.length < 1) setAlert(`Produk atau Paket Servis Belum Dipilih`, 'failed');
      if (time === '') setAlert(`Waktu Booking Belum Dipilih`, 'failed');
    }
  };

  const checkValidationCustomerForm = () => {
    if (!dataCustomer?.name) {
      setAlert('Silahkan masukan nama pelanggan', 'failed');
      return false;
    }
    if (!userCar?.license_plate) {
      setAlert('Silahkan masukan nomor polisi', 'failed');
      return false;
    }
    if (!userCar?.year) {
      setAlert('Silahkan masukan tahun kendaraan', 'failed');
      return false;
    }
    return true;
  };

  const checkValidationItemForm = () => {
    if (+cart.length > 0 && time) {
      handleChangeStep(1);
    } else {
      if (cart.length === 0) setAlert(`Produk atau Paket Servis Belum Dipilih`, 'failed');
      if (time === '') setAlert(`Waktu Booking Belum Dipilih`, 'failed');
    }
  };

  const submitOrder = async () => {
    setLoadingSubmit(true);
    let formattedPackage;
    let formattedItems;
    let formattedCustomItem;

    const filteredPackage = cart.filter((item) => item?.item_type === 'package');
    const packageDetailData = (item) => {
      return item?.packageDetail?.length > 0
        ? item?.packageDetail.map((childItem) => ({
            package_details: childItem?.value?.package_details_id,
            qty: childItem?.value?.qty,
          }))
        : [];
    };

    if (filteredPackage?.length > 0) {
      formattedPackage = filteredPackage.map((item) => ({
        package_id: item?.package_id,
        package_details:
          item?.packageDetail?.length > 0
            ? item?.packageDetail.map((childItem) => childItem?.value?.package_details_id)
            : [],
        package_detail_data: packageDetailData(item),
      }));
    }

    const filteredItem = cart.filter((item) => item?.item_type === 'product' || item?.item_type === 'service');

    if (filteredItem?.length > 0) {
      formattedItems = filteredItem.map((item) => {
        let payloadItem = {
          product_id: item?.product_id,
          qty: item?.qty,
          type: item?.item_type === 'product' ? 'part' : 'work',
        };

        if (item?.is_consignment) {
          assign(payloadItem, {
            is_guaranteed: item?.is_guaranteed,
          });
        }
        return payloadItem;
      });
    }

    const filteredCustomItem = cart.filter((item) => item?.formType === 'custom');

    if (filteredCustomItem?.length > 0) {
      formattedCustomItem = filteredCustomItem.map((item) => ({
        name: item?.name,
        type: item?.type === 'product' ? 'part' : 'work',
        brand_name: item?.brand,
        sell_price: item?.unit_price_before_tax?.formatMoney,
        cogs: item?.cogs?.formatMoney,
        shipping_cost: item?.shipping_cost?.formatMoney,
        qty: item?.qty?.formatMoney,
      }));
    }

    const payload = {
      draft_id: draftId || null,
      customer_id: dataCustomer?.id,
      customer_name: dataCustomer?.name,
      user_car_id: userCar?.id,
      mileage: userCar?.mileage !== '' ? Number(userCar?.mileage) : null,
      workshop_slug_name: authorizedWorkshopsSlug,
      booking_datetime: `${moment(date?.default).format('yyyy-MM-DD ') + time + ':00'}`,
      packages: formattedPackage?.length > 0 ? [...formattedPackage] : [],
      items: formattedItems?.length > 0 ? [...formattedItems] : [],
      custom_items: formattedCustomItem?.length > 0 ? [...formattedCustomItem] : [],
      notes: '',
    };
    try {
      const res = await api.post(`v2/gms/booking/`, payload);
      if (+res?.status === 201) {
        setAlert(`Berhasil membuat booking baru`, 'success');
        setTimeout(() => {
          history.push(`/order/detail/${res?.data?.data?.booking_code}`);
        }, 1000);
      }
    } catch (err) {
      handleError(err);
      setLoadingSubmit(false);
    }
  };

  const handleSubmitOrder = async () => {
    if (+activeStep === 0) {
      checkValidationItemForm();
    } else {
      const check = checkValidationCustomerForm();
      if (check) {
        setHasConfirmModal(!hasConfirmModal);
      }
    }
  };

  const setDataDraft = (res) => {
    const data = res?.data?.data;
    const dataCar = data?.car_detail;
    const items = data?.draft_data?.cart;
    const bookingDateTime = data?.draft_data?.booking_datetime;
    const customerCar = data?.draft_data?.customer_data?.customer_car;
    const customerData = data?.draft_data?.customer_data;

    let tempItems = [...items];
    setDraftData(data?.draft_data);
    setDraftCar(dataCar);
    setHasDatePickerModal(false);
    dispatch(setActiveCar(customerCar));
    dispatch(setDataCustomer(customerData));

    if (bookingDateTime) {
      const selectedDate = {
        default: bookingDateTime,
        formatted: moment(bookingDateTime).format('dddd, D MMM YYYY'),
        timeFormatted: moment(bookingDateTime).format('HH:MM'),
      };
      setDate(selectedDate);
      fetchWorkshopSchedule(selectedDate);
      setTime(moment(bookingDateTime).format('HH:mm'));
    }

    dispatch(setCart(tempItems));
  };

  const fetchDraftData = async (id) => {
    try {
      const res = await api.get(`v2/gms/draft/${id}/cart`);
      if (+res?.status === 200) {
        setDataDraft(res);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const checkAvailableTimeSlot = (is_available, actual_date, actual_time) => {
    const actual_datetime = moment(`${actual_date} ${actual_time}`);

    if (is_available || moment(actual_datetime).isAfter(moment().subtract(1, 'hours'))) {
      return false;
    }

    return true;
  };

  const handleChangeActiveCar = (value) => {
    const carValue = {
      ...value,
      value: `${value?.brand} - ${value?.id}`,
      label: `${value?.model} - ${value?.variant}`,
      customer_new_car: true,
      car_details: { id: value?.id },
    };
    setNewActiveCar(carValue);
    setHasModalChangeActiveCar(true);
  };

  const handleAddToCart = (item, formType) => {
    if (isEdit || item?.isEdit) {
      let items = [...cart];
      const replacedItemIdx = findIndex(items, (x) =>
        formType == 'custom' ? x.product_id == item?.product_id : x.product_id == item.product_id && x.name == item.name
      );
      let itemIndex = { ...items[replacedItemIdx] };
      itemIndex = item;
      if (item?.formType == 'custom') itemIndex.isEdit = false;
      items[replacedItemIdx] = itemIndex;
      dispatch(setCart(items));
      if (formType !== 'custom') {
        dispatch(setHasModalEditItems());
      } else {
        dispatch(setHasModalEditCustomItems());
      }
      dispatch(setActiveItems({}));
      dispatch(setIsEdit(false));
    } else {
      let existingItem = [...cart];
      let findItem = find(existingItem, (x) => x.product_id === item.product_id && x.name === item.name);
      let findIdx = findIndex(existingItem, (x) => x.product_id === item.product_id && x.name === item.name);

      if (!!findItem && item?.item_type !== 'package') {
        const sum = findItem.qty + item.qty;
        item.qty = sum;
        existingItem[findIdx] = item;
        dispatch(setCart(existingItem));
      } else {
        dispatch(setCart([...cart, item]));
      }
      if (formType == 'custom') {
        dispatch(setActiveItems({}));
      } else {
        dispatch(setHasModalEditItems());
        dispatch(setActiveItems({}));
      }
    }
    dispatch(setSaveLoading(false));
  };

  const handleChangeCar = () => {
    const tempDataCustomer = { ...dataCustomer, customer_car: { ...userCar } };
    dispatch(setActiveCar(newActiveCar));
    dispatch(setDataCustomer(tempDataCustomer));
    setHasModalChangeActiveCar(false);
    dispatch(setCart([]));
  };

  useEffect(() => {
    if (draftCar) {
      let tempCar = {
        car_model: {
          model_name: draftCar?.model,
          brand: {
            name: draftCar?.brand,
          },
        },
        id: draftCar?.id,
        variant: draftCar?.variant,
      };

      handleSelectedCar(tempCar, {}, false);
    }
  }, [draftCar]);

  useEffect(() => {
    if (draftId !== null) {
      setHasChooseCarModal(false);
      fetchDraftData(draftId);
    } else {
      setHasChooseCarModal(isEmpty(userCar));
    }
  }, [draftId]);

  useEffect(() => {
    const date = new Date();
    const selectedDate = {
      default: date,
      formatted: moment(date).format('dddd, D MMM YYYY'),
      timeFormatted: moment(date).format('HH:MM'),
    };
    setDate(selectedDate);
    fetchWorkshopSchedule(selectedDate);
    fetchWorkshopLocation();

    return () => {
      dispatch(setActiveCar(null));
      dispatch(setActiveItems({}));
      dispatch(setCart([]));
      dispatch(setActiveTab('items'));
      dispatch(setActiveCustomItems([]));
      dispatch(setIsEdit(false));
      dispatch(setDataCustomer({}));
    };
  }, []);

  const currentTime = moment().format('HH');

  useEffect(() => {
    if (time) {
      const selectedTime = time.split(':');
      if (selectedTime[0] < currentTime) {
        setTime('');
        setAlert('Waktu telah direset karena sudah melewati waktu aktual, pilih ulang waktu servis');
      }
    }
  }, [currentTime]);

  return (
    <>
      <WrapperNoFrame
        className="new-order"
        title="Order Baru"
        extras={
          <>
            <Button
              className="btn-save-draft text-center"
              onClick={handlePrintEstimation}
              style={{ border: 'none', minWidth: isDesktop ? 150 : 'unset' }}
              color="primary"
              disabled={loadingWorkshopLocation}
            >
              {loadingWorkshopLocation ? (
                <Spinner size="sm" style={{ margin: '0 auto' }} />
              ) : (
                <>
                  {isDesktop && <span>Cetak Estimasi</span>}
                  {!isDesktop && <span>Estimasi</span>}
                </>
              )}
            </Button>
            <Button
              className="btn-save-draft text-center"
              onClick={handleSaveDraft}
              style={{ border: 'none', minWidth: isDesktop ? 150 : 'unset' }}
              color="primary"
              disabled={loadingDraft}
            >
              {loadingDraft ? (
                <Spinner size="sm" style={{ margin: '0 auto' }} />
              ) : (
                <>
                  <img src={SaveDraftIcon} alt="" />
                  {isDesktop && <span>Simpan Draft</span>}
                </>
              )}
            </Button>
            <div
              className="btn-cancel-order"
              onClick={() => setHasCloseModal(true)}
              disabled={loadingDraft || loadingSubmit}
            >
              <img src={CloseIcon} alt="" />
            </div>
          </>
        }
      >
        <div className="new-order__body">
          <Col lg={9} md={12} sm={12} className="product-head p-0">
            <div className="stepper-wrapper">
              <div className="w-100">
                <Stepper
                  data={[{ title: 'Pilih Servis/Paket' }, { title: 'Data Pelanggan' }]}
                  activeStep={activeStep}
                />
              </div>
            </div>
            {renderActiveStep()}
            {/* <div style={{ marginBottom: 100 }} /> */}
          </Col>

          {isDesktop && (
            <Col lg={3} md={12} sm={12} className="order-summary">
              <OrderSummary activeStep={activeStep} />
              <div className="w-100 py-2">
                {activeStep === 0 && (
                  <>
                    <FormGroup>
                      <Label className="label-required fw-bold">Merek Kendaraan</Label>
                      <Row>
                        <SelectAsyncField
                          cacheOptions
                          defaultOptions={[]}
                          value={userCar?.car_details?.id ? userCar : null}
                          getOptionLabel={(e) => `${e?.model ?? e?.model} - ${e?.variant}` ?? ''}
                          getOptionValue={(e) => e?.id ?? e?.car_details?.id}
                          loadOptions={fetchCars}
                          menuPlacement="top"
                          onChange={handleChangeActiveCar}
                          placeholder="Pilih merek kendaraan anda"
                          disabled={loadingCar}
                          errorMessage={carError}
                          invalid={!!carError}
                        />
                      </Row>
                    </FormGroup>

                    <div
                      className="w-100"
                      style={{
                        width: 194,
                        height: 38,
                        display: 'flex',
                        alignItems: 'center',
                        border: '1px solid #cccccc',
                        borderRadius: 4,
                        padding: 8,
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                      onClick={handleOpenDatePicker}
                    >
                      <img src={DateIcon} alt="" loading="lazy" width={16} height={16} className="me-2" />
                      <span style={{ whiteSpace: 'nowrap', overflow: 'auto' }}>
                        {date?.formatted ?? moment().format('dddd, D MMM YYYY')}
                      </span>
                    </div>

                    <div className="mt-3" style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {map(wsTimeSlot, (item) => {
                        const timeDisabled = checkAvailableTimeSlot(item?.is_available, date?.formatted, item?.hour);

                        return (
                          <Col lg={4} md={4} sm={4} xs={4} className="px-1 py-1" key={item?.hour}>
                            <Button
                              color={item?.hour === time ? 'primary' : 'subtle'}
                              outline
                              block
                              size="sm"
                              className="time-button"
                              disabled={timeDisabled}
                              onClick={() => handleSelectTime(item?.hour)}
                            >
                              {item?.hour}
                            </Button>
                          </Col>
                        );
                      })}
                    </div>
                  </>
                )}
                {activeStep === 1 && (
                  <div className="d-grid">
                    <span className="fw-bold mb-3 fs-6">Jadwal Servis</span>
                    <span>Tanggal</span>
                    <span className="fw-bold mb-2">{date?.formatted}</span>
                    <span>Waktu</span>
                    <span className="fw-bold">{time ? time + ' WIB' : '-'}</span>
                  </div>
                )}

                <Divider />

                <div className="d-flex justify-content-between align-items-center mb-2">
                  <span className="price-title">Total Harga</span>
                  <span className="package-price text-end">{`Rp ${Helper.formatMoney(totalAmount)}`}</span>
                </div>

                {activeStep == 0 ? (
                  <Button
                    color="primary"
                    block
                    className="d-flex justify-content-between align-items-center"
                    onClick={() => checkValidationItemsForm()}
                  >
                    Lanjutkan <img src={ArrowRightIcon} alt="" />
                  </Button>
                ) : null}
                {activeStep == 1 ? (
                  <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Button
                        color="primary"
                        block
                        outline
                        onClick={() => {
                          // dispatch(setDataCustomer({}));
                          handleChangeStep(0);
                        }}
                        disabled={loadingSubmit}
                      >
                        Kembali
                      </Button>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Button
                        color="primary"
                        block
                        onClick={() => handleSubmitOrder()}
                        disabled={loadingSubmit || disabledSubmit}
                      >
                        {loadingSubmit ? <Spinner size="sm" color="primary" /> : 'Lanjutkan'}
                      </Button>
                    </Col>
                  </Row>
                ) : null}
              </div>
            </Col>
          )}
        </div>

        {!isDesktop && (
          <>
            <AbsoluteWrapper
              bottom
              className="orders position-fixed wrapper-fixed-bottom d-flex justify-content-between"
            >
              <div className="d-flex flex-column" onClick={() => setHasBottomSheetDetail(!hasBottomSheetDetail)}>
                <span>
                  {totalItems} item <img src={ChevronUpIcon} alt="" />
                </span>
                <span className="package-price">{`Rp ${Helper.formatMoney(totalAmount)}`}</span>
              </div>
              {activeStep == 0 ? (
                <Button
                  color="primary"
                  className="d-flex justify-content-between align-items-center"
                  onClick={() => checkValidationItemsForm()}
                  disabled={loadingSubmit}
                >
                  Lanjutkan <img src={ArrowRightIcon} alt="" />
                </Button>
              ) : null}
              {activeStep == 1 ? (
                <div>
                  <Button
                    color="primary"
                    outline
                    onClick={() => handleChangeStep(0)}
                    className="me-2"
                    disabled={loadingSubmit}
                  >
                    Kembali
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => handleSubmitOrder()}
                    disabled={loadingSubmit || disabledSubmit}
                  >
                    {loadingSubmit ? <Spinner size="sm" color="primary" /> : 'Lanjutkan'}
                  </Button>
                </div>
              ) : null}
            </AbsoluteWrapper>

            <BottomSheet
              open={hasBottomSheetDetail}
              snapPoints={({ maxHeight }) => [maxHeight]}
              onDismiss={() => setHasBottomSheetDetail(!hasBottomSheetDetail)}
              blocking={false}
              footer={
                <>
                  <FormGroup>
                    <Label className="label-required fw-bold">Merek Kendaraan</Label>
                    <Row>
                      <SelectAsyncField
                        cacheOptions
                        defaultOptions={[]}
                        value={userCar?.car_details?.id ? userCar : null}
                        getOptionLabel={(e) => `${e?.model ?? e?.model} - ${e?.variant}` ?? ''}
                        getOptionValue={(e) => e?.id ?? e?.car_details?.id}
                        loadOptions={fetchCars}
                        onChange={handleChangeActiveCar}
                        placeholder="Pilih merek kendaraan"
                        disabled={loadingCar}
                        errorMessage={carError}
                        invalid={!!carError}
                      />
                    </Row>
                  </FormGroup>

                  <div
                    className="w-100"
                    style={{
                      width: 194,
                      height: 38,
                      display: 'flex',
                      alignItems: 'center',
                      border: '1px solid #cccccc',
                      borderRadius: 4,
                      padding: 8,
                      position: 'relative',
                      cursor: 'pointer',
                    }}
                    onClick={handleOpenDatePicker}
                  >
                    <img src={DateIcon} alt="" loading="lazy" width={16} height={16} className="me-2" />
                    <span style={{ whiteSpace: 'nowrap', overflow: 'auto' }}>
                      {date.formatted ?? moment().format('dddd, D MMM YYYY')}
                    </span>
                  </div>

                  <div className="mt-3" style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {map(wsTimeSlot, (item) => {
                      const timeDisabled = checkAvailableTimeSlot(item?.is_available, date?.formatted, item?.hour);

                      return (
                        <Col lg={4} md={4} sm={4} xs={4} className="px-1 py-1">
                          <Button
                            color={item?.hour === time ? 'primary' : 'subtle'}
                            outline
                            block
                            size="sm"
                            className="time-button"
                            disabled={timeDisabled}
                            onClick={() => handleSelectTime(item?.hour)}
                          >
                            {item?.hour}
                          </Button>
                        </Col>
                      );
                    })}
                  </div>
                </>
              }
            >
              <div className="p-2 order-summary">
                <OrderSummary activeStep={activeStep} />
              </div>
            </BottomSheet>
          </>
        )}
      </WrapperNoFrame>

      <ChooseCarModal modal={hasChooseCarModal} onSave={handleSelectedCar} />

      <DatePickerModal
        isOpen={hasDatePickerModal}
        toggle={() => setHasDatePickerModal(!hasDatePickerModal)}
        onSubmit={handleChangeDate}
        value={date?.default}
      />

      <SelectProductModal
        isOpen={hasModalEditItems}
        toggle={() => dispatch(setHasModalEditItems())}
        item={activeItems}
        onSave={handleAddToCart}
      />

      <CustomFormModal handleAddToCart={handleAddToCart} activeStep={activeStep} />

      <ConfirmModal
        modal={hasCloseModal}
        icon={
          <div className="icon-bg-danger">
            <img src={AlertCircleIcon} alt="alert circle" />
          </div>
        }
        title="Keluar dari halaman order baru ?"
        description="Data yang telah kamu isi akan hilang. Simpan sebagai draft terlebih dahulu agar kamu dapat menyimpan data order."
        textSubmit="Keluar Halaman"
        textCancel="Kembali"
        onSubmit={() => handleCloseCreateOrder()}
        onCancel={() => setHasCloseModal(false)}
      />

      <ConfirmModal
        modal={hasConfirmModal}
        icon={
          <div className="icon-bg-warning">
            <img src={AlertTriangleIcon} alt="alert circle" />
          </div>
        }
        title="Sudah yakin ?"
        description="Pastikan kamu telah yakin dan telah melakukan pengecekan kembali pada rincian order servis."
        textSubmit="Buat Order"
        textCancel="Kembali"
        onSubmit={() => submitOrder()}
        onCancel={() => setHasConfirmModal(false)}
        loading={loadingSubmit}
      />

      <ConfirmModal
        modal={hasModalChangeActiveCar}
        icon={
          <div className="icon-bg-danger">
            <img src={AlertCircleIcon} alt="alert circle" />
          </div>
        }
        title="Yakin Ubah Kendaraan ?"
        description="Jika kamu mengubah data kendaraan, data order yang telah kamu masukkan akan diatur ulang."
        textSubmit="Ubah Data"
        textCancel="Kembali"
        onSubmit={() => handleChangeCar()}
        onCancel={() => setHasModalChangeActiveCar(false)}
        loading={loadingSubmit}
      />
    </>
  );
};

export default CreateOrder;
