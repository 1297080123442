import API from '@utils/API';

const api = new API('v2');
const urlPrefix = 'v2/gms/car-inspection/';

class CarInspectionService {
  async get(userCarId) {
    try {
      const response = await api.get(`${urlPrefix}${userCarId}/result`);
      return response;
    } catch (error) {
      return error.response;
    }
  }
  async getDetail(userCarId) {
    try {
      const response = await api.get(`${urlPrefix}${userCarId}/detail`);
      return response;
    } catch (error) {
      return error.response;
    }
  }
  async getPoint() {
    try {
      const response = await api.get(`${urlPrefix}point`);
      return response;
    } catch (error) {
      return error.response;
    }
  }
  async create(payload) {
    try {
      const response = await api.post(`${urlPrefix}`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new CarInspectionService();
