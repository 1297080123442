import { map } from 'lodash';
import React from 'react';
import { ScrollMenu as SM } from 'react-horizontal-scrolling-menu';

const ScrollMenu = (props) => {
  const { data, onClick, activeFilter, disabled } = props;

  const handleClick = (value) => {
    if (typeof onClick === 'function') onClick(value);
  };

  return (
    <SM>
      {map(data, (item, index) => {
        const isActive = activeFilter?.value == item?.value;

        return (
          <div
            key={item?.value ?? index}
            className="rounded-pill px-2 py-1 mx-1 cursor-pointer"
            style={{
              whiteSpace: 'nowrap',
              border: `1px solid ${isActive ? '#002eb4' : '#dee2e6'}`,
              color: disabled ? '#a1a3be' : '#303e67',
              backgroundColor: isActive ? '#D2DCF7' : 'unset',
            }}
            onClick={() => {
              !disabled && handleClick(item);
            }}
          >
            {item?.label ?? '-'}
          </div>
        );
      })}
    </SM>
  );
};

export default ScrollMenu;
