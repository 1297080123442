import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Badge from '../badge/Badge';
import Card from './Card';
import CardBody from './CardBody';
import Button from '../button/Button';
import Text from '../text/Text';
import { mapToCssModules, shortTag } from '../utils';

const propTypes = {
  title: PropTypes.string,
  tags: PropTypes.array,
  time: PropTypes.string,
  timeIcon: PropTypes.string,
  region: PropTypes.string,
  distance: PropTypes.string,
  rating: PropTypes.number,
  image: PropTypes.string,
  ratingImage: PropTypes.string,
  tierImage: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  totalReview: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  point: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imageClassName: PropTypes.string,
  pointIcon: PropTypes.string,
  ratingIcon: PropTypes.string,
  isShowOtopoints: PropTypes.bool,
  isShowDistance: PropTypes.bool,
  isShowTime: PropTypes.object,
  imgAlt: PropTypes.string,
  imgLazyLoad: PropTypes.bool,
  detailButtonTarget: PropTypes.func,
  openingTime: PropTypes.string,
  openingTimeIcon: PropTypes.string,
  originalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  volumePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isHaveDetailPrice: PropTypes.bool,
  isHavePrimaryPrice: PropTypes.bool,
  estimate: PropTypes.string,
  onCardClick: PropTypes.func,
  discountLabel: PropTypes.string,
  showFlagship: PropTypes.bool,
  flagshipIcon: PropTypes.string,
  flagshipText: PropTypes.string,
  flagshipDetailTarget: PropTypes.func,
  isFlagshipPlus: PropTypes.bool,
  isHasAvailableService: PropTypes.bool,
  availableServiceList: PropTypes.array,
  clickImageText: PropTypes.stirng,
  handleClickImage: PropTypes.func,
  dataAutomationTitle: PropTypes.string,
  dataAutomationTags: PropTypes.string,
  dataAutomationAddress: PropTypes.string,
};

const defaultProps = {
  distance: '0',
  region: '',
  tags: [],
  time: '',
  timeIcon: '',
  totalReview: '0',
  price: '0',
  point: '0',
  isShowOtopoints: true,
  isShowDistance: true,
  isShowTime: false,
  imgLazyLoad: true,
  originalPrice: '0',
  volumePrice: '0',
  isHaveDetailPrice: false,
  isHavePrimaryPrice: true,
  estimate: '',
};

const CardWorkshopExplore = (props) => {
  const {
    title,
    tags,
    time,
    timeIcon,
    region,
    distance,
    rating,
    image,
    ratingImage,
    tierImage,
    onClick,
    className,
    imageClassName,
    totalReview,
    price,
    point,
    pointIcon,
    ratingIcon,
    isShowOtopoints,
    isShowDistance,
    isShowTime,
    imgAlt,
    imgLazyLoad,
    detailButtonTarget,
    openingTime,
    openingTimeIcon,
    originalPrice,
    volumePrice,
    isHaveDetailPrice,
    isHavePrimaryPrice,
    estimate,
    onCardClick,
    discountLabel,
    showFlagship,
    flagshipIcon,
    flagshipText,
    flagshipDetailTarget,
    isFlagshipPlus,
    isHasAvailableService,
    availableServiceList,
    clickImageText,
    handleClickImage,
    dataAutomationTitle,
    dataAutomationTags,
    dataAutomationAddress,
    ...attributes
  } = props;

  const classes = mapToCssModules(classNames(className, 'card-workshops-explore'));

  const getPriority = (tier, linkTarget, type) => {
    if (tier && linkTarget) {
      if (type === tier) {
        return '';
      }
      return linkTarget;
    }
    if (type === tier) {
      return tier;
    }
    return linkTarget;
  };

  const getPricePriority = (primary, detail, type) => {
    if ((primary && detail) || (!primary && detail)) {
      if (type === 'primary') {
        return false; // primaryPrice
      }
      return true; // detailPrice
    }
    if (primary && !detail) {
      if (type === 'primary') {
        return true; // primaryPrice
      }
      return false; // detailPrice
    }
    return false;
  };

  return (
    <Card className={classes} {...attributes}>
      {getPriority(tierImage, detailButtonTarget, detailButtonTarget) && (
        <Button className="card-ws-explore-button rounded-pill floating-button" onClick={detailButtonTarget}>
          Detail
        </Button>
      )}

      {showFlagship && (
        <div onClick={flagshipDetailTarget} className={`flagship-ribbon pointer ${!isFlagshipPlus && '--plus'}`}>
          <img src={flagshipIcon} alt="falgship-icon" className="me-1" width={16} height={16} />
          <Text tag="span">{flagshipText}</Text>
        </div>
      )}
      <CardBody className="p-3 pb-2" onClick={onCardClick}>
        <div className="d-flex">
          <div className="left-content">
            <div className="img-wrapper ws-explore">
              {image && (
                <img
                  className="rounded-image"
                  src={image}
                  width={80}
                  height={80}
                  alt={imgAlt}
                  loading={imgLazyLoad ? 'lazy' : 'eager'}
                />
              )}
              {typeof handleClickImage === 'function' && (
                <div className="click-image" onClick={handleClickImage} role="presentation">
                  <span>{clickImageText}</span>
                </div>
              )}
              {rating > 0 && (
                <div className="rating-wrapper ws-explore">
                  <div className="d-flex justify-content-center">
                    <img src={ratingIcon} width={10} height={10} alt="icon-rating" loading="lazy" />
                    <span className="rating mx-1">{rating.toString()}</span>
                    <span className="total-review">({totalReview.toString()})</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-100 right-content">
            <div className="d-flex align-items-start justify-content-between mb-1">
              <h6
                color="body"
                className={`title ${tierImage || detailButtonTarget ? 'me-5' : 'me-1'}`}
                data-automation={dataAutomationTitle}
              >
                {title}
              </h6>
              {getPriority(tierImage, detailButtonTarget, tierImage) && (
                <img className="ms-1" src={tierImage} width={12} height={12} alt="icon-tier" loading="lazy" />
              )}
            </div>
            {openingTime && (
              <p className="time-ws-explore mb-1">
                <span className="me-1">
                  {openingTimeIcon ? (
                    <img
                      className=""
                      src={`${openingTimeIcon}`}
                      width="12"
                      height="12"
                      alt="icon-time"
                      loading="lazy"
                    />
                  ) : (
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.00016 0.833328C2.69898 0.833328 0.833496 2.69881 0.833496 5C0.833496 7.30118 2.69898 9.16666 5.00016 9.16666C7.30135 9.16666 9.16683 7.30118 9.16683 5C9.16683 2.69881 7.30135 0.833328 5.00016 0.833328ZM5.00016 2.49999C4.77004 2.49999 4.5835 2.68654 4.5835 2.91666V5C4.5835 5.10471 4.62212 5.20039 4.6859 5.27359L4.68523 5.27426L4.70125 5.29028L4.70988 5.29891L5.56082 6.14985C5.72354 6.31257 5.98737 6.31257 6.15009 6.14985C6.31281 5.98712 6.31281 5.7233 6.15009 5.56058L5.41683 4.82732V2.91666C5.41683 2.68654 5.23028 2.49999 5.00016 2.49999Z"
                        fill="#16A163"
                      />
                    </svg>
                  )}
                </span>
                {openingTime}
              </p>
            )}
            {(region || isShowDistance) && (
              // <div className="d-flex align-items-center mb-1">
              <div className="info d-flex flex-wrap align-items-center mb-1" data-automation={dataAutomationAddress}>
                <p className="address my-0">{region}</p>
                {isShowDistance && (
                  <>
                    <span className="mx-1">|</span>
                    <p className="distance">
                      {distance}
                      <span className={`estimate ${!estimate && 'd-none'}`}>({estimate})</span>
                    </p>
                  </>
                )}
                {isShowTime && (
                  <>
                    <span className="mx-1">|</span>
                    <p className="time">
                      <span>
                        {timeIcon !== '' ? (
                          <img className="" src={`${timeIcon}`} width="12" height="12" alt="icon-time" loading="lazy" />
                        ) : (
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.00016 0.833328C2.69898 0.833328 0.833496 2.69881 0.833496 5C0.833496 7.30118 2.69898 9.16666 5.00016 9.16666C7.30135 9.16666 9.16683 7.30118 9.16683 5C9.16683 2.69881 7.30135 0.833328 5.00016 0.833328ZM5.00016 2.49999C4.77004 2.49999 4.5835 2.68654 4.5835 2.91666V5C4.5835 5.10471 4.62212 5.20039 4.6859 5.27359L4.68523 5.27426L4.70125 5.29028L4.70988 5.29891L5.56082 6.14985C5.72354 6.31257 5.98737 6.31257 6.15009 6.14985C6.31281 5.98712 6.31281 5.7233 6.15009 5.56058L5.41683 4.82732V2.91666C5.41683 2.68654 5.23028 2.49999 5.00016 2.49999Z"
                              fill="#16A163"
                            />
                          </svg>
                        )}
                      </span>
                      {time}
                    </p>
                  </>
                )}
              </div>
              // </div>
            )}
            {tags.length > 0 && (
              <div className="mb-2" data-automation={dataAutomationTags}>
                {tags.map((item) => (
                  <Badge key={item} color="primary-light" fontColor="primary" className="me-1 tags-ws-explore">
                    {item?.length !== undefined && item?.length > 9 ? shortTag(item) : item}
                  </Badge>
                ))}
              </div>
            )}

            {getPricePriority(isHavePrimaryPrice, isHaveDetailPrice, 'detail') && (
              <div className="d-flex flex-column pricing-section mb-3">
                <div className="d-flex align-items-center">
                  <Text className="original-price text-strikethrough">{originalPrice}</Text>
                  {discountLabel && <Text className="discount-label text-xxs">{discountLabel}</Text>}
                </div>
                <Text color="secondary" className="text-sm fw-bold">
                  {price}
                </Text>
                <Text className="per-volume-price">{volumePrice}</Text>
              </div>
            )}

            {getPricePriority(isHavePrimaryPrice, isHaveDetailPrice, 'primary') && (
              <div className="pricing-section mb-3">
                <h6 className="label mb-1">Mulai dari</h6>
                <h4 className="price mb-0">{price}</h4>
              </div>
            )}

            {isShowOtopoints && (
              <div className="pricing-section my-2">
                <h6 className="label xs mb-1">Mulai dari</h6>
                <div className="d-flex align-items-center">
                  <img src={pointIcon} width={9} height={9} alt="icon-otopoints" loading="lazy" />
                  <h4 className="point ms-1 mb-0">
                    {point}
                    <span className="ms-1">Otopoints</span>
                  </h4>
                </div>
              </div>
            )}
          </div>
        </div>

        {isHasAvailableService && (
          <>
            <Text tag="span" className="available-services-title">
              Service yang tersedia
            </Text>
            <div className="available-services-wrapper">
              <div>
                <div className="list-wrapper">
                  {availableServiceList?.map((item, index) => (
                    <Card key={index}>
                      <CardBody>
                        {item?.image && <img src={item?.image} alt="" width={32} height={32} />}
                        <Text tag="span">{item?.text}</Text>
                      </CardBody>
                    </Card>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

CardWorkshopExplore.propTypes = propTypes;
CardWorkshopExplore.defaultProps = defaultProps;

export default CardWorkshopExplore;
