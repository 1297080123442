import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from '../utils';

const propTypes = {
  tag: tagPropType,
  className: PropTypes.string,
  cssModule: PropTypes.object,
};

const defaultProps = {
  tag: 'p',
};

const CardText = (props) => {
  const { className, cssModule, tag: Tag, ...attributes } = props;

  const classes = mapToCssModules(classNames(className, 'card-text'), cssModule);

  return <Tag {...attributes} className={classes} />;
};

CardText.propTypes = propTypes;
CardText.defaultProps = defaultProps;

export default CardText;
