import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from '../utils';

const propTypes = {
  tag: tagPropType,
  top: PropTypes.bool,
  bottom: PropTypes.bool,
  block: PropTypes.bool,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func]),
};

const defaultProps = {
  tag: 'div',
};

const AbsoluteWrapper = (props) => {
  const { top, bottom, block, className, cssModule, tag: Tag, innerRef, ...attributes } = props;

  const classes = mapToCssModules(
    classNames(
      'position-absolute',
      'p-3',
      top ? 'top-0' : false,
      bottom ? 'bottom-0' : false,
      block ? 'w-100' : false,
      className
    ),
    cssModule
  );

  return <Tag {...attributes} className={classes} ref={innerRef} />;
};

AbsoluteWrapper.propTypes = propTypes;
AbsoluteWrapper.defaultProps = defaultProps;

export default AbsoluteWrapper;
