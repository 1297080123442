import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import CustomForms from '@components/order/CustomForms';
import { getHasModalEditCustomItems } from '@reducers/Order';
import { useSelector } from 'react-redux';

const CustomFormModal = (props) => {
  const { handleAddToCart } = props;
  const hasModalEditCustomItems = useSelector(getHasModalEditCustomItems);

  return (
    <Modal isOpen={hasModalEditCustomItems} className="edit-custom-modal" centered size="lg">
      <ModalBody>
        <CustomForms onAddCart={handleAddToCart} source="modal-custom-form" />
      </ModalBody>
    </Modal>
  );
};

export default CustomFormModal;
