import React, { useMemo, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { customStyleInspectionTable } from '@utils/Constants';
import Skeleton from '@components/skeleton/Skeleton';
import CreateCarInspectionModal from '@components/modal/CreateCarInspectionModal';

const CarInspectionCustomerCard = (props) => {
  const { data, loading, bookingData } = props;
  const [modalCreateInspection, setModalInspection] = useState(false);
  const [source, setSource] = useState('');

  const conditionalRowStyles = [
    {
      when: () => true,
      style: {
        color: '#298FC2',
      },
    },
  ];

  const columns = useMemo(() => [
    {
      name: 'Tanggal Inspeksi Terakhir',
      selector: (row) => row?.inspection_date,
      cell: (row) => row?.inspection_date,
      sortField: 'inspection_date',
      sortable: true,
      minWidth: '140px',
    },
    {
      name: 'Input By',
      selector: (row) => row?.submitted_by,
      cell: (row) => row?.submitted_by,
      sortField: 'submitted_by',
      sortable: true,
      minWidth: '140px',
    },
  ]);

  const setCurrentInspection = () => {
    setModalInspection(false);
  };

  const handleSetModalInspectionClick = (value, source) => {
    setModalInspection(value);
    setSource(source);
  };

  return (
    <>
      <Row className="mb-4">
        <Col md={12}>
          <span className="font-weight-bold" style={{ fontSize: 16 }}>
            Hasil Inspeksi
          </span>
        </Col>
      </Row>

      {/* Display customer information */}
      {loading ? (
        <Skeleton height={15} width={'50%'} />
      ) : data ? (
        <Row>
          <Col md={6}>
            <DataTable
              clearSelectedRows={loading}
              persistTableHead
              highlightOnHover
              sortServer
              noHeader={true}
              progressPending={loading}
              data={[data]}
              className="table dt-responsive table-custom"
              customStyles={customStyleInspectionTable}
              columns={columns}
              onRowClicked={() => handleSetModalInspectionClick(!modalCreateInspection, 'detail')}
              conditionalRowStyles={conditionalRowStyles}
            />
          </Col>
          {bookingData?.booking?.booking_status?.slug != 'waiting' && (
            <Col md={6} className="d-flex justify-content-end align-items-end">
              <Button
                color="primary"
                size="sm"
                onClick={() => handleSetModalInspectionClick(!modalCreateInspection, '')}
              >
                Update Hasil Inspeksi
              </Button>
            </Col>
          )}
        </Row>
      ) : (
        <Row>
          <Col className="text-center">
            <p>
              Hasil inspeksi mobil ini belum ada nih.{' '}
              {bookingData?.booking?.booking_status?.slug != 'waiting' && <span>Yuk, segera tambahkan!</span>}
            </p>
            {bookingData?.booking?.booking_status?.slug != 'waiting' && (
              <Button
                color="primary"
                size="sm"
                onClick={() => handleSetModalInspectionClick(!modalCreateInspection, '')}
              >
                Tambahkan Hasil Inspeksi
              </Button>
            )}
          </Col>
        </Row>
      )}
      <CreateCarInspectionModal
        modal={modalCreateInspection}
        toggle={() => handleSetModalInspectionClick(!modalCreateInspection, '')}
        onSuccess={setCurrentInspection}
        bookingData={bookingData}
        source={source}
      />
    </>
  );
};

export default CarInspectionCustomerCard;
