import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import CustomModal from '@components/modal/CustomModal';
import CloseIcon from '@assets/icons/exit.svg';

const PromoCard = (props) => {
  const { data, handleChangeWorkshop } = props;
  const [openModal, setOpenModal] = useState(false);

  const handleClickCard = () => {
    if (handleChangeWorkshop) {
      handleChangeWorkshop(data);
    }
  };

  return (
    <>
      <Row className="promo-card px-3 py-1 mb-2 cursor-pointer" onClick={handleClickCard}>
        <Col>
          <Row className="text-bold">
            <span>{data?.name}</span>
          </Row>
          <Row>
            <span>{data?.code}</span>
          </Row>
          {data?.tnc && (
            <Row
              className="text-bold font-10 cursor-pointer"
              style={{ color: '#002EB4' }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal(true);
              }}
            >
              <span>Syarat & Ketentuan</span>
            </Row>
          )}
        </Col>
      </Row>
      <CustomModal
        modal={openModal}
        modalWidth={500}
        backdrop="static"
        toggle={() => setOpenModal(!openModal)}
        fullScreen="lg"
        size="lg"
      >
        <Row className="modal-title mb-2">
          <span>Syarat & Ketentuan</span>
          <span className="close-icon-box">
            <img
              className="close-icon mt-1 mr-2 cursor-pointer"
              src={CloseIcon}
              height="15px"
              onClick={() => setOpenModal(!openModal)}
            ></img>
          </span>
        </Row>
        <Row>
          <Col className="tnc-field" style={{ height: 500, overflow: 'auto', color: 'black' }}>
            <div
              style={{ color: 'black' }}
              className={data?.tnc?.includes('ol') ? '' : 'ml-2'}
              dangerouslySetInnerHTML={{
                __html: data?.tnc?.length > 0 ? data?.tnc : '-',
              }}
            ></div>
          </Col>
        </Row>
      </CustomModal>
    </>
  );
};

export default PromoCard;
