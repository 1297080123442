import React from 'react';
import { Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';

const DashboardCard = (props) => {
  const { title, total, type, className, onViewAll } = props;

  return (
    <>
      <Card className={className}>
        <CardBody>
          <CardTitle>{title}</CardTitle>
          <CardText tag="h3" className="text-center mb-3 text-white">
            {total}
          </CardText>
          {onViewAll && (
            <div className="d-flex justify-content-end">
              <Button className="btn-round white text-bold btn btn-black" color="black" onClick={onViewAll}>
                View All
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default DashboardCard;
