import React from 'react';
import Skeleton from '@components/skeleton/Skeleton';

const DetailField = ({ title, value, classValue, useSkeleton, badgeTitle, isRequired }) => (
  <dl className="row">
    <div className="col-sm-12 text-sm-left mb-2">
      <dt className={`font-weight-light ${isRequired && 'label-required'}`}>
        {title}
        {badgeTitle}
      </dt>
    </div>
    {useSkeleton ? <Skeleton height={15} width={'50%'} /> : <div className="col-sm-12 text-sm-left">{value}</div>}
  </dl>
);

export default DetailField;
