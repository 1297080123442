import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from '../utils';

const propTypes = {
  tag: tagPropType,
  image: PropTypes.string,
  title: PropTypes.string,
  fab: PropTypes.bool,
  imageWidth: PropTypes.number,
  active: PropTypes.bool,
  className: PropTypes.string,
  cssModule: PropTypes.object,
};

const defaultProps = {
  tag: 'div',
  imageWidth: 24,
};

const AppbarItem = (props) => {
  const { fab, title, image, active, imageWidth, className, cssModule, tag: Tag, ...attributes } = props;

  const classes = mapToCssModules(
    classNames('tab', active ? 'is-active' : false, fab ? 'fab' : false, className),
    cssModule
  );

  return (
    <Tag {...attributes} className={classes}>
      <img src={image} alt="" width={imageWidth} />
      <span>{title}</span>
    </Tag>
  );
};

AppbarItem.propTypes = propTypes;
AppbarItem.defaultProps = defaultProps;

export default AppbarItem;
