import React from 'react';

const Loading = () => {
  return (
    <div className="p-big">
      <div className="spinner-border text-primary" role="status"></div>
    </div>
  );
};

export default Loading;
