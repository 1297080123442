import React, { useEffect, useRef, useState } from 'react';
import Cookies from 'universal-cookie';
import ScrollMenu from '../scroll-menu/ScrollMenu';
import { Badge, Col, Row } from '../otoklix-elements';
import ProductItemsCard from '@components/card/ProductItemsCard';
import Empty from '@components/empty-state/Empty';
import CustomForms from '@components/order/CustomForms';
import { Spinner } from 'reactstrap';
import { ORDER_ITEM_CATEGORIES } from '@utils/Constants';
import useResponsive from '@utils/useResponsive';
import API from '@utils/API';
import { getActiveCar } from '@reducers/Car';
import {
  getCart,
  setCart,
  setHasModalEditItems,
  setActiveItems,
  getActiveTab,
  setActiveTab,
  getIsEdit,
  setIsEdit,
  setSaveLoading,
} from '@reducers/Order';
import { useDispatch, useSelector } from 'react-redux';
import { filter, find, findIndex, isEmpty, isUndefined, map, uniq, uniqBy } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import Select from 'react-select';
import Scrollbars from 'react-custom-scrollbars-2';
import SearchAsync from '@components/field/SearchAsync';

const OrderItemsForms = (props) => {
  const {} = props;
  const api = new API('v2');
  const { isDesktop } = useResponsive();
  const tracksRef = useRef(null);
  const dispatch = useDispatch();
  const userCar = useSelector(getActiveCar);
  const cart = useSelector(getCart);
  const activeTab = useSelector(getActiveTab);
  const isEdit = useSelector(getIsEdit);
  const cookies = new Cookies();
  const authorizedWorkshopsSlug = cookies.get('authorized_workshops_slug');

  const [productItems, setProductItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [carLoaded, setCarLoaded] = useState(false);

  const [pageIndex, setPageIndex] = useState(0);
  const [activeSearchQuery, setSearchQuery] = useState('');
  const [queryOptions, setQueryOptions] = useState([]);
  const [itemFilterCategories, setItemFilterCategories] = useState();
  const [categoriesFilter, setCategoriesFilter] = useState([]);
  const [selectedCategoriesFilter, setSelectedCategoriesFilter] = useState({
    id: null,
    value: 'all',
    label: 'Semua',
  });

  const [loadingSearchItems, setLoadingSearchItems] = useState(false);

  const getItems = async (
    q = '',
    itemCategory = itemFilterCategories,
    category = selectedCategoriesFilter,
    page = 1,
    source = ''
  ) => {
    if (q) setLoadingSearchItems(true);
    return await api
      .get(
        `v2/gms/item/${authorizedWorkshopsSlug}?item_type=${itemCategory?.value ?? 'all'}&variant_car_id=${
          userCar?.car_details?.id ?? ''
        }&limit=20&page=${page}&query=${q}&category=${category?.value}`
      )
      .then((res) => {
        if (q) setLoadingSearchItems(false);
        if (source == 'search-async') {
          setQueryOptions(res.data.data);
          setProductItems(res?.data?.data);
        }
        return res.data.data;
      })
      .catch(() => {
        if (q) setLoadingSearchItems(false);
      });
  };

  const getProductItemList = async (itemType, category, restart, page, query = '') => {
    await getItems(query, itemType, category, page)
      .then((res) => {
        if (res?.length < 20) setHasMoreItems(false);
        if (restart) {
          setProductItems(res);
        } else {
          setProductItems((prevState) => {
            const previousState = prevState ?? [];
            const response = res ?? [];
            const tempProductItems = [...previousState, ...response];
            return uniqBy(tempProductItems, (x) => x?.name);
          });
        }
        setLoading(false);
        setIsFetching(false);
        if (isUndefined(res)) {
          setHasMoreItems(false);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setHasMoreItems(false);
        setIsFetching(false);
      });
  };

  const fetchItems = (itemType, category, restart = false, page, query) => {
    let pages;

    try {
      setIsFetching(true);

      if (restart) {
        pages = 1;
        tracksRef?.current?.scrollToTop();
      } else {
        pages = page ?? pageIndex;
      }

      getProductItemList(itemType, category, restart, pages, query);
    } catch (error) {
      setIsFetching(false);
      setHasMoreItems(false);
      setLoading(false);
      console.log(error);
    }
  };

  const fetchCategories = async () => {
    await api
      .get(`v2/gms/item/${authorizedWorkshopsSlug}/categories?item_type=all`)
      .then((res) => {
        const dt = map(res?.data?.data, (item) => {
          return {
            id: item?.id,
            label: item?.name,
            value: item?.slug,
          };
        });

        setCategoriesFilter(dt);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadItems = () => {
    if (authorizedWorkshopsSlug) {
      setPageIndex(pageIndex + 1);
      setLoading(true);
      fetchItems(itemFilterCategories, selectedCategoriesFilter, false, pageIndex + 1, activeSearchQuery);
    } else {
      setHasMoreItems(false);
      setLoading(false);
    }
  };

  const onScrollUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 8; // padding before reach bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);

    if (t > 1 && !isFetching) {
      setLoading(false);
    }
  };

  const handleChangeTab = (tab) => {
    dispatch(setActiveTab(tab));
    dispatch(setIsEdit(false));
    dispatch(setActiveItems({}));
    if (tab == 'custom') {
      setSearchQuery('');
      setSelectedCategoriesFilter({
        id: null,
        value: 'all',
        label: 'Semua',
      });
      setDefaultFilterCategory();
    }
  };

  const handleChangeItemFilterCategories = (value) => {
    setItemFilterCategories(value);
    setProductItems([]);
    setPageIndex(1);
    setHasMoreItems(true);
    fetchItems(value, selectedCategoriesFilter, true, 1, activeSearchQuery);
  };

  const handleChangeCategories = (slug) => {
    setSelectedCategoriesFilter(slug);
    setProductItems([]);
    setPageIndex(1);
    setHasMoreItems(true);
    fetchItems(itemFilterCategories, slug, true, 1, activeSearchQuery);
  };

  const handleChangeQueriedItems = (value) => {
    setHasMoreItems(false);
    setProductItems([value]);
    setSearchQuery(value?.name);
  };

  const handleSelectPackage = async (item) => {
    const findItemIndex = findIndex(cart, (x) => {
      if (item?.item_type == 'package') return x.package_id == item?.package_id && x?.item_type === 'package';
      return x.product_id == item?.product_id && (x?.item_type === 'product' || x?.item_type === 'service');
    });
    const itemFound = findItemIndex > -1;
    const toUpdate = itemFound ? cart[findItemIndex] : item;

    await dispatch(setActiveItems(toUpdate));
    if (itemFound) dispatch(setIsEdit(true));
    dispatch(setHasModalEditItems());
  };

  const handleAddToCart = (item, formType) => {
    if (isEdit || item?.isEdit) {
      let items = [...cart];
      const replacedItemIdx = findIndex(items, (x) =>
        formType == 'custom' ? x.product_id == item?.product_id : x.product_id == item.product_id && x.name == item.name
      );
      let itemIndex = { ...items[replacedItemIdx] };
      itemIndex = item;
      if (item?.formType == 'custom') itemIndex.isEdit = false;
      items[replacedItemIdx] = itemIndex;
      dispatch(setCart(items));
      if (formType !== 'custom') dispatch(setHasModalEditItems());
      dispatch(setActiveItems({}));
      dispatch(setIsEdit(false));
    } else {
      let existingItem = [...cart];
      let findItem = find(existingItem, (x) => x.product_id === item.product_id && x.name === item.name);
      let findIdx = findIndex(existingItem, (x) => x.product_id === item.product_id && x.name === item.name);

      if (!!findItem && item?.item_type !== 'package') {
        const sum = findItem.qty + item.qty;
        item.qty = sum;
        existingItem[findIdx] = item;
        dispatch(setCart(existingItem));
      } else {
        dispatch(setCart([...cart, item]));
      }
      if (formType == 'custom') {
        dispatch(setActiveItems({}));
      } else {
        dispatch(setHasModalEditItems());
        dispatch(setActiveItems({}));
      }
    }
    dispatch(setSaveLoading(false));
  };

  const setDefaultFilterCategory = () => {
    const defaultItemFilterCategory = ORDER_ITEM_CATEGORIES[0];
    setItemFilterCategories(defaultItemFilterCategory);
  };

  const indicatorSeparatorStyle = {
    alignSelf: 'stretch',
    backgroundColor: '#fff',
    marginBottom: 8,
    marginTop: 8,
    width: 1,
  };

  const IndicatorSeparator = ({ innerProps }) => {
    return <span style={indicatorSeparatorStyle} {...innerProps} />;
  };

  useEffect(() => {
    if (typeof userCar == 'object' && !isEmpty(userCar)) setCarLoaded(true);
  }, [userCar]);

  useEffect(() => {
    if (activeTab == 'items') fetchItems(itemFilterCategories, selectedCategoriesFilter, true, 1, activeSearchQuery);
  }, [userCar?.variant, activeTab]);

  useEffect(() => {
    fetchCategories();
    setDefaultFilterCategory();
  }, []);

  return (
    <>
      <div className="product-tabs-head">
        <span
          onClick={() => handleChangeTab('items')}
          className={`product-tabs-head__item ${activeTab === 'items' ? 'active' : ''}`}
        >
          Pilih Paket Jasa/Sparepart
        </span>
        <span
          onClick={() => handleChangeTab('custom')}
          className={`product-tabs-head__item ${activeTab === 'custom' ? 'active' : ''}`}
        >
          Pesanan Custom
        </span>
      </div>

      <div style={{ padding: isDesktop ? '16px 16px 0px 16px' : '14px 14px 0px 14px' }}>
        {activeTab == 'items' && (
          <>
            <Row>
              <Col lg={6} md={12} sm={12} className={!isDesktop ? 'mb-2' : ''}>
                <span style={{ fontWeight: 600, fontSize: 20 }}>Pilih Produk atau Paket</span>
              </Col>
              <Col lg={3} md={12} sm={12} className={`py-0${!isDesktop ? ' mb-2' : ''}`}>
                <Select
                  placeholder="Pilih Kategori"
                  options={ORDER_ITEM_CATEGORIES}
                  value={itemFilterCategories}
                  onChange={handleChangeItemFilterCategories}
                  components={{ IndicatorSeparator }}
                  menuPortalTarget={document.body}
                  styles={{
                    menu: (baseStyles, state) => ({
                      ...baseStyles,
                      zIndex: 1,
                    }),
                  }}
                />
              </Col>
              <Col lg={3} md={12} sm={12} className={`py-0${!isDesktop ? ' mb-2' : ''}`}>
                <SearchAsync
                  loading={false}
                  options={queryOptions}
                  handleChangeQuery={(q) => {
                    if (q?.length > 0) {
                      getItems(q, itemFilterCategories, selectedCategoriesFilter, 1, 'search-async');
                    } else {
                      fetchItems(itemFilterCategories, selectedCategoriesFilter, true, 1, q);
                      setSearchQuery(q);
                    }
                  }}
                  onChange={handleChangeQueriedItems}
                  onBlurAction={() => setQueryOptions([])}
                />
              </Col>
            </Row>

            <Row className="mt-2">
              <Col>
                <ScrollMenu
                  data={categoriesFilter}
                  activeFilter={selectedCategoriesFilter}
                  onClick={handleChangeCategories}
                />
              </Col>
            </Row>

            {authorizedWorkshopsSlug === undefined ||
            (carLoaded && productItems?.length < 1 && !isFetching && !loading) ? (
              <div className="mt-3">
                <Empty type="empty" />
              </div>
            ) : null}

            {carLoaded ? (
              <Scrollbars
                className="mt-3"
                autoHide
                autoHeight
                autoHeightMin={isDesktop ? 'calc(100vh - 275px)' : '46vh'}
                universal={true}
                onUpdate={onScrollUpdate}
                ref={tracksRef}
              >
                <InfiniteScroll
                  threshold={500}
                  pageStart={0}
                  loadMore={handleLoadItems}
                  hasMore={hasMoreItems && !loading}
                  useWindow={false}
                >
                  {filter(productItems, (x) => x.category.slug == 'cuci')?.length > 0 && (
                    <div
                      className="mb-3"
                      style={
                        selectedCategoriesFilter?.value !== 'cuci'
                          ? {
                              backgroundColor: '#D2DCF7',
                              borderRadius: 8,
                              padding: '16px 0px',
                            }
                          : {}
                      }
                    >
                      {selectedCategoriesFilter?.value !== 'cuci' && (
                        <Badge color="primary" pill className="mx-2 mb-3">
                          Cuci
                        </Badge>
                      )}
                      <div className="cards">
                        {map(
                          filter(productItems, (x) => x.category.slug == 'cuci'),
                          (item, index) => {
                            return <ProductItemsCard item={item} key={index} onClick={handleSelectPackage} />;
                          }
                        )}
                      </div>
                    </div>
                  )}

                  <div className="cards">
                    {map(
                      filter(productItems, (x) => x.category.slug !== 'cuci'),
                      (item, index) => {
                        return <ProductItemsCard item={item} key={index} onClick={handleSelectPackage} />;
                      }
                    )}
                  </div>

                  {loading ? (
                    <div className="d-flex justify-content-center w-100">
                      <Spinner />
                    </div>
                  ) : null}
                </InfiniteScroll>
              </Scrollbars>
            ) : null}
          </>
        )}

        {activeTab == 'custom' && <CustomForms onAddCart={handleAddToCart} />}
      </div>
    </>
  );
};

export default OrderItemsForms;
