import React from 'react';
import { Badge } from 'reactstrap';

const StatusBadge = ({ status }) => {
  switch (status?.slug) {
    case 'waiting':
      return (
        <div className="badge-status">
          <Badge className="p-2 badge-warning">{status?.name}</Badge>
        </div>
      );
    case 'confirm':
      return (
        <div className="badge-status">
          <Badge className="p-2 badge-warning">{status?.name}</Badge>
        </div>
      );
    case 'finish':
      return (
        <div className="badge-status">
          <Badge className="p-2 badge-success">{status?.name}</Badge>
        </div>
      );
    case 'cancel':
      return (
        <div className="badge-status">
          <Badge className="p-2 badge-danger">{status?.name}</Badge>
        </div>
      );
    case 'payment':
      return (
        <div className="badge-status">
          <Badge className="p-2 badge-warning">{status?.name}</Badge>
        </div>
      );
    case 'review':
      return (
        <div className="badge-status">
          <Badge className="p-2 badge-info">{status?.name}</Badge>
        </div>
      );
    case 'on-process':
      return (
        <div className="badge-status">
          <Badge className="p-2 badge-secondary">{status?.name}</Badge>
        </div>
      );
    case 'service-done':
      return (
        <div className="badge-status">
          <Badge className="p-2 badge-success">{status?.name}</Badge>
        </div>
      );
    case 'complain':
      return (
        <div className="badge-status">
          <Badge className="p-2 badge-danger">{status?.name}</Badge>
        </div>
      );
    default:
      return (
        <div className="badge-status">
          <Badge className="p-2 badge-dark">{status?.name}</Badge>
        </div>
      );
  }
};

export default StatusBadge;
