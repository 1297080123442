import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from '../utils';

const propTypes = {
  tag: tagPropType,
  className: PropTypes.string,
  cssModule: PropTypes.object,
};

const defaultProps = {
  tag: 'div',
};

const CardTitle = (props) => {
  const { className, cssModule, tag: Tag, ...attributes } = props;
  const classes = mapToCssModules(classNames('card-title', className), cssModule);

  return <Tag {...attributes} className={classes} />;
};

CardTitle.propTypes = propTypes;
CardTitle.defaultProps = defaultProps;

export default CardTitle;
