import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from '../utils';

const propTypes = {
  tag: tagPropType,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  placeholder: PropTypes.string,
  leftImage: PropTypes.string,
  rightImage: PropTypes.string,
  bgColor: PropTypes.string,
  className: PropTypes.string,
  leftImageClass: PropTypes.string,
  rightImageClass: PropTypes.string,
  cssModule: PropTypes.object,
};

const defaultProps = {
  tag: 'div',
};

const CardVehicle = (props) => {
  const {
    tag: Tag,
    title,
    subtitle,
    placeholder,
    leftImage,
    rightImage,
    bgColor,
    className,
    leftImageClass,
    rightImageClass,
    cssModule,
    ...attributes
  } = props;

  const classes = mapToCssModules(
    classNames(className, 'card-vehicle', bgColor ? `bg-${bgColor}` : `bg-off-white`, 'rounded-pill'),
    cssModule
  );
  const leftImageClasses = mapToCssModules(classNames(leftImageClass, 'left-image'), cssModule);
  const rightImageClasses = mapToCssModules(classNames(rightImageClass, 'right-image'), cssModule);

  return (
    <Tag {...attributes} className={classes}>
      {leftImage && <img className={leftImageClasses} src={leftImage} alt="icon-car" loading="lazy" />}
      <div className="flex-grow-1 mx-3">
        {!title && !subtitle ? (
          <div className="empty">{placeholder}</div>
        ) : (
          <>
            <div className="title">{title}</div>
            <div className="subtitle">{subtitle}</div>
          </>
        )}
      </div>
      {rightImage && <img className={rightImageClasses} src={rightImage} alt="icon-left-nav" loading="lazy" />}
    </Tag>
  );
};

CardVehicle.propTypes = propTypes;
CardVehicle.defaultProps = defaultProps;

export default CardVehicle;
