import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  type: PropTypes.string,
  color: PropTypes.string,
};

const Star = (props) => {
  const { width, height, type = 'full', color = '#FFCC68' } = props;

  return (
    <div>
      {type === 'zero' ? (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.35016 2.02524C7.64964 1.54692 8.35068 1.54693 8.65016 2.02524L10.4002 4.82029C10.5056 4.98858 10.673 5.10935 10.8667 5.15686L14.0844 5.94591C14.635 6.08094 14.8517 6.74302 14.4861 7.17367L12.35 9.69016C12.2214 9.84168 12.1574 10.0371 12.1718 10.2347L12.4104 13.5174C12.4513 14.0792 11.8841 14.4884 11.3587 14.2762L8.28848 13.0365C8.10362 12.9618 7.89671 12.9618 7.71185 13.0365L4.64163 14.2762C4.11622 14.4884 3.54907 14.0792 3.58991 13.5174L3.82852 10.2347C3.84289 10.0371 3.77895 9.84168 3.65034 9.69016L1.51422 7.17367C1.14866 6.74302 1.3653 6.08094 1.91594 5.94591L5.13363 5.15686C5.32737 5.10935 5.49477 4.98858 5.60014 4.82029L7.35016 2.02524Z"
            fill="#EFF0F6"
          />
        </svg>
      ) : type === 'quarter' ? (
        <svg width={width} height={height} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.1225 0.481463C8.5268 -0.160489 9.4732 -0.160487 9.8775 0.481465L12.24 4.23271C12.3823 4.45857 12.6083 4.62066 12.8698 4.68442L17.2137 5.74342C17.9571 5.92464 18.2495 6.81322 17.756 7.3912L14.8723 10.7686C14.6986 10.9719 14.6123 11.2342 14.6317 11.4995L14.9538 15.9052C15.009 16.6592 14.2433 17.2083 13.534 16.9236L9.38923 15.2597C9.13966 15.1595 8.86034 15.1595 8.61077 15.2597L4.46598 16.9236C3.75668 17.2083 2.99103 16.6592 3.04615 15.9052L3.36829 11.4995C3.38768 11.2342 3.30137 10.9719 3.12773 10.7686L0.243974 7.3912C-0.249525 6.81322 0.0429301 5.92464 0.786301 5.74342L5.13019 4.68442C5.39173 4.62066 5.61772 4.45857 5.75996 4.23271L8.1225 0.481463Z"
            fill="#EFF0F6"
          />
          <path
            d="M7 15.9063V2.26378L5.75996 4.23271C5.61772 4.45857 5.39173 4.62066 5.13019 4.68442L0.786301 5.74342C0.0429301 5.92464 -0.249525 6.81322 0.243974 7.3912L3.12773 10.7686C3.30137 10.9719 3.38768 11.2342 3.36829 11.4995L3.04615 15.9052C2.99103 16.6592 3.75668 17.2083 4.46598 16.9236L7 15.9063Z"
            fill="#FFCF71"
          />
        </svg>
      ) : type === 'half' ? (
        <svg width={width} height={height} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.1225 0.481464C8.5268 -0.160489 9.4732 -0.160487 9.8775 0.481465L12.24 4.23271C12.3823 4.45857 12.6083 4.62066 12.8698 4.68442L17.2137 5.74342C17.9571 5.92464 18.2495 6.81322 17.756 7.3912L14.8723 10.7686C14.6986 10.9719 14.6123 11.2342 14.6317 11.4995L14.9538 15.9052C15.009 16.6592 14.2433 17.2083 13.534 16.9236L9.38923 15.2597C9.13966 15.1595 8.86034 15.1595 8.61077 15.2597L4.46598 16.9236C3.75668 17.2083 2.99103 16.6592 3.04615 15.9052L3.36829 11.4995C3.38768 11.2342 3.30137 10.9719 3.12773 10.7686L0.243974 7.3912C-0.249525 6.81322 0.0429301 5.92464 0.786301 5.74342L5.13019 4.68442C5.39173 4.62066 5.61772 4.45857 5.75996 4.23271L8.1225 0.481464Z"
            fill="#EFF0F7"
          />
          <path
            d="M9 0C8.66232 -3.31056e-08 8.32465 0.160488 8.1225 0.481464L5.75996 4.23271C5.61772 4.45857 5.39173 4.62066 5.13019 4.68442L0.786301 5.74342C0.0429301 5.92464 -0.249525 6.81322 0.243974 7.3912L3.12773 10.7686C3.30137 10.9719 3.38768 11.2342 3.36829 11.4995L3.04615 15.9052C2.99103 16.6592 3.75668 17.2083 4.46598 16.9236L8.61077 15.2597C8.73556 15.2096 8.86778 15.1846 9 15.1846V0Z"
            fill="#FFCF71"
          />
        </svg>
      ) : type === 'three-quarter' ? (
        <svg width={width} height={height} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.1225 0.481463C8.5268 -0.160489 9.4732 -0.160487 9.8775 0.481465L12.24 4.23271C12.3823 4.45857 12.6083 4.62066 12.8698 4.68442L17.2137 5.74342C17.9571 5.92464 18.2495 6.81322 17.756 7.3912L14.8723 10.7686C14.6986 10.9719 14.6123 11.2342 14.6317 11.4995L14.9538 15.9052C15.009 16.6592 14.2433 17.2083 13.534 16.9236L9.38923 15.2597C9.13966 15.1595 8.86034 15.1595 8.61077 15.2597L4.46598 16.9236C3.75668 17.2083 2.99103 16.6592 3.04615 15.9052L3.36829 11.4995C3.38768 11.2342 3.30137 10.9719 3.12773 10.7686L0.243974 7.3912C-0.249525 6.81322 0.0429301 5.92464 0.786301 5.74342L5.13019 4.68442C5.39173 4.62066 5.61772 4.45857 5.75996 4.23271L8.1225 0.481463Z"
            fill="#EFF0F7"
          />
          <path
            d="M9.8775 0.481465C9.4732 -0.160487 8.5268 -0.160489 8.1225 0.481463L5.75996 4.23271C5.61772 4.45857 5.39173 4.62066 5.13019 4.68442L0.786301 5.74342C0.0429301 5.92464 -0.249525 6.81322 0.243974 7.3912L3.12773 10.7686C3.30137 10.9719 3.38768 11.2342 3.36829 11.4995L3.04615 15.9052C2.99103 16.6592 3.75668 17.2083 4.46598 16.9236L8.61077 15.2597C8.86034 15.1595 9.13966 15.1595 9.38923 15.2597L11 15.9063V2.26378L9.8775 0.481465Z"
            fill="#FFCF71"
          />
        </svg>
      ) : (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.35016 2.02524C7.64964 1.54692 8.35068 1.54693 8.65016 2.02524L10.4002 4.82029C10.5056 4.98858 10.673 5.10935 10.8667 5.15686L14.0844 5.94591C14.635 6.08094 14.8517 6.74302 14.4861 7.17367L12.35 9.69016C12.2214 9.84168 12.1574 10.0371 12.1718 10.2347L12.4104 13.5174C12.4513 14.0792 11.8841 14.4884 11.3587 14.2762L8.28848 13.0365C8.10362 12.9618 7.89671 12.9618 7.71185 13.0365L4.64163 14.2762C4.11622 14.4884 3.54907 14.0792 3.58991 13.5174L3.82852 10.2347C3.84289 10.0371 3.77895 9.84168 3.65034 9.69016L1.51422 7.17367C1.14866 6.74302 1.3653 6.08094 1.91594 5.94591L5.13363 5.15686C5.32737 5.10935 5.49477 4.98858 5.60014 4.82029L7.35016 2.02524Z"
            fill={color}
          />
        </svg>
      )}
    </div>
  );
};

Star.propTypes = propTypes;
export default Star;
