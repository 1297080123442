import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from '../utils';
import Icon from '../icon/Icon';

const propTypes = {
  tag: tagPropType,
  title: PropTypes.string,
  subtitle: PropTypes.any,
  textRight: PropTypes.string,
  textRightClick: PropTypes.func,
  iconLeftImage: PropTypes.string,
  iconLeftBgColor: PropTypes.string,
  iconLeftImageWidth: PropTypes.number,
  iconLeftImageHeight: PropTypes.number,
  leftImage: PropTypes.string,
  leftImageWidth: PropTypes.number,
  leftImageHeight: PropTypes.number,
  titleClassName: PropTypes.string,
  subtitleClassName: PropTypes.string,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  dataAutomationRightLink: PropTypes.string,
};

const defaultProps = {
  tag: 'div',
  iconLeftImageWidth: 32,
  iconLeftImageHeight: 32,
  leftImageWidth: 60,
  leftImageHeight: 60,
};

const CardListMedia = (props) => {
  const {
    title,
    subtitle,
    textRight,
    textRightClick,
    iconLeftImage,
    iconLeftBgColor,
    iconLeftImageWidth,
    iconLeftImageHeight,
    leftImage,
    leftImageWidth,
    leftImageHeight,
    titleClassName,
    subtitleClassName,
    className,
    cssModule,
    tag: Tag,
    dataAutomationRightLink,
    ...attributes
  } = props;

  const classes = mapToCssModules(classNames('d-flex', 'card-list-media', className), cssModule);

  const titleClasses = mapToCssModules(classNames('title', titleClassName));

  const subtitleClasses = mapToCssModules(classNames('subtitle', 'mt-1', subtitleClassName));

  return (
    <Tag {...attributes} className={classes}>
      {iconLeftImage && (
        <div className="flex-shrink-0 me-3">
          <Icon
            imageWidth={iconLeftImageWidth}
            imageHeight={iconLeftImageHeight}
            bgIconColor={iconLeftBgColor}
            image={iconLeftImage}
          />
        </div>
      )}

      {leftImage && (
        <div className="flex-shrink-0 me-3">
          <img alt="" className="rounded left-image" width={leftImageWidth} height={leftImageHeight} src={leftImage} />
        </div>
      )}

      <div className="flex-grow-1">
        <div className={titleClasses}>{title}</div>

        {subtitle && <div className={subtitleClasses}>{subtitle}</div>}
      </div>

      {textRight && (
        <a
          href="#"
          onClick={textRightClick}
          className="ms-auto text-right-link"
          data-automation={dataAutomationRightLink || 'text_right_list_media'}
        >
          {textRight}
        </a>
      )}
    </Tag>
  );
};

CardListMedia.propTypes = propTypes;
CardListMedia.defaultProps = defaultProps;

export default CardListMedia;
