import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Spinner from '../spinner/Spinner';
import { mapToCssModules, tagPropType } from '../utils';

const propTypes = {
  active: PropTypes.bool,
  'aria-label': PropTypes.string,
  block: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  loading: PropTypes.bool,
  tag: tagPropType,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  onClick: PropTypes.func,
  size: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  close: PropTypes.bool,
};

const defaultProps = {
  color: 'primary',
  size: 'md',
  tag: 'button',
};

const Button = (props) => {
  let {
    active,
    'aria-label': ariaLabel,
    block,
    className,
    close,
    cssModule,
    color,
    outline,
    size,
    loading,
    tag: Tag,
    innerRef,
    ...attributes
  } = props;

  let spinnerColor = 'light';

  if (color === 'light') {
    spinnerColor = 'dark';
  }

  if (color === 'link') {
    spinnerColor = 'primary';
  }

  if (outline) {
    spinnerColor = color;
  }

  if (loading) {
    attributes.children = <Spinner className="align-middle" size="sm" color={spinnerColor} />;
  }

  const btnOutlineColor = `btn${outline ? '-outline' : ''}-${color}`;

  const classes = mapToCssModules(
    classNames(
      className,
      close && 'btn-close',
      close || 'btn',
      close || btnOutlineColor,
      size ? `btn-${size}` : false,
      block ? 'd-block w-100' : false,
      { active, disabled: props.disabled }
    ),
    cssModule
  );

  if (attributes.href && Tag === 'button') {
    Tag = 'a';
  }

  const defaultAriaLabel = close ? 'Close' : null;

  const onClick = (e) => {
    if (props.disabled) {
      e.preventDefault();
      return;
    }

    if (props.onClick) {
      return props.onClick(e);
    }
  };

  return (
    <Tag
      type={Tag === 'button' && attributes.onClick ? 'button' : undefined}
      {...attributes}
      className={classes}
      ref={innerRef}
      onClick={onClick}
      aria-label={ariaLabel || defaultAriaLabel}
    />
  );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
