import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from './Card';
import CardBody from './CardBody';
import Button from '../button/Button';
import Icon from '../icon/Icon';
import { mapToCssModules } from '../utils';

const propTypes = {
  title: PropTypes.string,
  timeArrival: PropTypes.string,
  distance: PropTypes.string,
  rating: PropTypes.number,
  buttonLabel: PropTypes.string,
  image: PropTypes.string,
  ratingImage: PropTypes.string,
  onButtonClick: PropTypes.func,
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
  imageClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  subtitleClassName: PropTypes.string,
  pricing: PropTypes.element,
  cssModule: PropTypes.object,
  dataAutomationCardWorkshopSlide: PropTypes.string,
  imgLazyLoad: PropTypes.bool,
};

const defaultProps = {
  timeArrival: '',
  distance: '',
  imgLazyLoad: true,
};

const CardWorkshopsSlide = (props) => {
  const {
    title,
    timeArrival,
    distance,
    rating,
    buttonLabel,
    image,
    ratingImage,
    onButtonClick,
    className,
    bodyClassName,
    imageClassName,
    titleClassName,
    subtitleClassName,
    pricing,
    cssModule,
    dataAutomationCardWorkshopSlide,
    imgLazyLoad,
    ...attributes
  } = props;

  const classes = mapToCssModules(classNames(className, 'card-workshops-slide'));
  const bodyClasses = mapToCssModules(classNames(bodyClassName, 'card-body'));
  const mainImageClasses = mapToCssModules(classNames('card-image', imageClassName), cssModule);
  const titleClasses = mapToCssModules(classNames('title', titleClassName), cssModule);
  const subtitleClasses = mapToCssModules(classNames('sub-title', subtitleClassName), cssModule);

  return (
    <Card
      className={classes}
      {...attributes}
      onClick={onButtonClick}
      data-automation={dataAutomationCardWorkshopSlide || 'card_workshop_slide'}
    >
      <CardBody className={bodyClasses}>
        {image && (
          <img
            className={mainImageClasses}
            src={image}
            width={86}
            height={86}
            alt="main-img"
            loading={imgLazyLoad ? 'lazy' : 'eager'}
          />
        )}
        <div className="d-flex flex-column">
          <span className={titleClasses}>{title}</span>
          <span className={subtitleClasses}>
            {distance} - {timeArrival}
          </span>
          <div className="pricing-section">{pricing}</div>
          <div className="container-bottom d-flex">
            {rating > 0 && (
              <Icon
                textRight
                imageWidth={14}
                imageHeight={13}
                title={rating}
                textClassName="text-rating"
                iconClassName="icon-rating"
                className="box-icon"
                image={ratingImage}
              />
            )}
            <Button className="btn-show-workshop" color="secondary" size="sm">
              {buttonLabel}
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

CardWorkshopsSlide.propTypes = propTypes;
CardWorkshopsSlide.defaultProps = defaultProps;

export default CardWorkshopsSlide;
