import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../button/Button';
import Card from './Card';
import CardImg from './CardImg';
import Badge from '../badge/Badge';
import Divider from '../divider/Divider';
import { mapToCssModules } from '../utils';
import Icon from '../icon/Icon';

const propTypes = {
  size: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  tagTitle: PropTypes.string,
  price: PropTypes.string,
  originalPrice: PropTypes.string,
  distanceIcon: PropTypes.string,
  distance: PropTypes.string,
  timeArrival: PropTypes.string,
  discount: PropTypes.string,
  active: PropTypes.bool,
  cssModule: PropTypes.object,
  className: PropTypes.string,
  cardBadgeClassName: PropTypes.string,
  ratingWrapperClassName: PropTypes.string,
  workshopIcon: PropTypes.string,
  workshopTitle: PropTypes.string,
  workshopSubtitle: PropTypes.string,
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ratingImage: PropTypes.string,
  buttonVehicleImage: PropTypes.string,
  buttonLocationImage: PropTypes.string,
  homescreen: PropTypes.bool,
  buttonLabel: PropTypes.string,
  showButton: PropTypes.bool,
  showButtonVehicle: PropTypes.bool,
  showButtonLocation: PropTypes.bool,
  buttonVehicleClick: PropTypes.func,
  buttonLocationClick: PropTypes.func,
  buttonClick: PropTypes.func,
  dataAutomationCardPackage: PropTypes.string,
  imgLazyLoad: PropTypes.bool,
  compatibleTip: PropTypes.bool,
  clickImageText: PropTypes.stirng,
  handleClickImage: PropTypes.func,
};

const defaultProps = {
  active: false,
  imgLazyLoad: true,
};

const CardPackage = (props) => {
  const {
    size,
    title,
    tagTitle,
    price,
    originalPrice,
    distanceIcon,
    distance,
    timeArrival,
    discount,
    image,
    active,
    workshopIcon,
    workshopTitle,
    workshopSubtitle,
    rating,
    ratingImage,
    buttonVehicleImage,
    buttonLocationImage,
    homescreen,
    buttonLabel,
    showButton,
    showButtonVehicle,
    showButtonLocation,
    buttonVehicleClick,
    buttonLocationClick,
    buttonClick,
    cssModule,
    className,
    cardBadgeClassName,
    ratingWrapperClassName,
    dataAutomationCardPackage,
    imgLazyLoad,
    compatibleTip,
    clickImageText,
    handleClickImage,
    ...attributes
  } = props;
  const classes = mapToCssModules(
    classNames(
      className,
      'card-package',
      size ? `size-${size}` : false,
      homescreen && 'home',
      active ? 'bg-white-lg' : false
    )
  );

  const cardBadgeClasses = mapToCssModules(
    classNames('badge-top-right', 'h-auto', 'position-absolute', 'rounded-pill', 'p-2', cardBadgeClassName),
    cssModule
  );

  const ratingWrapperClasses = mapToCssModules(
    classNames('d-flex', 'justify-content-between', 'align-items-center', ratingWrapperClassName),
    cssModule
  );

  return (
    <Card className={classes} {...attributes}>
      <CardImg top width="100%" height="140px" src={image} loading={imgLazyLoad ? 'lazy' : 'eager'} className="" />
      <Badge color="warning-light" fontColor="warning-dark" className={cardBadgeClasses}>
        {tagTitle}
      </Badge>
      {typeof handleClickImage === 'function' && (
        <div className="click-image" onClick={handleClickImage} role="presentation">
          <span>{clickImageText}</span>
        </div>
      )}
      {discount && (
        <div className="ribbon-discount">
          <svg width="27" height="36" viewBox="0 0 27 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27 36V13H0V36L13.5 32.8056L27 36Z" fill="#FFC727" />
            <path d="M0 0H17C22.5228 0 27 4.47715 27 10V13H0V0Z" fill="#FFC727" />
            <path d="M0 12H27V14H0V12Z" fill="#FFC727" />
          </svg>

          <span className="ribbon-text fw-semi-bold">
            <div>{discount}</div>
            <div className="text-white-lg">OFF</div>
          </span>
        </div>
      )}
      {compatibleTip && (
        <div className={`compatible-tip${discount ? '-downed' : ''}`}>
          <span>Sesuai Mobilmu</span>
        </div>
      )}
      {homescreen && (
        <div className="card-content p-2">
          <div className={ratingWrapperClasses}>
            <div className="d-flex justify-content-start align-items-center">
              {workshopIcon && <img className="workshop-icon me-2" src={workshopIcon} alt="icon-tier" loading="lazy" />}
              <div className="d-flex flex-column text-body">
                <span className="workshop-title text-title-active fw-medium">{workshopTitle}</span>
                <span className="workshop-subtitle">{workshopSubtitle}</span>
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center">
              <Icon
                title={rating}
                image={ratingImage}
                imageWidth={12}
                imageHeight={12}
                textRight
                textClassName="workshop-subtitle"
              />
            </div>
          </div>
          <Divider type="w-100" />
          <div className="title fw-semi-bold">{title}</div>
          <div className="price-section">
            {originalPrice && <div className="text-price-discount text-decoration-line-through">{originalPrice}</div>}
            <div className="text-price">{price}</div>
          </div>
        </div>
      )}
      {!homescreen && (
        <div className="card-content">
          <div>
            {workshopTitle && (
              <div className="d-flex mb-1">
                <div className="overline text-dark fw-bold">{workshopTitle}</div>
                {workshopIcon && (
                  <img className="mx-1" src={workshopIcon} width={12} height={12} alt="icon-tier" loading="lazy" />
                )}
              </div>
            )}
            <div className="title title-active fw-bold mb-1">{title}</div>
            {!showButtonLocation && distance && (
              <div className="d-flex mb-1">
                <Icon
                  image={distanceIcon}
                  imageWidth={14}
                  imageHeight={14}
                  textRight
                  title={distance}
                  size="sm"
                  className=""
                  textClassName="fw-bold text-primary"
                />
                <div className="ms-1 text-label icon-text">{timeArrival}</div>
              </div>
            )}
          </div>
          {showButtonLocation && (
            <div
              className={`mt-2 ${showButtonVehicle ? 'mb-2' : 'mb-1'}`}
              onClick={buttonLocationClick}
              role="button"
              aria-hidden="true"
            >
              <div className="d-flex align-items-center">
                {buttonLocationImage && (
                  <img
                    className="me-2"
                    src={buttonLocationImage}
                    width={14}
                    height={14}
                    alt="icon-location"
                    loading="lazy"
                  />
                )}
                <div className="">
                  <div className="btn-title text-primary">Pilih Lokasi</div>
                  <div className="btn-subtitle">Untuk melihat jarak</div>
                </div>
              </div>
            </div>
          )}
          {showButtonVehicle && (
            <div className="add-car mt-2" onClick={buttonVehicleClick} role="button" aria-hidden="true">
              <div className="d-flex align-items-center mx-2">
                {buttonVehicleImage && (
                  <img
                    className="me-2"
                    src={buttonVehicleImage}
                    width={20}
                    height={20}
                    alt="icon-add-car"
                    loading="lazy"
                  />
                )}
                <div className="">
                  <div className="btn-title text-secondary">Tambah Mobil</div>
                  <div className="btn-subtitle">Untuk melihat harga</div>
                </div>
              </div>
            </div>
          )}
          {!showButtonVehicle && (
            <div className="d-flex flex-column">
              <div className="price-section">
                {originalPrice && (
                  <div className="text-price-discount text-decoration-line-through">{originalPrice}</div>
                )}
                <div className="text-price">{price}</div>
              </div>
              {showButton && (
                <Button
                  block
                  color="secondary"
                  size="xs"
                  className="mt-1"
                  onClick={buttonClick}
                  data-automation={dataAutomationCardPackage || 'card_package'}
                >
                  {buttonLabel}
                </Button>
              )}
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

CardPackage.propTypes = propTypes;
CardPackage.defaultProps = defaultProps;
export default CardPackage;
