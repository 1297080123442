export const UserType = {
  general: 'General',
  ucd: 'Used Car Dealer',
};

export const HourOptions = [
  { value: '00:00', label: '00:00' },
  { value: '01:00', label: '01:00' },
  { value: '02:00', label: '02:00' },
  { value: '03:00', label: '03:00' },
  { value: '04:00', label: '04:00' },
  { value: '05:00', label: '05:00' },
  { value: '06:00', label: '06:00' },
  { value: '07:00', label: '07:00' },
  { value: '08:00', label: '08:00' },
  { value: '09:00', label: '09:00' },
  { value: '10:00', label: '10:00' },
  { value: '11:00', label: '11:00' },
  { value: '12:00', label: '12:00' },
  { value: '13:00', label: '13:00' },
  { value: '14:00', label: '14:00' },
  { value: '15:00', label: '15:00' },
  { value: '16:00', label: '16:00' },
  { value: '17:00', label: '17:00' },
  { value: '18:00', label: '18:00' },
  { value: '19:00', label: '19:00' },
  { value: '20:00', label: '20:00' },
  { value: '21:00', label: '21:00' },
  { value: '22:00', label: '22:00' },
  { value: '23:00', label: '23:00' },
];

export const CommonLabels = {
  at: 'Automatic',
  mt: 'Manual',
  ucd: 'Used Car Dealer',
  general: 'General',
  P: 'Bensin',
  D: 'Solar',
};

export const gmapConfig = () => {
  const config = {
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    fullscreenControl: false,
    gestureHandling: 'greedy',
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
    ],
  };
  return config;
};

export const monasLocation = {
  lat: -6.1753924,
  lng: 106.8271528,
};

export const GMAPS_API_KEY = 'AIzaSyC2pqT6mc7rU9DwBaNUIKnxLNucdahHzFU';

export const regexOnlyAllowSomeSymbols = "^[ A-Za-z0-9',./]*$";
export const regexOnlyAllowNumber = '^[0-9]*$';
export const regexOnlyAllowNumberCharacter = '^[A-Za-z0-9]*$';
export const regexOnlyAllowNumberCharacterSpace = '^[A-Za-z0-9 ]*$';
export const regexOnlyAllowCharacter = /^[a-zA-Z\s]*$/;
export const regexWithMinimumNumberCharacter = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

export const DISCOUNT_OPTIONS = { value: 5, label: '5%' };

export const DEFAULT_PRICE_AGREEMENT_VALUE = { value: 0, label: '0%' };

export const accountTypeOptions = [
  // delete soon
  { id: 1, value: 'b2c', label: 'B2C' },
  { id: 2, value: 'b2b', label: 'B2B' },
];

export const customerTypeOptions = [
  { id: 1, value: 'b2c', label: 'B2C' },
  { id: 2, value: 'b2b', label: 'B2B' },
];

export const userTypeOption = [
  { id: 1, value: 'General', label: 'General' },
  { id: 2, value: 'UCD', label: 'UCD' },
  { id: 3, value: 'O2O', label: 'O2O' },
];

export const flagTypeOption = [
  { id: 1, value: 'default', label: 'None' },
  { id: 2, value: 'suspect abuser', label: 'Suspect Abuser' },
];

export const statusOptions = [
  { id: 1, value: 'trial', label: 'Trial' },
  { id: 1, value: 'live', label: 'Live' },
];

export const INSPECTION_SCORE_LIST = [
  {
    value: 0,
    label: '0',
    description: 'Perlu ganti sebelum 1,000 km',
  },
  {
    value: 10,
    label: '10',
    description: 'Perlu ganti sebelum 1,000 km',
  },
  {
    value: 20,
    label: '20',
    description: 'Perlu ganti sebelum 1,000 km',
  },
  {
    value: 30,
    label: '30',
    description: 'Perlu ganti sebelum 10,000 km',
  },
  {
    value: 40,
    label: '40',
    description: 'Perlu ganti sebelum 10,000 km',
  },
  {
    value: 50,
    label: '50',
    description: 'Perlu servis atau perawatan',
  },
  {
    value: 60,
    label: '60',
    description: 'Perlu servis atau perawatan',
  },
  {
    value: 70,
    label: '70',
    description: 'Aman tapi butuh cek rutin',
  },
  {
    value: 80,
    label: '80',
    description: 'Aman tapi butuh cek rutin',
  },
  {
    value: 90,
    label: '90',
    description: 'Prima/baru',
  },
  {
    value: 100,
    label: '100',
    description: 'Prima/baru',
  },
];

export const CAR_TRANSMISSION_OPTIONS = [
  { label: 'Manual', value: 'mt' },
  { label: 'Automatic', value: 'at' },
];

export const BOOKING_CATEGORY_OPTIONS = [
  { label: 'Produk', value: 'product' },
  { label: 'Jasa', value: 'service' },
];

export const ProductTypeNameInit = [
  { label: 'Produk', value: 'part' },
  { label: 'Jasa', value: 'work' },
];

export const categoriesFilter = [
  { label: 'Semua', value: 'all' },
  { label: 'Oli', value: 'oli' },
  { label: 'Ban', value: 'ban' },
  { label: 'Cuci', value: 'cuci' },
  { label: 'Tune Up', value: 'tune-up' },
  { label: 'Aki', value: 'aki' },
  { label: 'Body Repair', value: 'body-repair' },
  { label: 'AC', value: 'ac' },
  { label: 'Detailing', value: 'detailing' },
  { label: 'Berkala', value: 'berkala' },
  { label: 'Eksterioir', value: 'eksterior' },
  { label: 'Kaki-kaki', value: 'kaki-kaki' },
  { label: 'Rem', value: 'rem' },
  { label: 'Mesin', value: 'mesin' },
];

export const ORDER_ITEM_CATEGORIES = [
  { value: '', label: 'Semua' },
  { value: 'package', label: 'Paket' },
  { value: 'product', label: 'Produk' },
  { value: 'service', label: 'Jasa' },
];

export const customStylesHeaderTable = {
  headCells: {
    style: {
      bacgroundColor: '#E6E6E4',
      color: '#1A1919',
    },
  },
};

export const statusFilter = [
  {
    value: '',
    label: 'Semua',
  },
  {
    value: 'waiting',
    label: 'Butuh Persetujuan',
  },
  {
    value: 'confirm',
    label: 'Menunggu Kedatangan',
  },
  {
    value: 'on-process',
    label: 'Service Berjalan',
  },
  {
    value: 'service-done',
    label: 'Service Selesai',
  },
  {
    value: 'finish',
    label: 'Order Selesai',
  },
  {
    value: 'cancel',
    label: 'Batal',
  },
];

export const LUMOS_REPORT_BY_WS_SLUG = [
  {
    slug: 'otoklix-simprug',
    name: 'Otoklix Simprug',
    url: 'https://lumos.otoklix.com/dashboard/202',
    embedUrl: 'http://lumos.otoklix.com/public/dashboard/ead7b59f-ac4d-4adb-b277-c45c9d830f67',
  },
  {
    slug: 'otoklix-ciputat',
    name: 'Otoklix Ciputat',
    url: 'https://lumos.otoklix.com/dashboard/215',
    embedUrl: 'http://lumos.otoklix.com/public/dashboard/266e30cd-0f7b-423b-b31a-21ce0d66ed4d',
  },
  {
    slug: 'otoklix-mangga-dua',
    name: 'Otoklix Mangga Dua',
    url: 'https://lumos.otoklix.com/dashboard/216',
    embedUrl: 'http://lumos.otoklix.com/public/dashboard/48612f43-4fa4-4833-a961-1d401e7f3613',
  },
  {
    slug: 'otoklix-plus-depok',
    name: 'Otoklix Depok',
    url: 'https://lumos.otoklix.com/dashboard/213',
    embedUrl: 'http://lumos.otoklix.com/public/dashboard/874a50c9-af7c-4a95-9cac-d52a161cb8d7',
  },
  {
    slug: 'otoklix-daan-mogot',
    name: 'Otoklix Daan Mogot',
    url: 'https://lumos.otoklix.com/dashboard/212',
    embedUrl: 'http://lumos.otoklix.com/public/dashboard/cbfdf70b-cf53-4c39-b75c-592233006a2d',
  },
  {
    slug: 'otoklix-jatimakmur',
    name: 'Otoklix Jatimakmur',
    url: 'https://lumos.otoklix.com/dashboard/214',
    embedUrl: 'http://lumos.otoklix.com/public/dashboard/ea94a551-ae3c-489f-be35-92ef99ac6791',
  },
  {
    slug: 'otoklix-palembang',
    name: 'Otoklix Palembang',
    url: 'https://lumos.otoklix.com/dashboard/211',
    embedUrl: 'http://lumos.otoklix.com/public/dashboard/3a502dde-d6de-4bcd-9c50-3cd965e7cbb5',
  },
];

export const customStyleInspectionTable = {
  rows: {
    style: {
      maxHeight: '40px', // override the row height
    },
  },
  headCells: {
    style: {
      maxHeight: '54px',
    },
  },
  // cells: {
  //   style: {
  //     minHeight: '40px', // override the row height
  //   },
  // },
};
