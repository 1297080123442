import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCart,
  setCart,
  setTotalAmount,
  setHasModalEditItems,
  setActiveItems,
  setIsEdit,
  getActiveTab,
  setActiveTab,
  getActiveItems,
  getIsEdit,
  setActiveCustomItems,
  getActiveCustomItems,
  setHasModalEditCustomItems,
} from '@reducers/Order';
import EditIcon from '@assets/icons/edit.svg';
import TrashIcon from '@assets/icons/trash.svg';
import Helper from '@helpers/Helper';
import { map } from 'lodash';
import { findIndex } from 'lodash';
import { find } from 'lodash';

const OrderSummary = (props) => {
  const { activeStep } = props;
  const dispatch = useDispatch();
  const cart = useSelector(getCart);
  const activeTab = useSelector(getActiveTab);
  const activeItems = useSelector(getActiveItems);
  const activeCustomItems = useSelector(getActiveCustomItems);
  const isEdit = useSelector(getIsEdit);

  const handleEditCartItem = async (index) => {
    let tempData = { ...cart[index] };
    if (tempData?.formType == 'custom') {
      // const findExistingItem = find(activeCustomItems, (x) => x.product_id == tempData.product_id);
      // if (!findExistingItem)
      //   await dispatch(setActiveCustomItems([...activeCustomItems, { ...tempData, isEdit: true }]));

      await dispatch(setActiveCustomItems([{ ...tempData, isEdit: true }]));
    } else {
      await dispatch(setActiveItems(tempData));
      dispatch(setIsEdit(true));
    }
    if (cart[index].formType == 'custom') {
      if (activeStep == 0) {
        dispatch(setActiveTab('custom'));
      } else {
        dispatch(setHasModalEditCustomItems(true));
      }
    } else {
      dispatch(setActiveTab('items'));
      dispatch(setHasModalEditItems());
    }
  };

  const handleDeleteCartItem = (index) => {
    let items = [...cart];
    if (items[index].formType == 'custom') {
      const findItems = findIndex(activeCustomItems, (x) => x.product_id == items[index].product_id);
      let tempData = [...activeCustomItems];
      tempData.splice(findItems, 1);
      dispatch(setActiveCustomItems([...tempData]));
    } else if (items[index].product_id && activeItems?.product_id) {
      dispatch(setActiveItems({}));
    }
    if (isEdit) dispatch(setIsEdit(false));
    items.splice(index, 1);
    dispatch(setCart(items));
  };

  return (
    <>
      <div className="item-wrapper">
        {cart?.length > 0 && <span className="fw-bold">Rincian Order</span>}
        {cart?.length > 0
          ? map(cart, (item, index) => {
              const price = item?.formType == 'custom' ? item?.price?.formatMoney : item?.calculatedPrice ?? 0;
              const qty = item?.formType == 'custom' ? item?.qty?.formatMoney : item?.qty ?? 0;

              return (
                <div className="items" key={index}>
                  <div className="d-flex">
                    <span className="qty">{`${qty}x`}</span>
                    <div className="d-flex flex-column">
                      <span className="name">{item?.name}</span>
                      <span className="price">{`Rp ${Helper.formatMoney(item?.is_guaranteed ? 0 : price)}`}</span>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <img
                      src={EditIcon}
                      alt=""
                      width={16}
                      height={16}
                      className="cursor-pointer mb-2"
                      onClick={() => handleEditCartItem(index)}
                    />
                    <img
                      src={TrashIcon}
                      alt=""
                      width={16}
                      height={16}
                      className="cursor-pointer"
                      onClick={() => handleDeleteCartItem(index)}
                    />
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </>
  );
};

export default OrderSummary;
