import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Badge from '../badge/Badge';
import Card from './Card';
import { mapToCssModules, shortTag } from '../utils';
import CardImg from './CardImg';
import CardBody from './CardBody';
import Text from '../text/Text';
import Star from '../rating/Star';
import RatingPrice from '../rating/RatingPrice';

const propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  image: PropTypes.string,
  imgLazyLoad: PropTypes.bool,
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reviewTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  address: PropTypes.string,
  distance: PropTypes.string,
  eta: PropTypes.string,
  priceLevel: PropTypes.number,
  tags: PropTypes.array,
  onCardClick: PropTypes.func,
  dataAutomationRating: PropTypes.string,
  dataAutomationReview: PropTypes.string,
  dataAutomationName: PropTypes.string,
  dataAutomationAddress: PropTypes.string,
  dataAutomationDistance: PropTypes.string,
  showFlagship: PropTypes.bool,
  flagshipIcon: PropTypes.string,
  flagshipDetailTarget: PropTypes.func,
  isFlagshipPlus: PropTypes.bool,
  dataAutomationFlagship: PropTypes.string,
  dataAutomationTags: PropTypes.string,
};

const defaultProps = {
  imgLazyLoad: true,
  priceLevel: 0,
  tags: [],
  dataAutomationRating: 'recommend_workshop_rating',
  dataAutomationReview: 'recommend_workshop_review',
  dataAutomationName: 'recommend_workshop_name',
  dataAutomationAddress: 'recommend_workshop_address',
  dataAutomationDistance: 'recommend_workshop_distance',
};

const CardRecommendWorkshop = (props) => {
  const {
    title,
    className,
    cssModule,
    image,
    imgLazyLoad,
    rating,
    reviewTotal,
    address,
    distance,
    eta,
    priceLevel,
    tags,
    onCardClick,
    dataAutomationRating,
    dataAutomationReview,
    dataAutomationName,
    dataAutomationAddress,
    dataAutomationDistance,
    showFlagship,
    flagshipIcon,
    flagshipDetailTarget,
    isFlagshipPlus,
    dataAutomationFlagship,
    dataAutomationTags,
    ...attributes
  } = props;

  const classes = mapToCssModules(classNames(className, 'card-recommend-workshop'), cssModule);

  return (
    <Card className={classes} {...attributes}>
      {showFlagship && (
        <div
          onClick={flagshipDetailTarget}
          className={`flagship-ribbon pointer ${!isFlagshipPlus && '--plus'}`}
          data-automation={dataAutomationFlagship}
        >
          <img src={flagshipIcon} alt="falgship-icon" />
        </div>
      )}

      <div onClick={onCardClick}>
        <CardImg top width="100%" height="110px" src={image} alt={title} loading={imgLazyLoad ? 'lazy' : 'eager'} />
        <div className="rating-label d-flex align-items-center justify-content-center">
          <div className="mb-1">
            <Star height={12} width={12} color="#FF6400" />
          </div>
          <Text color="secondary" className="text-xs mx-1" data-automation={dataAutomationRating}>
            {rating}
          </Text>
          <Text color="label" className="text-xxs" data-automation={dataAutomationReview}>
            ({reviewTotal})
          </Text>
        </div>

        <CardBody>
          <Text weight="bold" className="text-md workshop-title" data-automation={dataAutomationName}>
            {title}
          </Text>
          <div className="d-flex text-xxs mt-1 workshop-attr">
            <Text
              color="label"
              className={`text-truncate workshop-address ${!eta ? '--full' : ''}`}
              data-automation={dataAutomationAddress}
            >
              {address}
            </Text>
            <Text color="placeholder"> | </Text>
            <Text color="title-active" className="workshop-distance" data-automation={dataAutomationDistance}>
              {distance}
            </Text>
            {eta && <Text className="eta">{eta}</Text>}
            {priceLevel && (
              <>
                <Text color="placeholder"> | </Text>
                <RatingPrice rating={priceLevel} />
              </>
            )}
          </div>
          {tags?.length > 0 && (
            <div className="d-flex overflow-auto tag-ws" data-automation={dataAutomationTags}>
              {tags.map((item) => (
                <Badge key={item} color="primary-light" fontColor="primary" className="me-1 mt-1 tags-ws-recommend">
                  {item?.length !== undefined && item?.length > 9 ? shortTag(item) : item}
                </Badge>
              ))}
            </div>
          )}
        </CardBody>
      </div>
    </Card>
  );
};

CardRecommendWorkshop.propTypes = propTypes;
CardRecommendWorkshop.defaultProps = defaultProps;

export default CardRecommendWorkshop;
