import React from 'react';

const Stepper = (props) => {
  const { data, activeStep } = props;

  return (
    <div className="d-flex justify-content-center align-items-center Stepper">
      {data?.map((item, index) => (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <div
              style={{
                padding: '5px 12px',
                backgroundColor: index <= activeStep ? '#002eb4' : '#f6f6f4',
                color: index <= activeStep ? '#fff' : '#000',
                borderRadius: '50%',
                textAlign: 'center',
                marginBottom: 8,
              }}
            >
              {index + 1}
            </div>
            <span className={index <= activeStep ? 'active' : ''}>{item?.title}</span>
          </div>
          {index < data?.length - 1 && <hr className={activeStep === index + 1 ? 'active' : ''} />}
        </>
      ))}
    </div>
  );
};

export default Stepper;
