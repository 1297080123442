import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import Img500 from '@assets/icons/error/internalservererror.svg';
import ImgEmpty from '@assets/icons/error/empty.svg';
import ImgEmptyCustomOrder from '@assets/icons/error/empty-custom-order.svg';
import ImgNoCon from '@assets/icons/error/no-connection.svg';
import { Button } from '../otoklix-elements';

const Empty = (props) => {
  const { type = '', withReloadButton = false } = props;

  const renderType = () => {
    switch (type) {
      case '500':
        return {
          img: Img500,
          title: 'Ada yang salah nih',
          description: 'Maaf, halaman ini sedang dalam perbaikan. Silakan akses beberapa saat lagi.',
        };
      case 'empty':
        return {
          img: ImgEmpty,
          title: 'Hasil tidak ditemukan',
          description: 'Coba periksa lagi kata kunci yang Anda masukkan atau tambah SKU secara custom',
        };
      case 'nocon':
        return {
          img: ImgNoCon,
          title: 'OOPS!',
          description: 'Tidak ada koneksi internet, coba cek kembali koneksi internet kamu.',
        };
      case 'empty-custom-order':
        return {
          img: ImgEmptyCustomOrder,
          title: 'Belum ada pesanan kustom',
          description: 'Jika ada pesanan kustom, Anda bisa klik +Tambah Item',
        };
      default:
        break;
    }
  };

  const errorRenderer = renderType();

  return (
    <Card>
      <CardBody>
        <Row className="w-100">
          <Col className="d-flex justify-content-center align-items-center flex-column">
            <img src={errorRenderer?.img} alt="" width={128} height={128} className="mb-2" />
            <h6 className="fw-bold mb-2">{errorRenderer?.title}</h6>
            <span>{errorRenderer?.description}</span>
            {withReloadButton && (
              <Button color="primary" outline onClick={() => window.location.reload()}>
                Muat Ulang Halaman
              </Button>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Empty;
