import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Text from '../text/Text';
import { mapToCssModules, tagPropType } from '../utils';

const propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  imgAlt: PropTypes.string,
  rightIcon: PropTypes.string,
  imgHeight: PropTypes.number,
  imgWidth: PropTypes.number,
  textClass: PropTypes.string,
  onClick: PropTypes.func,
  tag: tagPropType,
  labelTag: tagPropType,
};

const defaultProps = {
  color: 'primary',
  label: 'Related search',
  imgAlt: 'right-icon',
  imgHeight: 14,
  imgWidth: 14,
  textClass: 'text-xs',
  tag: 'a',
  labelTag: 'h3',
};

const AccordionButton = (props) => {
  const {
    tag: Tag,
    color,
    label,
    className,
    imgAlt,
    rightIcon,
    imgHeight,
    imgWidth,
    textClass,
    labelTag,
    ...atrributes
  } = props;

  const classes = mapToCssModules(
    classNames(className, 'accordion-button-list pointer d-flex justify-content-between align-items-center')
  );

  return (
    <Tag className={classes} {...atrributes}>
      <Text tag={labelTag} className={textClass} color={color}>
        {label}
      </Text>

      <img src={rightIcon} width={imgWidth} height={imgHeight} alt={imgAlt} />
    </Tag>
  );
};

AccordionButton.propTypes = propTypes;
AccordionButton.defaultProps = defaultProps;

export default AccordionButton;
