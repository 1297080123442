import React from 'react';
import { Badge } from 'reactstrap';

const SettlementBadge = ({ status, statusName }) => {
  switch (status) {
    case 'pending':
      return (
        <div className="badge-settlement">
          <Badge className="p-2 badge-warning">{statusName}</Badge>
        </div>
      );
    case 'settlement':
      return (
        <div className="badge-settlement">
          <Badge className="p-2 badge-secondary">{statusName}</Badge>
        </div>
      );
    case 'expire':
      return (
        <div className="badge-settlement">
          <Badge className="p-2 badge-danger">{statusName}</Badge>
        </div>
      );
    case 'cancel':
      return (
        <div className="badge-settlement">
          <Badge className="p-2 badge-warning">{statusName}</Badge>
        </div>
      );
    case 'capture':
      return (
        <div className="badge-settlement">
          <Badge className="p-2 badge-info">{statusName}</Badge>
        </div>
      );
    case 'deny':
      return (
        <div className="badge-settlement">
          <Badge className="p-2 badge-warning">{statusName}</Badge>
        </div>
      );
    default:
      return (
        <div className="badge-settlement">
          <Badge className="p-2 badge-dark">{statusName}</Badge>
        </div>
      );
  }
};

export default SettlementBadge;
