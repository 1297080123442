import React from 'react';
import ReactDOM from 'react-dom';
import Popup from 'react-popup';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from '@store/configureStore';
import './styles';
import { AlertProvider } from './contexts/AlertContext';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Popup closeBtn={true} closeHtml={null} defaultOk="Ok" />
        <AlertProvider>
          <App />
        </AlertProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
