import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules } from '../utils';

const propTypes = {
  textColor: PropTypes.string,
  color: PropTypes.string,
  borderColor: PropTypes.string,
  image: PropTypes.string,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  onClose: PropTypes.func,
  showClose: PropTypes.bool,
  floating: PropTypes.bool,
};

const defaultProps = {
  textColor: 'black-lg',
  color: 'secondary-light',
  borderColor: 'secondary',
  showClose: true,
  floating: false,
};

const Alert = (props) => {
  let {
    color,
    textColor,
    borderColor,
    children,
    className,
    image,
    cssModule,
    innerRef,
    showClose,
    floating,
    ...attributes
  } = props;

  const classes = mapToCssModules(
    classNames(
      className,
      'box-alert',
      `bg-${color}`,
      `border-${borderColor}`,
      textColor ? `text-${textColor}` : false,
      floating ? '--floating' : ''
    ),
    cssModule
  );

  const handleClose = (e) => {
    if (props.onClose) {
      return props.onClose(e);
    }
  };

  return (
    <div className={classes} ref={innerRef} {...attributes}>
      {image && <img className="image" src={image} alt="" />}
      <div className="text">{children}</div>
      {showClose && (
        <div className="close" onClick={handleClose} aria-hidden="true">
          <svg
            className={`fill-${borderColor}`}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="current"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.0445 12.9783C12.6416 13.4307 11.9666 13.4536 11.5368 13.0295L3.00347 4.60844C2.5737 4.18432 2.55193 3.47377 2.95484 3.02138C3.35775 2.56899 4.03277 2.54607 4.46255 2.97018L12.9959 11.3912C13.4256 11.8154 13.4474 12.5259 13.0445 12.9783Z"
              fill="current"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.9783 2.955C13.4307 3.35791 13.4536 4.03294 13.0295 4.46271L4.60844 12.996C4.18432 13.4258 3.47377 13.4476 3.02138 13.0447C2.56899 12.6418 2.54607 11.9667 2.97018 11.537L11.3912 3.00364C11.8154 2.57386 12.5259 2.55209 12.9783 2.955Z"
              fill="current"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;

export default Alert;
