import React from 'react';
import Helper from '@helpers/Helper';
import { Badge } from '../otoklix-elements';
import DefaultImage from '@assets/images/default-image.jpeg';

const ProductItemsCard = (props) => {
  const { item, onClick } = props;

  const handleClick = () => {
    if (typeof onClick == 'function') onClick(item);
  };

  const isSellable = () => {
    if (item?.item_type == 'service' || item?.item_type == 'package') return item?.is_sellable;
    if (item?.item_type == 'product') return item?.is_sellable && item?.sellable_qty > 0;
  };

  return (
    <div className={`card-product cursor-pointer`} onClick={handleClick}>
      <div className="img-wrapper">
        {item?.is_compatible && <div className="compatible">Sesuai Kendaraan Pilihan</div>}

        <img
          className={`img-display`}
          src={item?.image_link ? item?.image_link : DefaultImage}
          alt=""
          width={150}
          height={154}
          loading="lazy"
        />

        {item?.category?.slug == 'oli' && item?.car_oil_qty > 0 ? (
          <Badge color="line" pill className="item-badge-oil">
            {`${item?.car_oil_qty} Liter`}
          </Badge>
        ) : null}
      </div>
      <div className="text-center">
        <span className="item-name">{Helper.shortenString(item?.name, 50) ?? 'N/A'}</span>
      </div>
    </div>
  );
};

export default ProductItemsCard;
