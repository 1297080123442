import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from './Card';
import CardImg from './CardImg';
import Badge from '../badge/Badge';
import { mapToCssModules } from '../utils';

const propTypes = {
  size: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  tagTitle: PropTypes.string,
  price: PropTypes.string,
  originalPrice: PropTypes.string,
  distance: PropTypes.string,
  timeArrival: PropTypes.string,
  discount: PropTypes.string,
  active: PropTypes.bool,
  cssModule: PropTypes.object,
  className: PropTypes.string,
  cardBadgeClassName: PropTypes.string,
  ratingWrapperClassName: PropTypes.string,
  workshopIcon: PropTypes.string,
  workshopTitle: PropTypes.string,
  workshopSubtitle: PropTypes.string,
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ratingImage: PropTypes.string,
  showLocation: PropTypes.bool,
  onClick: PropTypes.func,
  dataAutomationCardPromo: PropTypes.string,
  city: PropTypes.string,
  detailPrice: PropTypes.string,
  quantity: PropTypes.string,
  imgLazyLoad: PropTypes.bool,
};

const defaultProps = {
  active: false,
  imgLazyLoad: true,
};

const CardPromo = (props) => {
  const {
    size,
    title,
    tagTitle,
    price,
    originalPrice,
    distance,
    timeArrival,
    discount,
    image,
    active,
    workshopIcon,
    workshopTitle,
    workshopSubtitle,
    rating,
    ratingImage,
    showLocation,
    onClick,
    cssModule,
    className,
    cardBadgeClassName,
    ratingWrapperClassName,
    dataAutomationCardPromo,
    city,
    detailPrice,
    quantity,
    imgLazyLoad,
    ...attributes
  } = props;
  const classes = mapToCssModules(
    classNames(className, 'card-package', size ? `size-${size}` : false, active ? 'bg-white-lg' : false)
  );

  const cardBadgeClasses = mapToCssModules(
    classNames('badge-top-right', 'h-auto', 'position-absolute', 'rounded-pill', 'p-2', cardBadgeClassName),
    cssModule
  );

  return (
    <Card
      className={classes}
      {...attributes}
      onClick={onClick}
      data-automation={dataAutomationCardPromo || 'card_package'}
    >
      <CardImg top width="100%" height="140px" src={image} className="" loading={imgLazyLoad ? 'lazy' : 'eager'} />
      <Badge color="secondary-light" fontColor="secondary" className={cardBadgeClasses}>
        {tagTitle}
      </Badge>
      {discount && (
        <div className="ribbon-discount">
          <svg width="27" height="36" viewBox="0 0 27 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27 36V13H0V36L13.5 32.8056L27 36Z" fill="#FFC727" />
            <path d="M0 0H17C22.5228 0 27 4.47715 27 10V13H0V0Z" fill="#FFC727" />
            <path d="M0 12H27V14H0V12Z" fill="#FFC727" />
          </svg>

          <span className="ribbon-text fw-semi-bold">
            <div>{discount}</div>
            <div className="text-white-lg">OFF</div>
          </span>
        </div>
      )}
      <div className="card-content">
        <div>
          {workshopTitle && (
            <div className="d-flex justify-content-between mb-1">
              <div className="overline text-dark fw-bold">{workshopTitle}</div>
              {workshopIcon && (
                <img className="mx-1" src={workshopIcon} width={12} height={12} alt="icon-tier" loading="lazy" />
              )}
            </div>
          )}
          <div className="title title-active fw-bold mb-1">{title}</div>
          {showLocation && distance && (
            <div className="d-flex flex-wrap align-items-center mb-1">
              <div className="text-city text-body">{city} |</div>
              <div className="text-body text-price-discount">{distance}</div>
              <div className="text-label text-price-discount">({timeArrival})</div>
            </div>
          )}
          <div className="d-flex flex-column">
            {quantity && <div className="text-label icon-text mb-2">{quantity}</div>}
            {originalPrice && <div className="text-price-discount text-decoration-line-through">{originalPrice}</div>}
            <div className="text-price">{price}</div>
            {detailPrice && <div className="text-price-discount">{detailPrice}</div>}
          </div>
        </div>
      </div>
    </Card>
  );
};

CardPromo.propTypes = propTypes;
CardPromo.defaultProps = defaultProps;
export default CardPromo;
