import React, { useState } from 'react';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { Button } from '../otoklix-elements';

const ConfirmModal = (props) => {
  const { modal, onSubmit, onCancel, textSubmit, textCancel, title, description, icon, loading, children } = props;

  return (
    <Modal isOpen={modal} centered className="confirm-modal">
      <ModalBody>
        <div className="text-center">
          {icon}
          <h4>{title}</h4>
          <span>{description}</span>
          {children}
        </div>
        <div className="d-flex justify-content-between mt-4">
          <Button color="primary" outline size="sm" onClick={onCancel} disabled={loading}>
            {textCancel}
          </Button>
          <Button color="primary" size="sm" onClick={onSubmit} disabled={loading}>
            {loading ? <Spinner size={'sm'} /> : textSubmit}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmModal;
