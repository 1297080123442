import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  Button,
  Form,
  Spinner,
  Input,
  InputGroup,
  FormFeedback,
} from 'reactstrap';
import { authByUsername, getUserPermission } from '@actions/Auth';
import { setUserLoggedIn, setUserPermission } from '@reducers/Auth';
import { validate } from 'react-email-validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Logo from '@assets/images/logo.svg';
import { useHistory } from 'react-router-dom';
import BackgroundAccount from '@components/background/Account';
import Helper from '@helpers/Helper';

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isDisabled, setIsDisabled] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState();
  const [passwordErrorMessage, setPasswordErrorMessage] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailFormatInvalid, setIsEmailFormatInvalid] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleEmailChange = (event) => {
    const email = event.target.value;
    let emailCheck = validate(email);
    if (emailCheck) {
      setEmailErrorMessage();
      setIsEmailFormatInvalid(false);
      setIsEmailInvalid(false);
    } else if (!email) {
      setEmailErrorMessage('Email tidak boleh kosong');
      setIsEmailFormatInvalid(false);
      setIsEmailInvalid(true);
    } else if (!emailCheck) {
      setEmailErrorMessage('Format Email salah');
      setIsEmailFormatInvalid(true);
      setIsEmailInvalid(true);
    }

    setHasError(false);
    setErrorMessage();
    setEmail(email);
  };

  const handlePasswordChange = (event) => {
    const password = event.target.value;
    if (!password) {
      setPasswordErrorMessage('Password tidak boleh kosong');
      setIsPasswordInvalid(true);
    } else {
      setPasswordErrorMessage();
      setIsPasswordInvalid(false);
    }

    setHasError(false);
    setErrorMessage();
    setPassword(password);
  };

  const validation = () => {
    let status = true;

    if (isEmailFormatInvalid) {
      setEmailErrorMessage('Format Email salah');
      setIsEmailInvalid(true);
      status = false;
    }
    if (!email) {
      setEmailErrorMessage('Email tidak boleh kosong');
      setIsEmailInvalid(true);
      status = false;
    }
    if (!password) {
      setPasswordErrorMessage('Password tidak boleh kosong');
      setIsPasswordInvalid(true);
      status = false;
    }

    return status;
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (!validation()) {
      return;
    }

    setIsDisabled(true);

    dispatch(authByUsername(email, password))
      .then((response) => {
        setIsDisabled(false);
        const decodeJWT = Helper.decodedJWT(response?.data?.auth_token);
        const fullName = response?.data?.name;
        const payload = {
          loggedIn: true,
          token: response?.data,
          username: email,
          name: fullName,
          authorizedWorkshopSlug: decodeJWT?.authorized_workshops[0]?.slug,
        };

        dispatch(setUserLoggedIn(payload));
        dispatch(getUserPermission)
          .then((response) => {
            dispatch(setUserPermission(response));
          })
          .catch((error) => {
            setHasError(true);
            setIsDisabled(false);
            setErrorMessage(error?.response?.data ?? 'error login');
          });
      })
      .catch((error) => {
        setHasError(true);
        setIsDisabled(false);
        setErrorMessage(error?.response?.data?.error?.message ?? 'error login');
      });
  };

  return (
    <Container fluid={true}>
      <Row className="vh-100">
        <Col md={6} className="p-0 h-100vh d-flex justify-content-center login-bg">
          <BackgroundAccount />
        </Col>
        <Col md={6} className="justify-content-center">
          <div className="auth-page px-5 py-3">
            <Card body className="border-0 mb-0 h-100">
              <div className="mb-5 align-items-left">
                <a href="/" className="logo logo-admin">
                  <span>
                    <img src={Logo} height={40} alt="logo" className="my-3" />
                  </span>
                </a>
              </div>
              <div className="align-items-end">
                <h2 className="font-weight-semibold font-36 mb-0 mt-5 text-black">Welcome back</h2>
                <div className="text-black">Welcome back! Please enter your details.</div>
                <Form className="form-horizontal auth-form my-5" onSubmit={handleLogin}>
                  <FormGroup>
                    <Input
                      className="input-login"
                      placeholder="Email"
                      invalid={isEmailInvalid}
                      onChange={handleEmailChange}
                    />
                    <FormFeedback>{emailErrorMessage}</FormFeedback>
                  </FormGroup>
                  <FormGroup className="mb-2">
                    <InputGroup>
                      <Input
                        className="input-login"
                        type={!showPassword ? `password` : `text`}
                        placeholder="Password"
                        invalid={isPasswordInvalid}
                        onChange={handlePasswordChange}
                      />
                      <Button color="link" onClick={() => setShowPassword(!showPassword)}>
                        {!showPassword ? (
                          <FontAwesomeIcon icon={faEye} style={{ color: '#000000' }} />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} style={{ color: '#000000' }} />
                        )}
                      </Button>
                    </InputGroup>

                    <FormFeedback>{passwordErrorMessage}</FormFeedback>
                  </FormGroup>

                  {hasError && <div className="alert alert-danger">{errorMessage}</div>}
                  <div
                    className="text-black text-decoration-underline mb-5 cursor-pointer text-right"
                    onClick={() => history.push('/forgot-password')}
                  >
                    Forgot Password?
                  </div>
                  <FormGroup>
                    <Button
                      block
                      size="lg"
                      color="black"
                      className="btn-login"
                      disabled={isDisabled}
                      onClick={(e) => {
                        handleLogin(e);
                        setHasError(false);
                        setErrorMessage();
                      }}
                      type="submit"
                    >
                      {isDisabled && (
                        <>
                          <Spinner color="light" size="sm">
                            Loading...
                          </Spinner>{' '}
                          &nbsp;
                        </>
                      )}
                      Log in
                    </Button>
                  </FormGroup>
                </Form>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
