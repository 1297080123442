import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from '../utils';

const propTypes = {
  tag: tagPropType,
  color: PropTypes.string,
  weight: PropTypes.oneOf(['normal', 'medium', 'semi-bold', 'bold']),
  truncate: PropTypes.bool,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func]),
};

const defaultProps = {
  tag: 'span',
  weight: 'normal',
};

const Text = (props) => {
  const { color, weight, truncate, className, cssModule, tag: Tag, innerRef, ...attributes } = props;

  const classes = mapToCssModules(
    classNames(
      color ? `text-${color}` : false,
      truncate ? 'text-truncate' : false,
      weight ? `fw-${weight}` : false,
      className
    ),
    cssModule
  );

  return <Tag {...attributes} className={classes} ref={innerRef} />;
};

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

export default Text;
