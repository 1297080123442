import { useSelector } from 'react-redux';
import { getFullName } from '@reducers/Auth';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Helper from '@helpers/Helper';
import moment from 'moment';

export default class EstimationPricePdf {
  static print(content) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'potrait',
      content: content,
      footer: function (currentPage, pageCount) {
        return [
          {
            text: 'Halaman ' + currentPage.toString() + ' dari ' + pageCount,
            alignment: 'center',
            bold: true,
            color: '#d6d8e7',
            fontSize: 10,
          },
        ];
      },
      styles: {
        bodyContent: {
          fontSize: 10,
        },
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.download('Estimation Price');
  }

  static getBodyContent(data) {
    let dataContent = [
      [
        {
          text: 'No',
          bold: true,
          alignment: 'center',
        },
        {
          text: 'Tipe',
          bold: true,
          alignment: 'center',
        },
        {
          text: 'Deskripsi',
          bold: true,
          alignment: 'center',
        },
        {
          text: 'Qty',
          bold: true,
          alignment: 'center',
        },
        {
          text: 'Harga',
          bold: true,
          alignment: 'center',
        },
        {
          text: 'Sub-Total',
          bold: true,
          alignment: 'center',
        },
      ],
    ];

    if (data?.items?.length == 0) {
      dataContent.push([
        {
          text: 'Tidak ada data',
          colSpan: 6,
          alignment: 'center',
        },
        {
          text: '',
        },
        {
          text: '',
        },
        {
          text: '',
        },
        {
          text: '',
        },
        {
          text: '',
        },
      ]);
    } else {
      data.items.map((item, index) => {
        let itemBody = [
          {
            text: index + 1,
            alignment: 'center',
          },
          {
            text:
              item?.type == 'package'
                ? 'Package'
                : item?.type == 'product'
                ? 'Product'
                : item?.type == 'service'
                ? 'Service'
                : '-',
            alignment: 'center',
          },
          {
            text: item?.name ?? '-',
          },
          {
            text: item?.qty ?? '0',
            alignment: 'right',
          },
          {
            text: 'Rp ' + Helper.formatMoney(item?.price),
            alignment: 'right',
          },
          {
            text: 'Rp ' + Helper.formatMoney(item?.sub_total),
            alignment: 'right',
          },
        ];

        if (item?.detail_items && item?.detail_items !== 0) {
          let valueItemDetailBody = [];
          item.detail_items.map((itemDetail) => {
            if (itemDetail?.value) valueItemDetailBody.push(itemDetail?.value);
          });
          if (valueItemDetailBody?.length !== 0) {
            const itemDetailBody = {
              stack: [
                item?.name ?? '-',
                {
                  ul: valueItemDetailBody,
                },
              ],
            };
            itemBody[2] = itemDetailBody;
          }
        }
        dataContent.push(itemBody);
      });
    }

    return dataContent;
  }

  static getContent(data) {
    const bodyContent = this.getBodyContent(data);
    let content = [
      {
        table: {
          widths: ['50%', '50%'],
          body: [
            [
              {
                rowSpan: 2,
                image: Helper.logoBase64(),
                alignment: 'left',
                width: 80,
                border: [false, false, false, false],
              },
              {
                text: data?.workshop_name ?? '',
                border: [false, false, false, false],
                alignment: 'right',
                fontSize: 11,
                bold: true,
              },
            ],
            [
              '',
              {
                text: data?.street_address ?? '',
                border: [false, false, false, false],
                alignment: 'right',
                fontSize: 8,
              },
            ],
          ],
        },
      },
      {
        table: {
          widths: ['100%'],
          body: [
            [
              {
                text: 'Estimasi Pengerjaan Servis',
                border: [false, false, false, false],
                alignment: 'center',
                fontSize: 13,
                bold: true,
                color: 'black',
                margin: [0, 20, 0, 20],
              },
            ],
          ],
        },
      },
      {
        style: 'bodyContent',
        table: {
          widths: ['11%', '38%', '17%', '34%'],
          body: [
            [
              {
                rowSpan: 2,
                text: 'Kendaraan',
                bold: true,
                border: [false, false, false, false],
              },
              {
                rowSpan: 2,
                text: ': ' + data?.car ?? '-',
                border: [false, false, false, false],
              },
              {
                text: 'Bengkel',
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: ': ' + data?.workshop_name ?? '-',
                border: [false, false, false, false],
              },
            ],
            [
              { text: '' },
              { text: '' },
              {
                text: 'Pembuat Estimasi',
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: ': ' + data?.pic ?? '-',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        style: 'bodyContent',
        table: {
          widths: ['100%'],
          body: [
            [
              {
                text: 'Service Details',
                bold: true,
                border: [false, false, false, false],
                margin: [0, 10, 0, 10],
              },
            ],
          ],
        },
      },
      {
        style: 'bodyContent',
        table: {
          widths: ['4%', '10%', '37%', '9%', '16%', '24%'],
          body: bodyContent,
        },
      },
      {
        table: {
          widths: ['100%'],
          body: [
            [
              {
                text: '',
                bold: true,
                border: [false, false, false, false],
                margin: [0, 5, 0, 0],
              },
            ],
          ],
        },
      },
      {
        style: 'bodyContent',
        table: {
          widths: ['4%', '10%', '37%', '9%', '16%', '24%'],
          body: [
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: 'Total',
                bold: true,
              },
              {
                text: 'Rp ' + Helper.formatMoney(data?.total_amount),
                bold: true,
                alignment: 'right',
              },
            ],
          ],
        },
      },
      {
        text: 'Harga sudah Termasuk Pajak 11%',
        alignment: 'right',
        fontSize: 9,
        margin: [0, 3, 0, 0],
      },
      {
        style: 'bodyContent',
        absolutePosition: { x: 40, y: 650 },
        table: {
          widths: ['100%'],
          body: [
            [
              {
                text: 'Estimasi dicetak pada ' + moment().format('DD MMMM YYYY (HH:mm:ss [GMT]Z)'),
                italics: true,
                alignment: 'left',
                margin: [0, 125, 0, 0],
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ];

    return content;
  }

  static create(cart, totalAmount, fullName, userCar, workshop) {
    const carBrand = userCar?.car_model ? userCar?.car_model?.brand?.name ?? '' : userCar?.brand ?? '';
    const carModel = userCar?.car_model ? userCar?.car_model?.model_name ?? '' : userCar?.model ?? '';
    const carVariant = userCar?.variant ?? '';
    let carDetail = carBrand == '' ? '' : carBrand + ' ';
    carDetail += carModel == '' ? carDetail : carModel + ' ';
    carDetail += carVariant == '' ? carDetail : carVariant;

    let data = {
      workshop_name: workshop?.name ?? '',
      street_address: workshop?.street_address ?? '',
      pic: fullName,
      car: carDetail,
      items: [],
      total_amount: totalAmount,
    };

    cart.map((item) => {
      const isCustom = item?.formType == 'custom' ? true : false;
      const price = isCustom
        ? item?.unit_price?.formatMoney
        : item?.item_type == 'package'
        ? item?.calculatedPrice
        : item?.price ?? 0;
      const qty = isCustom ? item?.qty?.formatMoney : item?.qty ?? 0;
      let items = {
        type: isCustom ? item?.type : item?.item_type,
        name: item?.name ?? '',
        qty: qty.toFixed(2),
        price: item?.is_guaranteed ? 0 : price,
        sub_total: item?.is_guaranteed ? 0 : item?.calculatedPrice ?? 0,
        detail_items: [],
      };

      if (item?.packageDetail && item?.packageDetail?.length !== 0) {
        item?.packageDetail.map((item_detail) => {
          items.detail_items.push({
            label: item_detail?.component_label ?? '',
            value: item_detail?.value?.name ?? '',
          });
        });
      }

      data.items.push(items);
    });

    const content = this.getContent(data);

    this.print(content);
  }
}
