import React from 'react';
import Select, { components } from 'react-select';

const FilterSelectField = (props) => {
  const {
    value,
    disabled,
    selectName,
    width = 'w-200',
    placeholder,
    closeMenuOnSelect,
    isSearchable,
    options,
    onChange,
    onClick,
  } = props;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: '500',
      backgroundColor: state?.isSelected ? '#0d6efd' : state?.isFocused ? '#E0CFFC' : '#fff',
      color: state?.isSelected ? '#fff' : state?.isFocused ? '#0d6efd' : '#6C757D',
      cursor: state?.isFocused ? 'pointer' : '',
      '&:hover': {
        color: state?.isSelected ? '#fff' : '#0d6efd',
        backgroundColor: state?.isSelected ? '#0d6efd' : '#E0CFFC',
        cursor: 'pointer',
      },
    }),
    control: (provided, state) => ({
      ...provided,
      height: 40,
      border: state?.isFocused || state?.hasValue ? '1px solid #0d6efd' : '1px solid #E0E7ED',
      borderRadius: 8,
      backgroundColor: '#fff',
      boxShadow: 'none',
      fontWeight: '600',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#0d6efd!important',
    }),
    input: (provided) => ({
      ...provided,
      color: '#0d6efd',
      '[type="text"]': {
        fontWeight: '600',
      },
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      borderRadius: 4,
      paddingBottom: 0,
      border: '0.5px solid #0d6efd',
    }),
    placeholder: (provided) => {
      return {
        ...provided,
        color: '#BDBDBD',
        fontWeight: '200',
      };
    },
  };

  const DropdownIndicator = (props) => {
    const isActive = props?.isFocused || props?.hasValue ? true : false;
    return (
      <components.DropdownIndicator {...props}>
        {isActive && <div className="arrow-inactive-select">{''}</div>}
        {!isActive && <div className="arrow-active-select">{''}</div>}
      </components.DropdownIndicator>
    );
  };

  return (
    <Select
      className={'select-field-filter btn-create-responsive' + ' ' + width}
      components={{ IndicatorSeparator: () => null, DropdownIndicator }}
      name={selectName}
      placeholder={placeholder}
      isSearchable={isSearchable}
      closeMenuOnSelect={closeMenuOnSelect}
      styles={customStyles}
      value={value}
      options={options}
      onChange={onChange}
      onClick={onClick}
      isDisabled={disabled}
    />
  );
};

export default FilterSelectField;
