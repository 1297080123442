import Cookies from 'universal-cookie';
import { createSlice } from '@reduxjs/toolkit';

const cookies = new Cookies();

export const initialState = {
  isLoggedIn: false,
  username: null,
  fullname: null,
  currentUser: {
    token: null,
    jwtToken: null,
    refreshToken: null,
  },
  userPermission: [],
};

export const app = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setUserLoggedIn: (state, action) => {
      console.log('action', action);
      state.isLoggedIn = action.payload.loggedIn;
      state.currentUser = {
        ...state.currentUser,
        token: action.payload.token.auth_token,
        jwtToken: action.payload.token.auth_token,
        refreshToken: action.payload.token.refresh_token,
        username: action.payload.username,
        fullname: action.payload.name,
      };
      state.userPermission = action.payload.permissions;

      cookies.set('gms_jwt_token', action.payload.token.auth_token, { path: '/' });
      cookies.set('gms_refresh_token', action.payload.token.refresh_token, { path: '/' });
      cookies.set('authorized_workshops_slug', action.payload.authorizedWorkshopSlug);
    },
    setAuthInfo: (state, action) => {
      state.currentUser = {
        ...state.currentUser,
        userInfo: action.payload,
      };
    },
    setJWTToken: (state, action) => {
      state.currentUser = {
        ...state.currentUser,
        jwtToken: action.payload,
      };

      cookies.set('gms_jwt_token', action.payload, { path: '/' });
      cookies.set('authorized_workshops_slug', action.payload.authorizedWorkshopSlug);
    },
    resetAuth: () => initialState,
    setUserPermission: (state, action) => {
      state.userPermission = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.currentUser = {
        ...state.currentUser,
        token: action.payload,
        jwtToken: action.payload.auth_token,
        refreshToken: action.payload.refresh_token,
      };

      cookies.set('gms_jwt_token', action.payload.auth_token, { path: '/' });
      cookies.set('gms_refresh_token', action.payload.refresh_token, { path: '/' });
      cookies.set('authorized_workshops_slug', action.payload.authorizedWorkshopSlug);
    },
  },
});

export const { setUserLoggedIn, setJWTToken, setUserPermission, resetAuth, setRefreshToken, setAuthInfo } = app.actions;
export const hasLoggedIn = (state) => state.auth.isLoggedIn;
export const permission = (state) => state.auth.userPermission;
export const getUsername = (state) => state.auth.currentUser.username;
export const getFullName = (state) => state.auth.currentUser.fullname;
export const getUserInfo = (state) => state.auth.currentUser.userInfo;

export default app.reducer;
