import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Row, Col, Button, Label, FormGroup, Input } from 'reactstrap';
import CloseIcon from '@assets/icons/exit.svg';

const ModalPayment = (props) => {
  const { modal, toggle, backdrop, handleSubmit, options } = props;

  const [optionList, setOptionList] = useState([]);

  const handleSelectPayment = (value, index) => {
    optionList[index].selected = value;
    optionList[index].nominal = { formatMoney: 0, formatMoneyString: '0' };
    setOptionList([...optionList]);
  };

  useEffect(() => {
    if (modal) {
      setOptionList(options);
    }
  }, [modal]);

  return (
    <Modal className={'payment modal-dialog-centered modal-payment'} isOpen={modal} toggle={toggle} backdrop={backdrop}>
      <ModalBody className="body">
        <Row className="modal-title">
          <span>Pilih Metode Pembayaran</span>
          <span className="close-icon-box">
            <img className="close-icon mt-1 mr-2 cursor-pointer" src={CloseIcon} height="15px" onClick={toggle}></img>
          </span>
        </Row>
        <Row className="mt-3">
          {optionList.map((item, index) => {
            return (
              <Col
                lg={6}
                sm={6}
                key={item?.name}
                className="mb-2"
                onClick={() => handleSelectPayment(!item?.selected, index)}
              >
                {/* <Button
                  className={`button-select font-12 w-p-100 ${!item?.selected ? '' : 'outline'}`}
                  key={index}
                  color="link"
                  onClick={() => handleSelect(index)}
                >
                  {item?.label}
                </Button> */}
                <FormGroup key={index} check>
                  <Input className="cursor-pointer" type="checkbox" checked={item?.selected} />
                  <Label className="cursor-pointer">{item?.label}</Label>
                </FormGroup>
              </Col>
            );
          })}
          <Col lg={12} className="d-flex justify-content-center mt-2">
            {/* <Button color="primary" outline size="sm" className="w-p-35 mx-3" onClick={toggle}>
              Kembali
            </Button> */}
            <Button color="primary" size="sm" className="w-p-35 mx-3" onClick={() => handleSubmit(optionList)}>
              Lanjutkan
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ModalPayment;
