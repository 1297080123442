import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules } from '../utils';
import Card from './Card';
import CardBody from './CardBody';
import CardTitle from './CardTitle';
import CardSubtitle from './CardSubtitle';
import Tags from '../badge/Tags';
import Button from '../button/Button';
import Collapse from '../collapse/Collapse';

const propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  verifiedImage: PropTypes.string,
  noBorder: PropTypes.bool,
  hasAdditionalBadge: PropTypes.bool,
  additionalText: PropTypes.string,
  imageRightLink: PropTypes.string,
  imageRightClick: PropTypes.func,
  bodyClassName: PropTypes.string,
  subtitleClassName: PropTypes.string,
  detailClassName: PropTypes.string,
  detailImageLink: PropTypes.string,
  detailImageWidth: PropTypes.number,
  detailButtonTitle: PropTypes.string,
  detailButtonClick: PropTypes.func,
  cardInfoClassName: PropTypes.string,
  toggleImage: PropTypes.string,
  toggleImageClick: PropTypes.func,
  onEntering: PropTypes.func,
  onExiting: PropTypes.func,
  cardInfoOpen: PropTypes.any,
  accordionData: PropTypes.any,
  cardExtraInfo: PropTypes.any,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  cssModule: PropTypes.object,
  dataAutomationDetailButton: PropTypes.string,
  dataAutomationInfoImg: PropTypes.string,
  dataAutomationRightImg: PropTypes.string,
};

const defaultProps = {
  noBorder: false,
  detailImageWidth: 200,
};

const CardGarage = (props) => {
  const {
    title,
    subtitle,
    verifiedImage,
    noBorder,
    hasAdditionalBadge,
    additionalText,
    imageRightLink,
    imageRightClick,
    bodyClassName,
    subtitleClassName,
    detailClassName,
    detailImageLink,
    detailImageWidth,
    detailButtonTitle,
    detailButtonClick,
    cardInfoClassName,
    toggleImage,
    toggleImageClick,
    onEntering,
    onExiting,
    cardInfoOpen,
    accordionData,
    className,
    buttonClassName,
    cssModule,
    cardExtraInfo,
    dataAutomationDetailButton,
    dataAutomationInfoImg,
    dataAutomationRightImg,
    ...attributes
  } = props;

  const classes = mapToCssModules(classNames('card-garage', noBorder ? 'border-0' : false, className), cssModule);

  const cardInfoClasses = mapToCssModules(classNames('card-info', 'pt-2', 'pb-4', cardInfoClassName));

  const cardDetailClasses = mapToCssModules(classNames('card-detail', 'pt-3', detailClassName));

  const subtitleClasses = mapToCssModules(classNames('mb-2', 'text-muted', subtitleClassName));

  const bodyClasses = mapToCssModules(classNames('pb-4', bodyClassName));
  const buttonClasses = mapToCssModules(classNames('mt-3', buttonClassName));

  return (
    <Card className={classes} {...attributes}>
      <CardBody className={bodyClasses}>
        {imageRightLink && (
          <div
            role="button"
            aria-hidden="true"
            onClick={imageRightClick}
            data-automation={dataAutomationRightImg || 'button_right_img_link'}
          >
            <img src={imageRightLink} className="edit-pen" alt="icon-right-link" loading="lazy" />
          </div>
        )}

        {title && (
          <CardTitle tag="h5">
            {title} {verifiedImage && <img src={verifiedImage} className="mx-1" alt="icon-verified" loading="lazy" />}
          </CardTitle>
        )}

        {subtitle && (
          <CardSubtitle tag="h6" className={subtitleClasses}>
            {subtitle}
          </CardSubtitle>
        )}

        {hasAdditionalBadge ? (
          <Tags title={additionalText} pill color="success-light" textColor="success" size="sm" />
        ) : (
          <br />
        )}

        <div className={cardDetailClasses}>
          {detailImageLink && (
            <img src={detailImageLink} width={detailImageWidth} alt="img-car-detail" loading="lazy" />
          )}
          {detailButtonTitle && (
            <Button
              className={buttonClasses}
              onClick={detailButtonClick}
              outline
              size="sm"
              data-automation={dataAutomationDetailButton || 'button_detail'}
            >
              {detailButtonTitle}
            </Button>
          )}
        </div>
      </CardBody>
      {toggleImage && (
        <div className={cardInfoClasses}>
          {cardExtraInfo}
          <img
            role="presentation"
            src={toggleImage}
            onClick={toggleImageClick}
            className="arrow-collapse"
            alt="icon-arrow-collapse"
            loading="lazy"
            data-automation={dataAutomationInfoImg || 'icon_info'}
          />

          <Collapse isOpen={cardInfoOpen} onEntering={onEntering} onExiting={onExiting}>
            {accordionData}
          </Collapse>
        </div>
      )}
    </Card>
  );
};

CardGarage.propTypes = propTypes;
CardGarage.defaultProps = defaultProps;

export default CardGarage;
