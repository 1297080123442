import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { mapToCssModules } from '../utils';
import Card from './Card';
import CardBody from './CardBody';
import Text from '../text/Text';
import Button from '../button/Button';
import Divider from '../divider/Divider';

const propTypes = {
  image: PropTypes.string,
  imageClassName: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  buttonText: PropTypes.string,
  onCardClick: PropTypes.func,
  showFooter: PropTypes.bool,
  cssModule: PropTypes.object,
  dataAutomationCardService: PropTypes.string,
  categoryLabel: PropTypes.string,
  discountLabel: PropTypes.string,
  showStartPrice: PropTypes.bool,
  imgLazyLoad: PropTypes.bool,
  quantity: PropTypes.string,
  detailPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isRecommended: PropTypes.string,
  showUsp: PropTypes.bool,
  guaranteeIcon: PropTypes.string,
  discountIcon: PropTypes.string,
  bookmarkIcon: PropTypes.string,
  imgAlt: PropTypes.string,
  isOnCart: PropTypes.bool,
  showCartButton: PropTypes.bool,
  onAddToCart: PropTypes.func,
  onDeleteItem: PropTypes.func,
  descriptions: PropTypes.array,
  descriptionListIcon: PropTypes.string,
  clickImageText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  handleClickImage: PropTypes.func,
  dataAutomationCategoryLabel: PropTypes.string,
  dataAutomationTitle: PropTypes.string,
  dataAutomationOriginalPrice: PropTypes.string,
  dataAutomationDiscountPrice: PropTypes.string,
  dataAutomationPrice: PropTypes.string,
  dataAutomationDetailPrice: PropTypes.string,
};

const defaultProps = {
  showFooter: true,
  imgLazyLoad: true,
};

const CardServices = (props) => {
  const {
    image,
    imageClassName,
    title,
    titleClassName,
    price,
    subPrice,
    onCardClick,
    cssModule,
    dataAutomationCardService,
    categoryLabel,
    discountLabel,
    showStartPrice,
    buttonText,
    imgLazyLoad,
    quantity,
    detailPrice,
    isRecommended,
    showUsp,
    guaranteeIcon,
    discountIcon,
    bookmarkIcon,
    imgAlt,
    isOnCart,
    showCartButton,
    onAddToCart,
    onDeleteItem,
    descriptions,
    descriptionListIcon,
    clickImageText,
    handleClickImage,
    dataAutomationCategoryLabel,
    dataAutomationTitle,
    dataAutomationOriginalPrice,
    dataAutomationDiscountPrice,
    dataAutomationPrice,
    dataAutomationDetailPrice,
    ...attributes
  } = props;

  const classes = mapToCssModules(classNames('card-services pointer'));
  const bodyClasses = mapToCssModules(classNames('d-flex p-3'));
  const titleClasses = mapToCssModules(classNames('title', titleClassName), cssModule);
  const mainImageClasses = mapToCssModules(classNames('main-image', imageClassName), cssModule);

  return (
    <Card
      className={classes}
      {...attributes}
      data-automation={dataAutomationCardService || 'card_service'}
      onClick={onCardClick}
    >
      <CardBody className="p-0">
        {isRecommended && (
          <Text color="white" className={`recommended-ribbon text-xxs ${showUsp && '--active'}`}>
            {isRecommended}
          </Text>
        )}

        {showUsp && (
          <div className="usp-top">
            <div className="me-2">
              {bookmarkIcon && <img className="me-1" src={bookmarkIcon} alt="original" height={10} />}
              <span>Dijamin Ori</span>
            </div>
            <div className="ms-2">
              {guaranteeIcon && <img className="me-1" src={guaranteeIcon} alt="garansi" height={10} />}
              <span>Garansi 14 Hari</span>
            </div>
          </div>
        )}

        <div className={bodyClasses}>
          {image && (
            <div className="box-image">
              <img
                className={mainImageClasses}
                src={image}
                width={80}
                height={80}
                alt={imgAlt}
                loading={imgLazyLoad ? 'lazy' : 'eager'}
              />
              {typeof handleClickImage === 'function' && (
                <div className="click-image" onClick={handleClickImage} role="presentation">
                  <span>{clickImageText}</span>
                </div>
              )}
            </div>
          )}
          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex flex-column align-items-start">
              <Text className="category-label text-xxs text-center mb-1" data-automation={dataAutomationCategoryLabel}>
                {categoryLabel}
              </Text>
              <Text weight="bold" className={`${titleClasses}`} data-automation={dataAutomationTitle}>
                {title}
              </Text>

              {quantity && (
                <Text color="label" className="text-xxs d-block pb-2">
                  {quantity}
                </Text>
              )}
            </div>

            {descriptions?.length < 1 ? (
              <>
                {showStartPrice && (
                  <Text color="label" className="text-xxs d-block my-1">
                    Mulai dari
                  </Text>
                )}

                <>
                  <div className="d-flex align-items-center">
                    {subPrice && (
                      <Text color="placeholder" className="text-xxs text-strikethrough d-block">
                        {subPrice}
                      </Text>
                    )}
                    {discountLabel && <Text className="discount-label text-xxs">{discountLabel}</Text>}
                  </div>
                  <Text color="secondary" weight="bold" className="text-xs d-block">
                    {price}
                  </Text>
                  {detailPrice && (
                    <Text color="light-light" className="text-xxs d-block">
                      {detailPrice}
                    </Text>
                  )}
                </>
              </>
            ) : null}

            {showCartButton && (
              <>
                {isOnCart ? (
                  <div className="d-flex align-items-center">
                    <div className="on-cart-text-horizontal">Sudah di Keranjang</div>
                    <Button className="extras-button ms-1" color="light" size="sm" outline onClick={onDeleteItem}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12" fill="none">
                        <path
                          d="M8.66252 4.73413C8.66252 4.73413 8.39102 8.10163 8.23352 9.52013C8.15852 10.1976 7.74002 10.5946 7.05452 10.6071C5.75002 10.6306 4.44402 10.6321 3.14002 10.6046C2.48052 10.5911 2.06902 10.1891 1.99552 9.52363C1.83702 8.09263 1.56702 4.73413 1.56702 4.73413"
                          stroke="#002EB4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.35412 3.11987H0.875122"
                          stroke="#002EB4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.72029 3.11987C7.32779 3.11987 6.98979 2.84237 6.91279 2.45787L6.79129 1.84987C6.71629 1.56937 6.46229 1.37537 6.17279 1.37537H4.05629C3.76679 1.37537 3.51279 1.56937 3.43779 1.84987L3.31629 2.45787C3.23929 2.84237 2.90129 3.11987 2.50879 3.11987"
                          stroke="#002EB4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Button>
                  </div>
                ) : (
                  <Button className="extras-button mt-1" color="primary" size="sm" outline onClick={onAddToCart}>
                    {buttonText}
                  </Button>
                )}
              </>
            )}
          </div>
        </div>

        {descriptions?.length > 0 ? (
          <>
            <Divider type="dash" />

            <div className={`px-3 mb-2 d-flex justify-content-${descriptions?.length > 0 ? 'between' : 'end'}`}>
              {descriptions?.length > 0 ? (
                <div className="product-description-list">
                  <Text tag="span">Termasuk:</Text>
                  {descriptions?.map((description, index) => (
                    <div className="d-flex align-items-baseline" key={index}>
                      <img src={descriptionListIcon} className="me-1" alt="" width={10} height={10} />
                      <span>{description}</span>
                    </div>
                  ))}
                </div>
              ) : null}

              <div className="d-flex flex-column">
                {showStartPrice && (
                  <Text color="label" className="text-xxs d-block my-1">
                    Mulai dari
                  </Text>
                )}

                <div>
                  <div className="d-flex align-items-center">
                    {subPrice && (
                      <Text
                        color="placeholder"
                        className="text-xxs text-strikethrough d-block"
                        data-automation={dataAutomationOriginalPrice}
                      >
                        {subPrice}
                      </Text>
                    )}
                    {discountLabel && (
                      <Text className="discount-label text-xxs" data-automation={dataAutomationDiscountPrice}>
                        {discountLabel}
                      </Text>
                    )}
                  </div>
                  <Text
                    color="secondary"
                    weight="bold"
                    className="text-xs d-block"
                    data-automation={dataAutomationPrice}
                  >
                    {price}
                  </Text>
                  {detailPrice && (
                    <Text color="light-light" className="text-xxs d-block" data-automation={dataAutomationDetailPrice}>
                      {detailPrice}
                    </Text>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : null}

        {showUsp && (
          <div className="usp-bottom">
            {discountIcon && <img className="me-1" src={discountIcon} alt="diskon" height={10} />}
            <span>Booking online lebih murah</span>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

CardServices.propTypes = propTypes;
CardServices.defaultProps = defaultProps;
export default CardServices;
