import React from 'react';

const BackgroundAccount = () => {
  return (
    <div className="accountbg d-flex align-items-end p-5">
      <div className="account-title text-left p-2">
        <h2 className="font-40 text-white">
          Garage <div className="text-white">Management System</div>
        </h2>
        <div className="text-white">
          Software solution that allows us to provide data centralization and connectivity from inventory control,
          technician performance measurement, transaction input by cashier module, until manage queue of online and
          offline customer.
        </div>
      </div>
    </div>
  );
};

export default BackgroundAccount;
