import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from './Card';
import CardBody from './CardBody';
import CardImg from './CardImg';
import Button from '../button/Button';
import Icon from '../icon/Icon';
import Tags from '../badge/Tags';
import Text from '../text/Text';
import { mapToCssModules } from '../utils';

const propTypes = {
  badgeTitle: PropTypes.string,
  title: PropTypes.string,
  region: PropTypes.string,
  timeArrival: PropTypes.string,
  distance: PropTypes.string,
  rating: PropTypes.number,
  tier: PropTypes.string,
  buttonLabel: PropTypes.string,
  image: PropTypes.string,
  badgeImage: PropTypes.string,
  distanceImage: PropTypes.string,
  ratingImage: PropTypes.string,
  tierImage: PropTypes.string,
  onButtonClick: PropTypes.func,
  className: PropTypes.string,
  pricing: PropTypes.element,
  extraInfo: PropTypes.element,
  dataAutomationCardWorkshop: PropTypes.string,
};

const defaultProps = {
  timeArrival: '',
  distance: '',
  region: '',
};

const CardWorkshops = (props) => {
  const {
    badgeTitle,
    title,
    region,
    timeArrival,
    distance,
    rating,
    tier,
    buttonLabel,
    image,
    badgeImage,
    distanceImage,
    ratingImage,
    tierImage,
    onButtonClick,
    className,
    pricing,
    extraInfo,
    dataAutomationCardWorkshop,
    ...attributes
  } = props;

  const classes = mapToCssModules(classNames(className, 'card-workshops'));

  const infos = [region, distance, timeArrival]
    .filter((value) => value)
    .flatMap((value, index, array) => (array.length - 1 !== index ? [value, 'separator'] : value));

  return (
    <Card className={classes} {...attributes}>
      {badgeTitle && (
        <Tags
          icon={badgeImage}
          className="badge-top-right"
          title={badgeTitle}
          size="sm"
          textColor="secondary"
          color="secondary-light"
        />
      )}

      <CardImg top width="100%" height="146" rounded={image} src={image} alt="" />
      <CardBody className="p-3">
        <div className=" d-flex flex-column">
          <Text color="title-active" className="title mb-2" weight="semi-bold">
            {title}
          </Text>
          <div className="d-flex align-items-center">
            <img className="me-1" src={distanceImage} width={16} height={16} alt="" />
            <div className="subtitle">
              {infos.map((info, index) => {
                if (index % 2 === 0) {
                  return <span key={index}>{info}</span>;
                }
                return <span key={index} className="mx-2 dot" />;
              })}
            </div>
          </div>
          <div className="d-flex">
            {rating > 0 && (
              <Icon
                card
                size="sm"
                textRight
                imageWidth={16}
                imageHeight={16}
                textClassName="subtitle me-3"
                title={rating.toString()}
                image={ratingImage}
              />
            )}

            {tier && (
              <Icon
                card
                size="sm"
                textRight
                imageWidth={16}
                imageHeight={16}
                textClassName="subtitle"
                title={tier}
                image={tierImage}
              />
            )}
          </div>
        </div>
        {extraInfo}
        <div className="d-flex align-items-center">
          <div className="pricing-section me-2">{pricing}</div>
          <div className="cta-container">
            <Button
              color="secondary"
              size="sm"
              onClick={onButtonClick}
              data-automation={dataAutomationCardWorkshop || 'button_card_workshop'}
            >
              {buttonLabel}
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

CardWorkshops.propTypes = propTypes;
CardWorkshops.defaultProps = defaultProps;

export default CardWorkshops;
