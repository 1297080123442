import React from 'react';
import { Col, Row } from 'reactstrap';
import NoDataImage from '@assets/images/no-data.png';

const NoData = () => {
  return (
    <Row>
      <Col className="no-data">
        <img src={NoDataImage} height={128} width={128} />
        <h4>Data Tidak Tersedia</h4>
        <p>Kami tidak memiliki data untuk ditampilkan saat ini.</p>
      </Col>
    </Row>
  );
};

export default NoData;
