import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  name: 'gms',
  version: '1.0',
  activeLink: '',
};

export const app = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setActiveLink: (state, action) => {
      state.activeLink = action.payload;
    },
  },
});

export const { setActiveLink } = app.actions;
export const getAppInfo = (state) => state.app;
export const getActiveLink = (state) => state.app.activeLink;

export default app.reducer;
