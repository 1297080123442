import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '../card/Card';
import CardTitle from '../card/CardTitle';
import CardBody from '../card/CardBody';
import { mapToCssModules } from '../utils';

const propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  classNameSubtitle: PropTypes.string,
  bodyClassName: PropTypes.string,
  classNameTitle: PropTypes.string,
  leftPadding: PropTypes.bool,
  noPaddingBody: PropTypes.bool,
  smallBodyPadding: PropTypes.bool,
  subtitleClick: PropTypes.func,
  noBottomMargin: PropTypes.bool,
  children: PropTypes.any,
  cardTitleProps: PropTypes.any,
  dataAutomationSubtitle: PropTypes.string,
  dataAutomationTitle: PropTypes.string,
};

const defaultProps = {
  noPaddingBody: true,
  noBottomMargin: false,
};

const ContentWrapper = (props) => {
  const {
    title,
    subtitle,
    className,
    classNameTitle,
    classNameSubtitle,
    bodyClassName,
    leftPadding,
    noPaddingBody,
    smallBodyPadding,
    subtitleClick,
    noBottomMargin,
    children,
    cardTitleProps,
    dataAutomationSubtitle,
    dataAutomationTitle,
    ...attributes
  } = props;

  const classes = mapToCssModules(classNames('content-body', 'border-0', className));

  const classesSubtitle = mapToCssModules(classNames('card-subtitle', classNameSubtitle));

  const classesTitle = mapToCssModules(classNames(leftPadding ? 'pleft-title' : false, classNameTitle));

  const bodyClasses = mapToCssModules(
    classNames(
      noBottomMargin ? false : 'mb-3',
      noPaddingBody ? 'p-0' : false,
      smallBodyPadding ? 'small-px' : false,
      bodyClassName
    )
  );

  return (
    <Card className={classes} {...attributes}>
      <CardTitle className={classesTitle} {...cardTitleProps} data-automation={dataAutomationTitle}>
        {title}
      </CardTitle>

      {subtitle && (
        <CardTitle
          className={classesSubtitle}
          onClick={subtitleClick}
          data-automation={dataAutomationSubtitle || 'content_wrapper_click'}
        >
          {subtitle}
        </CardTitle>
      )}

      <CardBody className={bodyClasses}>{children}</CardBody>
    </Card>
  );
};

ContentWrapper.defaultProps = defaultProps;
ContentWrapper.propTypes = propTypes;

export default ContentWrapper;
