import Cookies from 'universal-cookie';
import { createSlice } from '@reduxjs/toolkit';

const cookies = new Cookies();

export const initialState = {
  activeCar: null,
};

export const app = createSlice({
  name: 'car',
  initialState: initialState,
  reducers: {
    setActiveCar: (state, action) => {
      state.activeCar = action.payload;
    },
  },
});

export const { setActiveCar } = app.actions;
export const getActiveCar = (state) => state?.car?.activeCar;

export default app.reducer;
