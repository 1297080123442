import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from '../utils';

const propTypes = {
  tag: tagPropType,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  size: PropTypes.string,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func]),
};

const defaultProps = {
  tag: 'div',
};

const MobileWrapper = (props) => {
  const { className, cssModule, size, tag: Tag, innerRef, ...attributes } = props;

  const classes = mapToCssModules(classNames('wrapper', size ? `wrapper-${size}` : false, className), cssModule);

  return <Tag {...attributes} className={classes} ref={innerRef} />;
};

MobileWrapper.propTypes = propTypes;
MobileWrapper.defaultProps = defaultProps;

export default MobileWrapper;
