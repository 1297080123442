import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from '../utils';

const propTypes = {
  color: PropTypes.string,
  pill: PropTypes.bool,
  size: PropTypes.string,
  tag: tagPropType,
  fontNormal: PropTypes.bool,
  fontColor: PropTypes.string,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  cssModule: PropTypes.object,
};

const defaultProps = {
  color: 'secondary',
  pill: false,
  size: 'md',
  tag: 'span',
};

const Badge = (props) => {
  let { className, cssModule, color, size, innerRef, fontNormal, fontColor, pill, tag: Tag, ...attributes } = props;

  const classes = mapToCssModules(
    classNames(
      className,
      'badge',
      `bg-${color}`,
      fontNormal ? 'fw-normal' : false,
      fontColor ? `text-${fontColor}` : false,
      size ? `badge-${size}` : false,
      pill ? 'rounded-pill' : false
    ),
    cssModule
  );

  if (attributes.href && Tag === 'span') {
    Tag = 'a';
  }

  return <Tag {...attributes} className={classes} ref={innerRef} />;
};

Badge.propTypes = propTypes;
Badge.defaultProps = defaultProps;

export default Badge;
