import React, { useRef, useState, useEffect } from 'react';
import { Input, Modal, ModalBody, ModalHeader, Col, Row } from 'reactstrap';
import API from '@utils/API';
import SelectAsyncField from '@components/field/SelectAsyncField';
import { Button, Label } from '../otoklix-elements';
import { useHistory } from 'react-router-dom';
import { isUndefined } from 'lodash';

const ChooseCarModal = (props) => {
  const { modal, onSave } = props;
  const api = new API('v2');
  const inputRef = useRef();
  const history = useHistory();

  const [selectedCustomerCar, setSelectedCustomerCar] = useState();
  const [selectedNewCar, setSelectedNewCar] = useState();
  const [customerCarError, setCustomerCarError] = useState('');
  const [newCarError, setNewCarError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const optionsNewCar = {
    id: 0,
    label: '+ Kendaraan Baru',
    value: 0,
  };

  const fetchCars = async (q) => {
    try {
      const res = await api.get(`v2/gms/car/variant/search?query=${q}`);
      const data = res?.data?.data;
      const transformedOptions = data.map((item) => {
        let label = `${item?.model} - ${item?.variant}`;
        return {
          ...item,
          label: label,
          value: `${item?.brand} - ${item?.id}`,
        };
      });
      return transformedOptions;
    } catch (err) {
      console.error(err);
    }
  };

  const fetchCustomerCars = (q) => {
    if (q) {
      return api.get(`v2/gms/car/search/customer-car?query=${q}`).then((res) => {
        const data = res?.data?.data;
        const transformedOptions = data.map((item) => {
          let label = item?.license_plate
            ? `${item?.license_plate} - ${item?.model} ${item?.variant} (${item?.customer?.name})`
            : `${item?.model} ${item?.variant}`;
          return {
            ...item,
            label: label,
            value: `${item?.brand} - ${item?.id ?? item?.car_details?.id}`,
          };
        });
        return [...transformedOptions];
      });
    }
  };

  const handleChangeCustomerCar = (value) => {
    setSelectedCustomerCar(value);
    if (value) setCustomerCarError('');
  };

  const handleChangeCar = (value) => {
    const tempValue = {
      ...value,
      car_details: {
        id: value?.id,
      },
    };
    setSelectedNewCar(tempValue);
    if (value) setNewCarError('');
  };

  const handleClickCreate = () => {
    handleChangeCustomerCar(optionsNewCar);
    setSelectedNewCar({});
  };

  const validation = () => {
    let isValid = true;

    if (isUndefined(selectedCustomerCar?.id)) {
      setCustomerCarError('Harus memilih Plat Nomor!');
      isValid = false;
    }

    if (selectedCustomerCar?.id && !selectedCustomerCar?.id && !selectedNewCar?.value) {
      setCustomerCarError('Harus memilih Kendaraan!');
      isValid = false;
    }

    if (selectedCustomerCar?.id && selectedCustomerCar?.id == 0 && !selectedNewCar?.value) {
      setNewCarError('Harus memilih Kendaraan!');
      isValid = false;
    }

    if (selectedCustomerCar?.id == 0 && !selectedNewCar?.value) {
      setNewCarError('Harus memilih Kendaraan!');
      isValid = false;
    }

    return isValid;
  };

  const onSubmit = () => {
    setIsLoading(true);
    let isValid = validation();
    if (isValid) {
      if (typeof onSave === 'function')
        onSave(
          selectedCustomerCar?.id === 0 || !selectedCustomerCar?.id ? selectedNewCar : selectedCustomerCar,
          selectedCustomerCar?.customer,
          true
        );
    }
  };

  const onOpenModal = () => {
    inputRef?.current?.focus();
  };

  useEffect(() => {
    inputRef?.current?.focus();
  }, [selectedNewCar]);

  return (
    <Modal isOpen={modal} centered className="choose-car-modal" onOpened={onOpenModal}>
      <ModalHeader>Masukkan Data Pelanggan</ModalHeader>
      <ModalBody>
        <Row>
          <Label className="label-required fw-bold">Cari Plat Nomor</Label>
        </Row>
        <Row>
          <SelectAsyncField
            cacheOptions
            defaultOptions={[]}
            value={selectedCustomerCar}
            getOptionLabel={(e) => e?.label}
            getOptionValue={(e) => e?.value}
            loadOptions={fetchCustomerCars}
            onChange={handleChangeCustomerCar}
            menuPlacement="top"
            placeholder="Pilih Nomor Polisi"
            disabled={isLoading}
            errorMessage={customerCarError}
            invalid={!!customerCarError}
            isCreate
            textLabelCreate="Kendaraan Baru"
            onClickCreate={() => handleClickCreate()}
          />
        </Row>
        <div style={{ display: selectedCustomerCar?.id === 0 ? 'block' : 'none' }}>
          <Row>
            <Label className="label-required fw-bold mt-3">Pilih Merek Kendaraan</Label>
          </Row>
          <Row>
            <SelectAsyncField
              cacheOptions
              defaultOptions={[]}
              value={selectedNewCar}
              getOptionLabel={(e) => e?.label}
              getOptionValue={(e) => e?.value}
              loadOptions={fetchCars}
              onChange={handleChangeCar}
              menuPlacement="top"
              placeholder="Pilih Mobil"
              disabled={isLoading}
              errorMessage={newCarError}
              invalid={!!newCarError}
              selectAsyncReff={inputRef}
            />
          </Row>
        </div>
        {selectedCustomerCar?.id && selectedCustomerCar?.id !== 0 ? (
          <>
            <Row>
              <Label className="label-required fw-bold mt-3">Data Pelanggan</Label>
            </Row>
            <Row className="px-3">
              <Input
                disabled
                onChange={(e) => handleChangeUserName(e.target.value)}
                value={`${selectedCustomerCar?.customer?.name} - ${selectedCustomerCar?.customer?.phone_number}`}
              />
            </Row>
          </>
        ) : (
          ''
        )}

        <div className="d-flex justify-content-end mt-2">
          <Button outline color="primary" size="sm" onClick={() => history.push('/order')}>
            Kembali
          </Button>
          <Button color="primary" className="ml-2" size="sm" onClick={onSubmit}>
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ChooseCarModal;
