import React, { useEffect, useState, useRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Button, FormGroup, Label, Input, Row } from 'reactstrap';
import CarInspectionService from '@services/car-inspection';
import { useForm, Controller } from 'react-hook-form';
import useAlert from '@hooks/useAlert';

const inspectionValue = [
  { label: 'Aman', value: 100, color: '#16A163' },
  { label: 'Siaga', value: 80, color: '#F5C518' },
  { label: 'Kritis', value: 40, color: '#FA5343' },
];
const CreateCarInspectionModal = (props) => {
  const formRef = useRef();
  const { setAlert } = useAlert();

  const { modal, onSuccess, toggle, bookingData, source } = props;
  const [disabledButton, setDisabledButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [inspectionPoint, setInspectionPoint] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const isDetailSource = source === 'detail';
  const openConfirmationModal = () => {
    const formValues = [];

    inspectionPoint.forEach((item) => {
      const values = {
        inspection_point_id: item.id,
        value: getValues(`inspection_point[${item.id}]`) || null,
        note: getValues(`note[${item.id}]`) || '',
      };

      if (values.value !== null) {
        formValues.push(values);
      }
    });
    var mileage = getValues(`mileage`) || null;
    if (formValues.length == 0) {
      setAlert('Titik inspeksi wajib diisi salah satu !', 'failed');
    } else if (mileage == null) {
      setAlert('Kilometer wajib diisi !', 'failed');
    } else {
      setIsConfirmationModalOpen(true);
    }
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleConfirmation = () => {
    closeConfirmationModal();
    handleSubmit(onSubmit)();
  };

  const {
    reset,
    control,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const handleRadioChange = (itemIndex, selectedValue) => {
    const newSelectedValues = [...selectedValues];
    newSelectedValues[itemIndex] = selectedValue;
    setSelectedValues(newSelectedValues);
  };

  const fetchInspectionPoint = async () => {
    const response = await CarInspectionService.getPoint();
    const data = response?.data?.data;
    setInspectionPoint(data);
  };

  const getCurrentDateTimeInGMT7 = () => {
    const now = new Date();
    const gmt7Offset = 7 * 60;
    const gmt7Time = new Date(now.getTime() + gmt7Offset * 60 * 1000);
    return gmt7Time.toISOString().slice(0, 16);
  };

  const [initialDateTime, setInitialDateTime] = useState(getCurrentDateTimeInGMT7());

  const fetchInspectionDetail = async (userCarId) => {
    const response = await CarInspectionService.getDetail(userCarId);
    const data = response?.data?.data;
    if (response?.status == 200) {
      data?.car_health?.forEach((healthItem) => {
        var condition_value = null;
        if (healthItem.condition != null) {
          if (healthItem.condition <= 40) {
            condition_value = 40;
          } else if (healthItem.condition > 40 && healthItem.condition <= 80) {
            condition_value = 80;
          } else if (healthItem.condition > 80 && healthItem.condition <= 100) {
            condition_value = 100;
          }
        }
        const inspectionPoint = `inspection_point[${healthItem.inspection_point_id}]`;
        const inspectionNote = `note[${healthItem.inspection_point_id}]`;
        setValue(inspectionPoint, condition_value);
        setValue(inspectionNote, healthItem.description);
      });

      setValue('summary', data?.summary);
      if (data?.last_inspection_datetime != null) {
        const lastInspectionDateTime = new Date(data?.last_inspection_datetime);

        const year = lastInspectionDateTime.getFullYear();
        const month = (lastInspectionDateTime.getMonth() + 1).toString().padStart(2, '0');
        const day = lastInspectionDateTime.getDate().toString().padStart(2, '0');
        const hours = lastInspectionDateTime.getHours().toString().padStart(2, '0');
        const minutes = lastInspectionDateTime.getMinutes().toString().padStart(2, '0');

        const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;

        setInitialDateTime(formattedDateTime);
      }
    }
  };

  const onSubmit = async (data) => {
    const formValues = [];

    inspectionPoint.forEach((item) => {
      const values = {
        inspection_point_id: item.id,
        value: getValues(`inspection_point[${item.id}]`) || null,
        note: getValues(`note[${item.id}]`) || '',
      };

      if (values.value !== null) {
        formValues.push(values);
      }
    });

    const payload = {
      user_car_id: bookingData?.user_car?.id,
      workshop_id: bookingData?.workshop?.id,
      origin: bookingData?.workshop?.slug,
      booking_id: bookingData?.booking?.booking_id,
      inspection_date: data.inspection_date,
      mileage: data.mileage,
      summary: data.summary || '',
      inspection_points: formValues,
    };

    setDisabledButton(true);
    const response = await CarInspectionService.create(payload);
    if (response?.status == 200) {
      setDisabledButton(false);
      setErrorMessage('');
      onSuccess(response?.data?.data);
      closeConfirmationModal();
      reset();
    } else {
      setErrorMessage('Inspeksi gagal.');
      setDisabledButton(false);
      setTimeout(() => {
        setErrorMessage('');
      }, 2000);
    }

    formRef.current.submit();
  };

  const toggleModal = () => {
    toggle('close');
  };

  useEffect(() => {
    fetchInspectionPoint();
    if (bookingData) {
      if (source != 'detail') {
        setValue('mileage', bookingData?.user_car?.mileage);
      }
      fetchInspectionDetail(bookingData?.user_car?.id);
    }
    setValue('inspection_date', initialDateTime);
  }, [initialDateTime, bookingData, setValue]);

  return (
    <Modal
      className="modal-dialog-centered"
      style={{ borderRadius: '2px' }}
      isOpen={modal}
      toggle={() => toggleModal()}
      size="lg"
      scrollable
      centered
    >
      <ModalHeader style={{ backgroundColor: '#002EB4', height: '65px', position: 'relative' }}>
        <span style={{ color: '#FFFFFF' }}>Pengisian Data Inspeksi</span>
        <span
          style={{
            cursor: 'pointer',
            fontSize: '1.5em',
            position: 'absolute',
            right: '15px',
            top: '50%',
            transform: 'translateY(-50%)',
            color: '#FFFFFF',
          }}
          onClick={toggleModal}
        >
          &times;
        </span>
      </ModalHeader>
      <ModalBody>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <FormGroup row className="align-items-center">
                <Label sm={4} className="required">
                  Booking ID
                </Label>
                <Col sm={8}>
                  <Input type="text" value={bookingData?.booking?.booking_code} disabled />
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Label sm={4} className="required">
                  KM
                </Label>
                <Col sm={8}>
                  <Controller
                    name="mileage"
                    control={control}
                    rules={{ required: 'KM wajib diisi' }}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <Input
                          onChange={(e) => {
                            const newValue = Math.max(0, parseInt(e.target.value, 10));
                            onChange(newValue);
                          }}
                          type="number"
                          value={value}
                          disabled={isDetailSource}
                        />
                        {errors.mileage && <div className="text-danger">{errors.mileage.message}</div>}
                      </>
                    )}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup row className="align-items-center">
                <Label sm={4} className="required">
                  Nama Bengkel
                </Label>
                <Col sm={8}>
                  <Input type="text" value={bookingData?.workshop?.name} disabled />
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Label sm={4} className="required">
                  Tanggal Inspeksi
                </Label>
                <Col sm={8}>
                  <Controller
                    name="inspection_date"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Input
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        type="datetime-local"
                        value={value || initialDateTime}
                        disabled={isDetailSource}
                      />
                    )}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <hr />
          {inspectionPoint
            ? inspectionPoint.map((item, index) => (
                <>
                  <Row style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <Col sm={4}>
                      <Label>{`${index + 1}. ${item?.name}`}</Label>
                    </Col>
                    <Col sm={8}>
                      {inspectionValue.map((point, i) => (
                        <FormGroup check inline key={i} className="align-items-center mr-5">
                          <Label check>
                            <Controller
                              name={`inspection_point[${item?.id}]`}
                              control={control}
                              defaultValue={selectedValues[index] || ''}
                              render={({ field }) => (
                                <Input
                                  type="radio"
                                  className="form-check-input"
                                  value={point?.value}
                                  checked={field.value === point?.value}
                                  onChange={() => {
                                    field.onChange(point?.value);
                                    handleRadioChange(index, point?.label);
                                  }}
                                  style={{
                                    backgroundColor: field.value === point?.value ? point?.color : '',
                                    borderColor: field.value === point?.value ? point?.color : '',
                                    pointerEvents: isDetailSource ? 'none' : 'auto',
                                  }}
                                  onClick={(e) => {
                                    if (isDetailSource) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              )}
                            />
                            {point?.label}
                          </Label>
                        </FormGroup>
                      ))}
                    </Col>
                  </Row>
                  <Row style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <Col sm={4}></Col>
                    <Col sm={8}>
                      <FormGroup key={item?.id}>
                        <Controller
                          name={`note[${item?.id}]`}
                          control={control}
                          rules={{ maxLength: { value: 200, message: 'Maksimal 200 karakter' } }}
                          render={({ field: { value, onChange } }) => (
                            <>
                              <Input
                                onChange={onChange}
                                maxLength={200}
                                value={value}
                                type="text"
                                placeholder="Catatan (maksimal 200 karakter)"
                                disabled={isDetailSource}
                              />
                              {errors[`note[${item?.id}]`] && (
                                <div className="text-danger">{errors[`note[${item?.id}]`].message}</div>
                              )}
                            </>
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr />
                </>
              ))
            : 'loading'}
          <Row style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            <Col sm={4}>Catatan</Col>
            <Col sm={8}>
              <FormGroup>
                <Controller
                  name="summary"
                  control={control}
                  rules={{ maxLength: { value: 1000, message: 'Maksimal 1000 karakter' } }}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <Input
                        type="textarea"
                        style={{ fontSize: '12px' }}
                        value={value}
                        maxLength={1000}
                        onChange={onChange}
                        disabled={isDetailSource}
                        placeholder="Catatan (maksimal 1000 karakter)"
                      />
                      {errors.summary && <div className="text-danger">{errors.summary.message}</div>}
                    </>
                  )}
                />
              </FormGroup>
              <div className="pl-4" style={{ fontSize: '11px' }}>
                Silahkan isi rangkuman hasil inspeksi Anda di kolom ini. Jika ada kondisi tambahan selain{' '}
                {inspectionPoint?.length} titik inspeksi, silahkan ditulis juga.
              </div>
            </Col>
          </Row>
          {errorMessage && <div className={`alert alert-danger`}>{errorMessage}</div>}
          {!isDetailSource && (
            <div className="float-right mt-4 pb-4">
              <Button color="primary" outline size="sm" disable={disabledButton} onClick={() => toggleModal()}>
                Kembali
              </Button>{' '}
              <span className="ml-2"></span>
              <Button color="primary" size="sm" disabled={disabledButton} onClick={openConfirmationModal}>
                Simpan
              </Button>
            </div>
          )}
          <ConfirmationModal
            isConfirmationModalOpen={isConfirmationModalOpen}
            closeConfirmationModal={closeConfirmationModal}
            submitForm={handleSubmit(onSubmit)}
            bookingCode={bookingData?.booking?.booking_code}
            isDisabled={disabledButton}
          />
        </form>
      </ModalBody>
    </Modal>
  );
};

export default CreateCarInspectionModal;

const ConfirmationModal = ({
  isConfirmationModalOpen,
  closeConfirmationModal,
  submitForm,
  bookingCode,
  isDisabled,
}) => {
  return (
    <Modal isOpen={isConfirmationModalOpen} toggle={closeConfirmationModal} centered>
      <ModalHeader style={{ backgroundColor: '#002EB4', position: 'relative' }}>
        <span style={{ color: '#FFFFFF' }}>Simpan Hasil Inspeksi?</span>
      </ModalHeader>
      <ModalBody>
        Simpan hasil inspeksi <strong>Booking Code {bookingCode}?</strong>
      </ModalBody>{' '}
      <ModalFooter style={{ backgroundColor: '#FFFFFF' }}>
        <Button color="primary" outline onClick={closeConfirmationModal}>
          Batalkan
        </Button>
        <Button color="primary" disabled={isDisabled} onClick={submitForm}>
          Ya, Simpan
        </Button>
      </ModalFooter>
    </Modal>
  );
};
